

    export class Buffer {

        public list: Array<string>;

        public constructor(value: string) {
            this.list = (value) ? value.split('') : new Array<string>();
        }

            // Take and remove the first element in the buffer
        public Take(): string {
            if (this.list.length > 0) {
                const res = this.list.shift();
                return (res) ? <string>res : '';
            } else {
                return '';
            }
        }

        // Returns the first element in the buffer but does not remove it
        public Peek(): string {
            if (this.list.length < 1) {
                return '';
            } else  {
                return this.list[0];
            }
        }

        // Inserts an element in th buffer at a given position or index
        public insert(index: number, item: string): void {
            this.list.splice(index, 0, item);
        }

        /// <summary> Test if the buffer is empty </summary>
        public get isEmpty(): boolean {
            {
                return this.list.length < 1;
            }
        }

        /// <summary> Returns the length of the buffer </summary>
        public get Count(): number {
            {
                return this.list.length;
            }
        }


    } // end class
