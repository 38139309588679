import { QuestionDataTypes } from './types';
import { NameValue } from 'common/tools';

export class Question {

	static assign(source: Record<string, any>): Question {
		const q = new Question();

		if ('id' in source) {
			q.id = (<any> source)['id'];
		} else { throw new Error('assign Question: id is required'); }

		if ('caption' in source) {
			q.caption = (<any> source)['caption'];
		} else { throw new Error('assign Question: caption is required'); }

		if ('description' in source) {
			q.description = (<any> source)['description'];
		}

		if ('icon' in source) {
			q.icon = (<any> source)['icon'];
		}

		if ('dataType' in source) {
			q.dataType = (<any> source)['dataType'];
		} else { throw new Error('assign Question: dataType is required'); }

		if ('valueList' in source) {
			const a = (<any> source)['valueList'];
			if (Array.isArray(a)) {
				q.valueList = new Array<string>();
				a.forEach((value) => { if (q.valueList) { q.valueList.push(value); } });
			}
		}

		if ('defaultValue' in source) {
			q.defaultValue = (<any> source)['defaultValue'];
		} else { q.defaultValue = undefined; }

		if ('value' in source) {
			q.value = (<any> source)['value'];
		} else { q.value = undefined; }

		if ('enabled' in source) {
			q.enabled = (<any> source)['enabled'];
		} else { q.enabled = true; }

		if ('active' in source) {
			q.active = (<any> source)['active'];
		} else { q.active = false ; }

		return q;
	}

	public id: string = '';
	public caption: string = '';
	public description?: string;
	public icon?: string;
	public dataType: QuestionDataTypes = 'string';
	public valueList?: Array<string>;
	public defaultValue?: any;
	public value?: any;
	public enabled: boolean = false;
	public active: boolean = false;

	public get toNameValue(): Array<NameValue> {
		const nv = new Array<NameValue>();
		if (this.valueList) {
			this.valueList.forEach((value: string) => {
				nv.push(NameValue.createUnformatted(value));
			});
		}
		return nv;
	}

}


export class Answer {

	constructor(key?: string, value?: string) {
		this.key = (key) ? key : undefined;
		this.value = (key) ? value : undefined;
	}

	public key?: string;
	public value?: string;
}
