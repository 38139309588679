// ----------------------------------------------------------------------------------
// Dog Names
// ----------------------------------------------------------------------------------
// rating not implemented


import { HttpClient } from '@angular/common/http';
import { of, throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Tools } from '@common/tools';
import { Gender } from '@common/classes/types';
import { Favorites } from './favorites';
import { IKey } from '@common/classes/interfaces';


export class DogNames {

	private _males: Array<DogName>;
	private _females: Array<DogName>;

	constructor(private http: HttpClient) {
	}

	public get females(): Observable<Array<DogName>> {
		if (this._females) {
			return of(this._females);
		} else {
			return this.load('DogNames_Females.txt');
		}
	}

	public get males(): Observable<Array<DogName>> {
		if (this._males) {
			return of(this._males);
		} else {
			return this.load('DogNames_Males.txt');
		}
	}

	public getNameByRange(range: string, gender: Gender): Observable<Array<DogName>> {
		let sub: Observable<Array<DogName>>;

		if (gender === 'female') {
			sub = this.females.pipe(
				map((names: Array<DogName>) => {
					return this.filterNames(range, names);
				})
			);
		} else {
			sub = this.males.pipe(
				map((names: Array<DogName>) => {
					return this.filterNames(range, names);
				})
			);
		}
		return sub;
	}

	public getRandomNames(gender: Gender, count: number = 1): Observable<Array<DogName>> {
		let sub: Observable<Array<DogName>>;

		if (gender === 'female') {
			sub = this.females.pipe(
				map((names: Array<DogName>) => {
					return this.randomNames(names, count);
				})
			);
		} else {
			sub = this.males.pipe(
				map((names: Array<DogName>) => {
					return this.randomNames(names, count);
				})
			);
		}
		return sub;
	}

	private filterNames(range: string, source: Array<DogName>): Array<DogName> {
		range = range.toLowerCase().trim();
		if (range === 'all') { return source; }
		if (range.length === 1) { range = range + '-' + range; }
		if (range.length !== 3) { throw new Error('Invalid range : ' + range); }

		const res = new Array<DogName>();
		for (const dName of source) {
			const l = dName.name[0].toLowerCase();
			if (l >= range[0] && l <= range[2]) { res.push(dName); }
		}
		return res;
	}

	private randomNames(source: Array<DogName>, count: number = 1): Array<DogName> {
		const l = source.length;
		const lst = new Array<DogName>();
		for (let i = 0; i < count; i++) {
			const index = Tools.randomInteger(0, source.length);
			lst.push(source[index]);
		}
		return lst;
	}

	// load dog names from asset files
	private load(fileName: string): Observable<Array<DogName>> {
		const gender: Gender = (fileName.includes('Females')) ? 'female': 'male';
		const url = '/assets/data/' + fileName;
		const ret = this.http.get<string>(url, { responseType: 'text' as 'json'}).pipe(
			map((r) => {
				r = r.replace(/\r/g, '');
				const res = r.split('\n');
				const names = res.map((value: string) => {
					const name = new DogName(value);
					name.gender = gender;
					return name;
				});
				if (fileName.includes('Females')) { this._females = names; } else { this._males = names; }
				return names;
			}),
			catchError((error: any) => {
				console.error(error);
				return throwError(error);
			})
		);

		return ret;
	}

}

// Dog Name format: name.gender.rating => name.f.4
export class DogName implements IKey{

	public constructor(dogName?: string) {

		if (!dogName) {
			this.name = '';
			this.gender = undefined;
			this.rating = undefined;
		} else {
			const parts = dogName.split('.');
			if (!parts || parts.length < 1 || parts.length > 3) {
				throw new Error('Invalid or missing dog name');
			} else {
				this.name = parts[0];
			}
			if (parts.length > 1) {
				switch(parts[1].toLowerCase()) {
					case 'f': this.gender = 'female'; break;
					case 'm': this.gender = 'male'; break;
					default : this.gender = undefined;
				}
			}
			if (parts.length > 2) {
				if (Number.isInteger(parts[2])) {
					const n = Number.parseInt(parts[2]);
					if (n >=0 && n < 5) { this.rating = n; } else { this.rating = undefined; }
				} else { this.rating = undefined; }
			}
		}
	}

	public name: string;
	public gender?: Gender ;
	public rating?: number;

	private get genderAbbreviation(): string {
		return (this.gender) ? this.gender[0].toLowerCase() : '?';
	}

	public toString(): string {
		return `${this.name}.${this.genderAbbreviation}${(this.rating) ? '.' + this.rating : ''}`;
	}

	public toKey(): string {
		return `${this.genderAbbreviation}.${this.name}`;
	}

}

// export class DogNameSelection3 {

// 	// public static fromFavorites(favs: Favorites): DogNameSelection {
// 	// 	const sel = new DogNameSelection();
// 	// 	if (favs && favs.dogNames) {
// 	// 		sel._names = [...favs.dogNames.names];
// 	// 	}
// 	// 	return sel;
// 	// }

// 	private _names: Array<DogName>;

// 	public constructor() {
// 		this._names = new Array<DogName>();
// 	}

// 	public get females(): Array<DogName> { return this._names.filter((n: DogName) => {
// 		return (n.gender && n.gender === 'female');
// 	}); }

// 	public get males(): Array<DogName> { return this._names.filter((n: DogName) => {
// 		return (n.gender && n.gender === 'male');
// 	}); }

// 	public get names(): Array<DogName> {
// 		return this._names;
// 	}

// 	public find(name: DogName): DogName | undefined {
// 		return this._names.find((n: DogName) => {
// 			return (n.gender === name.gender && n.name === name.name);
// 		});
// 	}

// 	public findIndex(name: DogName): number {
// 		const idx = this._names.findIndex((n: DogName) => {
// 			return (n.gender === name.gender && n.name === name.name);
// 		});
// 		return idx;
// 	}

// 	public has(name: DogName): boolean {
// 		return (this.findIndex(name) >= 0);
// 	}

// 	public deselect(dName: DogName): void {
// 		const found = this.findIndex(dName);
// 		if (found >= 0) { this._names.splice(found, 1); }
// 	}

// 	public select(dName: DogName): void {
// 		const found = this.findIndex(dName);
// 		if (found < 0) { this._names.push(dName); }
// 	}

// 	public isSelected(dName: DogName): boolean { return this.has(dName); }

// 	public toggleSelection(dName: DogName): void {
// 		if (this.isSelected(dName)) {
// 			this.deselect(dName);
// 		} else {
// 			this.select(dName);
// 		}
// 	}

// }
