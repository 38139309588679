import { Injector, isDevMode } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { ibSupportedCountries, SearchFuzzyResponse } from './geoLocation';
import { Sizes } from './types';

// Defaults
// ---------------------------------------------------------------------------------------------


export enum SpecialValuesGUID {
	DefaultGUID = 0,
	iBreeder = 1,
	None = 2,
	All = 3,
	Visitor = 4,
	Master = 9,
	AllBreeds = 10,
	VoidLitter = 20,
	DogCategory = 30,
	One = 251,
	Two = 252,
	Three = 253,
	Empty = 255
}

export class Defaults {

	private static _uid = '00000000-0000-0000-0001-000000000000';
	private static _guestUID = '00000000-0000-0000-0002-000000000000';
	private static _visitorUID = '00000000-0000-0000-0003-000000000000';
	private static _blankUID = '00000000-0000-9999-0000-000000000000';
	private static _supportedCountries: ibSupportedCountries;

	public static iBreederAccountID = 'USIB0000';

	public static get BlankUID(): string { return Defaults._blankUID; }

	public static get iBreederUID(): string { return Defaults._uid; }

	public static get VisitorUID(): string { return Defaults._visitorUID; }

	public static SpecialGuid(type: SpecialValuesGUID): string {
		switch (type) {
			case SpecialValuesGUID.DefaultGUID:
				return '00000000-0000-0000-0001-000000000000';
			case SpecialValuesGUID.iBreeder: return Defaults._uid;
			case SpecialValuesGUID.Visitor: return Defaults._visitorUID;
			case SpecialValuesGUID.All:
				return '00000000-0000-0000-0001-900000000000';
			case SpecialValuesGUID.None:
				return '00000000-0000-0000-0000-000000000000';
			case SpecialValuesGUID.AllBreeds:
				return '00000000-0000-0000-0001-100000000000';
			case SpecialValuesGUID.VoidLitter:
				return '00000000-0000-0000-0001-200000000000';
			case SpecialValuesGUID.DogCategory:
				return '00000000-0000-0000-0001-300000000000';
			case SpecialValuesGUID.Empty:
				return '00000000-0000-0000-0000-000000000000';
			default: throw new Error('Internal Error: SpecialGUID out of bounds');
		}
	}

	public static get guestUid(): string { return Defaults._guestUID; }

	public static get BlobAccountName(): string { return 'iBreeder1'; }

	/// <summary> Blob Domain -iBreeder </summary>
	public static get BlobDomain(): string { return Defaults.BlobAccountName + '.blob.core.windows.net'; }

	public static get BlobRoot(): string { return this.BlobDomain + '/'; }

	public static get apiKey(): string { return environment.key; }

	// returns the root url for the api server configured for dev and prod
	public static get apiUrl(): string { return environment.apiUrl;	}

	// returns the root url for the client
	public static clientUrl: string;

	// returns the database name for the Function Server
	public static apiDatabaseName = 'iBreeder';

	// returns the database name for the Function Server takes devMode into account but not staging
	public static get apiContainerName(): string {
		return (isDevMode) ? 'dev' : 'main';
	};

	// returns deployment state
	public static deployment = (isDevMode) ? 'dev' : 'prod';

	// used to inject services in regular classes - initialized with default injector in app.component
	public static injectorInstance: Injector;

	// access to countries supported by iBreeder
	public static get countries(): ibSupportedCountries {
		if (! Defaults._supportedCountries) { Defaults._supportedCountries = new ibSupportedCountries(); }
		return Defaults._supportedCountries;
	}


} // end class

