//  Contact info
// ------------------------------------------------------------------------------
import { Address } from './address';
import { eMail } from './email';
import { Phone } from './phone';
import { PersonDocument } from '../documents/person-document';
import { SocialMediaInfo } from './social-media-info';

export class ContactInfo {

	public static assign(source: Record<string, any>): ContactInfo {

		const t = new ContactInfo();

		if ('locations' in source) {
			const s = <Array<any>> (<any> source)['locations'];
			s.forEach((v) => { t.locations.push(Location.assign(v)); });
		}

		if ('phones' in source) {
			const s = <Array<any>> (<any> source)['phones'];
			s.forEach((v) => { t.phones.push(Phone.assign(v)); });
		}

		if ('emails' in source) {
			const s = <Array<any>> (<any> source)['emails'];
			s.forEach((v) => { t.emails.push(eMail.assign(v)); });
		}

		// if (source.hasOwnProperty('team')) {
		// 	const s = <Array<any>> (<any> source)['team'];
		// 	s.forEach((v) => { t.team.push(PersonDocument.assign(v)); });
		// }

		if ('socialMedia' in source) {
			const s = <Array<any>> (<any> source)['socialMedia'];
			s.forEach((v) => { t.socialMedia.push(SocialMediaInfo.assign(v)); });
		}

		return t;
	}

	constructor() {
		this.locations = new Array<Location>();
		this.phones = new Array<Phone>();
		this.emails = new Array<eMail>();
		this.teamMembers = Array<PersonDocument>();
		this.socialMedia = Array<SocialMediaInfo>();
	}

	public locations: Array<Location>;
	public phones: Array<Phone>;
	public emails: Array<eMail>;
	public teamMembers: Array<PersonDocument>;
	public socialMedia: Array<SocialMediaInfo>;

	public getLocation(name: string): Location | undefined {
		if (!this.locations) { return undefined; }
		for (const l of this.locations) {
			if (l.name === name) { return l; }
		}
		return undefined;
	}

	public getPhone(name: string): Phone | undefined {
		for (const p of this.phones) {
			if (p.name === name) { return p;}
		}
		if (name === 'Main' && this.phones.length > 0) { return this.phones[0]; } else { return undefined; }
	}

	public getSMSPhone(name?: string): Phone | undefined {
		if (name) {
			for (const p of this.phones) {
				if (p.name === name && p.smsEnabled) { return p; }
			}
		}
		for (const p of this.phones) {
			if (p.smsEnabled) { return p; }
		}
		return undefined;
	}

	public getEMail(name: string): eMail | undefined {
		for (const e of this.emails) {
			if (e.name === name) { return e; }
		}
		if (name === 'Main' && this.emails.length > 0) { return this.emails[0]; } else { return undefined; }
	}


	public getTeamMember(name: string): PersonDocument | undefined {
		for (const p of this.teamMembers) {
			if (p.name === name) { return p; }
		}
		return undefined;
	}

	public getSocialMedia(name: string): SocialMediaInfo | undefined {
		for (const s of this.socialMedia) {
			if (s.name === name) { return s; }
		}
		return undefined;
	}




}

export class Location {

	public static assign(source: Record<string, any>): Location {

		const t = new Location();

		if ('name' in source) {
			t.name = (<any> source)['name'];
		} else {
			throw Error('\'name\' is required');
		}

		if ('description' in source) {
			t.description = (<any> source)['description'];
		} else {
			t.description = undefined;
		}

		if ('icon' in source) {
			t.icon = (<any> source)['icon'];
		} else {
			t.icon = undefined;
		}

		if ('address' in source) {
			const addr = (<any> source)['address'];
			t.address = Address.assign(addr);
			t.address.name = t.name;
		} else {
			throw Error('\'address\' is required');
		}

		return t;
	}

	constructor(name?: string) {
		this.address = Address.CreateNew();
		this.phones = new Array<string>();
		this.emails = new Array<string>();
		this.teamMembers = new Array<string>();
		if (name) { this.name = name; this.address.name = name; }
	}

	public name: string = '';
	public description?: string;
	public icon?: string;
	public address: Address;
	public phones: Array<string>;
	public emails: Array<string>;
	public teamMembers: Array<string>;
}
