
import { OwnerTypes } from '@entities/parts/owner';




export class Banner {

	public OwnerUID: string = ''
	public OwnerID: string = '';
	public OwnerType: OwnerTypes = OwnerTypes.Blank

	public render(): string {
		let HTML = '';
		return HTML;
	}

}

export class BreedBanner extends Banner {

	public breedName: string = '';
	public caption?: string;
	public rank?: number;
	public group?: string;

	public render(): string {
		let HTML = `
			<div class='ls-layer show-banner' style="top: 100%; left: 0%;">
				<div class='top-line'>
					<div class='left-line'>${this.breedName}</div>
					<div class='right-line'>#${this.rank}</div>
				</div>
				<div class='middle-line'>
					<div class='left-line'>${this.group}</div>
				</div>
				<div class='bottom-line'>
					<div class='right-line'>${this.caption}</div>
				</div>
			</div>`;

		return HTML;
	}
}

export class BreederBanner extends Banner {

}

export class LitterBanner extends Banner {

}

export class DogBanner extends Banner {

}
