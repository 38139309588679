// ------------------------------------------------------------
// Identity Service
// ------------------------------------------------------------

import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, finalize } from 'rxjs/operators';
import   jwtDecode from "jwt-decode";

import { ibCoreService } from '@common/services/core-services/core.service';
import { Tools } from '@common/tools';
import { UserDocument } from './user-document';
import { AuthResponse, Credentials, SignInResponse } from './types';
import { Role, Roles } from './role';
import { Defaults } from '@common/classes/defaults';
import { Owner, OwnerGuest } from '@entities/parts/owner';
import { environment } from 'environments/environment';
import { getUserResponse, getUsersResponse, IdentityData } from './identityData';
import { Icons } from '@common/classes/icons';
import { Validate } from '@common/classes/data/validate';

@Injectable({providedIn: 'root'})
export class ibIdentityService {

    private pKey: string;
	private rootUserName: string = '';
	private userNamesMatchingRoot: Array<string> = new Array<string>();

    private urlRoot = Defaults.apiUrl;
    private _currentUser: UserDocument | undefined;
    private _signInAttempts: number = 0;

    public currentUser$: BehaviorSubject<UserDocument | undefined>;
    public isAuthenticated$: BehaviorSubject<boolean>;

    constructor(private http: HttpClient,
                private coreService: ibCoreService) {
        this.currentUser$ = new BehaviorSubject<UserDocument | undefined>(undefined);
        this.isAuthenticated$ = new BehaviorSubject<boolean>(false);
    }

    // Returns jwt token public encryption key
    private async getKey(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.pKey) { resolve(this.pKey)};
            const body = { "type": "get-key" };

            const h = {
                iBreeder: environment.key,
            }
            this.http.post<string>(this.urlRoot + 'db-auth', body, { 'headers': new HttpHeaders(h) }).subscribe((ret: string) => {
                console.log(ret);
                this.pKey = ret;
                resolve(this.pKey);
            });
        });
    }

    public getToken(): IdentityData {
        return new IdentityData();
    }

    public get currentUser(): UserDocument | undefined{
        return this._currentUser;
    }

    public set currentUser(value: UserDocument | undefined) {
        this._currentUser = value;
        this.currentUser$.next(value);
        this.isAuthenticated$.next(this.isAuthenticated());
    }

    public canSignIn(): boolean {
        return (this._signInAttempts < 3);
    }

    public signIn(credentials: Credentials): Observable<SignInResponse> {
        if (!credentials) { return of(SignInResponse.badRequest()); }

        if (this._signInAttempts > 2) { return of(SignInResponse.TooManyAttempts()); }
        this._signInAttempts++;

        const body = {
            "type": "sign-in",
            "deployment": "dev",
            "username": credentials.username,
            "password": credentials.password,
            "persistent": credentials.persistent
        }

        const h = {
            iBreeder: environment.key,
        }

        const icon = Icons.authIcon('renew');
        icon.caption.text= 'signing in';

        this.coreService.startProgress( icon);

		alert('URL Root ' + this.urlRoot);

        const obs = this.http.post(this.urlRoot + "db-auth", body, { headers: new HttpHeaders(h), observe: 'response' }).pipe(
            map((res: any) => {
                const response = SignInResponse.assign(res);
                if (response && response.isAuthorized && response.user) {
                    this.currentUser= response.user;
                    // get headers
                    const jwt = res.headers.get('jwt');
                    const expiration = res.headers.get('expiration');
                    const persistent = res.headers.get('persistent');
                    // store headers
                    localStorage.setItem('jwt', jwt);
                    localStorage.setItem('expiration', expiration);
                    localStorage.setItem('persistent', persistent);
                    this.currentUser$.next(this.currentUser);
                    this.isAuthenticated$.next(true);
                } else {
                    // unauthorized -> clear storage
                    localStorage.removeItem("jwt");
                    localStorage.removeItem("expiration");
                    localStorage.removeItem('persistent');
                    this.currentUser = undefined;
                    this.currentUser$.next(undefined);
                    this.isAuthenticated$.next(false);
                }
                return response;
            }),
            catchError((err: HttpErrorResponse) => {
				// keeps stored jwt
                this.currentUser =undefined;
                this.currentUser$.next(undefined);
                this.isAuthenticated$.next(false);
                return of(SignInResponse.faulted(err));
            })
        );
        return obs;
    }

    // renew security token - checks validity and expiration - if user not logged in and persistent true auto login
    public async renew(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
			let progressID: string | undefined
            try {
                const token = localStorage.getItem("jwt");
                const persistent = localStorage.getItem("persistent");
                const h = {
                    iBreeder: environment.key,
                    jwt: (token) ? token : '',
                    persistent: (persistent) ? persistent : 'false'
                }
                const body = { type: 'renew' };
                if (isDevMode()) { (<any>body)['deployment'] = 'dev'; }
				setTimeout(() => {
					progressID = this.coreService.startProgress(Icons.authIcon('renew'), 1000);
				}, 0)

                this.http.post(this.urlRoot + 'db-auth', body, { 'headers': new HttpHeaders(h), observe: 'response' }).pipe(
                    map(async (ret: HttpResponse<any>) => {
                        const returnedHeaders = ret.headers
                        const newToken = returnedHeaders.get('jwt');
                        const expiration = returnedHeaders.get('expiration');
                        const persistent = returnedHeaders.get('persistent');

                        localStorage.setItem('jwt', (newToken) ? newToken : '');
                        localStorage.setItem('expiration', (expiration) ? expiration : '');
                        localStorage.setItem('persistent', (persistent) ? persistent : '');

                        if (newToken) {
                            // token payload not encrypted - public key not needed because token validity is not checked
                            const tokenContent = jwtDecode(newToken);
                            if (tokenContent && typeof tokenContent === 'object') {
                                if ('id' in tokenContent) {
                                    const id = (<any>tokenContent)['id'];
                                    const roles = new Roles((<any>tokenContent)['role']);
                                    const topRole = roles.topRole;
                                    if (!id ) {
                                        if (topRole.userType !== 'guest') {
                                            reject('Received an invalid token: id value and role conflict');
                                        } else {
                                            resolve(true);
                                        }
                                    } else {
                                        try {
                                            const user = await this.getUser(id);
                                            this.currentUser = user;
                                            // this.currentUser$.next(this.currentUser);  // publish user change ?? may need to publish only on user change
                                            resolve(true);
                                        } catch(err) {
                                            reject(`Invalid User ID in Token: ${err}`);
                                        }
                                    }
                                } else {
                                    reject('The token is not valid: the \'id\' property is missing');
                                }
                            } else {
                                reject('The token is not valid: token is not an object');
                            }
                        } else { reject('Backend did not return a token'); }

                    }),
                    catchError((err: any) => {
                        this.currentUser = undefined;
                        localStorage.removeItem('jwt');
                        localStorage.removeItem('expiration');
                        localStorage.removeItem('persistent');

                        if (err instanceof(HttpErrorResponse)) {
                            reject(err.message + "  (" + err.error?.data + ")");
                        } else {
                            reject('Unknown function server error');
                        }
                        return (of(false));
                    }),
                    finalize(()=> {
                        this.currentUser$.next(this.currentUser);
                        this.coreService.endProgress(progressID);
                    })).subscribe()
            } catch(err) {
                reject('Renew encountered an error: ' + err);
            }
        });
    }

    public resetPassword(id: string, password: string): Observable<boolean | string> {
        if (!id || ! password) { return of("Invalid request: credentials are required"); }

        const body = {
            "type": "set-password",
            "deployment": "dev",
            "id": id,
            "password": password,
        }

        const h = {
            iBreeder: environment.key,
        }

        const obs = this.http.post(this.urlRoot + "db-auth", body, { headers: new HttpHeaders(h), observe: 'response' }).pipe(
            map((res: any) => {
				const resp = AuthResponse.assign(res);
				if (resp.isSuccess) {
					return true;
				} else {
					return (resp.error) ? resp.error : 'undefined error';
				}
		}),
		catchError((err: HttpErrorResponse) => {
            return of(err.statusText);
		}));
        return obs;
    }

    // obsolete
    public signOut(): void {

        // clear local storage
        localStorage.removeItem("jwt");
        localStorage.removeItem("expiration");
        localStorage.removeItem("persistent");

        this.currentUser$.next(undefined);
        this.isAuthenticated$.next(false);

        if (this._currentUser) {
            this.currentUser = undefined;

            const body = {
                "type": "sign-out",
                "deployment": "dev",
            }

            const h = {
                iBreeder: environment.key,
            }

            // inform server
            this.coreService.startProgress(Icons.authIcon('renew'));
            this.http.post(this.urlRoot + "db-auth", body, { 'headers': new HttpHeaders(h) }).pipe(
                map(() => {
                    return true;
                }),
                catchError((err: HttpErrorResponse) => {
                    console.error(err.message);
                    return of(err.message);
                }),
                finalize(() => { this.coreService.endProgress(); })
            ).subscribe();
        }
    }

    public isAuthenticated(): boolean {
        return (this._currentUser && this._currentUser.roles.topRole.userType !== 'guest') ? true : false;
    }

    public isInRole(role: string | Array<string>): boolean {

        const userRoles= (this.currentUser) ? this.currentUser.roles : new Roles(['guest']);
        if (userRoles.length < 1) { userRoles.add('guest'); }
        if (Array.isArray(role)) {
            for(const r of role) {
                if (r === 'all') { return true; }
                const matchingRole = userRoles.getRole(r);
                if (matchingRole) { return true; }
            }
            return false;
        } else {
            if (role === 'all') { return true; }
            const targetRole = Role.parse(role);
            const topRole = userRoles.topRole;
            return (topRole.precedenceIndex >= targetRole.precedenceIndex);
        }
    }

    public getUser(id: string): Promise<UserDocument> {
        return new Promise((resolve, reject) => {
            try {
                const body = {
                    "type": "get-user",
                    "deployment": Defaults.deployment,
                    "id": id,
                }

                const h = {
                    iBreeder: environment.key,
                }

                this.http.post<string>(this.urlRoot + 'db-auth', body, { 'headers': new HttpHeaders(h) }).subscribe((ret: any) => {
                    const result = getUserResponse.assign(ret);
                    if (ret.statusCode !== 200) {
                        reject('get User error: ' + result.message);
                    } else {
                        if ( result.user) {
                            resolve(result.user);
                        } else {
                            reject('User not found');
                        }
                    }
                });
            } catch (err) {
                reject('getUser encountered an error: ' + err);
            }
        });
    }

    public getUsers(owner?: Owner): Promise<Array<UserDocument>> {

        if (!owner && this.isAuthenticated()) { owner = this.currentUser?.owner; }

        return new Promise((resolve, reject) => {
            try {
                if ( !owner || !owner.canHaveUsers ) {
                    reject('Missing owner or owner does not support users')
                } else {
                    const body = {
                        "type": "get-user",
                        "deployment": Defaults.deployment,
                        "query":  "SELECT * FROM c WHERE c.DType='UserDocument' AND c.owner.ownerType = @type AND c.owner.uid = @uid",
                        "parameters": [{ "name": "@type", "value": 0 }, { "name": "@uid", "value": '00000000-1515-4000-a000-000000000009' }]
                    }

                    // "query":  "SELECT * FROM c WHERE c.DType='UserDocument' AND c.owner.ownerType = @type and c.owner.uid = @uid ",


                    const h = {
                        iBreeder: environment.key,
                    }

                    this.http.post<string>(this.urlRoot + 'db-auth', body, { 'headers': new HttpHeaders(h) }).subscribe((ret: any) => {
                        if (ret) {
                            try {
                                const result = getUsersResponse.assign(ret);
                                if (result.statusCode < 200 || result.statusCode > 299) {
                                    reject('Error retrieving users - ' + result.message);
                                } else {
                                    resolve(result.users);
                                }
                            } catch (reason: any) {
                                reject('Error retrieving users - ' + reason);
                            }
                        } else {
                            reject('Error retrieving users - no response from server');
                        }
                    });
                }
            } catch (err) {
                reject('getUsers encountered an error: ' + err);
            }
        });
    }

    // overwrites user or create a new user - password required
    public putUser(user: UserDocument, password?: string): Promise<UserDocument> {
        return new Promise((resolve, reject) => {
            try {
                const body = {
                    "type": "put-user",
                    "deployment": Defaults.deployment,
                    "create": true,
                    "password": password,
                    "user": user
                }

                const h = {
                    iBreeder: environment.key,
                }

                this.http.post<string>(this.urlRoot + 'db-auth', body, { 'headers': new HttpHeaders(h) }).subscribe((ret: any) => {
                    if (ret.statusCode !== 200) {
                        reject('put User error: ' + ret.error);
                    } else {
                        const u = UserDocument.assign(ret.data);
                        resolve(ret.data);
                    }
                });
            } catch (err) {
                reject('getMatchingUserNames encountered an error: ' + err);
            }
        });
    }

	public delUser(id: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                const body = {
                    "type": "del-user",
                    "deployment": Defaults.deployment,
                    "id": id,
                }

                const h = {
                    iBreeder: environment.key,
                }

                this.http.post<string>(this.urlRoot + 'db-auth', body, { 'headers': new HttpHeaders(h) }).subscribe((ret: any) => {
                    const result = getUserResponse.assign(ret);
                    if (result.statusCode !== 200) {
                        reject('Del User encountered an error: ' + result.message);
                    } else {
                            resolve(true);
                    }
                });
            } catch (err) {
                reject('Del User encountered an error: ' + err);
            }
        });
    }

    public getAccountId(user: UserDocument): string {
        if (!user) { throw new Error("the user must be specified"); }
        if (!user.firstName || user.firstName.length < 1) { throw new Error("first name must be specified");}
        if (!user.lastName || user.lastName.length < 2) { throw new Error("last name must be specified");}

        const lName = Tools.extractFirstWord(user.lastName,true);
        const id = user.firstName.substr(0, 1).toLowerCase() + lName;

        // need to check with db

        return id;

    }

	// User name can be denomination, phone, email
    public createUser(owner: Owner, username: string, roles: Array<string>): UserDocument {
        const u = this.createDefaultUser();
        u.id= Tools.newGuid();
		u.owner = owner;
        u.username = username;
		if (Validate.isPhone(username)) {
			u.phoneNumber = username;
			u.name = '';
		} else if (Validate.iseMail(username)) {
			u.eMail = username;
			u.name = '';
		} else {
			u.name = username;
		}
        u.roleList = roles;
        return u;
    }

    // returns an array of usernames starting with 'startwith' - case insensitive
    public getMatchingUsernames(startWith: string): Promise<Array<string>> {
        return new Promise((resolve, reject) => {
			if (!startWith || startWith.length  <3 ) {
				reject('root name must have at least 3 characters');
				return;
			}
			if ( this.rootUserName.startsWith(startWith)) {
				resolve(this.userNamesMatchingRoot);
				return;
			}
            try {
				this.rootUserName = startWith;
				this.userNamesMatchingRoot.length = 0;
                const body = {
                    "deployment": Defaults.deployment,
                    "type": "usernames",
                    "username": startWith
                };

                const h = {
                    iBreeder: environment.key,
                }

                this.http.post<string>(this.urlRoot + 'db-auth', body, { 'headers': new HttpHeaders(h) }).subscribe((ret: any) => {
                    if (ret.statusCode !== 200) {
                        throw new Error(ret.error);
                    } else {
						this.userNamesMatchingRoot = ret.data;
                        resolve(ret.data);
                    }
                });
            } catch (err) {
                reject('getMatchingUserNames encountered an error: ' + err);
            }
        });
    }

    private createDefaultUser(): UserDocument {
		const pwd = Tools.generatePassword();
        const u = new UserDocument();
        u.DType="UserDocument";
        u.id = Defaults.BlankUID;
        u.owner = new OwnerGuest();
        u.username = 'guest';
        u.name = u.username;
        u.firstName="";
        u.lastName="";
        u.eMail="";
        u.phoneNumber="";
		u.temporaryPassword = pwd;
        u.created = new Date();
        u.modified = new Date();
        u.changed =true;
        u.changedBy = 'system';
        u.isEMailConfirmed = false;
        u.isPhoneNumberConfirmed=false;
        u.isSMS = false;
        u.locked = false;
        u.persistent =true;
		u.roleList = ['guest'];
        return u;
    }

    // private createMasterUser(): UserDocument {
    //     const u = this.createDefaultUser();
    //     u.id = Defaults.iBreederUID;
    //     u.owner = new OwneriBreeder();
    //     u.username = 'iBreederMaster';
    //     u.name = u.username;
    //     u.firstName="iBreeder";
    //     u.lastName="Master";
    //     u.eMail="info@iBreedr.com";
    //     u.phoneNumber="+1 (954) 757-7564";
    //     u.isEMailConfirmed = true;
    //     u.isPhoneNumberConfirmed=true;
    //     u.isSMS = true;
    //     u.locked = true;
    //     u.persistent =true;
    //     // u.roles.push( new Role(RoleEnum.iBreederOwner));
    //     return u;
    // }


}



