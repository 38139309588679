

<div id='logo' class='clickable' (click)='onLogoClick()'>
	<img src=".\assets\images\ibreeder-logo-2.0.svg" data-toggle='tooltip' title='Home' >
	<div>
		<h6>The best <strong class='ib-color-green-dark'>purebreds </strong></h6>
		<h6 class='last'>directly from the best breeders</h6>
		<h6 class='last-s'>directly from their breeder</h6>
	</div>
</div>
<h1 id='title'>iBreeder Admin</h1>
