// ----------------------------------------
// BreederDocument class
// ----------------------------------------

import { ibDocument } from '@common/entities/documents/ib-document';
import { Owner, OwnerBlank } from '@entities/parts/owner';
import { Role, Roles } from './role';

export class UserDocument extends ibDocument {


	// assign JSon Object to a typed User class
	// only properties existing in User Class are assigned - there is no type check
	static assign(source: object, target: UserDocument|null= null): UserDocument {

		let id: string;

		if ('id' in source) {
			id = (<any> source)['id'];
		} else {
			throw new Error('id is required');
		}

		if (!target) {
			target = new UserDocument(id);
		} else {
			target.id = id;
		}

		if ('name' in source) {
			target.name = (<any> source)['name'];
		}

		if ('owner' in source) {
			target.owner = Owner.assign((<any> source)['owner']);
		} else {
			throw new Error('owner is required');
		}

		if ('username' in source) {
			target.username = (<any> source)['username'];
			if (!target.name) { target.name = target.username; }
		} else {
			if (target.name) {
				target.username = target.name;
			} else {
				throw new Error('Both \'name\' and \'username\' as missing. One is required.')
			}
		}

		if ('eMail' in source) {
			target.eMail = (<any> source)['eMail'];
		} else {
			target.eMail = '';
		}

		if ('isEMailConfirmed' in source) {
			target.isEMailConfirmed = (<any> source)['isEMailConfirmed'];
		} else {
			target.isEMailConfirmed = false;
		}

		if ('firstName' in source) {
			target.firstName = (<any> source)['firstName'];
		} else {
			target.firstName = '';
		}

		if ('lastName' in source) {
			target.lastName = (<any> source)['lastName'];
		} else {
			target.lastName = '';
		}

		if ('phoneNumber' in source) {
			target.phoneNumber = (<any> source)['phoneNumber'];
		} else {
			target.phoneNumber = '';
		}

		if ('isSMS' in source) {
            target.isSMS = (<any> source)['isSMS'];
        } else {
            target.isSMS = false;
        }

		if ('isPhoneNumberConfirmed' in source) {
			target.isPhoneNumberConfirmed = (<any> source)['isPhoneNumberConfirmed'];
		} else {
			target.isPhoneNumberConfirmed = false;
		}

		if ('persistent' in source) {
            target.persistent = (<any> source)['persistent'];
        } else {
            target.persistent = false;
        }

		if ('roleList' in source) {
			const list = (<any> source)['roleList'];
			if (Array.isArray(list)) {
				target.roleList = list;
			} else {
				throw new Error('invalid roles');
			}
		}

		if ('temporaryPassword' in source) {
            target.temporaryPassword = (<any> source)['temporaryPassword'];
        } else {
            target.temporaryPassword = undefined;
        }


		if ('locked' in source) {
			target.locked = (<any> source)['locked'];
		} else {
			target.locked = false;
		}

		if ('reason' in source) {
			target.reason = (<any> source)['reason'];
		} else {
			target.reason = '';
		}

		return target;
	}

	constructor(id: string = '') {
		super('UserDocument', id);
		this.roleList = ['guest'];
	}

	public name: string = '';
	public owner: Owner = new OwnerBlank();
	public username: string = '';
	public eMail?: string;
	public isEMailConfirmed: boolean = false;
	public firstName?: string;
	public lastName?: string;
	public phoneNumber?: string;
	public isSMS: boolean = false;
	public isPhoneNumberConfirmed: boolean = false;
	public persistent: boolean = true;
	public roleList: Array<string>;
	public temporaryPassword?: string;
    // public password: string; in DB but kept secret
	public locked: boolean = false;
	public reason?: string;

	public get fullName(): string {
		if (this.lastName) {
			return `${this.firstName} ${this.lastName}`.trim();
		} else if (this.name) {
			return this.name;
		} else { return this.username; }
	}

	public get roles(): Roles {
		return new Roles(this.roleList);
	}

}

// this class is obsolete and can be removed 5/24/2021
export class NewUserDocument extends UserDocument {
    public owner: Owner = new OwnerBlank();
    public username: string = '';
    public firstName?: string;
    public lastName?: string;
    public eMail?: string;
    public isEMailConfirmed: boolean = false;
    public phoneNumber?: string;
    public isSMS: boolean = false;
    public isPhoneNumberConfirmed: boolean = false;
}
