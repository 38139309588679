
<div class="card">
    <ib-image source="@public/tracking" alt="Tracking Dog" class="card-img-top img-fluid"></ib-image>
    <div class="card-body">
      <h5 class="card-title">Easy <strong>tracking</strong></h5>
      <p class="card-text text-sm">Quickly find the right puppy for you<br>
      or we will alert you by email or text when dogs that you are looking for become available</p>

      <div class="btn-group btn-group-sm btn-block" role="group">
        <button class="btn btn btn-outline-secondary" (click)="onClick('Tips')">Tracking Tips</button>
        <button class="btn btn btn-outline-secondary" (click)="onClick('Alert')">{{getButtonCaption('eMail')}}</button>
      </div>
    </div>
</div>
