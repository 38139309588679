import { MailFormats } from '../../classes/enums';


// Superseded by note document
// -----------------------------
// still in use in breeder

export class Note {


	public static assign(source: Record<string, any>): Note {
		const n = new Note();

		if ('name' in source) {
			n.name = (<any> source)['name'];
		} else { throw new Error('Name is required'); }

		if ('index' in source) {
			n.index = (<any> source)['index'];
		} else { n.index = -1; }


		if ('name' in source) {
			n.name = (<any> source)['name'];
		} else { throw new Error('Name is required'); }

		if ('index' in source) {
			n.index = (<any> source)['index'];
		}

		if ('subject' in source) {
			n.subject = (<any> source)['subject'];
		} else { n.subject = ''; }

		if ('created' in source) {
			n.created = (<any> source)['created'];
		}

		if ('modified' in source) {
			n.created = (<any> source)['modified'];
		}

		if ('format' in source) {
			n.format = (<any> source)['format'];
		}

		if ('content' in source) {
			n.content = (<any> source)['content'];
		} else { n.content = ''; }

		if ('author' in source) {
			n.author = (<any> source)['author'];
		} else { n.author = ''; }

		return n;
	}

	constructor() {
		this.index = -1;
		this.created = new Date(Date.now());
		this.modified = this.created;
		this.format = MailFormats.Text;
	}

	public name: string = '';
	public index: number;
	public subject?: string;
	public created: Date;
	public modified: Date;
	public format: MailFormats;
	public content: string = '';
	public author?: string;

}











