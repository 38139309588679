import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UrlSerializer } from '@angular/router';

import { ibErrorPageComponent } from '@common/pages/ib-error-page/ib-error-page.component';

import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';

// import { CoreServicesModule } from '@common/services/core-services.module';
import { ibCoreComponentsModule } from '@common/components/core-components.module';
import { ibPublicCoreComponentsModule } from 'site/public/publicCoreComponentsModule';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TitleCaseUrlSerializer } from '@common/classes/ibUrl';
import { ibChromeModule } from 'site/chrome.module';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { HttpClientModule } from '@angular/common/http';









@NgModule({
  declarations: [
    AppComponent,
	ibErrorPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ibCoreComponentsModule,
	ibChromeModule,
    ibPublicCoreComponentsModule,
    // CoreServicesModule.forRoot(),
    TooltipModule,
    DropDownsModule,
    LabelModule,
    InputsModule,
    LayoutModule,
    IndicatorsModule,
    PopupModule,
    TreeViewModule,
    SortableModule,
    UploadsModule,
    HttpClientModule
  ],
  providers: [
    {
        provide: UrlSerializer,
        useClass: TitleCaseUrlSerializer // all urls case corrected to proper case including parameters
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
