import { ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscriptions, ibCoreService } from '@common/services/core-services/core.service';
import { SearchInfo } from '@search/search-info';
import { Slide } from '@common/classes/slide';
import { Owner, OwnerTypes } from '@entities/parts/owner';
import { Icons } from '@common/classes/icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class ibHomeComponent implements OnInit, OnDestroy {

	@HostBinding('style.background') hostBackground = '';

	private subs: Subscriptions;
	public info?: SearchInfo;
	public slides?: Array<Slide>;

	constructor(private cd: ChangeDetectorRef,
				private router: Router,
				// private searchService: ibSearchService,
				private coreService: ibCoreService,
				// private dbService: ibDBService
				) {
		this.subs = new Subscriptions();

		// this.subs.add('info', this.searchService.info.subscribe((info: SearchInfo) => {
		// 	this.info = info;
		// 	this.info.getSlides().pipe(
		// 		take(1),
		// 		finalize(() => {})
		// 	).subscribe((_slides: Array<Slide>) => {
		// 		this.slides = _slides;
		// 	});
		// }));
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	onLoaded() {
		this.hostBackground = 'white';
		this.coreService.endProgress();
	}

	onSelection(owner: Owner) {
		switch (owner.ownerType) {
			case OwnerTypes.Breed:
				// this.searchService.setBreedFromID(owner.id);
				// this.router.navigate(['Breed/' + owner.id]);
				break;
			case OwnerTypes.Breeder:
			case OwnerTypes.Litter:
			case OwnerTypes.Dog:
				break;
				default:
				// do nothing
		}
	}

	onClick(): void {

		this.router.navigate(['iBreeder/Home']);
		// this.coreService.isApiServerRunning().subscribe( (resp: boolean) => {
		// 	alert((resp) ? 'The server is running' : ' The server is not running ');
		// });

		// this.dbService.getDocument("1b07acdb-2698-49e0-898a-0574705bef8d", 'BreedDocument', 'main').subscribe((result) => {
		// 	if (result && result.isSuccess) {
		// 		const doc = result.value;
		// 		doc.changed = true;
		// 		doc.changedBy = 'Philippe ' + new Date().toUTCString();
		// 		console.log('FROM DOCUMENT');
		// 		console.log(doc);
		// 		this.dbService.putDocument(doc,'dev').subscribe((res: ibResult<unknown>) => {
		// 			console.log(res);
		// 		});
		// 	} else {
		// 		throw new Error('getDocument error: ' + (result) ? result._message : 'unknown error');
		// 	}
		// });

		// this.dbService.getDocuments("SELECT * FROM c WHERE c.DType = 'BreedDocument'").subscribe((docs) => {
		// 	console.log(docs);
		// });

	}

	onImageClick(e: MouseEvent) {
		if (e.ctrlKey) {
			this.router.navigate(['/Tests/InputTest']);
		}
	}
}
