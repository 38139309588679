import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Sizes } from '@common/classes/types';

import { ibCoreService, ResizeData } from '@common/services/core-services/core.service';

@Component({
  selector: 'ib-footer-tracking',
  templateUrl: './footer-tracking.component.html',
  styleUrls: ['./footer-tracking.component.scss']
})
export class ibFooterTrackingComponent implements OnInit {

	private size: Sizes = 'md';

	constructor(
		private cd: ChangeDetectorRef,
		private router: Router,
		private coreService: ibCoreService) {

	}

	ngOnInit() {
		this.coreService.resizeInfo$.subscribe((data: ResizeData | undefined) => {
			if (data) { this.size = data.size; }
		});
	}

	onClick(e: string) {
		let dest = "";
		switch (e) {
		case "Tips":
			dest = "Main/TrackingTips";
			break;
		case "Alert":
			dest = "Visitor";
			break;
		default: return;
		}

		this.router.navigate([dest]);
	}

	getButtonCaption(target: string): string {
		return (target === 'eMail' && this.size > 'md') ? "Receive eMail Alerts" : "eMail Alerts";
	}

}
