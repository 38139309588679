
export interface IAge {
    years: number;
    months: number;
    days: number;
    dayCount: number;
}

export class DateUtil {


    static getDayCount(fromDate: Date, toDate: Date): number {
       const millisecondsPerDay = 24 * 60 * 60 * 1000;
       return Math.round((toDate.valueOf() - fromDate.valueOf()) / millisecondsPerDay);
    }

    static getAge(dateOfBirth: Date | undefined): IAge {
       const dob = (dateOfBirth) ? dateOfBirth : new Date();
       const now = new Date();
       const yearNow = now.getFullYear();
       const monthNow = now.getMonth();
       const dateNow = now.getDate();

       const yearDob = dob.getFullYear();
       const monthDob = dob.getMonth();
       const dateDob = dob.getDate();

       let age: IAge;
    //    const ageString = "";
    //    const yearString = "";
    //    const monthString = "";
    //    const dayString = "";

       age = { years: 0, months: 0, days: 0, dayCount: 0 };
       age.dayCount = this.getDayCount(dob, now);

       age.years = yearNow - yearDob;

       if (monthNow >= monthDob) {
           age.months = monthNow - monthDob;
       } else {
           age.years--;
           age.months = 12 + monthNow - monthDob;
       }

       if (dateNow >= dateDob) {
           age.days = dateNow - dateDob;

       } else {
           age.months--;
           age.days = 31 + dateNow - dateDob;

           if (age.months < 0) {
               age.months = 11;
               age.years--;
           }
       }

       return age;
    }

    static getAgeString(age: IAge, PuppyMaxAge: number = 2): string[] {

       const resp: Array<string> = [];

       const yearString = (age.years > 1) ? ' years' : ' year';
       const monthString = (age.months > 1) ? ' months' : ' month';
       const dayString = (age.days > 1) ? ' days' : ' day';
    //    const weekString = (age.days > 7) ? ' weeks' : ' week';

       // less that 8 weeks
       if (age.dayCount <= 56) {
           resp.push('Puppy - not weaned');
           if (age.dayCount <= 7) {
               resp.push(age.dayCount + dayString);
           } else {
            //    const weeks = Math.floor(age.dayCount / 7);
           }

           return resp;
       }

       if (age.years <= PuppyMaxAge) {
           resp.push('Puppy');
           if (age.dayCount < 549) {
               const m = (age.years > 0) ? (12 * age.years) + age.months : age.months;
               resp.push(m + monthString);
           } else {
               resp.push(age.years + yearString + ' ' + age.months + monthString);
           }

           return resp;
       }

       resp.push('Adult');
       resp.push(age.years + yearString);
       return resp;
	}

	static fromIsoDate(source: Record<string, unknown>): Date {

		if ('isoDate' in source) {
			let s = <string> (<any> source)['isoDate'];
			let all = s.split('T');
			let dPart = all[0].split('-');
			let tPart = all[1].split(':');
			let sPart = tPart[2].split('.');
			let ms = (sPart.length > 1) ? Number(sPart[1]) : 0;

			let d = new Date(Number(dPart[0]),
							 Number(dPart[1]),
							 Number(dPart[2]),
							 Number(tPart[0]),
							 Number(tPart[1]),
							 Number(sPart[0]), ms);

			return d;
		} else {
			throw new Error(("Source does not have an IsoDate property"));
		}
	}

	static parseDateFromCosmosDb(value: string): Date | undefined {
		const parsedDate = Date.parse(value);
		if (parsedDate == undefined || parsedDate == undefined || isNaN(parsedDate)) { return undefined; }
		return new Date(parsedDate);
	}
}
