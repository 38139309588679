import { Address } from './address';
import { Phone } from './phone';
import { eMail } from './email';
import { Note } from './note';

// Contact
// ---------------------------------------------------

export class Contact {


	public static assign(source: Record<string, any>): Contact {

		const c = new Contact();

		if ('name' in source) {
			c.name = (<any> source)['name'];
		} else {
			throw new Error('Name is required');
		}

		if ('index' in source) {
			c.index = (<any> source)['index'];
		} else {
			c.index = -1;
		}

		if ('fullName' in source) {
			c.fullName = (<any> source)['fullName'];
		} else {
			throw new Error('Full Name is required');
		}

		if ('functionName' in source) {
			c.functionName = (<any> source)['functionName'];
		} else {
			c.functionName = '';
		}

		if ('addresses' in source) {
			const s = <Array<any>> (<any> source)['addresses'];
			s.forEach((v) => { c.addresses.push(Address.assign(v)); });
		}

		if ('phones' in source) {
			const s = <Array<any>> (<any> source)['phones'];
			s.forEach((v) => { c.phones.push(Phone.assign(v)); });
		}

		if ('eMails' in source) {
			const s = <Array<any>> (<any> source)['eMails'];
			s.forEach((v) => { c.eMails.push(eMail.assign(v)); });
		}

		if ('notes' in source) {
			const s = <Array<any>> (<any> source)['notes'];
			s.forEach((v) => { c.notes.push(Note.assign(v)); });
		}

		if ('comments' in source) {
			c.comments = (<any> source)['comments'];
		} else {
			c.comments = '';
		}

		if ('active' in source) {
			c.active = (<any> source)['active'];
		} else {
			c.active = false;
		}

		return c;
	}

	constructor() {
		this.addresses = new Array<Address>();
		this.phones = new Array<Phone>();
		this.eMails = new Array<eMail>();
		this.notes = new Array<Note>();
	}

	name: string = '';
	index: number = -1;
	fullName: string = '';
	functionName: string = '';
	addresses: Array<Address>;
	phones: Array<Phone>;
	eMails: Array<eMail>;
	notes: Array<Note>;
	comments?: string;
	active: boolean = false;

}

export enum ContactOfRecordStates {
	empty,
	any,
	phonePartial,
	phone,
	eMailPartial,
	eMail
}

export class ContactOfRecord {

	public constructor() {
		this.isValid = false;
		this.isPartial = true;
		this.value = '';
		this.formattedValue = '';
		this.state = ContactOfRecordStates.empty;
		this.verified = false;
		this.verificationDate = Date.now();
	}

	isValid: boolean;
	isPartial: boolean;
	value: string;
	formattedValue: string;
	state: ContactOfRecordStates;
	verified: boolean;
	verificationDate: number;

	get contactType(): 'eMail' | 'Phone' | 'unknown' {

		switch (this.state) {
			case ContactOfRecordStates.any:
			case ContactOfRecordStates.empty:
				return 'unknown';
			case ContactOfRecordStates.phone:
			case ContactOfRecordStates.phonePartial:
				return 'Phone';
			case ContactOfRecordStates.eMail:
			case ContactOfRecordStates.eMailPartial:
				return 'eMail';
			default: return 'unknown';
		}
	}

}

export enum NameOfRecordStates {
	empty,
	any,
	validated,
	duplicate,
	invalid
}


export class NameOfRecord {

	public constructor() {
		this.isValid = false;
		this.isPartial = true;
		this.value = '';
		this.formattedValue = '';
		this.state = NameOfRecordStates.empty;
		this.verified = false;
		this.verificationDate = Date.now();
	}

	isValid: boolean;
	isPartial: boolean;
	value: string;
	formattedValue: string;
	state: NameOfRecordStates;
	verified: boolean;
	verificationDate: number;

}

