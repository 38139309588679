// ---------------------------------------------------------
// ib help viewer
// ---------------------------------------------------------
// Displays help content given help topic from getHelp coreService


import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Icons } from '@common/classes/icons';
import { ibCoreService } from '@common/services/core-services/core.service';


@Component({
	selector: 'ib-help-button',
	templateUrl: './ib-help-button.component.html',
	styleUrls: ['./ib-help-button.component.scss']
})
export class ibHelpButtonComponent {


	@Input() helpTopic: string;
	@Input() title: string = 'Help';
	@Output() clicked = new EventEmitter();

	public helpIcon: string;

	constructor(private coreService: ibCoreService) {
		const i = Icons.generalIcon('help', 'light', 'sm');
		i.image.color ='ib-color-gray-300';
		this.helpIcon =i.imageClasses;
	}

	onClick(): void {
		// this.clicked.emit(this.params);
	}

}
