//------------------------------------------------------------
// Data type Validation and formatting
//------------------------------------------------------------

import { ibGeoLocationService } from "@common/services/misc-services/geoLocationService";
import { Defaults } from "../defaults";
import { PostalCode } from "../geoLocation";
import { DocumentTypesList, InputCompletionState } from "../types";
import { Pattern } from "./pattern";


export class Validate {


	private static symbols = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
	private static usPhonePattern: Pattern;

    public static isGuid(value: string): boolean {
        const pattern = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return pattern.test(value);
    }

	public static isID(value: string): boolean {
        const pattern = /^[a-zA-Z0-9]{2,15}$/g;
        return pattern.test(value);
    }

	public isUrl(value: string): boolean {
        const pattern = /^[a-zA-Z0-9-_.~]{3,25}$/g;
        return pattern.test(value);
	}

    public static isDomain(value: string): RegExpExecArray | null {
        const pattern = /(^[A-Za-z0-9]+[-]*[A-Za-z0-9]+)\.+([a-z]{2,}$)/;
        return pattern.exec(value);
    }

	// the iBreeder account id
    public static isAccountID(value: string): boolean {
        const pattern = /^([A-Za-z]{2})?([A-Za-z]{2})\s?([0-9]{0,4})$/g
        const res = pattern.test(value);
        return res;
    }

	// validate account id including supported country and country subdivision (sate)
	public static accountId(value: string): ValidationResult {
		if (!value) { return ValidationResult.empty(); }
        const pattern = /^([A-Za-z]{2})?([A-Za-z]{2})\s?([0-9]{4})$/g
        const matches = pattern.exec(value);
		if (!matches) {
			return ValidationResult.error(`${value} is not a valid accountId`, 0);
		} else {
			const formatted: string = '';
			const length = matches.length;
			if (matches.length !== 4) { return ValidationResult.error('Internal error - invalid matches count'); }

			let country = matches[1]
			country = (country) ? country.toUpperCase() : 'US';

			let state = matches[2];
			if (!state) {
				return ValidationResult.error('The country subdivision (state) is missing');
			} else {
				state = state.toUpperCase();
			}
			if (!Defaults.countries.isSubdivisionSupported(country, state)) {
				return ValidationResult.error('The country and/or subdivision (state) are not supported or invalid');
			}
			let index = matches[3]
			if (!index) { return ValidationResult.error('The index is missing'); }
			const idx = parseInt(index);
			if (idx === NaN || idx < 1 || idx > 9999) { return ValidationResult.error('The index is invalid');}

			index = idx.toString();
			index = ('0000' + idx.toString()).slice(-4);
			return ValidationResult.complete(country + state + index);
		}
	}

	// expects is as XXXX#[###]
	public static formatAccountID(id: string): string {
		const s = id.slice(0, 4);
		const n = id.slice(4);
		const idx = parseInt(n);
		const index = ('0000' + idx.toString()).slice(-4);
		return s + index;
	}

	// the denomination can be a last name first name, company name etc. Must be unique within zip code
	public static  isDenomination(value: string): boolean {
		const pattern = /^[.&@]?[A-Z0-9]+[A-Za-z0-9()\-_+*!]*(?:[ ][.&@]?[A-Z0-9]+[A-Za-z0-9()\-_+*!]*){0,4}/g;
		const r = pattern.exec(value);
        if (!r || r.length === 0) {
            return false
        } else {
            return (r[0].length === value.length);
        }
		return false;
	}

	// return the valid part of a denomination
	public static  ValidDenominationPart(value: string): string {
		const pattern = /^[.&@]?[A-Z0-9]+[A-Za-z0-9()\-_+*!]*(?:[ ][.&@]?[A-Z0-9]+[A-Za-z0-9()\-_+*!]*){0,4}/g
		const r = pattern.exec(value);
        return (!r || r.length === 0) ? '' : r[0];
	}

    public static get usernameMinLength(): number { return 5; }

    public static isUsername(value: string): boolean {
        const pattern = new RegExp('^[a-zA-Z0-9_ ]{4,}[a-zA-Z ]+[0-9]*$');
        return pattern.test(value);
    }

    public static iseMail(value: string): boolean {
        const pattern = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
        return pattern.test(value);
    }

    public static isPartialEMail(value: string): boolean {
        const pattern = /^([A-Za-z0-9!#$%&^*'+-//=?_{|}]+)(@)/;
        return pattern.test(value);
    }

    public static isPhone(value: string): boolean {
        const pattern = /^(\+\d{1,2}\s)?(\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})$/;
        return pattern.test(value);
    }

	public static phoneCompletionState(value: string): InputCompletionState {
		if (!Validate.usPhonePattern) { Validate.usPhonePattern = new Pattern('usPhone'); }
		return Validate.usPhonePattern.match(value);
	}

	public static isPartialPhone(value: string): boolean {
		const state = Validate.phoneCompletionState(value);
		return state === 'partial';
	}

    // insures that value includes only chars compatible with a US phone number
    public static parsePhone(value: string): RegExpExecArray | null {
        const pattern = /^^(\+\d{1,2}\s)?([0-9()\-. ]*)$/;
        return pattern.exec(value);
    }

    public static formatPhoneNumber(value: string): string {
        const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (pattern.test(value)) {
            const formattedNumber = value.replace(pattern, '($1) $2-$3');
            return formattedNumber;
        } else {
            return '';
        }
    }

    public static formatPartialPhoneNumber(value: string): string {
        const parts = this.parsePhone(value);
        if (!parts) {
            return '';
        }
        const international = (parts[1]) ? parts[1] : '+1 ';
        const digits = parts[2].replace(/\D/g, '');
		const excess = digits.substring(10);
        const pattern = /^([+][0-9]{1,2})[ ]([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})/;
        const res = pattern.exec(`${international}${digits}`);
        if (!res) {
            return '';
        }

        // let num = `${international} (${res[2]}`;
        let num = `(${res[2]}`;
        if (res[2].length === 3) {
            num += `) ${res[3]}`;
            if (res[3].length === 3) {
                num += `-${res[4]}`;
            }
        }

        return num + excess;
    }

	// tests 5digits or extended zip code
	public static isValidUSZipFormat(zip: string | number, extended: boolean = false): boolean {
		if (typeof zip === 'number') {
			zip = zip.toString();
		}
		if (!extended) {
			return /^\d{5}$/.test(zip);
		} else {
			return /^\d{5}(-\d{4})?$/.test(zip);
		}
	 }

	 // async checks with geo-services
	 public static isValidUSZip(zip: string | number): Promise<boolean> {
		 return new Promise((resolve) => {
			if (typeof zip === 'number') {
				zip = zip.toString();
			}
			const geo = Defaults.injectorInstance.get<ibGeoLocationService>(ibGeoLocationService);
			geo.getPostalCode(zip)
				.then((value: PostalCode) => { resolve(value.isValid); return; })
				.catch((reason: any) => { resolve(false); return; });
		 });

	 }

	 public static isNumber(number: string | number): boolean {
		const value = (typeof number === 'number') ? number.toString() : number;
		return !Number.isNaN(value);
	 }

	 public static isInteger(number: string | number): boolean {
		const value = (typeof number === 'number') ? number.toString() : number;
		return !Number.isInteger(value);
	 }

	 public static isIsoDate(date: string): boolean {
	 const isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
	 	return isoDateRegex.test(date);
	}

	public static isPrice(price: string | number): boolean {
		const pattern = /[0-9]+.[0-9]{2}|[0-9]+/gm;
		const value = (typeof price === 'number') ? price.toString() : price;
		const matches = pattern.exec(value);
		return (matches && matches['0'] === matches['input']) ? true : false;
	 }

	 public static isDocumentType(type: string): boolean {
		 const found = DocumentTypesList.find((value: string) => {
			 return (value === type);
		 });

		 return (found) ? true : false;
	 }


	 // ================================================== validate 1st char only

	 public static isDigit(char: string): boolean { return Validate.isMatch(char, /[0-9]/); }
	 public static isAlpha(char: string): boolean { return Validate.isMatch(char, /[A-Za-z]/); }
	 public static isUpper(char: string): boolean { return Validate.isMatch(char, /[A-Z]/); }
	 public static isLower(char: string): boolean { return Validate.isMatch(char, /[a-z]/); }
	 public static isWhiteSpace(char: string): boolean { return Validate.isMatch(char, /\s/); }
	 public static isSymbol(char: string): boolean { return Validate.isMatch(char, Validate.symbols); }

	 private static isMatch(char: string, exp: RegExp): boolean {
		if (char && char.length > 0) { return exp.test(char[0])} else { return false; }

	 }
}

export class ValidationResult {

	public static empty(): ValidationResult {
		const vr = new ValidationResult();
		vr._state = 'empty';
		vr._formattedValue = '';
		return vr;
	}

	public static error(message: string, position?: number): ValidationResult {
		const vr = new ValidationResult();
		vr._state = 'error';
		vr._message = message;
		vr._errorPosition = position;
		return vr;
	}

	public static partial(position?: number): ValidationResult {
		const vr = new ValidationResult();
		vr._state = 'partial';
		vr._errorPosition = position;
		return vr;
	}

	public static complete(formattedValue: string): ValidationResult {
		const vr = new ValidationResult();
		vr._state = 'complete';
		vr._formattedValue = formattedValue;
		return vr;
	}

	private _state: InputCompletionState = 'empty';
	private _message: string | undefined = undefined;
	private _errorPosition: number | undefined = undefined;
	private _formattedValue: string | undefined = undefined;

	public get state(): InputCompletionState { return this._state; }
	public get message(): string | undefined { return this._message; }
	public get errorPosition(): number | undefined { return this._errorPosition; }
	public get formattedValue(): string | undefined { return this._formattedValue; }
}
