import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ibCoreService } from '@common/services/core-services/core.service';
import { ibIcon } from '@common/classes/icon';
import { Icons } from '@common/classes/icons';
import { ibError } from '@common/classes/ib-error';

@Component({
	selector: 'ib-error-page',
	templateUrl: './ib-error-page.component.html',
	styleUrls: ['./ib-error-page.component.scss']
})
export class ibErrorPageComponent implements OnInit {

	@Input() error: ibError = ibError.create('Unknown Error');

	titleIcon: ibIcon;

	constructor( private router: Router,
				 private location: Location,
				 private coreService: ibCoreService) {

		this.titleIcon = (this.error.icon) ? this.error.icon : Icons.generalIcon('bug');
		const err = this.coreService.error;
		if (err instanceof ibError) {
			this.error.message = err.message;
			if (err.title) { this.error.title = err.title; }
			if (err.icon) { this.error.icon = err.icon; }
			if (err.reason) { this.error.reason = err.reason; }
		} else {
			this.error.message = 'An undetermined error occurred';
		}
	}

	ngOnInit() {
	}

	onClick(action: string) {
		switch (action) {
			case 'Back':
				this.location.back();
				break;
			case 'Home':
				this.router.navigate(['Home']);
				break;
			default:
		}
	}
}
