// ---------------------------------------
// Library
// ---------------------------------------

import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { Injectable } from "@angular/core";
import { NameValue, Tools, Units } from '@common/tools';
import { Token, Tokenizer } from './tokenizer';
import { Contained } from './enums';
import { ibUrl } from './ibUrl';


// Extends enumerations  also available in tools -- to be checked
export class EnumEx {
	static getNamesAndValues<T extends number>(e: any) {
		return EnumEx.getNames(e).map((n) => new NameValue(n, e[n]));
	}

	static getNames(e: any) {
		return EnumEx.getObjValues(e).filter((v) => typeof v === 'string') as Array<string>;
	}

	static getValues<T extends number>(e: any) {
		return EnumEx.getObjValues(e).filter((v) => typeof v === 'number') as Array<T>;
	}

	private static getObjValues(e: any): Array<number | string> {
		return Object.keys(e).map((k) => e[k]);
	}

	static getValue<T extends number>(e: any, name: string): T | null {
		const names = this.getNames(e);
		for (const _name of names) {
			if (_name.toLowerCase() === name.toLowerCase()) { return e[_name]; }
		}

		return null;
	}
}

export class IntegerRange {

	public startRange: number;
	public endRange: number;

	public static Parse(value: string): IntegerRange {
		const parts = value.split('-');
		if (parts.length !== 2) { throw new Error('Invalid range'); }
		const s = parseInt(parts[0], 10);
		const e = parseInt(parts[1], 10);
		if (Number.isNaN(s) || Number.isNaN(e)) { throw new Error('Invalid range'); }
		return new IntegerRange(s, e);
	}

	constructor(startRange: number, endRange: number) {

		if (startRange < endRange) {
			this.startRange = startRange;
			this.endRange = endRange;
		} else {
			throw new Error('Invalid range');
		}
	}

	public get spread(): number {
		return this.endRange - this.startRange;
	}


}

export class Range {

	public static assign(source: object): Range {
		if (!source) { throw new Error('Source is required'); }
		const r = new Range();

		if ('from' in source) {
			r.from = Number.parseInt((<any>source)['from']);
		} else {
			throw new Error('Expected property \'from\' not found');
		}

		if ('to' in source) {
			r.to = Number.parseInt((<any>source)['to']);
		} else {
			throw new Error('Expected property \'to\' not found');
		}

		if ('name' in source) {
			r.from = (<any>source)['name'];
		}

		return r;

	}


	constructor(from?: number, to?: number, name?: string) {
		this.from = (from) ? from : 0;
		this.to = (to) ? to : 0;
		this.name = name;
	}

	from: number;
	to: number;
	name?: string;

	public isInRange(value: Range | number): boolean {
		if (value instanceof Range) {
			return (this.from >= value.from && this.to <= value.to);
		} else {
			return (value >= this.from && value <= this.to);
		}
	}

	// filters a list of ranges and includes ranges that contain the from and to of the current range
	public getRanges(_ranges: Array<Range>): Array<Range> {
		const ret = new Map<string, Range>();
		if (_ranges && _ranges.length > 0) {
			for (const r of _ranges) {
				if (r.isInRange(this.from) && !ret.has(r.toString())) { ret.set(r.toString(), r); }
				if (r.isInRange(this.to) && !ret.has(r.toString())) { ret.set(r.toString(), r); }
			}
		}
		return Array.from(ret.values());
	}

	public getRangesString(ranges: Array<Range>): string {
		const _ranges = this.getRanges(ranges);
		let ret: string | undefined = '';
		if (_ranges.length > 0) {ret = _ranges[0].name; }
		if (_ranges.length > 1) {ret += ' to ' + <string> (_ranges[_ranges.length - 1].name); }
		return (ret) ? ret : '';
	}

	toString(): string {
		return this.from.toString() + '-' + this.to.toString();
	}
}

export class LetterRange {

	constructor(from?: string, to?: string) {

		this.from = 'a';
		this.to = 'z';

		if (from && from.includes('-')) {
			const parts = from.split('-');
			if (parts.length > 0) {	from = parts[0]; }
			if (parts.length > 1) {	to = parts[1]; }
		}

		this.from = (from) ? from[0].toLowerCase() : 'a';
		this.to = (to) ? to[0].toLowerCase() : this.from;
	}

	public readonly from: string;
	public readonly to: string;

	public isInRange(range: string | LetterRange): boolean {
		if (typeof range === 'string') {
			return (range >= this.from && range <= this.to);
		} else {
			return (this.from >= range.from && this.to <= range.to);
		}
	}

	toString(): string {
		return this.from + (this.to !== this.from) ? '-' + this.to : '';
	}

	public static getLetterRanges(data: Array<string>, count: number): Array<string> {

		let maxCount = Math.round(data.length / count);
		if (maxCount < 7) {
			maxCount = 7;
			count = Math.abs(data.length / 7);
		}

		const letterCounts: Record<string, any> = new Object();
		let currentLetter = '';
		for (const name of data) {
			const n = name[0].toLocaleLowerCase();
			if (n !== currentLetter) {
				currentLetter = n;
				(<any> letterCounts)[currentLetter] = 1;
			} else {
				(<any> letterCounts)[currentLetter] += 1;
			}
		}

		const ranges = new Array<string>();
		let rangeStart = '';
		let rangeEnd = '';
		let rangeCount = 0;
		let range = '';

		for (const letter in letterCounts) {
			if (letter in letterCounts) {
				const letterCount = <number> (<any> letterCounts)[letter];
				if (rangeStart === '') {
					rangeStart = letter;
					rangeEnd = letter;
					rangeCount = letterCount;
					continue;
				}
				if (rangeCount + letterCount <= count) {
					rangeCount += letterCount;
					rangeEnd = letter;
				} else {
					range = rangeStart;
					if (rangeEnd && rangeStart !== rangeEnd) { range += '-' + rangeEnd; }
					ranges.push(range);
					rangeStart = letter;
					rangeEnd = '';
					rangeCount = letterCount;
				}
			}
		}

		rangeEnd = 'z';
		range = rangeStart;
		if (rangeStart !== rangeEnd) { range += '-' + rangeEnd; }
		ranges.push(range);

		ranges.unshift('all');
		return ranges;
	}

	public static getRange(range: string[], value: string): string {
		if (!value || value.length < 1 || value === 'all') return 'all';
		for (let r of range) {
			const lr = new LetterRange(r);
			if (lr.isInRange(value[0].toLowerCase())) return r;
		}
		return 'all';
	}

}

export class Measure {

	public static heightRanges = [
		new Range(0, 15, 'toy'),
		new Range(16, 20, 'small'),
		new Range(21, 25, 'standard'),
		new Range(26, 1000, 'tall')
	];

	public static weightRanges = [
		new Range(0, 23, 'small'),
		new Range(24, 57, 'medium'),
		new Range(58, 98, 'large'),
		new Range(99, 1000, 'giant')
	];

	public static ageRanges = [
		new Range(0, 8, 'short'),
		new Range(9, 11, 'average'),
		new Range(11, 1000, 'long')
	];

	public static parse(value: string): Measure {
		const t = new Tokenizer(value);
		const tokens = Token.convertAll(t.list);
		return Measure.createFromTokens(tokens);
	}

	public static createFromTokens(tokens: Array<Token>): Measure {
		const m = new Measure();

		if (!tokens) { m.error = 'invalid - empty'; return m; }

		// const l = tokens.length;


		if (tokens.length < 1) { m.error = 'invalid - empty'; return m; }

		if (tokens[0].type !== 'number') {
			m.error = 'invalid - @ 1 number expected'; return m;
		} else {
			m.range.from = Number(tokens[0].value);
		}

		if (tokens.length < 2) { m.error = 'invalid - start of range missing'; return m; }

		if (tokens[1].type !== 'separator' && tokens[1].value === '-') {
			m.error = 'invalid - @ 2 dash expected'; return m;
		}

		if (tokens.length < 3) { m.error = 'invalid - end of range missing'; return m; }

		if (tokens[2].type !== 'number') {
			m.error = 'invalid - @ 3 number expected'; return m;
		} else {
			m.range.to = Number(tokens[2].value);
		}

		if (tokens.length < 4) { m.error = 'invalid - unit missing'; return m; }

		if (tokens[3].type !== 'string') {
			m.error = 'invalid - @ 4 unit expected'; return m;
		} else if (!Tools.isUnitName(tokens[3].value)) {
			m.error = 'invalid - @ 4 unknown unit'; return m;
		} else if (m.range.from > m.range.to) {
			m.error = `invalid - start of range [${m.range.from}] must be less than [${m.range.to}]`;
			return m;
		} else {
			const u = Tools.toUnitName(tokens[3].value);
			m.unit = (<any> Units)[u];
		}
		return m;

	}

	public static getKey(rangeString: string, ranges: Array<Range>): number {
		if (!rangeString) { return 0; }
		const levels = new Array<number>();
		ranges.forEach((value: Range, index: number) => {
			const name = (value.name) ? value.name : '';
			if (rangeString.includes(name)) {
				levels.push(index);
			}
		});
		if (levels.length < 1) { return 0; }
		levels.sort();
		let key: number = 0;
		for (let i = levels[0]; i <= levels[levels.length - 1]; i++) {
			// eslint-disable-next-line no-bitwise
			key = key | Math.pow(2, i);
		}
		return key;
	}

	constructor() {
		this.range = new Range();
	}

	public range: Range;
	public unit: Units = Units.inches;
	public error?: string;

	public get category(): string {
		if (this.error) { return this.error; }
		switch (this.unit) {
			case Units.inches:
				return this.range.getRangesString(Measure.heightRanges);
			case Units.pounds:
				return this.range.getRangesString(Measure.weightRanges);
			case Units.years:
				return this.range.getRangesString(Measure.ageRanges);
		}
	}

	public get field(): string {
		switch (this.unit) {
			case Units.inches: return 'height';
			case Units.pounds: return 'weight';
			case Units.years: return 'lifespan';
			default: return '';
		}
	}

	public toString(): string {
		if (!this.error) {
			return this.range.toString() + ' ' + Units[this.unit];
		} else {
			return this.error;
		}
	}
}

export class Rectangle {

	private _top: number;
	private _left: number;
	private _height: number;
	private _width: number;
	private _fullWidth: boolean;

	constructor() {
		this._top = 0;
		this._left = 0;
		this._height = 0;
		this._width = 0;
		this._fullWidth = false;
	}

	public get top(): number { return this._top; }
	public set top(value: number) { this._top = value; }

	public get height(): number { return this._height; }
	public set height(value: number) { this._height = value; }

	public get bottom(): number { return this._top + this._height; }
	public set bottom(value: number) { this._height = value + this.top; }

	public get left(): number { return this._left; }
	public set left(value: number) { this._left = value; }

	public get width(): number { return this._width; }
	public set width(value: number) { this._width = value; }

	public get right(): number { return this._left + this._width; }
	public set right(value: number) { this.left = value + this._width; }


	public get topString(): string { return `${this.top}px`; }
	public get leftString(): string { return `${this.left}px`; }
	public get heightString(): string { return `${this.height}px`; }
	public get widthString(): string { return `${this.width}px`; }

	public get xCenter(): number { return this.left + (this.width / 2); }
	public get yCenter(): number { return this.top + (this.height / 2); }
	public get xCenterString(): string { return `${this.xCenter}px`; }
	public get yCenterString(): string { return `${this.yCenter}px`; }

	public get fullWidth(): boolean { return this._fullWidth; }
	public set fullWidth(value: boolean) { this._fullWidth = value; }

	public contains(p: Point): Contained {
		if (p.x >= this.left && (this.fullWidth || p.x <= this.right) && p.y >= this.top && p.y <= this.bottom) {
			return (p.x >= this.xCenter) ? Contained.rightSide : Contained.leftSide;
		} else {
			return Contained.false;
		}
	}

	public static getElementRectangle(el: HTMLElement): Rectangle {
		const rect = new Rectangle();

		rect.top = el.offsetTop;
		rect.height = el.offsetHeight;
		rect.left = el.offsetLeft;
		rect.width = el.offsetWidth;

		return rect;
	}

	public static getElementOuterRectangle(el: HTMLElement): Rectangle {
		// double left margin + width
		const rect = new Rectangle();
		const style = window.getComputedStyle(el);

		const parent = el.parentElement;
		const offsetTop = (parent) ? parent.offsetTop : 0;
		const offsetLeft = (parent) ? parent.offsetLeft : 0;

		rect.top = el.offsetTop - offsetTop - this.getSize(style.marginTop);
		rect.height = el.offsetHeight + this.getSize(style.marginTop) + this.getSize(style.marginBottom);
		rect.left = el.offsetLeft - offsetLeft - this.getSize(style.marginLeft);
		rect.width = el.offsetWidth + this.getSize(style.marginLeft) + this.getSize(style.marginRight);

		return rect;
	}

	private static getSize(sizeString: string | null): number {
		return (sizeString) ? Math.floor(parseFloat(sizeString)) : 0;
	}


}

export class Point {

	public static assign(source: object): Point {
		if (!source) { throw new Error('Source is required'); }
		let x: number = 0;
		let y: number = 0;

		if ('x' in source) {
			x = Number.parseInt((<any>source)['x']);
		} else {
			throw new Error('Expected property \'x\' not found in source');
		}

		if ('y' in source) {
			x = Number.parseInt((<any>source)['\'y\'']);
		} else {
			throw new Error('Expected property y not found in source');
		}

		return new Point(x, y);

	}

	constructor(x: number, y: number) {
		this.x = x;
		this.y = y;
	}

	public x: number;
	public y: number;

	public get xString(): string { return `${this.x}px`; }
	public get yString(): string { return `${this.y}px`; }

}

export class Coordinates {

	constructor(lat: number, lng: number) {
		this.latitude = lat;
		this.longitude = lng;
	}

	public latitude: number;
	public longitude: number;

	// returns distance between two points in miles
	public getDistance(coordinates: Coordinates): number {
		const R = 6372.8; // km
		const x1 = coordinates.latitude - this.latitude;
		const dLat = this.toRadians(x1);
		const x2 = coordinates.longitude - this.longitude;
		const dLng = this.toRadians(x2);
		// eslint-disable-next-line max-len
		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.toRadians(this.latitude)) * Math.cos(this.toRadians(coordinates.latitude)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		let d = (R * c) / 1.609344;
		d = Math.round(d / 10) * 10;
		return d ;
	}

	private toRadians(value: number): number {
		return value * Math.PI / 180;
	}

}

export class ibGeoRange {

	constructor(lat: number = 39.381266, long: number = -97.922211, distance: number = 0) {
		this.latitude = lat;
		this.longitude = long;
		this.distance = distance;
	}

	public latitude: number;
	public longitude: number;
	public distance: number;
}

export class ibSearchRange extends ibGeoRange {

	public static createDisabled(): ibSearchRange {
		return new ibSearchRange(39.381266, -97.922211, 0, false);
	}

	constructor(lat: number = 39.381266, long: number = -97.922211, distance: number = 0, enabled: boolean = true) {
		super(lat, long, distance);
		this.enabled = enabled;
		this.country = 'US';
	}

	public enabled: boolean;
	public postalCode?: string;
	public country: string;
}

export class MapMarker {

	constructor(coordinates: Coordinates, id?: string, label?: string, title?: string) {
		this.coordinates = coordinates;
		this.id = (id) ? id : (label) ? label : '';
		this.label = label;
		this.title = title;
	}

	id: string;
	coordinates: Coordinates;
	label?: string;
	title?: string;
	url?: string;
}

// NOT USED IN iBreeder
// Used to provide case insensitive routing - used in app.module needs to be declared as provider in app.routing
// The entire url is lowercase including parameters
// export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
// 	parse(url: string): UrlTree {
// 		return super.parse(url.toLowerCase());
// 	}
// }

// // Used to provide case insensitive routing - used in app.module needs to be declared as provider in app.routing
// // A map of valid path is indexed as lowercase and points to the correctly cased path
// @Injectable()
// export class TitleCaseUrlSerializer extends DefaultUrlSerializer {

// 	public parse(url: string): UrlTree {
// 		const u = this.toTitleCase(url)
// 		const t =super.parse(u);
// 		return t;
// 		// return super.parse(this.toTitleCase(url));
// 	}

// 	private toTitleCase(url: string): string {
// 		return ibUrl.toTitleCase(url);
// 	}

// }


export class Share {

	private content?: string;

	constructor(selector: string) {

		// find component and extract content
		if (document) {
			const el = document.getElementsByClassName(selector);
			if (el && el.length > 0) {
				this.content = el[0].innerHTML;
			}
		}
	}

	print(title: string = 'iBreeder.com'): boolean {

		const popup = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
		if (popup) {
			popup.document.open();
			popup.document.write(`
                <html>
                    <head>
                    <title>${title}</title>
                    <style>
                    </style>
                    </head>
                <body onload="window.print();window.close()">${this.content}</body>
                </html>`
			);

			popup.document.close();

		}

		return true;
	}

	email(title: string = 'iBreeder.com'): boolean {
		window.location.href = `mailto:?subject=${title}&body=${this.content}}`;
		return true;
	}

}
