
<ul [class]="flatTopClass()" ibDropTarget (ibDrop)="onDrop($event)">
    <li id="item-{{i}}"
		*ngFor="let item of view?.views; let i=index"
        class="list-group-item {{mode}}"
        [ngClass]="{active: item.active === true, highlight: item.highlight === true }"
		[class.disabled]="item.disabled"
        (click)="onClick(item)"
        [ibDraggable]="{data: i}" ibDropTarget (ibDrop)="onDrop($event)">
        <div class="icon-container" >
            <i class="{{item.iconClasses}}"></i>
        </div>
        <div class="{{'caption ' + mode}}">{{item.caption}}</div>
        <div class="actions">
            <i class="fas fa-caret-up {{ isDynamic() }} xs" (click)="moveUp($event, item)"></i>
            <i class="fas fa-check ib-color-green {{ isChecked(item)}}"></i>
            <i class="fas fa-caret-down {{ isDynamic() }} xs" (click)="moveDown($event, item)"></i>
        </div>
    </li>
</ul>






