// Registry entry


export class RegistryEntry {

    public static assign(source: Record<string, any>): RegistryEntry {
        const e = new RegistryEntry();

        if ('registryID' in source) {
            e.registryID = (<any>source)['registryID'];
        } else {
            throw new Error('registryEntry.assign - registryID is missing');
        }

        if ('registryName' in source) {
            e.registryName = (<any>source)['registryName'];
            if (e.registryName === null) { e.registryName = e.registryID; }
        } else {
            e.registryName = e.registryID ;
        }

        if ('registryLink' in source) {
            e.registryLink = (<any>source)['registryLink'];
        }

        return e;

    }

    static create(id: string): RegistryEntry {
        const r = new RegistryEntry();
        r.registryID = id;
        r.registryName = "New Registry Entry";
        return r;
    }

    public registryID: string = '';
    public registryName: string = '';
    public registryLink: string = '';

}
