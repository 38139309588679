// ---------------------------------------------------------------------
// result class name
// ---------------------------------------------------------------------


// there is another ibError in common classes / they should be merged
export class ibResultError {

    private _operation: string;
    private _message: string;
    private _inner: object = {};

    constructor(operation?: string, message?: string) {
        this._operation = (operation) ? operation : '';
        this._message = (message) ? message : '';
    }

    get operation(): string { return this._operation; }

    get message(): string { return this._message; }
    set message(value: string) { this._message = value; }

    get inner(): object { return this._inner; }
    set inner(value: object) { this._inner = value; }

}

// export class ibResult {

//     private _ex: ibError | undefined;
//     private _message: string;
//     private _value: unknown;

//     public static success(value: unknown): ibResult {
//         const res = new ibResult();
//         res._ex = undefined;
//         res._message = 'success';
//         res._value = (value) ? value : undefined;
//         return res;
//     }

//     public static faulted(message: string, operation?: string): ibResult {
//         const res = new ibResult();
//         res._ex = new ibError(operation, message);
//         res._value = undefined;
//         return res;
//     }

//     public constructor() {
//         this._ex = undefined;
//         this._message = '';
//         this._value = undefined;
//     }

//     public get message(): string { return this._message; }
//     public set message(value: string) {this._message = value; }

//     public get error(): ibError | undefined { return this._ex; }
//     public set error(value: ibError | undefined) {this._ex = value; }

//     public value<T>(): T { return <T>this._value;}

//     public isSuccess(): boolean { return (!this._ex) ? true : false}



// }


// The result class is used by server functions to carry either the returned value or an error message
export class ibResult<T> {

    public ex?: ibResultError;
    public message?: string;
    public value?: T;

    public static faulted<T>(message: string): ibResult<T> {
        const res = new ibResult<T>(null);
        res.ex = new ibResultError("Result", message);
        res.message = message;
        return res;
    }

    public static success<T>(value: T): ibResult<T> {
        const res = new ibResult<T>(value);
        res.message = '';
        res.value = value;
        return res;
    }

    constructor(result: T | object | null) {

        if (result && (<object>result).hasOwnProperty('ex') && (<any>result)['ex']) {
            this.ex = new ibResultError("Result", "");
            this.ex.inner = (<any>result)['ex'];
            if (this.ex && this.ex.inner && this.ex.inner.hasOwnProperty('Message')) {
                this.message = (<any>this.ex.inner)['Message'];
                if (this.message) { this.message = this.message.replace(/['"]+/g, ''); }
            } else {
                this.message = "";
            }
            if (this.ex && this.ex.inner && this.ex.inner.hasOwnProperty("InnerException")) {
                const IEx = <object>(<any>this.ex.inner)["InnerException"];
                if (IEx && IEx.hasOwnProperty("Message")) {
                    let IMsg = <string>(<any>IEx)['Message'];
                    IMsg = IMsg.replace(/['"]+/g, '');
                    this.message = this.message + ' [ ' + IMsg + ' ]';
                }
            }
            if (this.ex && this.message) { this.ex.message = this.message; }

        } else {
            this.ex = undefined;
        }

        if (result) {
            if (('value' in <object>result)) {
                this.value = (<any>result)['value'];
            } else {
                this.value = <T>result;
            }
        } else {
            this.value = undefined;
        }
    }

    public get isSuccess(): boolean {
        return (!this.ex) ? true : false;
    }

}

