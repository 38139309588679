
// -------------------------------------------------
// ib progress component
// -------------------------------------------------
// show a progress line in the header

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

import { ibIcon, IconAnimationKind } from '@common/classes/icon';
import { Icons } from '@common/classes/icons';
import { ibCoreService } from '@common/services/core-services/core.service';

@Component({
    selector: 'ib-progress',
    templateUrl: './ib-progress.component.html',
    styleUrls: [ './ib-progress.component.scss' ],
    animations: [
        trigger('progressTrigger', [
            transition(':enter', [
              style({ opacity: 0 }),
              animate('1000ms', style({ opacity: 1 })),
            ]),
            transition(':leave', [
              animate('1000ms', style({ opacity: 0 }))
            ])
          ]),
    ]
})
export class ibProgressComponent implements OnInit {

	private readonly animationKind: IconAnimationKind = 'spin';

    public inProgress: boolean;
    public spinner: ibIcon;
    public caption?: string;
    public prefixIcon?: ibIcon;

    constructor(private coreService: ibCoreService) {
        this.spinner = Icons.generalIcon('spinner', 'regular', 'xs');
        this.spinner.image.fixedWidth = true;
		this.spinner.image.animationKind = this.animationKind;
        this.inProgress = false;
    }

    ngOnInit(): void {
		this.coreService.progress.inProgress$.subscribe((request) => {
			if (!request) {
				this.spinner.image.animationMode = 'none';
				this.inProgress = false;
			} else {
				setTimeout(() => {
					this.inProgress = request.active;
					this.spinner.image.animationMode = (this.inProgress) ? 'spinner' : 'none';
					this.caption = (request.icon && request.icon.caption.text) ? request.icon.caption.text : '';
					if (request.icon && request.icon?.image) {
						this.prefixIcon = request.icon.clone('image');
					} else {
						this.prefixIcon = undefined;
					}

				}, 0)
			}
        });
    }

}
