// ---------------------------------------------------------
// help popup directive
// ---------------------------------------------------------
// Shows help in a popup
// ---------------------------------------------------------
// helpTopic string format: [subject.]topic[#subtopic]

import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { PopupService, PopupRef, PopupSettings } from "@progress/kendo-angular-popup";
import { ibHelpViewerComponent } from '@common/components/core-components/ib-help-viewer/ib-help-viewer.component';


@Directive({ selector: '[ibHelpPopup]' })
export class HelpPopupDirective{

	@Input('ibHelpPopup') helpTopic: string;
	@Input() title: string = 'Help';
	@Output() closeHelp = new EventEmitter<string>();

	private popupRef: PopupRef | undefined;
	public helpString: string;

	@HostListener("click", ["$event"])
	public onClick(event: MouseEvent): void {
		event.stopPropagation();
		this.togglePopup(this.anchor);
	}

	constructor(private anchor: ElementRef,
				private popupService: PopupService) {
	}

	public togglePopup(anchor: ElementRef) {
		if (this.popupRef) {
			this.popupRef.close();
			this.popupRef = undefined;
		} else {
			this.popupRef = this.popupService.open({
			anchor: anchor,
			content: ibHelpViewerComponent
			});
			if (this.popupRef.content.instance instanceof ibHelpViewerComponent) {
				this.popupRef.content.instance.helpTopic = this.helpTopic;
				this.popupRef.content.instance.title = this.title;
				this.popupRef.popup.instance.anchorViewportLeave.subscribe(() => {
					if (this.popupRef) {
						this.popupRef.close();
						this.popupRef = undefined;
					}
				});
			}
		}
	}

  @HostListener("document:keydown", ["$event"])
  public keydown(event: KeyboardEvent): void {
    if (event.key === 'Escape' && this.popupRef) {
		if (this.popupRef) { this.togglePopup(this.anchor); }
    }
  }

  @HostListener("document:click", ["$event"])
  public documentClick(event: any): void {
	  if (this.popupRef) { this.togglePopup(this.anchor); }
  }

}
