import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ibCoreService } from '@common/services/core-services/core.service';
import { ibIcon, IconSizes, IconTypes } from '@common/classes/icon';
import { Icons } from '@common/classes/icons';

@Component({
	selector: 'ib-page-not-found',
	templateUrl: './ib-page-not-found.component.html',
	styleUrls: ['./ib-page-not-found.component.scss']
})
export class ibPageNotFoundComponent implements OnInit {

	titleIcon: ibIcon;
	badUrl: string | null = null;

	constructor(private coreService: ibCoreService,
				private router: Router,
				private location: Location) {
		this.titleIcon=Icons.generalIcon('danger', 'regular', 'xl');
	}

	ngOnInit() {
		this.badUrl = this.router.url;
	}

	onClick(action: string) {
		switch (action) {
			case 'Back':
				if (this.canGoBack) { this.location.back(); }
				break;
			case 'Home':
				this.router.navigate(['Home']);
				break;
			default:
		}
	}

	get canGoBack(): boolean {
		return this.coreService.canGoBack;
	}

}
