// ------------------------------------------------------------------------------
// iBreeder display sizes
// ------------------------------------------------------------------------------
// utility class for display sizes - uses the display width or the window

import { Sizes, SizeComparison } from "../types";

export class ibSizes {

	static _sizeList = ['xs', 'sm', 'md', 'lg', 'xl', 'hd', '4k'];
	static _sizes: Map<Sizes, number>;

	public static get currentSize(): Sizes {
		return ibSizes.sizeName(window.innerWidth);
	}

	public static sizeName(size: number): Sizes {
		if (!Number.isInteger(size)) return 'md';
		if (!ibSizes._sizes) { ibSizes.loadSizes(); }
		const s = ibSizes.size;
		if (size >= s('xs').from && size <= s('xs').to ) { return 'xs'; }
		if (size >= s('sm').from && size <= s('sm').to ) { return 'sm'; }
		if (size >= s('md').from && size <= s('md').to ) { return 'md'; }
		if (size >= s('lg').from && size <= s('lg').to ) { return 'lg'; }
		if (size >= s('xl').from && size <= s('xl').to ) { return 'xl'; }
		if (size >= s('hd').from && size <= s('hd').to ) { return 'hd'; }
		return '4k';
	}

	public static size(size: Sizes): { from: number, to: number } {
		if (!ibSizes._sizes) { ibSizes.loadSizes(); }
		const s = ibSizes._sizes;
		switch(size) {
			case 'xs': return {from: s.get('xs')!, to: s.get('sm')! - 1}
			case 'sm': return {from: s.get('sm')!, to: s.get('md')! - 1}
			case 'md': return {from: s.get('md')!, to: s.get('lg')! - 1}
			case 'lg': return {from: s.get('lg')!, to: s.get('xl')! - 1}
			case 'xl': return {from: s.get('xl')!, to: s.get('hd')! - 1}
			case 'hd': return {from: s.get('hd')!, to: s.get('4k')! - 1}
			case '4k': return {from: s.get('4k')!, to: NaN }
			default: return {from: s.get('md')!, to: s.get('lg')! - 1}
		}
	}

	public static compare(size1: Sizes, size2: Sizes): SizeComparison {
		const index1 = ibSizes.sizeIndex(size1);
		const index2 = ibSizes.sizeIndex(size2);
		if (index1 < index2) {
			return 'shorter';
		} else if (index1 > index2) {
			return 'longer';
		} else {
			return 'equal';
		}
	}

	static sizeIndex(size: Sizes): number {
		const index = ibSizes._sizeList.lastIndexOf(size);
		return (index> -1) ? index : 3;
	}

	private static loadSizes(): void {
		ibSizes._sizes = new Map<Sizes, number>();
		ibSizes._sizes.set('xs', 0);
		ibSizes._sizes.set('sm', 576);
		ibSizes._sizes.set('md', 768);
		ibSizes._sizes.set('lg', 992);
		ibSizes._sizes.set('xl', 1280);
		ibSizes._sizes.set('hd', 1920);
		ibSizes._sizes.set('4k', 4000);
	}

}


