// Litter Document

import { ibDocument } from './ib-document';
import { Owner, OwnerBlank } from '../parts/owner';
import { BreedStub } from '@entities/parts/breed-stub';
import { LitterStatuses } from '@common/classes/enums';
import { DogStub } from '@entities/parts/dog-stub';
import { Registration } from '@entities/parts/registrations';
import { Tools } from '@common/tools';





// Contains all the litters owned by a breeder
export class LitterDocumentArrayUtil {


	static assign(source: Array<Record<string, any>>): Array<LitterDocument> {

		const target = new Array<LitterDocument>();

		for (const o of source) {
			LitterDocumentArrayUtil.addLitter(target, o);
		}

		return target;
	}


	static addLitter(array: Array<LitterDocument>, litter: Record<string, any>) {

		const s = LitterDocument.assign(litter);
		if (!LitterDocumentArrayUtil.getLitterByUid(array, s.id)) {
			array.push(s);
		} else {
			throw new Error('Duplicate litter - ' + s.name + '- cannot be added');
		}
	}

	static getLitterByUid(array: Array<LitterDocument>, id: string): LitterDocument | null {
		for (const s of array) {
			if (s.id === id) { return s; }
		}
		return null;
	}


	public getLitter(array: Array<LitterDocument>, prettyUrl: string): LitterDocument | null {
		if (prettyUrl in array) {
			return (<any> array)[prettyUrl];
		} else {
			return null;
		}
	}

	// public getLitters(): Array<LitterDocument> {
	//    let lst = new Array<LitterDocument>();
	//    for (let k in this._store) {
	//        lst.push((<any>this._store)[k]);
	//    }
	//    return lst;
	// }

	public putLitter(array: Array<LitterDocument>, litter: LitterDocument) {
		(<any> array)[litter.prettyUrl] = litter;
		// let evt = new CustomEvent(this._eventName, { detail: { action: "put", litterName: litter.prettyUrl } })
		// dispatchEvent(evt);
	}

	public deleteLitter(array: Array<LitterDocument>, litter: LitterDocument) {
		delete (<any> array)[litter.id];
		// let evt = new CustomEvent(this._eventName, { detail: { action: "del", litterName: litter.prettyUrl } })
		// dispatchEvent(evt);
	}

}

// Litter Document
export class LitterDocument extends ibDocument {

	static assign(source: Record<string, any>, target: LitterDocument | null = null): LitterDocument {

		let id: string;

		if ('id' in source) {
			id = (<any> source)['id'];
		} else {
			throw new Error('id is required');
		}

		if (!target) {
			target = new LitterDocument(id);
		} else {
			target.id = id;
		}

		if ('name' in source) {
			target.name = (<any> source)['name'];
		} else {
			throw new Error('\'name\' is required');
		}

		if ('owner' in source) {
			target.owner = Owner.assign((<any> source)['owner']);
		} else {
			throw new Error('\'owner\' is required');
		}

		if ('manager' in source) {
			target.manager = Owner.assign((<any> source)['manager']);
		} else {
			throw new Error('\'manager\' is required');
		}

		if ('breed' in source) {
			target.breed = BreedStub.assign((<any> source)['breed']);
		} else {
			throw new Error('\'breed\' is required');
		}

		if ('dob' in source) {
			target.dob = new Date((<any> source)['dob']);
		} else {
			throw new Error('\'date\' is required');
		}

		if ('AKCRegistration' in source) {
			target.akcRegistration = (<any> source)['AKCRegistration'];
		}

		if ('registrations' in source) {
			target.registrations = (<any> source)['registrations'];
		}

		if ('comments' in source) {
			target.comments = (<any> source)['comments'];
		}

		if ('status' in source) {
			const s = (<any> source)['status'];
			target.status = (typeof s === 'string') ? (<any> LitterStatuses)[s] : <LitterStatuses> s;
		} else {
			target.status = LitterStatuses.Active;
		}

		if ('dam' in source) {
			target.dam = DogStub.assign((<any> source)['dam']);
		} else {
			target.dam = DogStub.createBlank('female');
		}

		if ('sire' in source) {
			target.sire = DogStub.assign((<any> source)['sire']);
		} else {
			target.sire = DogStub.createBlank('male');
		}

		if ('documents' in source) {
			target.documents = (<any> source)['documents'];
		}

		if ('events' in source) {
			target.events = (<any> source)['events'];
		}

		if ('notes' in source) {
			target.notes = (<any> source)['notes'];
		}

		if ('defaultImage' in source) {
			target.notes = (<any> source)['defaultImage'];
		}

		return target;
	}


	constructor(id: string) {
		super('LitterDocument', id);
		this.name = 'New Litter';
		this.owner = new OwnerBlank();
		this.manager = this.owner;
		this.breed = BreedStub.createBlank();
		this.dob = null;
		this.akcRegistration = null;
		this.registrations = new Array<Registration>();
		this.comments = null;
		this.status = LitterStatuses.New;
		this.dam = DogStub.createBlank('female');
		this. sire = DogStub.createBlank('male');
		this.documents = new Array<string>();
		this.events = new Array<string>();
		this.notes = new Array<string>();
		this.defaultImage = null;
	}

	public get litterID(): string { return this.name; }
	public name: string;
	public owner: Owner;
	public manager: Owner;
	public breed: BreedStub;
	public dob: Date | null;
	public akcRegistration: string | null;  // Obsolete
	public registrations: Array<Registration>;
	public comments: string | null;
	public status: LitterStatuses;
	public dam: DogStub;
	public sire: DogStub;
	public documents: Array<string>;
	public events: Array<string>;
	public notes: Array<string>;
	public defaultImage: string | null;

	get prettyUrl(): string { return Tools.toPrettyUrl(this.name, true); }

	registrationSummary(): string {
		let ret = '';
		for (const r of this.registrations) {
			ret += r.registrar?.toLowerCase() + ': ' + r.registrationNumber + ' / ';
		}
		if (ret.length >= 3) { ret = ret.substr(0, ret.length - 3); }
		return ret;
	}

}
