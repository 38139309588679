
// --------------------------------------------
// Tools
// --------------------------------------------


import { interval, timer } from "rxjs";
import { delayWhen, map, takeWhile } from "rxjs/operators";
import { ImageSizes, Separators } from "./classes/enums";
import { v4 as Guid, validate as validateGuid } from 'uuid';
import { ibUrl } from "./classes/ibUrl";

export interface INameIndex {
    name: string;
    index: number;
}

export enum DocumentTypes {
    Images = 0,
    Documents = 1
}

export enum Units {
    inches = 0,
    pounds = 1,
    years = 2
}


export class Tools {

    static _symbols = ' !#$%&*+?@^~';
    static _vowels = 'AEIOUY';
    static _consonants = 'BCDFGHJKLMNPQRSTVWXZ';
    static _digits = '0123456789';


    static createType<T>(type: (new () => T)): T {
        return new type();
    }

	static getObjectPropertyType<T, K extends keyof T>(obj: T, propertyName: K): T[K]{
		return obj[propertyName];
	}

    static ArrayMove(array: Array<any>, from: number, to: number): void {
        let tmp: any;

        // cast input parameters to integers
        const pos1 = parseInt(from.toString(), 10);
        const pos2 = parseInt(to.toString(), 10);
        if (pos1 !== pos2 && pos1 >= 0 && pos1 < array.length && pos2 >= 0 && pos2 < array.length) {
            // save element from position 1
            tmp = array[pos1];

            // move element down and shift other elements up
            if (pos1 < pos2) {
                for (let i: number = pos1; i < pos2; i++) {
                    array[i] = array[i + 1];
                }
            } else {
                // move element up and shift other elements down
                for (let i: number = pos1; i > pos2; i--) {
                    array[i] = array[i - 1];
                }
            }
            // put element from position 1 to destination
            array[pos2] = tmp;
		}
    }

    static ArrayScramble(array: Array<any>): Array<any> {
        let currentIndex = array.length;
        let temporaryValue: any;
        let randomIndex: number;

        while (currentIndex !== 0) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    static ArrayConcatUnique(a1: Array<string>, a2: Array<string>): Array<string> {
        const both = [ ...a1, ...a2 ];
        const res = new Array<string>();
        both.forEach((value: string) => {
            if (!res.includes(value)) { res.push(value); }
        });
        return res;
    }

	static permute(source: Array<any>, maxSize?: number, minSize?: number): Array<string> {

		// ' actual size

		// ' do '

		// ' add each letter from source'



		// var length = permutation.length,
		// 	result = [permutation.slice()],
		// 	c = new Array(length).fill(0),
		// 	i = 1, k, p;

		// while (i < length) {
		//   if (c[i] < i) {
		// 	k = i % 2 && c[i];
		// 	p = permutation[i];
		// 	permutation[i] = permutation[k];
		// 	permutation[k] = p;
		// 	++c[i];
		// 	i = 1;
		// 	result.push(permutation.slice());
		//   } else {
		// 	c[i] = 0;
		// 	++i;
		//   }
		// }
		return [];
	  }


	public static deepCopy<T>(source: T): T {
		return Array.isArray(source)
		? source.map(item => this.deepCopy(item))
		: source instanceof Date
		? new Date(source.getTime())
		: source && typeof source === 'object'
			  ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
				 Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
				 o[prop] = this.deepCopy((source as { [key: string]: any })[prop]);
				 return o;
			  }, Object.create(Object.getPrototypeOf(source)))
		: source as T;
	  }

    // Returns a random integer within a range
    static randomInteger(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    static generatePassword(pattern: string = ''): string {
        const buffer = new Array<string>();
        if (!pattern) { pattern = '##Cvc$Vcv'; }
        const p = <Array<'v' | 'V' | 'c' | 'C' | '#' | '$'>>pattern.split('');
        p.forEach((char: 'v' | 'V' | 'c' | 'C' | '#' | '$') => {
            buffer.push(this.getRandomChar(char));
        });
        return buffer.join('');
    }

    static getRandomChar(type: 'v' | 'V' | 'c' | 'C' | '#' | '$'): string {

        let char: string ='';
        switch(type) {
            case 'c':
            case 'C':
                char = Tools._consonants[this.randomInteger(0, Tools._consonants.length-1)];
                if (type === 'c') { char = char.toLocaleLowerCase(); }
                break;
            case 'v':
            case 'V':
                char = Tools._vowels[this.randomInteger(0, Tools._vowels.length-1)];
                if (type === 'v') { char = char.toLocaleLowerCase(); }
                break;
            case '$':
                char = Tools._symbols[this.randomInteger(0, Tools._symbols.length-1)];
                break;
            case '#':
                char = Tools._digits[this.randomInteger(0, Tools._digits.length-1)];
                break;
        }
        return char;
    }

	static removeChar(source: string, position: number): string {
		if (!source || source.length === 0 || position < 0 || position > source.length) { return source; }
		// console.log('original : ' + source + `    position : ${position}`);
		// console.log('left     : ' + source.slice(0, position -1));
		// console.log('right    : ' + source.slice(position, source.length));
		return source.slice(0, position -1) + source.slice(position, source.length);
	}

    // Returns the last integer in a string
    static extractLastInteger(source: string): number {
        if (!source) {
            throw new Error('extractLastInteger requires a source string');
        }
        let i = source.length;
        let buffer = '';
        while (i--) {
            const c = source[i];
            if (c >= '0' && c <= '9') {
                buffer = c + buffer;
            } else {
                break;
            }
        }
        return (!buffer) ? 0 : parseInt(buffer, 10);
    }

    // Returns the root and index in a string 'root-index'
    static extractNameIndex(source: string): INameIndex {
        if (!source) {
            throw new Error('extractLastInteger requires a source string');
        }
        const response: INameIndex = { name: '', index: -1 };
        let i = source.lastIndexOf('-');
        response.name = source.substr(0, i);
        response.index = parseInt(source.substring(++i), 10);
        return response;
    }

    static extractFirstWord(source: string, capitalize: boolean = false): string {
        const r = new RegExp(/(?:^|(?:[.!?]\s))(\w+)/);
        const res = r.exec(source);
        if (res) {
            return (capitalize) ? this.toTitleCase(res[0]) : res[0];
        } else { return ''; }
    }


	// ---------------------------------------------
	// string formatting
	// ---------------------------------------------

	// obsolete - moved to formatUrl in ibUrl
    static toPrettyUrl(value: string, preserveCase: boolean = false): string {
        if (!value) {
            return '';
        }
        let lastCharWasUnderscore = false;

        value = value.trim();
        const chars = value.split('');
        let buffer = '';
        chars.forEach((c) => {
            if (c >= '0' && c <= '9') {
                buffer = buffer + c;
                lastCharWasUnderscore = false;
                return;
            }

            if (c >= 'a' && c <= 'z') {
                buffer = buffer + c;
                lastCharWasUnderscore = false;
                return;
            }

            if (c >= 'A' && c <= 'Z') {
                if (!preserveCase) {
                    c = c.toLowerCase();
                }
                if (!lastCharWasUnderscore && buffer.length > 0) {
                    buffer = buffer + '-';
                }
                buffer = buffer + c;
                lastCharWasUnderscore = false;
                return;
            }

            if (c === ' ' || c === '-' || c === '_') {
                if (!lastCharWasUnderscore) {
                    buffer = buffer + '-';
                }
                lastCharWasUnderscore = true;
                return;
            }
        });

        return buffer;
    }

    // Replaces - with space and capitalize first letter of each word or space before numeric value
    static fromPrettyUrl(value: string): string {
        if (!value) {
            return '';
        }

        value = value.trim();
        const chars = value.split('');
        let lastCharWasSpace = true;
        let lastCharWasDigit = false;
        let buffer = '';

        chars.forEach((c) => {
            if (c === '-' || c === ' ') {
                buffer = buffer + ' ';
                lastCharWasSpace = true;
                lastCharWasDigit = false;
            }

            if (c >= '0' && c <= '9') {
                if (!lastCharWasDigit) {
                    buffer += ' ';
                }
                buffer = buffer + c;
                lastCharWasSpace = false;
                lastCharWasDigit = true;
            }

            if (c >= 'A' && c <= 'Z') {
                buffer = buffer + c;
                lastCharWasSpace = false;
            }

            if (c >= 'a' && c <= 'z') {
                if (lastCharWasSpace) {
                    c = c.toUpperCase();
                }
                buffer = buffer + c;
                lastCharWasSpace = false;
            }
        });
        return buffer;
    }

    // Replaces - capitalize first letter of each word after a space and remove - or space
    static toPropertyName(value: string): string {
        if (!value) {
            throw new Error('value cannot be empty');
        }

        value = value.trim();
        const chars = value.split('');
        let lastCharWasSpace = true;
        let buffer = '';

        chars.forEach((c) => {
            if (c === '-' || c === ' ') {
                // buffer = buffer + " ";
                lastCharWasSpace = true;
            }

            if (c >= '0' && c <= '9') {
                // if ( !LastCharWasDigit ) { buffer += ' '; }
                buffer = buffer + c;
                lastCharWasSpace = false;
            }

            if (c >= 'A' && c <= 'Z') {
                buffer = buffer + c;
                lastCharWasSpace = false;
            }

            if (c >= 'a' && c <= 'z') {
                if (lastCharWasSpace) {
                    c = c.toUpperCase();
                }
                buffer = buffer + c;
                lastCharWasSpace = false;
            }
        });
        return buffer;
    }

	// all lower case words separated by -
	static toKebabCase(value: string): string {
		if (value) {
        	value = value.trim();
			const m =  value.match(/[A-Z]{2,}(?=[A-Z][a-z]+|\b)|[A-Z]?[a-z]+|[A-Z]|[0-9]+/g);
			if (m) {
				return m.map((word) => word.toLowerCase()).join('-');
			}
        	}
            return value;
	}

	// transforms KebabCase to caption: words separated by space and words capitalized
    static toCaption(value: string): string {
		if (!value) { return ''; }
		const part = value.split('-');
		const capitalized = part.map((value: string) => {
			return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
		});
		return capitalized.join(' ');
    }

    static toTitleCase(value: string): string {
        if (!value) {
            return value;
        }
        return value.replace(/\w\S*/g, (txt) =>
            txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
    }

    static toCleanURL(url: string): string {
        url = url.replace(/\t/gi, '');
        url = url.replace(/\n/gi, ' ');
        return url;
    }

    static toRomanNumeral(value: number): string {
        const romanNumerals = [ 'M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I' ];
        const decimalNumerals = [ 1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1 ];

        if (value <= 0 || value >= 4000) {
            return value.toString();
        }

        let romanNumeral = '';
        for (let i = 0; i < romanNumerals.length; i++) {
            while (value >= decimalNumerals[i]) {
                value -= decimalNumerals[i];
                romanNumeral += romanNumerals[i];
            }
        }

        return romanNumeral;
    }

    static toAlpha(value: number): string {
        switch (value) {
            case 0: return '';
            case 1: return '1st';
            case 2: return '2nd';
            case 3: return '3rd';
            default: return `${value}th`;
        }
    }

	// ------------------------------------------------------
	// types
	// ------------------------------------------------------

    static isDigit(value: string): boolean {
        if (!value || value.length !== 1) { return false; }
        return !isNaN(parseInt(value, 10));
    }

    static isInteger(value: string): boolean {
        if (!value) { return false; }
        value = value.trim();
        value = value.replace(/^0+/, "") || "0";
        const n = Math.floor(Number(value));
        return n !== Infinity && String(n) === value && n >= 0;
    }

    static toUnitName(value: string): string {
        if (!value) {
            return 'unknown';
        }

        switch (value.toLowerCase()) {
            case 'inch':
            case 'inches':
            case 'in':
            case 'in.':
            case '"':
                return 'inches';
            case 'pound':
            case 'pounds':
            case 'lb':
            case 'lb.':
                return 'pounds';
            case 'year':
            case 'years':
            case 'yr':
            case 'yr.':
                return 'years';
            default:
                return 'unknown';
        }
    }

    static isUnitName(value: string): boolean {
        return (this.toUnitName(value) !== 'unknown');
    }

    static notNull(source: string | null): string {
        if (source) {
            return source;
        } else {
            return '';
        }
    }

    // static async wait(condition: () => boolean, maxTimeSpan: number = 2000): Promise<boolean> {
    //     let timeSpan: number = 0;
    //     while (condition() && timeSpan < maxTimeSpan) {
    //         await Tools.sleep(250).then(() => {
    //             timeSpan += 250;
    //         });
    //     }
    //     return (timeSpan <= maxTimeSpan);
    // }

    static wait(condition: () => boolean, maxTimeSpan: number = 2000):Promise<boolean> {

		return new Promise<boolean>((resolve, reject) => {
			let span: number;
			const retryDelay = () => timer(250);
			return interval(250).pipe(
				map(() => {
					span += 250;
					return condition(); }),
				takeWhile(() => {
					return !condition() && span < maxTimeSpan;
				}),
				delayWhen(retryDelay)
			).subscribe( (res: boolean) => {
				if (res) { resolve(true) } else { reject('timed out'); }
			})
		})
	}

    static sleep(ms: number): Promise<unknown> {
        return new Promise<unknown>((resolve) => setTimeout(resolve, ms));
    }

	static debounce(callback: () => void, wait: number) {
		let timeoutId: number | undefined;
		console.log('debouncing');
		return () => {
			window.clearTimeout(timeoutId);
			timeoutId = window.setTimeout(() => {
				callback.apply(null, []);
			}, wait);
		}
	}

    // extract value returned by resolvers.
    static getResolvedData<T>(value: object, propertyName: string = 'resolvedData'): T {
        if (!value) {
            return <T> {};
        }
        if (propertyName in value) {
            const v = <T> (<any> value)[propertyName];
            return v;
        } else {
            return <T> {};
        }
    }

    static getNextName(collection: Array<string> | Array<object> | undefined,
                       fieldName: string,
                       rootName: string = 'item',
                       startingIndex: number = 0,
                       separator: Separators = Separators.dash
    ): string {
        if (!collection || collection.length < 1) {
            return rootName;
        }

        let index = -1;
        for (const e of collection) {
            if (e) {
		   	    let value: string = '';
                if (typeof e === 'string') {
                    value = e;
                } else if (typeof e ==='object' && fieldName in e) {
                    value = <string> (<any> e)[fieldName];
                }
                if (value.startsWith(rootName)) {
                    value = value.replace(rootName, 'xxx');
                    const i = this.extractLastInteger(value);
                    if (i > index) {
                        index = i;
                    }
                }
            } else {
                throw new Error('Cannot generate a new name: The field Name cannot be found in at least one record');
            }
        }
        index += 1;
        if (index < startingIndex) {
            index = startingIndex;
        }
        return (index === 0) ? rootName : Tools.InsertPostIndex(rootName, index.toString(), separator);
    }

    static InsertPostIndex(value: string, index: string, sep: Separators = Separators.dash): string {
        switch (sep) {
            case Separators.space: return `${value} ${index}`;
            case Separators.dash: return `${value}-${index}`;
            case Separators.rightSlash: return `${value}/${index}`;
            case Separators.leftSlash: return `${value}\${index}`;
            case Separators.braces: return `${value}{${index}}`;
            case Separators.brackets: return `${value}[${index}]`;
            case Separators.parenthesis: return `${value}(${index})`;
            case Separators.angleBrackets: return `${value}<${index}>`;
            default: return '';
        }
    }

    static newGuid(): string {
        // creates a standard compatible uuid v4
        const guid = Guid();
        return guid;
    }

    static resizeImage(url?: string, size: ImageSizes = ImageSizes.small): string {
        if (!url) {
            throw new Error('Resize - source image url is null or empty');
        }
        const sizeString = (size !== null) ? ImageSizes[size] : '';
        const offset = (size !== null) ? 1 : 0;
        const dot = url.lastIndexOf('.');
        const dash = url.lastIndexOf('-');
        let s = url.substr(0, dash + offset) + sizeString + url.substr(dot);
        if (size === ImageSizes.master) {
            if (s.indexOf('/source/') < 0) {
                s = s.replace('/media/', '/source/');
            }
        } else {
            if (s.indexOf('/media/') < 0) {
                s = s.replace('/source/', '/media/');
            }
        }
        return s;
    }

	static imageSize(size: number) {
		const options = {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		  };
		if (size === 0) {
			return '0 byte';
		}
		if (size > 999999) {
			const n = size / 1000000;
			return `${n.toLocaleString('en', options)} MB`;
		}
		if (size > 999) {
			const n = size / 1000;
			return `${n.toLocaleString('en', options)} KB`;
		}
		return `${size} B`
	}

    static MetersToMiles(meters: number | undefined): number {
        if (!meters) {
            return 0;
        }
        return Math.floor(meters * 0.000621371192);
    }

    static MilesToMeters(miles: number | undefined): number {
        if (!miles) {
            return 0;
        }
        return Math.floor(miles * 1609.344);
    }

    static print(id: string): void {
        const _width = Tools.width() * 0.6;
        const _height = Tools.height() * 0.6;
        const content = document?.getElementById(id)?.innerHTML;
        if (content) {
            const w = window.open('', '', `height=${_width}, width=${_height}`);
            if (w) {
                w.document.write('<html>');
                w.document.write('<body >');
                w.document.write(content);
                w.document.write('</body></html>');
                w.document.close();
                w.print();
            }
        }
    }

    static width(): number {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }
    static height(): number {
        return window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
    }

    static getElementFullWidth(el: HTMLElement): number {
            const view = window.getComputedStyle(el);
            const width = parseFloat(view.getPropertyValue('width'));
            const marginLeft = parseFloat(view.getPropertyValue('margin-left'));
            const marginRight = parseFloat(view.getPropertyValue('margin-right'));
            return width + marginLeft + marginRight;
    }

}

// static fromIsoDate(source: Object): Date {

//    if ('isoDate' in source) {
//        let s = <string>(<any>source)['isoDate'];
//        let all = s.split('T');
//        let dPart = all[0].split('-');
//        let tPart = all[1].split(':');
//        let sPart = tPart[2].split('.');
//        let ms = (sPart.length > 1) ? Number(sPart[1]) : 0;

//        let d = new Date(Number(dPart[0]), Number(dPart[1]), Number(dPart[2]), Number(tPart[0]), Number(tPart[1]), Number(sPart[0]), ms);

//        return d
//    } else {
//        throw ("Source does not have an IsoDate property");
//    }

// }


// static ArrayMove(array: Array<any>, from: number, to: number): void {

//    let i, tmp: any;

//    // cast input parameters to integers
//    let pos1 = parseInt(from.toString(), 10);
//    let pos2 = parseInt(to.toString(), 10);
//    if (pos1 !== pos2 && pos1 >= 0 && pos1 < array.length && pos2 >= 0 && pos2 < array.length) {

//        // save element from position 1
//        tmp = array[pos1];

//        // move element down and shift other elements up
//        if (pos1 < pos2) {
//            for (i = pos1; i < pos2; i++) {
//                array[i] = array[i + 1];
//            }
//        }
//        // move element up and shift other elements down
//        else {
//            for (i = pos1; i > pos2; i--) {
//                array[i] = array[i - 1];
//            }
//        }
//        // put element from position 1 to destination
//        array[pos2] = tmp;
//    }
// }

// static replaceParameters(query: string, Parameters: Array<DicEntry>): string {

//    if (!query) return query;
//    let buffer = query;

//    for (let param of Parameters) {
//        let p = "@" + param.name.toLowerCase();
//        buffer = buffer.replace(p, param.value);
//    }

//    return buffer;

// }

// static getNextName(collection: Array<Object>, fieldName: string, rootName: string): string {
//    if (!collection || collection.length < 1) return rootName

//    let index = -1;
//    for (let e of collection) {
//        if (e && (<any>e)[fieldName]) {
//            let value = <string>(<any>e)[fieldName];
//            if (value.startsWith(rootName)) {
//                let i = this.extractLastInteger(value);
//                if (i > index) index = i;
//            }
//        } else {
//            throw ("Cannot generate a new name: The field Name cannot be found in at least one record")
//        }
//    }
//    index += 1;
//    return (index == 0) ? rootName : rootName + " " + index.toString();
// }

// Extends enumerations
export class EnumEx {
    static getNamesAndValues<T extends number>(e: any): Array<any> {
        return EnumEx.getNames(e).map((n) => ({ name: n, value: e[n] as T }));
    }

    static NamesValues<T extends number>(e: any): Array<NameValue> {
        const entries = EnumEx.getNamesAndValues<T>(e);
        const ret = new Array<NameValue>();
        entries.forEach((entry) => {
            ret.push(new NameValue(entry.name, entry.value));
        });
        return ret;
    }

    static NamesNames<T extends number>(e: any): Array<NameValue> {
        const entries = EnumEx.getNamesAndValues<T>(e);
        const ret = new Array<NameValue>();
        entries.forEach((entry) => {
            ret.push(new NameValue(entry.name, entry.name.toLowerCase()));
        });
        return ret;
    }


    static getNames(e: any): Array<string> {
        return EnumEx.getObjValues(e).filter((v) => typeof v === 'string') as Array<string>;
    }

    static getValues<T extends number>(e: any): Array<T> {
        return EnumEx.getObjValues(e).filter((v) => typeof v === 'number') as Array<T>;
    }

    private static getObjValues(e: any): Array<number | string> {
        return Object.keys(e).map((k) => e[k]);
    }
}


// // The result class is used by server functions to carry either the returned value or an error message
// export class ibResult<T> {
// 	public ex: ibError | null;
// 	public message: string;
// 	public value: T | null;

// 	public static faulted<T>(message: string): ibResult<T> {
// 		const res = new ibResult<T>(null);
// 		res.ex = new ibError('Result', message);
// 		res.message = message;
// 		res.value = null;
// 		return res;
// 	}

// 	public static success<T>(value: any = null): ibResult<T> {
// 		const res = new ibResult<T>(null);
// 		res.ex = null;
// 		res.message = '';
// 		res.value = value;
// 		return res;
// 	}

// 	constructor(result: any) {
// 		if (result && result.hasOwnProperty('ex') && (<any> result)['ex']) {
// 			this.ex = new ibError('Result', '');
// 			this.ex.inner = (<any> result)['ex'];
// 			if (this.ex && this.ex.inner && this.ex.inner.hasOwnProperty('Message')) {
// 				this.message = (<any> this.ex.inner)['Message'];
// 				this.message = this.message.replace(/['"]+/g, '');
// 			} else {
// 				this.message = '';
// 			}
// 			if (this.ex && this.ex.inner && this.ex.inner.hasOwnProperty('InnerException')) {
// 				const iEx = <object> (<any> this.ex.inner)['InnerException'];
// 				if (iEx && iEx.hasOwnProperty('Message')) {
// 					let iMsg = <string> (<any> iEx)['Message'];
// 					iMsg = iMsg.replace(/['"]+/g, '');
// 					this.message = this.message + ' [ ' + iMsg + ' ]';
// 				}
// 			}
// 			this.ex.message = this.message;
// 		} else {
// 			this.ex = null;
// 		}

// 		if (result) {
// 			if (result.hasOwnProperty('value')) {
// 				this.value = (<any> result)['value'];
// 			} else {
// 				this.value = <T> <unknown> result;
// 			}
// 		} else {
// 			this.value = null;
// 		}
// 	}

// 	public get isSuccess(): boolean {
// 		return (!this.ex) ? true : false;
// 	}
// }

// export class ibError {
// 	private _type: string;
// 	private _message: string;
// 	private _inner: object;

// 	constructor(type: string, message: string) {
// 		this._type = type;
// 	}

// 	get type(): string {
// 		return this._type;
// 	}

// 	get message(): string {
// 		return this._message;
// 	}
// 	set message(value: string) {
// 		this._message = value;
// 	}

// 	get inner(): object {
// 		return this._inner;
// 	}
// 	set inner(value: object) {
// 		this._inner = value;
// 	}

// 	public isType(type: string): boolean {
// 		if (!this._type || !type) {
// 			return false;
// 		}
// 		return (type.toLowerCase() === this._type.toLowerCase());
// 	}
// }


// name is normalized to caption, value to lowercase if string
export class NameValue {

    static assign(source: object): NameValue {
        const nv = new NameValue();

        if ('name' in source) {
            const val = <string> (<any> source)['name'];
            nv.name = Tools.fromPrettyUrl(val);
        } else {
            throw new Error('Required property \'name\' is missing');
        }

        if ('value' in source) {
            const val = <string> (<any> source)['value'];
            nv.value = Tools.toCaption(val);
        } else {
            nv.value = Tools.toPrettyUrl(nv.name);
        }

        if ('icon' in source) {
            nv.icon = <string> (<any> source)['icon'];
        } else {
            nv.icon = undefined;
        }


        return nv;
	}

	static fromEnum<T extends number>(e: any): Array<NameValue> {
        return EnumEx.getNames(e).map((n) => new NameValue(n, e[n]));
	}

	static fromArray(array: string[]): Array<NameValue> {
		if (array && Array.isArray(array)) {
			return array.map((value: string) => {
				return new NameValue(value);
			});
		} else {
			return new Array<NameValue>();
		}
	}

	static create(name: string = '', value?: any, icon?: string): NameValue {
        const nv = new NameValue(name, value);
        nv.icon = icon;
        return nv;
    }

	static createUnformatted(name: string = '', value: unknown = undefined, icon?: string): NameValue {
        const nv = new NameValue();
        nv.name = name;
        nv.value = value;
        if (icon) nv.icon = icon;
        return nv;
    }

    constructor(name: string = '', value?: unknown) {
        this.name = ibUrl.toTitleCase(name);
        if (!value && value !== 0) {
            this.value = Tools.toPrettyUrl(name);
        } else {
            this.value = (typeof value === 'string') ? value.toLowerCase() : value;
        }
    }

    public name: string;
    public value: unknown;
    public icon?: string;

    public get id(): any {
        return this.value;
	}

	public get display(): string {
		if (this.icon) {
			return `<i class='${this.icon}'></i><span>${this.name}</span>`;
		} else {
			return this.name;
		}
	}
}

//
export class KeyValue<T> {
    public key: string;
    public value: T;

    constructor(key: string, value: T) {
        this.key = key;
        this.value = value;
    }
}
// this is used to track changes across objects
// export class ChangeTracker {

//    private _valid: Object;
//    private _pristine: Object;
//    private _untouched: Object;
//    private _form: Object;

//    constructor() {
//        this._valid = new Object();
//        this._pristine = new Object();
//        this._untouched = new Object();
//        this._form = new Object();
//    }

//    public setState(form: NgForm, Field: string, OldField: string = null): boolean {

//        if (OldField) {
//            (<any>this._valid)[Field] = (<any>this._valid)[OldField];
//            (<any>this._pristine)[Field] = (<any>this._pristine)[OldField];
//            (<any>this._untouched)[Field] = (<any>this._untouched)[OldField];
//            (<any>this._form)[Field] = (<any>this._form)[OldField];

//            delete (<any>this._valid)[OldField];
//            delete (<any>this._pristine)[OldField];
//            delete (<any>this._untouched)[OldField];
//            delete (<any>this._form)[OldField];
//        }

//        (<any>this._valid)[Field] = form.valid;
//        (<any>this._pristine)[Field] = form.pristine;
//        (<any>this._untouched)[Field] = form.untouched;
//        (<any>this._form)[Field] = form;

//        return this.canSave;
//    }

//    get isValid(): boolean {
//        for (let p in this._valid) {
//            if (!(<any>this._valid)[p]) return false
//        }
//        return true;
//    }

//    get isPristine(): boolean {
//        for (let p in this._pristine) {
//            if (!(<any>this._pristine)[p]) return false
//        }
//        return true;
//    }

//    get isUnTouched(): boolean {
//        for (let p in this._untouched) {
//            if (!(<any>this._untouched)[p]) return false
//        }
//        return true;
//    }

//    public reset(): void {
//        for (let p in this._form) {
//            let f = <NgForm>(<any>this._form)[p];
//            f.reset(f.value); // this is not tested
//        }

//    }

//    public get canSave(): boolean {
//        return this.isValid && !this.isPristine;
//    }

// }

export class Dom {
    static hasClass(el: HTMLElement, className: string): boolean {
        if (el.classList) {
            return el.classList.contains(className);
        } else {
            return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
        }
    }

    static addClass(el: HTMLElement, className: string): void {
        if (el.classList) {
            el.classList.add(className);
        } else if (!Dom.hasClass(el, className)) {
            el.className += ' ' + className;
        }
    }

    static removeClass(el: HTMLElement, className: string): void {
        if (el.classList) {
            el.classList.remove(className);
        } else if (Dom.hasClass(el, className)) {
            const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
            el.className = el.className.replace(reg, ' ');
        }
    }

    static moveLiToTop(e: HTMLLIElement): void {
        const ul = <HTMLUListElement> e.parentElement;
        ul.scrollTop = (e.offsetTop - 50);
    }
}

export class BlobPath {
    constructor(container: string, path: string = '', documentType: DocumentTypes = 0) {
        this.container = container;
        this.path = path;
        this.documentType = documentType;
    }

    container: string;
    path: string;
    get FullPath(): string {
        return (this.path + '/' + DocumentTypes[this.documentType]).toLowerCase();
    }
    documentType: DocumentTypes;

    public ToString(): string {
        return (this.container.toLowerCase()) + '/' + this.FullPath;
    }
}

export type MatchResult = 'none' | 'partial' | 'complete';



// used in input component and phone number component - replaced by Pattern class
// can remove after input and phone number are removed
export class Format {

	private static _pattern: Array<string>;

	public static get pattern(): string { return Format._pattern.join(''); }
	public static set pattern(value: string) { Format._pattern = [ ...value ]; }

	public static match(value: string): MatchResult {
		if (!value) { return 'none' }
		const v = [...value];
		let match = 0;
		for (let i=0; i < v.length; i++) {
			if (v[i] === Format._pattern[i]) {
				match++;
			} else {
				break;
			}
		}
		return (match === 0) ? 'none' : (match < Format._pattern.length) ? 'partial' : 'complete'
	}

	public static toPattern(value: string): string {
		const result = [ ...Format._pattern ];
		const stripped = [ ...value ].filter((v: string) => Tools.isDigit(v));
		let patternIndex: number = 0;

		stripped.forEach((c: string) => {
			patternIndex = result.indexOf('#', patternIndex);
			if (patternIndex >=0){ result[patternIndex] = c; }
        });

        do {
			if (!Tools.isDigit(result[result.length-1])) { result.pop(); } else { break;};
        } while(result.length > 0);

        const res = result.join('');
        return res;
	}

	public static toRawArray(value: string | Array<string>): Array<string> {
		let raw = (typeof value === 'string') ? [ ...value ] : value;
		return raw.filter((v: string) => Tools.isDigit(v));
	}

	public static toRaw(value: string | Array<string>): string {
		return Format.toRawArray(value).join('');
	}

	// public static toRawIndex(formattedIndex: number): number {
	// 	if (!Format._pattern) { return -1; }
	// 	if (formattedIndex < 0 || formattedIndex > Format._pattern.length) { return -1; }
	// 	let patternPosition: number = -1;
	// 	let rawPosition: number = -1;
	// 	for(let v of Format._pattern) {}
	// 	// for(let v of Format._pattern) {
	// 	// 	patternPosition++;
	// 	// 	if (v === '#') {
	// 	// 		rawPosition++;
	// 	// 		if (patternPosition === formattedIndex) {
	// 	// 			return rawPosition;
	// 	// 		}
	// 	// 	}
	// 	// };
	// 	return -1;
	// }

	public static toFormattedIndex(rawIndex: number): number {
		if (!Format._pattern) { return -1; }
		if (rawIndex < 0) { return -1; }
		let patternPosition: number = -1;
        let rawPosition: number = -1;
        for (const v of Format._pattern) {
			patternPosition++;
			if (v === '#') {
				rawPosition++;
				if (rawPosition === rawIndex) {
					return patternPosition;
				}
			}
        }
		return -1;
	}

	// public static remove(value: string, startIndex: number, endIndex: number, direction: RemoveDirection): string {

    //     // console.log(`START  value: ${value} Range: ${startIndex}-${endIndex} Direction: ${direction}`);

    //     // const val = [ ...value ];
    //     // if (val.length <= 0) {return '';}
    //     // if (startIndex < 0) { startIndex = 0; }
    //     // if (endIndex > val.length) { endIndex = val.length; }
    //     // if (startIndex < endIndex) {
    //     //     const v = startIndex;
    //     //     startIndex = endIndex;
    //     //     endIndex = v;
    //     // }


    //     // console.log(' Value as array');
    //     // console.log(val);
    //     // console.log(`range: ${startIndex}-${endIndex}`);

    //     // if (direction === 'Backspace') {
    //     //     while(startIndex >= 0) {
    //     //         console.log(val[startIndex]);
    //     //         startIndex--;
    //     //     }
    //     // }
    //     // console.log("---------------------------");


	// 	// // if (startIndex === endIndex) {
	// 	// // 	if (direction === 'Backspace') {
	// 	// // 		startIndex--;
    //     // //         console.log(`REMOVE 1 CHARACTER @ ${startIndex} ${direction}`);
    //     // //         console.log(`removing: ${val[startIndex]}`);
	// 	// // 		// if(!Tools.isDigit(val[startIndex])) { startIndex--; }
	// 	// // 	} else {
    //     // //         console.log("REMOVE 1 CHARACTER " + direction);
	// 	// // 		endIndex++;
	// 	// // 		if(!Tools.isDigit(val[endIndex])) { endIndex++; }
	// 	// // 	}
	// 	// // }

	// 	// // val.splice(startIndex, endIndex - startIndex);
	// 	// let raw = Format.toRawArray(val.join());
	// 	// const _formatted = Format.toPattern(raw.join(''));
    //     // return _formatted;
    //     return '';
	// }


	public static get phonePattern(): string { return '(###) ###-####'; }
	public static setPhonePattern(): void { Format.pattern = Format.phonePattern; }


}


export class Format1 {

	public static PhoneNumber(value: string): string {
		const pattern = [ ...'(###) ###-####' ];
		const stripped = [ ...value ].filter((v: string) => Tools.isDigit(v));
		let patternIndex: number = 0;

		stripped.forEach((c: string) => {
			patternIndex = pattern.indexOf('#', patternIndex);
			if (patternIndex >=0){ pattern[patternIndex] = c; }
        });

        do {
			if (!Tools.isDigit(pattern[pattern.length-1])) { pattern.pop(); } else { break;};
        } while(pattern.length > 0);

        const res = pattern.join('');
        return res;
	}
}
