// ------------------------------------------------------------
// Badge config
// ------------------------------------------------------------
// renders a badge on a component

import { BadgeAlign } from "@progress/kendo-angular-indicators";
import { IconSizes, IconTypes } from "./icon";
import { IconList } from "./iconList";

export type badgeAlignment = 'start' | 'end';
export type badgePosition = 'edge' | 'inside' | 'outside';
export type badgeColor = 'info' | 'success' | 'warning' | 'error';


export class BadgeConfig {

	public key?: string;
	public text?: string;
	public iconName? : string;
	public verticalAlignment?: badgeAlignment;
	public horizontalAlignment?: badgeAlignment;
	public position?: badgePosition;
	public color?: badgeColor;
	public clickable?: boolean;

}

export class BadgeConfiguration extends BadgeConfig{

	static assign(source: object): BadgeConfiguration {
		const b = new BadgeConfiguration();
		if (!source) { return b; }

		if ('key' in source) {
			b.key = (<any>source)['key'];
		}

		if ('text' in source) {
			b.content = (<any>source)['text'];
		}

		if (!b.content && 'iconName' in source) {
			let icon = (<any>source)['iconName'] as string;
			if (icon && !icon.startsWith('@')) { icon = '@' + icon; }
			b.content = icon;
		}

		if ('verticalAlignment' in source) {
			b.verticalAlignment = (<any>source)['verticalAlignment'];
		}

		if ('horizontalAlignment' in source) {
			b.horizontalAlignment = (<any>source)['horizontalAlignment'];
		}

		if ('position' in source) {
			b.position = (<any>source)['position'];
		}

		if ('color' in source) {
			b.color = (<any>source)['color'];
		}

		if ('clickable' in source) {
			b.clickable = (<any>source)['clickable'] as boolean;
		}


		return b;
	}

	static create(content: string, color?: badgeColor, hAlignment?: badgeAlignment, vAlignment?: badgeAlignment, position?: badgePosition ){
		const b = new BadgeConfiguration();
		if (content) { b.content = content; }
		if (hAlignment) { b.horizontalAlignment = hAlignment; }
		if (vAlignment) { b.verticalAlignment = vAlignment; }
		if (position) { b.position = position; }
		if (color) { b.color = color; }

		return b;
	}

	static createDelete() {
		return BadgeConfiguration.create('-', 'warning');
	}

	constructor() {
		super();
		this.key = 'badge';
		this._content = '';
		this.horizontalAlignment = 'end';
		this.verticalAlignment = 'start';
		this.position = 'edge';
		this.color = 'info';
		this.clickable = false;
	}

	public key: string;
	public verticalAlignment: badgeAlignment;
	public horizontalAlignment: badgeAlignment;
	public position: badgePosition;
	public color: badgeColor;
	public clickable: boolean;

	private _content: string;
	public get content(): string { return this._content; }
	public set content(value: string) {
		if (value && value.startsWith('@')) {
			try {
				value = value.substr(1);
				const icon = IconList.getIcon(value);
				icon.image.size = 'xs';
				icon.image.type = 'solid';
				icon.image.color = 'ib-color-minus';
				this._content = `<i class="${icon.imageClasses} no-padding"></i>`;
			} catch (err: any) {
				this._content = "??";
			}
		}
	}

	public get alignment(): BadgeAlign {
		const va = (this.verticalAlignment === 'start') ? 'top' : 'bottom';
		return { vertical : va, horizontal: this.horizontalAlignment };
	}

}

