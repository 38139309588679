import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ibHomeComponent } from '@public/home/home.component';
import { ibMainMenuComponent } from '@public/main-menu/main-menu.component';
import { ibPageNotFoundComponent } from '@common/pages/ib-page-not-found/ib-page-not-found.component';
// import { ibSignInComponent } from '@common/identity/sign-in.component/sign-in.component';
import { ibErrorPageComponent } from '@common/pages/ib-error-page/ib-error-page.component';
import { AuthGuard } from '@common/guards/AuthGuard';
import { GuardData } from '@common/identity/types';

const routes: Routes = [
	{
		path: '',
		component: ibHomeComponent
	},
	{
		path: 'Home',
		component: ibHomeComponent
	},
	{
		path: 'Menu',
		component: ibMainMenuComponent
	},
	// for testing only
	// {
	// 	path: 'SignIn',
	// 	component: ibSignInComponent
	// },
	{
		path: 'Identity',
	 	loadChildren: () => import('@common/identity/identity.module').then((m) => m.ibIdentityModule),
	},
	{
		path: 'Main',
	 	loadChildren: () => import('@public/modules/main.module/main.module').then((m) => m.MainModule)
	},
	{
		path: 'Comms',
	 	loadChildren: () => import('@public/modules/communications.module/communications.routing.module').then((m) => m.CommunicationsRoutingModule)
	},
	{
		path: 'Visitor',
	 	loadChildren: () => import('@visitor/visitor.routing.module').then((m) => m.VisitorRoutingModule),
		//  canActivate: [AuthGuard],
		 data: new GuardData(
			 ['visitor.user'],
			 'your must be logged in to access your visitor account')
	},
	{
		path: 'iBreeder',
	 	loadChildren: () => import('@iBreeder/iBreeder.routing.module').then((m) => m.iBreederRoutingModule)
	},
	{
		path: 'Media',
	 	loadChildren: () => import('@common/components/media-components/media.routing.module').then((m) => m.ibMediaRoutingModule)
	},
	{
		path: 'Pedigree',
	 	loadChildren: () => import('@common/components/pedigree-component/pedigree.module').then((m) => m.PedigreeModule)
	},
	{
		path: 'Breeder',
	 	loadChildren: () => import('@breeder/breeder.routing.module').then((m) => m.ibBreederRoutingModule)
	},
	{
		path: 'Tests',
	 	loadChildren: () => import('@public/test-pages/tests.module').then((m) => m.TestsModule)
	},





	// {
	// 	matcher: AccountIDMatcher,
	// 	loadChildren: () => import('../public/modules/breeder.module/breeder-public.module').then((m) => m.BreederPublicModule)
	// },


	{
		path: 'Error',
		component: ibErrorPageComponent
	},
	{
		path: '**',
	  component: ibPageNotFoundComponent
  	}


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
		scrollPositionRestoration: 'enabled',
		scrollOffset: [ 0, 0 ],
		anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
