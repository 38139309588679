// ------------------------------------------------------
// url
// ------------------------------------------------------
//
// in iBreeder all urls are title cased with some exceptions located in the exception list
//
// TitleCaseUrlSerializer - converts urls to title case using ibUrl


import { Injectable } from "@angular/core";
import { DefaultUrlSerializer, UrlTree } from "@angular/router";

export class ibUrl {

	private static exceptions: Map<string, string>;
	private static isLoaded: boolean = false;

	public static isUrl(value: string): boolean {
        const pattern = /^[a-zA-Z0-9-_.~]{3,25}$/g;
        return pattern.test(value);
	}

	public static formatUrl(value: string, preserveCase: boolean = false): string {
		if (!value) { return ''; }
		let lastCharWasUnderscore = false;
		const chars = value.trim().split('');
		let buffer = '';

		chars.forEach((c) => {

			if (c >= '0' && c <= '9' || c >= 'a' && c <= 'z') {
				buffer = buffer + c;
				lastCharWasUnderscore = false;
				return;
			}

			if (c >= 'A' && c <= 'Z') {
				if (!preserveCase) {
					c = c.toLowerCase();
				}
				if (!lastCharWasUnderscore && buffer.length > 0) {
					buffer = buffer + '-';
				}
				buffer = buffer + c;
				lastCharWasUnderscore = false;
				return;
			}

			if (c === ' ' || c === '-' || c === '_' || c ==='.' || c === '~') {
				if (!lastCharWasUnderscore) {
					buffer = buffer + '-';
				}
				lastCharWasUnderscore = true;
				return;
			}
		});

		return buffer;

	}

	// converts any string (kebab, property name etc. to a caption style)
	// should replace tools.toCaption
	public static toCaption(value: string): string {
		if (!value) { return ''; }

        const chars = value.split('');
        let buffer = '';
		let wordBoundary = true;
		let	lowerCase = true;

		value = value.trim();
		if (value === 'iBreeder') { return value; }
        chars.forEach((c) => {
			if (c >= '0' && c <= '9') {
                buffer = buffer + c;
                wordBoundary = false;
				lowerCase = true;
            } else if (c >= 'a' && c <= 'z') {
				if (wordBoundary) {	c = c.toLocaleUpperCase(); }
                buffer = buffer + c;
                wordBoundary = false;
				lowerCase = true;
            } else if (c >= 'A' && c <= 'Z') {
				if (lowerCase) { c = ' ' + c; }
                buffer = buffer + c;
                wordBoundary = false;
				lowerCase = false;
            } else if (c === ' ' || c === '-' || c === '_') {
                buffer = buffer + ' ';
                wordBoundary = true;
				lowerCase = true;
            }
        });

		return buffer;
	}

	public static toTitleCase(url: string): string {
		let newUrl: string = '';
		if (url) {
			url = url.trim();
			const nodes = url.split('/').map((segment: string) => {
				const match = ibUrl.segmentTitleCase(segment);
				return match;
			});
			newUrl = nodes.join('/');
		} else {
			newUrl = '/';
		}
		return newUrl;
	}

	public static  toDashCase(url: string): string {
		if (url) {
			const parts = url.split('/');
			if (parts && parts.length > 0) {
				parts[parts.length-1] = ibUrl.segmentDashCase(parts[parts.length-1]);
				return parts.join('/');
			}
		}
		return '';
	}

	private static segmentTitleCase(seg: string): string {
		if(!seg) { return ''; }
		const match =ibUrl.match(seg);
		if (match) { return match; }

		const chars = seg.split('');
		const buffer = new Array<string>();
		let initial = true;
		for (let char of chars) {
			if (char >= 'a' && char <= 'z') {
				if (initial) {
					char = char.toUpperCase();
					initial = false;
				}
				buffer.push(char);
			} else if (char >= 'A' && char <= 'Z') {
				if (initial) {
					initial = false;
				} else {
					buffer.push(' ');
				}
				buffer.push(char);
			} else if (char >= '0' && char <= '9') {
				if (initial) { initial = false; }
				buffer.push(char);
			} else if (char === '@' || char === '*' || char === '+') {
				buffer.push(char);
				initial = true;
			} else if (char === '-' || char === '_' || char === '.') {
				initial = true;
			}
		}

		const changed = buffer.join('');
		return changed;
	}

	private static  segmentDashCase(seg: string): string {
		if(!seg) { return ''; }
		const res = seg.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
		return res;
	}

	private static match(value: string): string | undefined {
		if (!value || value.length < 2) { return ''; }
		if (!ibUrl.isLoaded) { ibUrl.initialize(); }
		value = value.toLowerCase();
		return ibUrl.exceptions.get(value);
	}

	private static initialize() {
		ibUrl.exceptions = new Map<string, string>();
		const raw = `
				AboutUs
				BreederCommitment
				BreederSpace
				CheckList
				ContactUs
				CopyrightInfringement
				DogNames
				GetStarted
				iBreeder
				MyAccount
				MyDogs
				MyInfo
				MyMessages
				MyNotifications
				MyPhotos
				MyProfile
				MySearches
				OurMission
				Registration
				ResponsibleBreeding
				SignIn
				SimilarBreeds
				SiteMap
				TrackingTips
				WhyPurebreds
		`
		const lines = raw.split('\n')
		for(let line of lines) {
			line = line.replace(/[\s\n\r\t]/g,'');
			ibUrl.exceptions.set(line.toLowerCase(), line);
		}
		ibUrl.isLoaded = true;
	}

}


// Used to provide case insensitive routing - used in app.module needs to be declared as provider in app.routing
// A map of valid path is indexed as lowercase and points to the correctly cased path
@Injectable()
export class TitleCaseUrlSerializer extends DefaultUrlSerializer {

	public parse(url: string): UrlTree {
		const u = this.toTitleCase(url)
		const t =super.parse(u);
		return t;
		// return super.parse(this.toTitleCase(url));
	}

	private toTitleCase(url: string): string {
		return ibUrl.toTitleCase(url);
	}

}

