import { ibIcon } from '@common/classes/icon';
import { ibNode } from '@common/classes/ui/trees/ibTree';
import { Defaults, SpecialValuesGUID } from 'common/classes/defaults';
import { BreedStub } from './breed-stub';


export type ListMode = 'officialNames' | 'allNames';

export class BreedNameList {

	private _officialNames: Array<BreedName>;
	private _allNames: Array<BreedName>;
	private _listMode: ListMode = 'officialNames';


	public constructor(names?: Array<BreedName>, mode?: ListMode) {
		this._officialNames = new Array<BreedName>();
		this._allNames = new Array<BreedName>();
		this._listMode = (mode) ? mode : 'officialNames';

		if (names) { names.forEach((item: BreedName) => {
			this.add(item);
		});}
	}

	public add(item: BreedName) {
		this.allNames.push(item);
		if (!item.name) { item.name = item.breedName; }
		if (item.name === item.breedName) {
			this.officialNames.push(item);
		}
	}

	public get officialNames(): Array<BreedName> { return this._officialNames; }
	public get allNames(): Array<BreedName> { return this._allNames; }
	public get names(): Array<BreedName> {
		switch(this._listMode) {
			case 'allNames': return this._allNames;
			case 'officialNames': return this._officialNames;
			default: throw new Error('BreedNameList.names error: invalid mode');
		}
	}

	public getBreed(breedID: string, mode?: ListMode) {
		let initial: ListMode = this._listMode;
		if (mode && mode !== this._listMode) { this._listMode = mode; }
		let breedName: BreedName | undefined
		switch(this._listMode) {
			case 'allNames':
				breedName = this._allNames.find((b: BreedName) => { return b.breedID === breedID });
				break;
			case 'officialNames':
				breedName = this._officialNames.find((b: BreedName) => { return b.breedID === breedID; });
				break;
			default: throw new Error('BreedNameList.getName error: invalid mode');
		}
		this._listMode = initial;
		return breedName;
	}

	public sort(): void {
		this.officialNames.sort((a: BreedName, b: BreedName) => {
			if(a.breedName < b.breedName) { return -1; }
			if(a.breedName > b.breedName) { return 1; }
			return 0;
		});
		this.allNames.sort((a: BreedName, b: BreedName) => {
			if(a.name < b.name) { return -1; }
			if(a.name > b.name) { return 1; }
			return 0;
		})
	}

	// returns list of official breed names that are within a range
	public getBreedsInRange(range: string = ''): Array<BreedName> {
		if (!range || range === 'all') {return this.officialNames; }

		const lst = new Array<BreedName>();
		const parts = range.toLowerCase().split('-');
		const rangeStart = parts[0];
		const rangeEnd = (parts.length > 1) ? parts[1] : parts[0];

		for (const breed of this.officialNames) {
			const n = breed.name[0].toLowerCase();
			if (n >= rangeStart && n <= rangeEnd && breed.name === breed.breedName) { lst.push(breed); }
		}

		return lst;
	}

		// returns list of all breed names that are within a range
	public getNamesInRange(range: string = ''): Array<BreedName> {
		if (!range || range === 'all') {return this.allNames; }

		const lst = new Array<BreedName>();
		const parts = range.toLowerCase().split('-');
		const rangeStart = parts[0];
		const rangeEnd = (parts.length > 1) ? parts[1] : parts[0];

		for (const otherName of this.allNames) {
			const n = otherName.name[0].toLowerCase();
			if (n >= rangeStart && n <= rangeEnd) { lst.push(otherName); }
		}

		return lst;
	}


	// returns list of breeds that match a start pattern
	public static getMatches(names: Array<BreedName>, pattern: string = ''): Array<BreedName> {
		if (!pattern) { return names; }

		const lst = new Array<BreedName>();
		for (const breed of names) {
			const n = breed.name.toLowerCase();
			if (n.startsWith(pattern.toLowerCase())) { lst.push(breed); }
		}

		return lst;
	}

	public static isOfficialName(name: BreedName): boolean {
		return (!name.breedName || name.name === name.breedName);
	}

		// calculate the range of letters from a list of breedNames (either strict breed name or all breed names)
	public getLetterRanges(count: number, all: boolean = true): Array<string> {

		const lst = (all) ? this.allNames : this.officialNames;
		let maxCount = Math.round(lst.length / count);
		if (maxCount < 7) {
			maxCount = 7;
			count = Math.abs(lst.length / 7);
		}

		const letterCounts: Record<string, any> = new Object();
		let currentLetter = '';
		for (const name of lst) {
			const n = name.name[0].toLocaleLowerCase();			// first letter
			if (n !== currentLetter) {
				currentLetter = n;
				(<any> letterCounts)[currentLetter] = 1;
			} else {
				(<any> letterCounts)[currentLetter] += 1;
			}
		}

		const ranges = new Array<string>();
		let rangeStart = '';
		let rangeEnd = '';
		let rangeCount = 0;
		let range = '';

		for (const letter in letterCounts) {
			if (letterCounts.hasOwnProperty(letter)) {
				const letterCount = (<any> letterCounts)[letter];
				if (rangeStart === '') {
					rangeStart = letter;
					rangeEnd = letter;
					rangeCount = letterCount;
					continue;
				}
				if (rangeCount + <number> letterCount <= count) {
					rangeCount += letterCount;
					rangeEnd = letter;
				} else {
					range = rangeStart;
					if (rangeEnd && rangeStart !== rangeEnd) { range += '-' + rangeEnd; }
					ranges.push(range);
					rangeStart = letter;
					rangeEnd = '';
					rangeCount = letterCount;
				}
			}
		}

		rangeEnd = 'z';
		range = rangeStart;
		if (rangeStart !== rangeEnd) { range += '-' + rangeEnd; }
		ranges.push(range);

		ranges.unshift('all');
		return ranges;
	}

	public getRangeForBreed(ranges: Array<string>, breedName: string): string {

			if (!breedName) { return 'All'; }
			const firstLetter = breedName[0].toLowerCase();
			for (const range of ranges) {
				if (range.length === 1) {
					if (range[0] === firstLetter)  { return range; }
				} else if (range.length === 3 && range !== 'all') {
					if (firstLetter >= range[0] && firstLetter <= range[2]) { return range; }
				}
			}

			return 'all';
	}

	public select(nameToSelect?: BreedName): BreedName | undefined {
		const lst = (this._listMode === 'officialNames') ? this._officialNames : this._allNames;

		let selected: BreedName | undefined = undefined;
		for (const name of lst) {
			if (nameToSelect && nameToSelect.breedID === name.breedID) {
				name.active = true;
				selected = name;
			} else { name.active = false; }
		}

		return selected;
	}
}

export class BreedName {


	public static assign(source: Record<string, any>): BreedName {
		const bn = new BreedName();

		if ('breedUID' in source) {
			bn.breedUID = (<any> source)['breedUID'];
		} else { bn.breedUID = Defaults.SpecialGuid(SpecialValuesGUID.Empty); }

		if ('breedID' in source) {
			bn.breedID = (<any> source)['breedID'];
		} else { throw new Error('assign breed name: breedID is missing'); }

		if ('breedName' in source) {
			bn.breedName = (<any> source)['breedName'];
		} else { throw new Error('assign breed name: breedName is missing'); }

		if ('name' in source) {
			bn.name = (<any> source)['name'];
		} else { bn.name = ''; }

		return bn;
	}

	public static fromStub(source: BreedStub): BreedName {
		const bn = new BreedName();
		bn.breedUID = source.breedUID;
		bn.breedID = source.breedID;
		bn.breedName = source.breedName;
		bn.name = '';
		bn.active = false;
		return bn;
	}

	constructor() {
		this.breedUID = '';
		this.breedID = '';
		this.breedName = '';
		this.name = '';
		this.active = false;
	}

	public breedUID: string;
	public breedID: string;
	public breedName: string;  // official breeder name
	public name: string;	   // official name or any other name the breed may have
	public tag: any | undefined;
	public active: boolean;

	public isOfficialName(): boolean {
		if (!this.breedName) { return true; }
		return (!this.name || this.name === this.breedName);
	}

	public equals(bName: BreedName): boolean {
		if (!bName.name) {
			return (this.breedID === bName.breedID);
		} else {
			return ((this.breedID === bName.breedID) && (this.name === bName.name));
		}
	}

	public toNode(parent?: ibNode): ibNode {
		const n = new ibNode({
			parent: parent,
			caption: this.breedName,
			icon: ibIcon.getIcon('general', 'breed'),
		});
		return n;
	}

}


