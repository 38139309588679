
// -------------------------------------------------
// ib menu item
// -------------------------------------------------
// if the icon descriptor canSpin is true the icon is responsive to progressService changes
// an will show a spinner

import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ibIcon, IconSizes } from '@common/classes/icon';
import { ibCoreService } from '@common/services/core-services/core.service';

@Component({
    selector: 'ib-menu-item',
    templateUrl: './ib-menu-item.component.html',
    styleUrls: [ './ib-menu-item.component.scss' ]
})
export class ibMenuItemComponent implements OnInit, OnChanges {
    private _icon: ibIcon;
    public get icon(): ibIcon {
        return this._icon;
    }

	/* eslint-disable */
    @Input('icon') set newIcon(value: ibIcon) {
        // if (value instanceof IconDescriptor) {
        //     this._icon = ibIcons.fromIconDescriptor(value);
        // } else {
            this._icon = value;
        // }
        this._icon.image.size = 'lg';
        this._icon.caption.size = 'lg';
        this._icon.caption.color = 'red';
        this._captionClasses = (this._icon && this._icon.caption) ? this._icon.caption.classes() : '';
        this._iconClasses = (this._icon && this._icon.image) ? this._icon.image.classes() : '';

        if (this._icon) {
            if (this._icon.isHidden) {
                this._isHidden = true;
                this._isDisabled = false;
            } else {
                this._isDisabled = (this._icon.isDisabled);
                this._isClickable = !this._isDisabled;
                this._isSelected = (this._icon.isSelectable && this._icon.isSelected);
            }
        }
	}
	/* eslint-enable */
	@Input() public noBottomSpacing: boolean = false;
	@Input() public noFixedWidth: boolean = true;
    @Output() selected = new EventEmitter<string>();


    @HostBinding('class.disabled') _isDisabled: boolean = false;
    @HostBinding('class.clickable') _isClickable: boolean = true;
    @HostBinding('class.active') _isSelected: boolean = false;
    @HostBinding('class.hidden') _isHidden: boolean = false;

    private isSpinning = false;
    private _captionClasses: string = '';
    private _iconClasses: string = '';

	constructor(private coreService: ibCoreService)	{ }

    ngOnInit(): void {
        // listen tp progress Service changes regardless of can spin
        // this.coreService.progressChange.subscribe((value: string | null) => {
        //     this.isSpinning = (value && value.length > 0) ? true : false;
        //     if (this._icon && this._icon.image) {
        //         this._iconClasses = this._icon.image.classes();
        //     }
        // });
        // this.coreService.progress.inProgress$.subscribe((request) => {
        //     if (request && this._icon && this._icon.image) {
        //         this._iconClasses = this._icon.image.classes(request.active);
        //     }
        // });

    }
    ngOnChanges(simpleChanges: SimpleChanges): void {
        if ((<object> simpleChanges).hasOwnProperty('icon')) {
            const res = simpleChanges['icon'].currentValue;
            // if (res instanceof IconDescriptor) {
            //     this._icon = ibIcons.fromIconDescriptor(res);
            // } else {
                this._icon = res;
            // }

            this._captionClasses = (this._icon) ? this._icon.caption.classes() : '';
            this._iconClasses = (this._icon) ? this._icon.image.classes() : '';

            if (this._icon) {
                if (this._icon.isHidden) {
                    this._isHidden = true;
                    this._isDisabled = false;
                } else {
                    this._isDisabled = (this._icon.isDisabled);
                    this._isClickable = !this._isDisabled;
                    this._isSelected = (this._icon.isSelected);
                }
            }
        }
    }

    onClick(id?: string): void {
        this.selected.emit(id?.replace('Icon', ''));
    }

	// generates a list of css classes to control aspect
	classes(): string {
		const bSpace = (!this.noBottomSpacing) ? 'b-space' : '';
		const fWidth = (!this.noFixedWidth) ? ' f-width' : '';
		return (bSpace + fWidth).trim();
	}

    iconClasses(): string {
        if (this._iconClasses) {
            return this._iconClasses;
        } else {
            return 'fas fa-paw ib-color-navy lg';
        }
    }

    captionClasses(): string {
        if (this._captionClasses) {
            return this._captionClasses;
        } else {
            return '';
        }
    }

    hasCaption(): boolean {
        if (this.icon && this._icon.caption) {
            return (this._icon.caption.text !== undefined && this._icon.caption.text.length > 0);
        }
        return false;
    }


    public get tooltip(): string {
        return (this._icon && this._icon.tooltip) ? this._icon.tooltip : '';
    }
}
