
<span class="copyright">
    <a [routerLink]="[]" (click)="onClick('Copyright')" >{{copyright}}</a>
</span>
<span class="menu">
    <a [routerLink]="[]" (click)="onClick('Privacy')" >Privacy</a>
    <a [routerLink]="[]" (click)="onClick('terms')" >{{terms}}</a>
    <!-- <a [routerLink]="" (click)="onClick('SiteMap')" >{{map}}</a> -->
</span>


