import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ibCoreService, Subscriptions } from '@common/services/core-services/core.service';
import { ibNotification } from '@common/classes/notification';
import { ibIcon } from '@common/classes/icon';
import { IconList } from "@common/classes/iconList";
import { Icons } from '@common/classes/icons';

@Component({
    selector: 'ib-title',
    templateUrl: './ib-title.component.html',
    styleUrls: [ './ib-title.component.scss' ]
})
export class ibTitleComponent implements AfterViewInit, OnInit, OnDestroy {
    private subscriptions: Subscriptions;
    public homeIcon: ibIcon;
    public backIcon: ibIcon;
    public closeIcon: ibIcon;
    public notification: ibNotification;

	@ViewChild('titleBar', { static: false }) titleBar: ElementRef;

    @Input() icon: ibIcon;
    @Input() type: 'back' | 'close' | '' | null;
    @Input() toolbarItems: IconList | Array<ibIcon>;
	@Input() showNotifications: boolean = true;
    @Output() closeEvent = new EventEmitter<'back' | 'close' | '' | null>();
    @Output() iconEvent = new EventEmitter<string>();
    @Output() toolbarEvent = new EventEmitter<string>();

    constructor(private location: Location,
                private router: Router,
                private coreService: ibCoreService) {
        this.subscriptions = new Subscriptions();

		this.homeIcon= Icons.generalIcon('home', 'solid', 'lg');
		this.homeIcon.caption.showCaption =false;
		this.homeIcon.image.color = 'ib-color-title-icon';
		this.backIcon = Icons.generalIcon('back', 'solid', 'xxl');
		this.closeIcon = Icons.generalIcon('close', 'solid', 'xl');
    }

    ngOnInit(): void {
        this.subscriptions.addSubscription('notification', this.coreService.notification$.subscribe((notification: ibNotification  | undefined) => {
            if (this.showNotifications && notification) { this.notification = notification; }
        }));

    }

	ngAfterViewInit(): void {
		// this.coreService.getSizes(true);
		// console.log('ib-title init ' + this.height)
	}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    iconClasses(): string {
        let classes: string;
        if (!this.icon) {
            return '';
        } else {
            classes = this.icon.image.classes();
        }
        return classes;
    }

	titleTooltip(): string {
		return (this.icon && this.icon.tooltip) ? this.icon.tooltip : '';
	}

	get height(): string {
		const bar = this.titleBar.nativeElement as HTMLDivElement;
		return (bar) ? `${bar.offsetHeight}px` : '';
	}

    // handle back arrow and close button - moves router back

    // this has to be completely reevaluated and placed in a callable function to insure constant behavior
    onClick(event: UIEvent): void {
        event.stopPropagation();
		const replace = (this.type==='close');
        this.closeEvent.emit(this.type);
        let url = this.coreService.previousUrl;
        if (!url) {
            this.router.navigate([ '/Home' ], { replaceUrl: replace });
        } else if (url.includes('/Home')) {
            this.router.navigate([ url ], { replaceUrl: replace });
        } else if (url.endsWith('Design')) {
            this.router.navigate([ '/Admin/Home' ], { replaceUrl: replace });
        } else if (url==='/') {
            this.router.navigate([ '/Home' ], { replaceUrl: replace });
        } else if (this.coreService.returnUrl) {
            url = this.coreService.returnUrl;
            this.coreService.returnUrl = '';
            this.router.navigate([ url ], { replaceUrl: replace });
        } else {
            this.router.navigate([ url ], { replaceUrl: replace });
        }
    }

    onHomeClick(): void {
        // switch (this.coreService.siteMode) {
        // 	case 'breeder-admin':
        // 		this.router.navigate(['Breeder/Admin/Home']);
        // 		break;
        // 	case 'iBreeder-admin':
        // 		this.router.navigate(['iBreeder/Account']);
        // 		break;
        // 	case 'public':
        // 		this.router.navigate(['home']);
        // 		break;
        // }
        this.router.navigate([ '/Home' ]);
    }

    onIconClick(): void {
        this.iconEvent.emit(this.icon.id);
    }

    onToolBarClicked(event: string): void {
        this.toolbarEvent.emit(event);
    }

    onNotificationClick(): void {
        if (this.notification) {
            this.notification.visible = false;
        }
    }

    showNotification(): boolean {
        const ret = (this.notification && this.notification.visible);
        return ret;
    }
}
