import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common'

import { ibCoreService, ResizeData, Subscriptions } from '@common/services/core-services/core.service';
import { ibIdentityService } from '@common/identity/identity.service';
import { ibIcon } from '@common/classes/icon';
import { Sizes } from '@common/classes/types';

@Component({
  selector: 'ib-iBreeder-header',
  templateUrl: './iBreeder-header.component.html',
  styleUrls: ['./iBreeder-header.component.scss']
})
export class ibiBreederHeaderComponent implements OnInit, OnDestroy {

	private subscriptions: Subscriptions
	public size: Sizes;
	public headerHeight: string;
	public isAuthenticated: boolean = false;
	public progressState = '';

	public menuItems: Array<ibIcon>;
	private myDogsIcon: ibIcon;

	constructor(private router: Router,
				private location: Location,
				private identityService: ibIdentityService,
				private coreService: ibCoreService) {

		this.subscriptions = new Subscriptions();
		this.size = 'md';
	}

	ngOnInit(): void {

		// monitor Identity Changes
		this.subscriptions.addSubscription('identity', this.identityService.isAuthenticated$.subscribe((authenticated: boolean) => {
			this.isAuthenticated = authenticated;
		}));

		// monitor and update resize  TO BE CHECKED
		this.subscriptions.addSubscription('resize', this.coreService.resizeInfo$.subscribe((data: ResizeData) => {
			if (data && this.myDogsIcon) { this.myDogsIcon.isHidden = (data.clientWidth < 571); }
			this.headerHeight = `${data.headerHeight}px`;
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

  	onLogoClick() {
		this.coreService.setSiteMode('guest');
		this.router.navigate(['/Home']);   // clicking on logo returns to Home
	}
}



