import { NgModule } from '@angular/core';
import { DraggableDirective } from './draggable.directive';
import { DropTargetDirective } from './drop.target.directive';
import { FocusDirective } from './focus.directive';
import { HelpPopupDirective } from './help-popup.directive';
import { SafeHtmlPipe } from './safeHtmlPipe';

@NgModule({
    declarations: [
        DraggableDirective,
        DropTargetDirective,
        FocusDirective,
		HelpPopupDirective,
        SafeHtmlPipe
    ],
    imports: [
    ],
    exports: [
        DraggableDirective,
        DropTargetDirective,
        FocusDirective,
		HelpPopupDirective,
        SafeHtmlPipe
    ],
    providers: [
    ]
})
export class CoreDirectivesModule {

}
