// -------------------------------------------
// Progress class
// -------------------------------------------
// controls the display of the progress bar on headers
// supports concurrent progress requests
// used by ib progress

import { Tools } from "@common/tools";
import { Observable, of, Subject} from "rxjs";
import { take, map } from 'rxjs/operators';
import { ibIcon } from "./icon";
import { Icons } from "./icons";


export class Progress {

	private _requests: Array<ProgressRequest>;
	private _inProgress$: Subject<ProgressRequest | undefined>;

	constructor() {
		this._requests = new Array<ProgressRequest>();
		this._inProgress$ = new Subject<ProgressRequest | undefined>();
	}

	public get inProgress$(): Observable<ProgressRequest | undefined> {
		return this._inProgress$.asObservable();
	}

	public start(icon?: ibIcon, duration?: number): string {
		const request = new ProgressRequest(icon, duration);
		this._requests.unshift(request);
		this.update();
		request.change$.pipe(
			take(1), map((res) => {
				this.end(request.id);
			})).subscribe();
		return request.id;
	}

	public save(caption: string = 'Saving...'): string {
		const icon = Icons.generalIcon('save');
		icon.caption.setText(caption);
		return this.start(icon, 1250);
	}

	public end(id?: string ) {
		if (!id) {
			this._requests.forEach((r: ProgressRequest) => { r.active = false; });
		} else {
			for (const request of this._requests) {
				if (request.id === id) { request.active = false; }
			}
		}
		this.update();
	}

	private update(): void {
		this._requests = this._requests.filter((request: ProgressRequest) => { return request.active === true; });
		if (this._requests.length < 1) {
			this._inProgress$.next(undefined);
		} else {
			this._inProgress$.next(this._requests[0]);
		}
	}

}

export class ProgressRequest {

	private _id: string;
	private _change$: Subject<ProgressRequest>;

	constructor(icon?: ibIcon, duration?: number) {

		this._id = Tools.newGuid();
		this.icon = icon;
		this.duration = (duration) ? duration : 0;
		this._change$ = new Subject<ProgressRequest>();
		this.active = true;
		if (this.duration > 0) {
			setTimeout(() => {
				this.active = false;
				this._change$.next(this);
			}, this.duration)
		}
	}

	public get id(): string { return this._id; }
	public icon?: ibIcon;
	public duration: number;
	public active: boolean;
	public get change$(): Observable<ProgressRequest> {
		return this._change$.asObservable();
	}

}
