import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({ selector: '[ibFocus]' })
export class FocusDirective implements OnInit {
    @Input('ibFocus') isFocused: boolean = false;

    constructor(private hostElement: ElementRef, private renderer: Renderer2) {}

    ngOnInit(): void {
        if (this.isFocused) {
            this.hostElement.nativeElement.focus();
        }
    }
}
