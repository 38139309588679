// Drag Service
import { Injectable } from '@angular/core';
import { DraggableData } from '@common/directives/draggable.directive';

@Injectable({ providedIn :'any' })export class ibDragService {
    private source: string;

    startDrag = (source: DraggableData): void => {
        this.source = source.source.trim().toLowerCase();
    };

    accepts(sources: Array<string>): boolean {
        if (!sources || sources.length < 1 || !this.source) {
            return false;
        }
        const accepted = sources.find((s: string) => s === this.source);
        return (accepted) ? true : false;
    }
}
