import { BreedStub } from './breed-stub';
import { DogStub } from './dog-stub';
import { OwnerBreeder } from './owner';
import { Defaults, SpecialValuesGUID } from '@common/classes/defaults';
import { ibBreedService } from '@common/services/entity-services/breed.service';
import { IKey } from '@common/classes/interfaces';


export class LitterStub implements IKey{

	static assign(source: Record<string, any>): LitterStub {

		const target = new LitterStub();

		if ('id' in source) {
			target.id = (<any> source)['id'];
		}

		if ('name' in source) {
			target.name = (<any> source)['name'];
		}

		if ('breed' in source) {
			target.breed = BreedStub.assign((<any> source)['breed']);
		}

		// if (source.hasOwnProperty('dam')) {
		//     target.dam = DogStub.assign((<any>source)['dam']);
		// }

		// if (source.hasOwnProperty('sire')) {
		//     target.sire = DogStub.assign((<any>source)['sire']);
		// }

		if ('dob' in source) {
			target.dob = new Date((<any> source)['dob']);
		}

		return target;
	}

	static createBlank(breedID?: string): Promise<LitterStub> {
		return new Promise<LitterStub>((resolve) => {
			const breedService = Defaults.injectorInstance.get<ibBreedService>(ibBreedService);
			const lStub = new LitterStub();
			if (breedID) {
				const stub = breedService.getBreedStub(breedID);
				lStub.breed = (stub) ? stub : BreedStub.createBlank();
			} else {}
				lStub.breed = BreedStub.createBlank();;
				resolve(lStub);
			});
	}

	public constructor() {
		this.id = Defaults.SpecialGuid(SpecialValuesGUID.Empty);
		this.owner = OwnerBreeder.createBlank();
		this.name = 'New Litter';
		this.breed = BreedStub.createBlank();
		this.dam = DogStub.createBlank('female');
		this.sire = DogStub.createBlank('male');
		this.dob = new Date(Date.now());
	}

	public id: string;
	public owner: OwnerBreeder;
	public name: string;
	public breed: BreedStub;
	public dam: DogStub;
	public sire: DogStub;
	public dob: Date;

	public toKey(): string { return this.name; }

}
