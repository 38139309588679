// ======================================================
// COMPONENT ibMenu
// ======================================================
// Contains a menu of selectable items (views)
// ======================================================

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { ibCoreService, ResizeData, Subscriptions } from '@common/services/core-services/core.service';
import { Tools } from '@common/tools';
import { DropData } from '@common/directives/drop.target.directive';
import { View } from '@common/classes/views';
import { IconSizes } from '@common/classes/icon';
import { ibViewModes } from '@common/classes/types';

@Component({
	selector: 'ib-menu',
	templateUrl: 'ib-menu.component.html',
	styleUrls: ['ib-menu.component.scss']
})
export class ibMenuComponent implements OnChanges, OnInit, OnDestroy {

	@Input() view: View;
	@Input() width: number = -1;				// width of container pan if in db-views component
	@Input() selected: Array<View>;
	@Input() dynamic: boolean;
	@Input() flatTop: boolean;
	@Input() modeThreshold: number = 700;
	@Output() orderChanged = new EventEmitter<Array<View>>();
	@Output() selectionChanged = new EventEmitter<Array<View>>();

	private subs: Subscriptions;
	private _mode: 'full' | 'icon' = 'full';

	constructor(private router: Router,
				private coreService: ibCoreService
	) {
		this.subs = new Subscriptions();
		this.selected = new Array<View>();
		this.dynamic = false;
	}

	ngOnInit() {
		this.subs.addSubscription('size', this.coreService.resizeInfo$.subscribe((info: ResizeData) => {
			if (info && info.clientWidth >= this.modeThreshold) {
				if (this._mode !== 'full') {
					this._mode = 'full';
					this.view.resizeSubViewIcons('md', 'lg');
				}
			} else {
				if (this._mode !== 'icon') {
					this._mode = 'icon';
					this.view.resizeSubViewIcons('lg', 'md');
				}
			}
		}));
	}

	ngOnChanges(changes: SimpleChanges) {
		if ('view' in changes) {
			if (this.view) {
				if (this.subs.subscriptions.has('sel')) {
					this.subs.subscriptions.delete('sel');
				}
				this.subs.addSubscription('sel', this.view.selectionChange$.subscribe((selectedView: View | undefined) => {
					if (selectedView) {
						this.selectionChanged.emit(<Array<View>> [selectedView]);
					} else {
						console.log(('No view selected'));
					}
				}));
			} else {
				console.log('ib-menu Change to [no current] view')
			}
		} else if('width' in changes) {
			// console.log('Pane width' + this.width )
		}
	}

	ngOnDestroy() {
		if (this.subs) { this.subs.unsubscribe(); }
	}

	onClick(item: View): void {
		this.selected.length = 0;
		if (this.view) {
			const s = this.view.select(item);
			if (s) { this.selected.push(s); }
			this.selectionChanged.emit(this.selected);
		}
	}

	flatTopClass(): string { return (this.flatTop) ? 'list-group flat-top' : 'list-group'; }

	get mode(): string { return this._mode; }

	isDynamic(): string {
		return (this.dynamic) ? '' : 'hidden';
	}

	isChecked(view: View): string {
		return (view.isChecked) ? '' : 'hidden';
	}

	onDrop(event: unknown) {
		if (event instanceof DropData && event.range) {
			Tools.ArrayMove(this.view.views, event.range.from, event.range.to);
			this.orderChanged.emit(this.view.views);
		}
	}

	moveUp(e: UIEvent, view: View) {
		if (this.view) {
			// this.view.;
		}
	}

	moveDown(e: UIEvent, view: View) {
		if (this.view) {
			// this.view.selectPrevious();
		}
	}

}
