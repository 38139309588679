
// -------------------------------------------------
// ib icon
// -------------------------------------------------
// usage takes an icon descriptor
// if the icon descriptor canSpin is true the icon is responsive to progressService changes
// and will show a spinner


import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ibIcon, IconSizes } from '@common/classes/icon';
import { Icons } from '@common/classes/icons';
import { Sizes } from '@common/classes/types';
import { ibCoreService, ResizeData } from '@common/services/core-services/core.service';

@Component({
  selector: 'ib-button',
  templateUrl: './ib-button.component.html',
  styleUrls: ['./ib-button.component.scss']
})
export class ibButtonComponent implements OnInit {

	@Input() icon: ibIcon = Icons.generalIcon('paw');
	@Input() size: Sizes = 'xl';
	@Input() helpTopic: string | null;
	@Input() disabled = false;
	@Output() buttonClick = new EventEmitter();
	@Output() helpClick = new EventEmitter<string>();

	@ViewChild('textContent', { static: true }) textContent: ElementRef;

	private _hostClasses: string;
	// private isSpinning = false;
	public helpString: string;


	constructor(private host: ElementRef,
				private coreService: ibCoreService) {
			this.helpString = '';
			const h: HTMLElement = this.host.nativeElement;             // get additional classes on element
			this._hostClasses = h.classList.toString();
	}

	ngOnInit() {
		// listen tp progress Service changes regardless of can spin
		// this.coreService.progressChange.subscribe((value: ProgressConfig) => {
		// 	this.isSpinning = (value && value.active) ? true : false;
		// });

		this.coreService.resizeInfo$.subscribe((data: ResizeData) => {
			this.size = data.size;
		});

		if (this.helpTopic) {
			this.coreService.help(this.helpTopic).subscribe((resp: string) => { this.helpString = resp; });
		}
	}

	public get showText(): boolean {
		const span = this.textContent?.nativeElement as HTMLSpanElement;
		return (span && !span.innerHTML.trim() && this.icon?.caption?.showCaption);
	}

	public get showHelp(): boolean {
		return (!!this.helpTopic);
	}

  // generates a list of css classes to control icon
	iconClasses(): string {
		return (this.icon) ? this.icon.imageClasses : '';
	}

	hostClasses(): string {
		if (!this._hostClasses) { return 'btn btn-outline-secondary btn-block ' + this.size; }
		if (!this._hostClasses.includes('btn')) { this._hostClasses = 'btn ' + this._hostClasses; }
		return this._hostClasses + ' ' + this.size;
	}

	public captionClasses(): string {
		return this.icon.caption.classes();
	}

	public hasCaption(): boolean {
		return (this.icon.caption && this.icon.caption.text) ? true : false;
	}

	public isDisabled() { return this.disabled; }

	public get tooltip(): string {
		return (this.icon && this.icon.tooltip) ? this.icon.tooltip : '';
	}

	public onClick(e: UIEvent) {
		this.buttonClick.emit();
	}

	public onHelp(e: MouseEvent) {
		e.cancelBubble = true;
		e.preventDefault();
		this.helpClick.emit();
	}

}
