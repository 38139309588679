import { Injectable, OnDestroy } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, fromEvent, Observable, Observer, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, map, pairwise, take } from 'rxjs/operators';

import { iBreederInfo } from '@common/classes/iBreederInfo';
import { ibNotification } from '@common/classes/notification';
import { ImageSizes, Orientations } from '@common/classes/enums';
import { SiteMode, Sizes } from '@common/classes/types';
import { Progress } from '@common/classes/progress';
import { ibError } from '@common/classes/ib-error';
import { Location } from '@angular/common';
import { ibUrl } from '@common/classes/ibUrl';
import { ibSizes } from '@common/classes/ui/ib-sizes';
import { ibIcon } from '@common/classes/icon';
import { Icons } from '@common/classes/icons';

// ----------------------------------------------------------------------------------------------
// component Com Service - used to communicate between two components
// ----------------------------------------------------------------------------------------------
// not fully tested 2/18/2020

@Injectable({ providedIn :'any' })
export class ibComponentComService {
    // can be limited to master detail components by adding providers: [ibComponentComService] to component annotation

    private _data: BehaviorSubject<any>;

    constructor() {
        this._data = new BehaviorSubject<any>(undefined);
    }

    // used to transmit data from parent to child component
    get data$(): Observable<any> {
        return this._data.asObservable();
    }
    setData(value: any): void {
        this._data.next(value);
    }
}

// ----------------------------------------------------------------------------------------------
// subscription management service
// ----------------------------------------------------------------------------------------------
// Registers subscription and insures they are destroyed when component is destroyed
// renamed from subs 2/18/2020


export class Subscriptions {
    private _subscriptions: Map<string, Subscription> = new Map<string, Subscription>();

    constructor() {
        this._subscriptions = new Map<string, Subscription>();
    }

    public addSubscription(name: string, subscription: Subscription): Subscription {
        if (this._subscriptions.has(name)) {
            const s = this._subscriptions.get(name);
            if (s) {
                s.unsubscribe();
            }
        }
        this._subscriptions.set(name, subscription);
        return subscription;
    }

    public unsubscribe(name?: string): void {
		if (name) {
			if (this._subscriptions.has(name)) {
				const s = this._subscriptions.get(name);
				if (s) { s.unsubscribe(); }
				this._subscriptions.delete(name);
			}
		} else {
			this._subscriptions.forEach((s) => { s.unsubscribe(); });
			this._subscriptions.clear();
		}
    }

    public get subscriptions(): Map<string, Subscription> {
        return this._subscriptions;
    }
}

// ----------------------------------------------------------------------------------------------
// core service provides the essential services for iBreeder
// ----------------------------------------------------------------------------------------------
@Injectable({ providedIn :'root' })
export class ibCoreService implements OnDestroy{

  	private _siteMode$: BehaviorSubject<SiteMode>;
    private _previousUrl: string = '';
    private _returnUrl: string = '';
    private _command$: Subject<string>;
    private _data$: BehaviorSubject<any>;
    private _currentItem$: Subject<any>;
    private _notification$ = new BehaviorSubject<ibNotification | undefined>(undefined);
    private _lastError: ibError | undefined;
    private _scripts: Array<IScriptModel>;
	private _oldSize: number;
	private _progress: Progress;
	private _navigateIcon: ibIcon;

    public resizeInfo$: BehaviorSubject<ResizeData>;
    public resizeData: ResizeData;

    constructor(private router: Router,
                private location: Location,
                private http: HttpClient,
				) {

		this._siteMode$ = new BehaviorSubject<SiteMode>('guest');  				// site always starts with public
    	this._scripts = new Array<IScriptModel>();
        this._data$ = new BehaviorSubject<any>(null);
		this._progress = new Progress();
        this._command$ = new Subject<string>();
        this._currentItem$ = new Subject<any>();
		this._navigateIcon = Icons.generalIcon('navigate', 'solid', 'xl');

		// record previous Url on route changes
        this.router.events.pipe(
            filter((e) => e instanceof RoutesRecognized),
            pairwise()
        ).subscribe((event: Array<any>) => {
            this._previousUrl = event[0].urlAfterRedirects;
            this._returnUrl = '';
        }, ((err: any) => {
            console.error(err);
        }));

        // capture window size changes and calculate new scss variables for window, header height and title height
		// tried unsuccessfully to use debounce -do not know why it does not work
		this._oldSize = 0;
		this.getSizes();
		fromEvent(window, 'resize').subscribe((e: Event) => {
			this.getSizes();
		});
    }

	ngOnDestroy() {
		// this.unsubscribe();
	}

    // return json setup file
  	getSettingFile(fileName: string): Observable<unknown> {

        if (!fileName) { return of(undefined); }
    	const url = '/assets/data/'.concat(fileName);
        if (!url.endsWith('.json')) { url.concat('.json'); }

    	const ret = this.http.get(url, { responseType: 'json' }).pipe(
    		take(1),
    		map((r) => { return r; }),
    		catchError((error: any) => {
    			console.error(error);
    			return of(`${error.message} ${url}`);
    		}));

    	return ret;
    }

    getIBreederInfo(): Observable<iBreederInfo | undefined> {
		const url = '/assets/data/iBreederInfo.json';

		const ret = this.http.get(url, { responseType: 'json' }).pipe(
			take(1),
			map((r) =>
				iBreederInfo.assign(r),
			catchError((error: any) => {
				console.error(error);
				return of(`${error.message} ${url}`);
			})
		));

		return ret;
	}

    // return help html for help topic
    help(topic: string, subtopic?: string): Observable<string> {
        const parts = topic.split('.');
        let url = '/assets/help';
        for (let i = 0; i < parts.length; i++) {
            url += '/' + parts[i];
        }
		const idx = url.indexOf('#');
		if (idx > 0) {
			subtopic = url.substring(idx + 1);
			url = url.substring(0, url.indexOf('#'));
		}
        url += '.html';
        const ret = this.http.get(url, { responseType: 'text' }).pipe(
            take(1),
            map((r) => {
                if (subtopic) {
                    const target = '### ' + subtopic;
                    let _start = r.indexOf(target, 0);
                    if (_start > -1) {
                        _start = _start + target.length + 1;
                    }
                    const _end = r.indexOf('###', _start);
                    const ret = (_end > -1) ? r.substring(_start, _end) : r.substring(_start);
                    return ret;
                } else {
                    return r;
                }
            }),
            catchError((error: any) => {
                console.error(error);
                return of(`${error.message} ${url}`);
            })
        );

        return ret;
    }

    // Progress ------------------------
	public get progress(): Progress { return this._progress; }

	public startProgress(icon?: ibIcon, duration?: number): string {
		return this._progress.start(icon, duration);
	}

    public endProgress(id?: string): void {
		this._progress.end(id);
    }

	// sizes - updates css variables for style layout - recalculated on each view change and resize
	public getSizes(force: boolean = false): void {

		if (!force && this._oldSize === window.innerWidth) { return; }
		this._oldSize = window.innerWidth;

		// get font size from HTML element
		let fontSize = 12.5;
		const elements = document.getElementsByTagName('HTML');
		if (elements && elements.length > 0) {
			const style: string | null = window.getComputedStyle(elements[0]).getPropertyValue('font-size');
			if (style) { fontSize = parseFloat(style); }
		}

		if (this.resizeData) {
			this.resizeData.update(window.innerWidth, window.innerHeight, fontSize);
		} else {
			this.resizeData = new ResizeData(window.innerWidth, window.innerHeight, fontSize);
		}

		this.resizeData.setElementSizes(this.getElementHeight('header'), this.getElementHeight('#title-bar'));

		if (!this.resizeInfo$) { this.resizeInfo$ = new BehaviorSubject<ResizeData>(this.resizeData); }
		this.resizeInfo$.next(this.resizeData);

		const viewWidth = `${this.resizeData.clientWidth}px`;
		const viewHeight = `${this.resizeData.clientHeight}px`;
		const headerHeight = `${this.resizeData.headerHeight}px`;
		const titleHeight = `${this.resizeData.titleHeight}px`;

		let margin = (Math.sqrt(this.resizeData.clientWidth) - 20) * 10;
		if (margin < 25) { margin = 25; }
		if (margin > 250) { margin = 250; }
		const dynamicMargin = `${margin}px`;

		document.documentElement.style.setProperty('--client-width', viewWidth);
		document.documentElement.style.setProperty('--client-height', viewHeight);
		document.documentElement.style.setProperty('--dynamic-margin', dynamicMargin );
		document.documentElement.style.setProperty('--header-height', headerHeight);
		document.documentElement.style.setProperty('--title-height', titleHeight);
	}

    getElementHeight(selector: string): number {
        if (!selector) { return 0; }
        const element = document.querySelector(selector) as HTMLElement;
        if (!element) { return 0; }

		const styles = window.getComputedStyle(element);
		const margin = parseFloat(styles['marginTop']) +
					 parseFloat(styles['marginBottom']);

        const height = Math.ceil(element.offsetHeight + margin);
        return height;
    }

	// templates
    getHTMLTemplate(name: string): Observable<string> {
        const url = `/assets/templates/${name}.template.html`;
        const ret = this.http.get(url, { responseType: 'text' }).pipe(
            take(1),
            map((r) => r),
            catchError((error: any) => {
                console.error(error);
                return of(`${error.message} ${url}`);
            })
        );

        return ret;
    }

    getTextTemplate(name: string): Observable<string> {
        const url = `/assets/templates/${name}.template.text`;
        const ret = this.http.get(url, { responseType: 'text' }).pipe(
            take(1),
            map((r) => r),
            catchError((error: any) => {
                console.error(error);
                return of(`${error.message} ${url}`);
            })
        );

        return ret;
    }

    // return observable of site mode guest, visitor or breeder admin or iBreeder Admin
    public get siteMode$(): Observable<SiteMode> {
        return this._siteMode$;
    }

	setSiteMode(mode: SiteMode): void {
		this.getSizes();
		this._siteMode$.next(mode);
	}

    // return the current url
    get currentUrl(): string {
        return this.router.url;
    }

    get previousUrl(): string {
        if (this._returnUrl) {
            return this._returnUrl;
        } else {
            return this._previousUrl;
        }
    }

	get canGoBack(): boolean {
		return !(!this.previousUrl || this.previousUrl === '/') ;
	}

    // the return url overrides the previousUrl when it is set
    get returnUrl(): string {
        return this._returnUrl;
    }
    set returnUrl(value: string) {
        this._returnUrl = value;
    }

	// -------------------------------------------
	// Navigate
	// -------------------------------------------

	// REFACTOR navigate tp previous etc. to use the navigate function

    public navigateToPreviousUrl(): void {
        if (!this._previousUrl && this.returnUrl) { this._previousUrl === this.returnUrl; }
        const url = this._previousUrl;
        if (!url) {
            this.router.navigate([ '/Home' ]);
        } else if (url.includes('/Home')) {
            this.router.navigate([ url ]);
        } else if (url==='/') {
            this.router.navigate([ '/Home' ]);
        } else {
            this.router.navigate([url]).catch(() => { this.location.back; });
        }
    }

	// set url to title case, show progress, navigate to url and handles error
	async navigate(url: string): Promise<boolean> {
		let _url: string = '/';
		let _res: boolean = false;
		let progressID: string = '';
		try {
			if (url) {
				_url = ibUrl.toTitleCase(url);
			}

			progressID =this.startProgress(	this._navigateIcon);
			_res = await this.router.navigate([_url]);
		} catch(reason: any) {
			_res = false;
			this.showError(ibError.create(`Error navigating to: ${_url}: ${reason}`));
		} finally {
			this.endProgress(progressID);
			return _res;
		}
	}

    // the returnUrl is reset on use
    navigateToReturnUrl(): void {
        if (!this._returnUrl) { return this.navigateToPreviousUrl(); }
        this.router.navigate([this._returnUrl])
            .catch(() => { this.location.back; })
            .finally(() => { this.returnUrl = ''; });
    }

    get nativeWindow(): any {
        return _window();
    }

    // used to transmit commands from parent to child component
    get command$(): Observable<string> {
        return this._command$.asObservable();
    }
    setCommand(value: string): void {
        this._command$.next(value);
    }

    // used to transmit data from parent to child component
    get data$(): Observable<any> {
        return this._data$.asObservable();
    }
    setData(value: any): void {
        this._data$.next(value);
    }

    // get / set notification
    get notification$(): Observable<ibNotification | undefined> {
        return this._notification$.asObservable();
    }
    setNotification(notification: ibNotification | undefined): void {
        this._notification$.next(notification);
    }

    get currentItem$(): Subject<any> {
        return this._currentItem$;
    }

    // get / set last error
    get error(): ibError | undefined {
        return this._lastError;
    }
    setLastError(error: ibError): void {
        this._lastError = error;
    }
    resetLastError() { this._lastError = undefined; }

    // navigates to the error component
    public showError(error: ibError | string, reason?: string): void {
		if (error instanceof ibError && reason) { error.reason = reason; }
		if (typeof error === 'string') { error = ibError.create(error, reason); }
        this.setLastError(error);
        this.router.navigate([ 'Error' ]);
    }

    // lazy load a js script
    public loadScript$(script: IScriptModel): Observable<IScriptModel> {
        return new Observable<IScriptModel>((observer: Observer<IScriptModel>) => {
            const existingScript = this._scripts.find((s) => s.name === script.name);

            // Complete if already loaded
            if (existingScript && existingScript.loaded) {
                observer.next(existingScript);
                observer.complete();
            } else {
                // Add the script
                this._scripts = [ ...this._scripts, script ];

                // Load the script
                const scriptElement = document.createElement('script');
                scriptElement.type = 'text/javascript';
                scriptElement.src = script.src;

                scriptElement.onload = (): void => {
                    script.loaded = true;
                    observer.next(script);
                    observer.complete();
                };

                scriptElement.onerror = (): void => {
                    observer.error('Couldn\'t load script ' + script.src);
                };

                document.getElementsByTagName('body')[0].appendChild(scriptElement);
            }
        });
    }
}

// ----------------------------------------------------------------------------------------------
// ResizeData
// ----------------------------------------------------------------------------------------------
// heights clientHeight - the entire fold  Main height below header content height below header and title
export class ResizeData {
    private _size: Sizes;
    private _clientWidth: number;
    private _clientHeight: number;
	private _headerHeight: number;
	private _titleHeight: number;
    private _orientation: Orientations;
    private _fontSize: number;


    constructor(width: number, height: number, fontSize: number = 12.5) {
		this.update(width, height, fontSize);
	}

	public update(width: number, height: number, fontSize: number): void {
        this._clientWidth = width;
        this._clientHeight = height;
        this._fontSize = fontSize;

		this._size = ibSizes.sizeName(this._clientWidth);
        this._orientation = (this._clientWidth <= this._clientHeight) ? Orientations.portrait : Orientations.landscape;
    }

	public setElementSizes(headerHeight: number, titleHeight: number) {
		this._headerHeight = headerHeight;
		this._titleHeight = titleHeight;
	}

    get size(): Sizes {
        return this._size;
    }
    get clientHeight(): number {
        return this._clientHeight;
    }
    get clientWidth(): number {
        return this._clientWidth;
    }
    get fontSize(): number {
        return this._fontSize;
    }
    get orientation(): Orientations {
        return this._orientation;
    }

	get headerHeight(): number { return this._headerHeight; }
	get titleHeight(): number { return this._titleHeight; }

	get mainHeight(): number { return this._clientHeight - this._headerHeight; }
	get contentHeight(): number { return this._clientHeight - this.headerHeight - this._titleHeight; }

	pixels(size: number): string {
		return `${size}px`;
	}
    remToPixels(value: number): number {
        return value * this._fontSize;
    }
    pixelsToRem(value: number): number {
        return value / this._fontSize;
    }
    toImageSize(): ImageSizes {
        switch (this.size) {
            case 'xs': return ImageSizes.medium;
            case 'sm': return ImageSizes.large;
            case 'md':
            case 'lg':
            default: return ImageSizes.extraLarge;
        }
    }
}


// used for importing scripts
export interface IScriptModel {
    name: string;
    src: string;
    loaded: boolean;
}

// return the global native browser window object
function _window(): any {
    return window;
}
