import { Defaults } from '@common/classes/defaults';
import { IKey } from '@common/classes/interfaces';
import { Measure } from 'common/classes/lib';
import { BreedName } from './breed-name';



export class BreedStub implements IKey{

	static assign(source: Record<string, any>): BreedStub {
		const s = new BreedStub();

		if ('id' in source) {
			s.breedUID = (<any> source)['id'];
		} else if ('breedUID' in source) {
			s.breedUID = (<any> source)['breedUID'];
		} else {
			throw new Error('Required property \'id\' is missing');
		}

		if ('breedID' in source) {
			s.breedID = (<any> source)['breedID'];
		}

		if ('breedName' in source) {
			s.breedName = (<any> source)['breedName'];
		} else {
			throw new Error('Required property \'breedName\' is missing');
		}

		if ('caption' in source) {
			s.caption = (<any> source)['caption'];
		}

		if ('symbol' in source) {
			s.symbol = (<any> source)['symbol'];
		}

		if ('rank' in source) {
			s.rank = (<any> source)['rank'];
		}

		if ('group' in source) {
			s.group = (<any> source)['group'];
		}

		if ('weight' in source) {
			s.weight = (<any> source)['weight'];
		}

		if ('height' in source) {
			s.height = (<any> source)['height'];
		}

		if (s.weight) {
			const m = Measure.parse(s.weight);
			if (!m.error) {
				s.size = m.category;
				s.sizeKey = Measure.getKey(s.size, Measure.weightRanges);
			}
		} else if (s.height) {
			const m = Measure.parse(s.height);
			if (!m.error) {
				s.size = m.category;
				s.sizeKey = Measure.getKey(s.size, Measure.heightRanges);
			}
		}

		return s;
	}

	static createBlank(): BreedStub {
		const b = new BreedStub();
		b.breedUID = Defaults.BlankUID;
		b.breedID = 'blank';
		b.breedName = 'Blank';
		return b;
	}

	public breedUID: string = '';
	public breedID = '';
	public breedName: string = '';
	public caption = '';
	public symbol = '';
	public rank?: number;
	public group?: string;
	public weight?: string;
	public height?: string;
	public size?: string;
	public sizeKey?: number;

	public toBreedName(): BreedName {
		const bName = new BreedName();
		bName.breedUID = this.breedUID;
		bName.breedID = this.breedID;
		bName.breedName = this.breedName;
		bName.name = this.breedName;
		return bName;
	}

	public toKey(): string { return this.breedID; }

}
