// -------------------------------------------
// ib-error
// -------------------------------------------
// used by the error page, set in core service

import { ibIcon } from "./icon";
import { Icons } from "./icons";


export class ibError {

    public static create(message: string, reason?: string, title?: string, icon?: ibIcon): ibError {
        const err = new ibError();
        err.message = message;
        if (reason) { err.reason = reason; }
        if (title) { err.title = title; }
        if (icon) { err.icon = icon; }
        return err;
    }

    constructor() {
        this.title = 'Oops';
        this.message = 'An unknown error has ocurred';
        this.icon = Icons.generalIcon('bug');
    }

    public title: string;
    public message: string;
    public reason?: string;
    public code?: number;
    public icon?: ibIcon;
}