
<div class="card">
    <ib-image source="@public/irish" alt="Tracking Dog" class="card-img-top img-fluid"></ib-image>
    <div class="card-body">
        <div class="card-title">
            <h5>The best <strong>purebreds </strong></h5>
            <h6>directly from the best breeders</h6>
        </div>
        <ul class="footer">
            <li class="item">
                <a [routerLink]="[]" (click)="onClick('OurMission')">Our Mission</a>
            </li>
            <li class="item">
                <a [routerLink]="[]" (click)="onClick('Commitment')">Breeder Commitment</a>
            </li>
            <li class="item">
                <a [routerLink]="[]" (click)="onClick('WhyPurebreds')">Why Purebred Dogs</a>
            </li>
            <li class="item">
                <a [routerLink]="[]" (click)="onClick('ResponsibleBreeding')">Responsible Breeding</a>
            </li>
        </ul>
    </div>
</div>
