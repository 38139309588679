// ------------------------------------------------------
// ibTree & ibNode
// ------------------------------------------------------
// hierarchical tree structure with change notification
// sub nodes contained in item
// items can be loaded dynamically: fetch contains the name of the loader function

import { Observable, of } from "rxjs";
import { catchError, map, take } from "rxjs/operators";
import { Defaults } from "@common/classes/defaults";
import { ibIcon } from "@common/classes/icon";
import { ibCoreService } from "@common/services/core-services/core.service";
import { ibTreeLoader } from "@common/services/data-services/treeLoader.service";
import { Icons } from "@common/classes/icons";
import { ibTreeIcon } from "./treeIcon";

export interface INode {
	caption: string;
	items: Array<ibNode>;
	fetch?: string;
	parent?: ibNode;
	readonly loaded: boolean;
}

export interface INodeConfig {
	caption?: string,
	icon?: ibIcon,
	fetch?: string,
	parent?: ibNode,
	data?: any,
}

export class ibNode implements INode{

	protected _caption: string;
	protected _icon?: ibTreeIcon;
	protected _items: Array<ibNode>;
	protected _fetch?: string;
	protected _parent?: ibNode | ibTree;
	protected _data?: any;

    private _loaded: boolean = false;
	private _loader: ibTreeLoader;

	public static assign(source: object, parent?: ibNode | ibTree): ibNode | ibTree {
		if (!source) { throw new Error('ibNode assign - source is required'); }
		const node: ibNode | ibTree =
			('type' in source && (<any>source)['type'] === 'root') ? new ibTree() : new ibNode();

		if (parent) { node.parent = parent; }

		if ('caption' in source) {
			node.caption = (<any>source)['caption'];
		} else {
			throw new Error('ibNode assign - caption is required');
		}

		// if ('icon' in source) {

		// } else {
		// 	node.icon = undefined;
		// }

		// if ('icon' in source) {
		// 	const path = (<any>source)['icon'];
		// 	const i = ibIcon.create(path);
		// 	if (i instanceof ibIcon) { node._icon = i; } else {
		// 		throw new Error('ibNode assign - icon is invalid');
		// 	}
		// }

		if ('items' in source) {
			const i = (<any>source)['items'];
			if (Array.isArray(i)) {
				for (const item of i) {
					const newNode = ibNode.assign(item, node);
					node.items.push(newNode);
				}
			} else {
				throw new Error('ibNode assign - items is invalid');
			}
		}

		if ('fetch' in source) {
			node.fetch = (<any>source)['fetch'];
		}

		if ('data' in source) {
			node.data = (<any>source)['data'];
		}

		return node;
	}

	public static root(node: INode): INode {
		if (!node) { throw new Error('A node is required!'); }
		let current = node;
		while (true) {
			if (!current.parent) {
				return current;
			} else {
				current = current.parent;
			}
		}
	}

	constructor(config?: INodeConfig) {
		this._loader = new ibTreeLoader();
		this._caption = '';
		this._items = new Array<ibNode>();

		if (config) { this.config(config); }
	}

	public addChild(value: ibNode | INodeConfig): ibNode {
		let node: ibNode;
		if (value instanceof ibNode) {
			node = value;
		} else {
			node = new ibNode(value);
		}

		node.parent = this;
		this.items.push(node);

		return node;
	}

	public get caption(): string { return this._caption; }
	public get text(): string { return this._caption; }
	public set caption(value: string) { this._caption = value; }

	public get icon(): ibIcon | undefined {
		return Icons.generalIcon('paw');
	}
	// public set icon(value: ibIcon | undefined) { this._icon = value; }

	public get fetch(): string | undefined { return this._fetch; }
	public set fetch(value: string | undefined) { this._fetch = value; }

	public get items(): Array<ibNode> { return this._items; }
	public set items(value: Array<ibNode>) { this._items = value; }

	public get parent(): ibNode | ibTree | undefined { return this._parent; }
	public set parent(value: ibNode | ibTree | undefined) { this._parent = value; }

	public get data(): any { return (this._data); }
	public set data(value: any) { this._data = value; }

	public get path(): string {
		const res = new Array<number>();
		if (!this.parent) { return ''; }
		let currentNode: ibNode = this;
		let parent= this._parent;

		while (parent) {
			res.unshift(parent.items.indexOf(currentNode));
			currentNode = parent;
			parent = currentNode._parent;
		}

		return res.join('_');
	}

	public get loaded(): boolean { return this._loaded; }

	public hasChildren(): boolean {
		if (this._fetch) {
			if (this._loaded) {
				return this.items.length > 0;
			} else {
				return true;
			}
		} else {
			return this.items.length > 0;
		}
	}

	public children(): Observable<Array<any>> {
		if (this._fetch && !this.loaded) {
			return new Observable<Array<any>>((observer) => {
				(async () => {
					this._items = await this._loader.load(this);
					this._loaded = true;
					observer.next(this.items);
					observer.complete();
				})();
			});
		} else {
			return of(this._items);
		}
	}

	private config(c: INodeConfig) {
		if ('parent' in c) { this._parent = c['parent']; }
		if ('caption' in c) { this._caption = c['caption']!; }
		// if ('icon' in c) { this._icon = c['icon']; }
		if ('fetch' in c) { this._fetch = c['fetch']}
		if ('data' in c) { this._data = c['data']; }
	}

}

// the root node
export class ibTree extends ibNode {

	private static coreService: ibCoreService;
	private static loading: boolean

	public static assign(source: object): ibTree {
		const t = new ibTree();
		const n = ibNode.assign(source, t);
		t.caption = n.caption;
		// t.icon = n.icon;
		t.fetch = n.fetch;
		t.parent = undefined;
		t.data = n.data;
		t.items = n.items;
		return t;
	}

	public static load(name: string): Observable<ibTree | Error> {
		ibTree.loading = true;
		if (!ibTree.coreService) {
			ibTree.coreService = Defaults.injectorInstance.get<ibCoreService>(ibCoreService);
		}

		return this.coreService.getSettingFile(name).pipe(
			take(1),
			map((value) => {
				if (value instanceof Object) {
					try {
						const t = ibTree.assign(value);
						ibTree.loading = false;
						return t;
					} catch(error: any) {
						ibTree.loading = false;
						throw new Error('ibTree load - Invalid setting file' + error);
					}
				} else {
					ibTree.loading = false;
					throw new Error('ibTree load - Internal Error');
				}
			}),
			catchError((err: any) => {
				ibTree.loading = false;
				return of(new Error('ibTree load - ' + err));
			})
		);
	}

	constructor(config?: INodeConfig ) {
		super(config);
	}

}



