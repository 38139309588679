
// -------------------------------------------------
// ib icon-list
// -------------------------------------------------

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit,
         Output, SimpleChanges, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';

import { ibCoreService } from '@services/core-services/core.service';
import { ibIcon } from '@common/classes/icon';
import { IconList } from "@common/classes/iconList";

@Component({
    selector: 'ib-icon-list',
    templateUrl: './ib-icon-list.component.html',
    styleUrls: [ './ib-icon-list.component.scss' ]
})
export class ibIconListComponent implements AfterViewInit,  OnInit, OnChanges {

    @Input() list: IconList = new IconList();
    @Output() selection = new EventEmitter<ibIcon | undefined>();

    @ViewChild('imageContainer') images: ElementRef;

    private ul: HTMLUListElement;
    private elementBounds: Array<DOMRect>;
    private elementWidth: number;

    constructor(private coreService: ibCoreService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ( this.ul && changes.list && this.list) {

            let el: HTMLCollectionOf<Element> | undefined ;
            this.elementBounds = new Array<DOMRect>();
            // const w = this.ul.clientWidth;
            // const sw = this.ul.scrollWidth;
            // if (sw > w) {
            //     this.elementWidth = sw / this.list.length;
            // } else {
            //     this.elementWidth = 0;
            // }

            setTimeout(() => {
                el = this.ul.getElementsByTagName('LI');

                if (el) {
                    for (let i = 0; i < el.length; i++) {
                        this.elementBounds.push( el[i].getBoundingClientRect() );
                    }
                 }
                 if (this.elementBounds.length > 0) {
                     this.elementWidth = this.elementBounds[0].width;
                } else {
                    this.elementWidth = 0;
                }

                this.bringIntoView();
            }, 0);
        }
    }
    ngOnInit(): void {
        // fromEvent(window, 'resize').subscribe(()=>{});
    }

    ngAfterViewInit() {
        this.ul = this.images.nativeElement;
    }

    scrollLeft(): void {
        if (this.ul && this.elementWidth) { this.ul.scrollLeft -= this.elementWidth; }
    }

    scrollRight(): void {
        if (this.ul && this.elementWidth) { this.ul.scrollLeft += this.elementWidth; }
    }

    isActive(item: ibIcon): boolean {
        return (item) ? item.isSelected : false;
    }

    onClick(item: ibIcon): void {
        if (item && item.id) { this.list.setActive(item.id); }
        this.selection.emit((item) ? item : undefined);
        this.bringIntoView();
    }

    onDrop(): void {
        // Frag drop not yet supported - only needs to handle drop
    }

    private bringIntoView(): void {
        const el: HTMLCollectionOf<Element> | null = document.getElementsByClassName('active');
        if (el && el.length > 0) {
            setTimeout(() => {el[0].scrollIntoView({behavior: "smooth", block: "end", inline: "center"});}, 0);
            el[0].scrollIntoView({behavior: "smooth", block: "end", inline: "center"});
        }
    }





}
