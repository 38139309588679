// import { Observable } from 'rxjs';
// import { Subscriptions } from 'common/services/core-services/core.service';

// {
// 	"name": "iBreeder",
// 	"address": {
// 		"route": "1999 N. University Drive",
// 		"premise": "Suite 406",
// 		"city": "Coral Springs",
// 		"state": "FL.",
// 		"postalCode": 33071,
// 		"Country": "USA"
// 	},
// 	"phone": "+1 (954) 999-9999",
// 	"eMails": {
// 		"main": "info@iBreeder.com",
// 		"support": "support@iBreeder.com",
// 		"sales": "sales@iBreeder.com"
// 	}
// }

export class iBreederInfo {

	static assign(source: object): iBreederInfo | undefined {
		if (!source) { return undefined; }
		const i = new iBreederInfo();

		if ('name' in source) {
			i.name = (<any> source)['name'];
		} else {
			throw new Error('the name is required');
		}

		if ('address' in source) {
			i.address = SimpleAddress.assign((<any> source)['address']);
		} else {
			throw new Error('the address is required');
		}

		if ('phone' in source) {
			i.phone = (<any> source)['phone'];
		} else {
			throw new Error('the phone is required');
		}

		if ('eMails' in source) {
			const obj = <Record<string, unknown>> (<any> source)['eMails'];

			if ('main' in obj) {
				i._emails.set('main', (<any> obj)['main']);
			}
			if ('support' in obj) {
				i._emails.set('support', (<any> obj)['support']);
			}
			if ('sales' in obj) {
				i._emails.set('sales', (<any> obj)['sales']);
			}
		} else {
			throw new Error('the phone is required');
		}




		return i;
	}

	private _emails: Map<string, string>;

	constructor() {
		this.address = new SimpleAddress();
		this._emails = new Map<string, string>();
	}

	public name: string = '';
	public address?: SimpleAddress;
	public phone?: string;
	public email(key: string): string {
		const res = this._emails.get(key);
		return (res) ? res : '';
	}

}

export class SimpleAddress {

	static assign(source: Record<string, unknown>): SimpleAddress | undefined {
			if (!source) { return undefined; }
			const s = new SimpleAddress();

			if ('route' in source) {
				s.route = (<any> source)['route'];
			} else {
				s.route = '';
			}

			if ('premise' in source) {
				s.premise = (<any> source)['premise'];
			} else {
				s.premise = undefined;
			}

			if ('city' in source) {
				s.city = (<any> source)['city'];
			} else {
				s.city = '';
			}

			if ('state' in source) {
				s.state = (<any> source)['state'];
			} else {
				s.state = '';
			}

			if ('postalCode' in source) {
				s.postalCode = (<any> source)['postalCode'];
			} else {
				s.postalCode = '';
			}


			if ('country' in source) {
				s.country = (<any> source)['country'];
			} else {
				s.country = 'USA';
			}

			return s;
	}

	public constructor() {
		this.route = '';
		this.city = '';
		this.state = '';
		this.postalCode = '';
		this.country = '';
	}

	public route: string;
	public premise?: string;
	public city: string;
	public state: string;
	public postalCode: string;
	public country: string;

	public get cityStatePostalCode(): string {
		return `${this.city}, ${this.state} ${this.postalCode}`;
	}

	public get formattedAddress(): string {
		const p = (this.premise) ? ' ' + this.premise + ' ' : ' ';
		return `${this.route}${p}${this.city}, ${this.state} ${this.postalCode}`;
	}
}
