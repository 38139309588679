
import { ConsoleLogger } from '@angular/compiler-cli/private/localize';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ibIcon } from '@common/classes/icon';
import { IconList } from '@common/classes/iconList';

@Component({
    selector: 'ib-icon-menu',
    templateUrl: './ib-icon-menu.component.html',
    styleUrls: [ './ib-icon-menu.component.scss' ]
})
export class ibIconMenuComponent implements OnInit, OnChanges {

    @Input() items: IconList;
	@Input() mode: 'normal' | 'delete' = 'normal';
	@Input() columns: string = '';
	@Input() navigate: boolean = false;
	@Output() selectionChanged = new EventEmitter<ibIcon>();
	@Output() badgeClick = new EventEmitter<string>()

    constructor(private router: Router) { }

    ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		// console.log('Icon menu ===================================');
		// console.log(this.items);
		// if ('items' in changes) {
		// 	if (!changes.items) {
		// 		this.icons = new Array<ibIcon>();
		// 	} else if (this.items instanceof IconList) {
		// 		this.items.showText('all', 'lg')
		// 		this.icons = this.items.toArray;
		// 	} else {
		// 		this.icons = this.items;
		// 	}
		// 	console.log('***** Menu component change:');
		// 	console.log(this.items);
		// }
	}

	public get columnClass(): string {
		const cols = Number.parseInt(this.columns);
		if (cols) {
			if (cols > 0 && cols < 3) { return ` col-${cols}`; }
		}
		return ''
	}

    onClick(item: ibIcon) {
        if (item) {
			if (this.navigate) {
				let url = '';
				url = (item.url) ? item.url : item.data;
				if (url) { this.router.navigate([ url ]); }
			} else {
				this.selectionChanged.emit(item);
			}
        }
    }

	onBadgeClick(key: string) {
		this.badgeClick.emit(key);
	}
}
