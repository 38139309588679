


import { map, take } from 'rxjs/operators';
import { OwnerTypes } from '@entities/parts/owner';
import { ibBreedService } from '@common/services/entity-services/breed.service';
import { ibImageService } from '@common/services/entity-services/image.service';
import { Defaults } from './defaults';
import { ImageStub } from '@common/entities/documents/image-meta-document';
import { Tools } from '@common/tools';
import { BreedStub } from '@entities/parts/breed-stub';
import { Orientations } from './enums';
import { ibGeoRange } from './lib';

export class ibImageQuery {

	public accountID: string;
	public category: string;
	public customClause: string;
	public imageName: string;

	public static iBreeder(): string {
		return '';
	}

	constructor(accountID: string, category: string = '' , customClause: string = '', imageName: string = '') {
		this.accountID = accountID;
		this.category = (category) ? category : 'self';
		this.customClause = customClause;
		this.imageName = imageName;
	}

	public get sql(): string {
		let q = 'SELECT * FROM c WHERE c.DType="ImageDocument"';
		if (this.accountID) { q += ` AND c.accountID="${this.accountID}`; }
		if (this.category) { q += ` AND c.category="${this.category}`; }
		// if (this.customClause) { q += ` AND c.accountID="${this.customClause}`; }
		if (this.imageName) { q += ` AND c.imageName="${this.imageName}`; }

		console.log('Query SQL' + q);

		return q
	}

}

// Image Query

export enum SortDirections {
	ascending,
	descending
}

export class ImageQueryUtil {

	// assign JSon Object to a typed Breeder class
	// only properties existing in Breeder Class are assigned - there is no type check
	static assign(source: Record<string, any>, target: ImageQuery | null = null): ImageQuery {
		if (!target) {
			target = new ImageQuery();
		}

		// if (source.hasOwnProperty('top')) {
		//     target.top = (<any>source)['top'];
		// } else {
		//     target.top = -1;
		// }

		// if (source.hasOwnProperty('accountType')) {
		//     target.accountType = (<any>source)['accountType'];
		// } else {
		//     target.accountType = "unknown";
		// }

		// if (source.hasOwnProperty('accountID')) {
		//     target.accountID = (<any>source)['accountID'];
		// } else {
		//     target.accountID = "";
		// }

		// if (source.hasOwnProperty('category')) {
		//     target.category = (<any>source)['category'];
		// } else {
		//     target.category = "self";
		// }


		return target;
	}
}


// --------------------------------------------------------------------
// Image Query
// --------------------------------------------------------------------

// Required: OwnerType (breed, breeder, litter or dog)
// ownerID
// 			for breed: 1 breedID or array of breedIDs or count of breeds randomly selected
//
// Names:
// 			for breed: 1 or array of image names or count of image to retrieve per breed
// 			or range of breeds to retrieve expresses as 1.99 - get 1 to 99 images per breed at random


export class ImageQuery {

	private _ownerType: OwnerTypes = OwnerTypes.Blank;
	private _ownerID: string | Array<string> | number = '';
	private _names: string | Array<string> | number = '';

	public get ownerType(): OwnerTypes { return this.ownerType; }
	public set ownerType(value: OwnerTypes) { this._ownerType = value; }

	public get ownerID(): string | Array<string> | number { return this._ownerID; }
	public set ownerID(value: string | Array<string> | number) { this._ownerID = value;}

	public get names(): string | Array<string> | number { return this._names; }
	public set names(value: string | Array<string> | number) { this._names = value; }

	private breedService: ibBreedService;
	private imageService: ibImageService;

	constructor() {
		this.breedService = Defaults.injectorInstance.get<ibBreedService>(ibBreedService);
		this.imageService = Defaults.injectorInstance.get<ibImageService>(ibImageService);
	}

	public run(): Promise<Array<ImageStub>> {
		return new Promise<Array<ImageStub>>((resolve, reject) => {
			// this.generateQuery().then((Q) => {
			// 	this.imageService.SQLQuery(Q).subscribe((stubs: Array<ImageStub>) => {
			// 		if (stubs && stubs.length > 0) {
			// 			if (Array.isArray(this.ownerID)) {
			// 				if (typeof this.names === 'number') {
			// 					stubs = this.imageService.TakePerBreed(stubs, this.names);
			// 				}
			// 			} else if (typeof this.ownerID === 'string') {
			// 				stubs = Tools.ArrayScramble(stubs);
			// 				if (typeof this.names === 'number') {
			// 					if (this.names > 0) { stubs.slice(0, this.names); }
			// 				}
			// 			}
			// 			resolve(stubs);
			// 		} else {
			// 			reject('No images found');
			// 		}
			// 	});
			// }).catch((error) => {
			// 	reject(error);
			// });
		});
	}

	private async generateQuery(): Promise<string> {

		return new Promise<string>((resolve, reject) => {

			// handle type
			let oType: number = 0;
			switch (this._ownerType) {
				case OwnerTypes.Breed:
					oType = OwnerTypes.Breed;
					alert('generate query - ph changed does not work');
					// if (typeof this._ownerID === 'number') {
					// 	// get random list of breeds
					// 	const count = (this._ownerID > 0) ? this._ownerID : 1;
					// 	this.breedService.getRandomBreeds(count).then((res: Array<BreedStub>) => {
					// 		this._ownerID = new Array<string>();
					// 		res.forEach((value: BreedStub) => {
					// 			(<Array<string>> this._ownerID).push(value.breedID);
					// 		});
					// 		resolve(this._ownerID);

					// 	})

					// }
					break;
				case OwnerTypes.Breeder:
					oType = OwnerTypes.Breeder;
					break;
				case OwnerTypes.Litter:
					oType = OwnerTypes.Litter;
					break;
				case OwnerTypes.Dog:
					oType = OwnerTypes.Dog;
					break;
				default:
					reject('Image query run: invalid owner type');
			}

			const Q = `SELECT * FROM c where c.DType='ImageDocument' AND c.ownerType=${oType}`;

			// handle owners
			let idClauseOwner: string = '';
			if (this._ownerID) {
				if (Array.isArray(this._ownerID)) {
					if (this._ownerID.length > 0) {
						idClauseOwner = ' AND c.ownerID IN (';
						(<Array<string>> this._ownerID).forEach((value: string) => {
							idClauseOwner += `'${value}', `;
						});
						idClauseOwner = idClauseOwner.slice(0, idClauseOwner.length - 2) + ')';
					} else {
						reject('Image query run: empty owner list');
					}
				} else if (typeof this._ownerID === 'string') {
					idClauseOwner = ` AND c.ownerID = '${this._ownerID}'`;
				}
			} else {
				reject('Image query run: invalid owner list');
			}

			// handle image names
			let idClauseNames: string = '';
			if (this._names) {
				if (Array.isArray(this._names)) {
					if (this._names.length > 0) {
						idClauseNames = ' AND c.imageName IN (';
						(<Array<string>> this._names).forEach((value: string) => {
							idClauseNames += `'${value}', `;
						});
						idClauseNames = idClauseNames.slice(0, idClauseNames.length - 2) + ')';
					} else {
						idClauseNames = '';
					}
				} else if (typeof this._names === 'string') {
					idClauseNames = ` AND c.imageName = '${this.names}'`;
				}
			} else if (typeof this._names === 'number') {

			} else {
				idClauseNames = '';
			}

			resolve(Q + idClauseOwner + idClauseNames);
		});
	}
}

// used in image viewer and server - to be eventually replaced
// allows to get a direct url
export class SimpleImageQuery {

	constructor() {
		this.paths = new Array<string>();
		this.count = -1;
		this.orientation = Orientations.either;
	}

	public paths: Array<string>;
	public count: number;
	public orientation: Orientations;
	public distance?: ibGeoRange;
	public sortBy?: string;
	public rootName?: string;

	public addPath(path: string) {
		this.paths.push(path);
	}


}
