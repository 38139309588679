import { ImageStub } from 'common/entities/documents/image-meta-document';
import { Tools } from 'common/tools';
import { Banner } from './banner';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';


export class Slide {

	private static transitions2d = [1, 2, 3, 4, 5, 10, 11, 12, 13, 14, 15, 16, 17, 18, 73, 78,
									81, 83, 84, 85, 86, 87, 88, 98, 99, 100, 101, 102, 103, 104, 105, 106];
	private static transitions3d = [1, 2, 3, 4, 45, 46, 47, 98];

	public static FromImageStubs(images: Array<ImageStub>): Observable<Array<Slide>> {
		const oSlides = new Array<Observable<Slide>>();
		for (const img of images) {
			oSlides.push(Slide.FromImageStub(img));
		}

		return forkJoin(oSlides);
	}

	public static FromImageStub(stub: ImageStub): Observable<Slide> {

		return stub.bannerData.pipe(
			take(1),
			map((banner: Banner | undefined) => {
				const s = new Slide();
				s.name = stub.id;
				s.imageUrl = stub.path + '';
				s.duration = 2800;
				const px = (stub.headPositionX >= 0) ? stub.headPositionX : 50;
				const py = (stub.headPositionY >= 0) ? stub.headPositionY : 50;
				s.bgPosition = `${px}%, ${py}%`;
				const t = this.setTransition();
				s.transitionType = t.type;
				s.allowedTransitions = t.allowed;
			 	s.bannerData = (banner) ? banner : undefined;

				return s;
			})
		);
	}



	constructor() {
		this.name = '';
		this.index = -1;
		this.thumbnail = false;
		this.duration = 2800;
		this.bgPosition = '';
		this.transitionType = '2d';
		this.allowedTransitions = 'all';
		this.title = 'Title Here';
	}

	public name: string;
	public alt: string = 'iBreeder dog image';
	public index: number;
	public imageUrl: string = '';
	public thumbnail: boolean;
	public duration: number;
	public bgPosition: string;
	public transitionType: '2d' | '3d';
	public allowedTransitions: string;
	public kenburn?: { zoom: 'in' | 'out'; scale: number;};
	public bannerData?: Banner;
	public title: string;

	public settings() {
		return `duration: ${this.duration}; bgposition: ${this.bgPosition}; transition${this.transitionType}: ${this.allowedTransitions};`;
	}

	private static setTransition(): { type: '2d' | '3d'; allowed: string;} {
		const t: '2d' | '3d' = (Tools.randomInteger(0,1000) < 800) ? '2d' : '3d';
		let a: string = '';
		if (t === '2d') {
			for (let i = 0; i < 3; i++) {
				a += Tools.randomInteger(0,this.transitions2d.length - 1).toString() + ',';
			}
			a = a.substr(0, a.length - 1);
		} else {
			for (let i = 0; i < 2; i++) {
				a += Tools.randomInteger(0,this.transitions3d.length - 1).toString() + ',';
			}
			a = a.substr(0, a.length - 1);
		}
		return {type: t, allowed: a};
	}

}
