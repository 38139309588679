// ======================================================
// SHARED IMAGE COMPONENT
// ======================================================
// Selects the proper sized image automatically when view port is resized
//
// Usage provide src url, alternative text and any classes to apply to the image


import { Component } from '@angular/core';


@Component({
    selector: 'ib-loader',
    templateUrl: './ib-loader.component.html',
	styleUrls: ['ib-loader.component.scss']
})
export class ibLoaderComponent {

    constructor() {}

}
