import { DropDownItemDisplay } from "../documents/dog-document";


export class Color {

    constructor(Index: number, Gender: string, Name: string, Hex: string) {
        this.index = Index;
        this.gender = Gender;
        this.name = Name;
        this.hex = Hex;
    }

    public index: number;
    public gender: string;
    public name: string;
    public hex: string;
}


export class Colors {

    private static _femaleColors: Array<Color> =
    [
        // Feminine Colors
        new Color(100, 'f', 'tomato', '#FF6347'),
        new Color(101, 'f', 'coral', '#FF7F50'),
        new Color(102, 'f', 'light coral', '#F08080'),
        new Color(103, 'f', 'dark salmon', '#E9967A'),
        new Color(104, 'f', 'salmon', '#FA8072'),
        new Color(105, 'f', 'light salmon', '#FFA07A'),
        new Color(106, 'f', 'pale golden rod', '#EEE8AA'),
        new Color(107, 'f', 'khaki', '#F0E68C'),
        new Color(108, 'f', 'lawn green', '#7CFC00'),
        new Color(108, 'f', 'chartreuse', '#7FFF00'),
        new Color(110, 'f', 'green yellow', '#ADFF2F'),
        new Color(111, 'f', 'lime', '#00FF00'),
        new Color(112, 'f', 'lime green', '#32CD32'),
        new Color(113, 'f', 'light green', '#90EE90'),
        new Color(114, 'f', 'pale green', '#98FB98'),
        new Color(115, 'f', 'dark sea green', '#8FBC8F'),
        new Color(116, 'f', 'medium spring green', '#00FA9A'),
        new Color(117, 'f', 'spring green', '#00FF7F'),
        new Color(118, 'f', 'aqua', '#00FFFF'),
        new Color(119, 'f', 'cyan', '#00FFFF'),
        new Color(120, 'f', 'light cyan', '#E0FFFF'),
        new Color(121, 'f', 'dark turquoise', '#00CED1'),
        new Color(122, 'f', 'aqua marine', '#7FFFD4'),
        new Color(123, 'f', 'powder blue', '#B0E0E6'),
        new Color(124, 'f', 'light blue', '#ADD8E6'),
        new Color(125, 'f', 'sky blue', '#87CEEB'),
        new Color(126, 'f', 'light sky blue', '#87CEFA'),
        new Color(127, 'f', 'slate blue', '#6A5ACD'),
        new Color(128, 'f', 'medium slate blue', '#7B68EE'),
        new Color(129, 'f', 'medium purple', '#9370DB'),
        new Color(130, 'f', 'medium orchid', '#BA55D3'),
        new Color(131, 'f', 'thistle', '#D8BFD8'),
        new Color(132, 'f', 'plum', '#DDA0DD'),
        new Color(133, 'f', 'violet', '#EE82EE'),
        new Color(134, 'f', 'magenta', '#FF00FF'),
        new Color(135, 'f', 'orchid', '#DA70D6'),
        new Color(136, 'f', 'medium violet red', '#C71585'),
        new Color(137, 'f', 'pale violet red', '#DB7093'),
        new Color(138, 'f', 'deep pink', '#FF1493'),
        new Color(139, 'f', 'hot pink', '#FF69B4'),
        new Color(140, 'f', 'light pink', '#FFB6C1'),
        new Color(141, 'f', 'pink', '#FFC0CB'),
        new Color(142, 'f', 'antique white', '#FAEBD7'),
        new Color(143, 'f', 'beige', '#F5F5DC'),
        new Color(144, 'f', 'bisque', '#FFE4C4'),
        new Color(145, 'f', 'blanched almond', '#FFEBCD'),
        new Color(146, 'f', 'wheat', '#F5DEB3'),
        new Color(147, 'f', 'corn silk', '#FFF8DC'),
        new Color(148, 'f', 'lemon chiffon', '#FFFACD'),
        new Color(149, 'f', 'light golden rod yellow', '#FAFAD2'),
        new Color(150, 'f', 'light yellow', '#FFFFE0'),
        new Color(151, 'f', 'burly wood', '#DEB887'),
        new Color(152, 'f', 'tan', '#D2B48C'),
        new Color(153, 'f', 'rosy brown', '#BC8F8F'),
        new Color(155, 'f', 'moccasin', '#FFE4B5'),
        new Color(156, 'f', 'navajo white', '#FFDEAD'),
        new Color(157, 'f', 'peach puff', '#FFDAB9'),
        new Color(158, 'f', 'misty rose', '#FFE4E1'),
        new Color(159, 'f', 'lavender blush', '#FFF0F5'),
        new Color(160, 'f', 'linen', '#FAF0E6'),
        new Color(161, 'f', 'old lace', '#FDF5E6'),
        new Color(162, 'f', 'papaya whip', '#FFEFD5'),
        new Color(163, 'f', 'sea shell', '#FFF5EE'),
        new Color(164, 'f', 'mint cream', '#F5FFFA'),
        new Color(165, 'f', 'lavender', '#E6E6FA'),
        new Color(166, 'f', 'floral white', '#FFFAF0'),
        new Color(167, 'f', 'alice blue', '#F0F8FF'),
        new Color(168, 'f', 'ghost white', '#F8F8FF'),
        new Color(169, 'f', 'honeydew', '#F0FFF0'),
        new Color(170, 'f', 'ivory', '#FFFFF0'),
        new Color(171, 'f', 'azure', '#F0FFFF'),
        new Color(172, 'f', 'snow', '#FFFAFA'),
        new Color(173, 'f', 'light gray', '#D3D3D3'),
        new Color(174, 'f', 'gray', '#DCDCDC'),
        new Color(175, 'f', 'white smoke', '#F5F5F5'),
        new Color(176, 'f', 'white', '#FFFFFF')
    ];

    private static _maleColors: Array<Color> =
    [
        // Masculine Colors
        new Color(200, 'm', 'maroon', '#800000'),
        new Color(201, 'm', 'dark red', '#8B0000'),
        new Color(202, 'm', 'brown', '#A52A2A'),
        new Color(203, 'm', 'firebrick', '#B22222'),
        new Color(204, 'm', 'red', '#FF0000'),
        new Color(205, 'm', 'indian red', '#CD5C5C'),
        new Color(206, 'm', 'orange red', '#FF4500'),
        new Color(207, 'm', 'dark orange', '#FF8C00'),
        new Color(208, 'm', 'orange', '#FFA500'),
        new Color(209, 'm', 'gold', '#FFD700'),
        new Color(210, 'm', 'dark golden rod', '#B8860B'),
        new Color(211, 'm', 'golden rod', '#DAA520'),
        new Color(212, 'm', 'dark khaki', '#BDB76B'),
        new Color(213, 'm', 'olive', '#808000'),
        new Color(214, 'm', 'yellow', '#FFFF00'),
        new Color(215, 'm', 'yellow green', '#9ACD32'),
        new Color(216, 'm', 'dark olive green', '#556B2F'),
        new Color(217, 'm', 'olive drab', '#6B8E23'),
        new Color(218, 'm', 'dark green', '#006400'),
        new Color(219, 'm', 'green', '#008000'),
        new Color(220, 'm', 'forest green', '#228B22'),
        new Color(221, 'm', 'sea green', '#2E8B57'),
        new Color(222, 'm', 'medium aqua marine', '#66CDAA'),
        new Color(223, 'm', 'medium sea green', '#3CB371'),
        new Color(224, 'm', 'light sea green', '#20B2AA'),
        new Color(225, 'm', 'dark slate gray', '#2F4F4F'),
        new Color(226, 'm', 'teal', '#008080'),
        new Color(227, 'm', 'dark cyan', '#008B8B'),
        new Color(228, 'm', 'dark turquoise', '#00CED1'),
        new Color(229, 'm', 'turquoise', '#40E0D0'),
        new Color(230, 'm', 'medium turquoise', '#48D1CC'),
        new Color(231, 'm', 'cadet blue', '#5F9EA0'),
        new Color(232, 'm', 'steel blue', '#4682B4'),
        new Color(233, 'm', 'corn flower blue', '#6495ED'),
        new Color(234, 'm', 'deep sky blue', '#00BFFF'),
        new Color(235, 'm', 'dodger blue', '#1E90FF'),
        new Color(236, 'm', 'midnight blue', '#191970'),
        new Color(237, 'm', 'navy', '#000080'),
        new Color(238, 'm', 'dark blue', '#00008B'),
        new Color(239, 'm', 'medium blue', '#0000CD'),
        new Color(240, 'm', 'blue', '#0000FF'),
        new Color(241, 'm', 'royal blue', '#4169E1'),
        new Color(242, 'm', 'blue violet', '#8A2BE2'),
        new Color(243, 'm', 'indigo', '#4B0082'),
        new Color(244, 'm', 'dark slate blue', '#483D8B'),
        new Color(245, 'm', 'dark magenta', '#8B008B'),
        new Color(246, 'm', 'dark violet', '#9400D3'),
        new Color(247, 'm', 'dark orchid', '#9932CC'),
        new Color(248, 'm', 'purple', '#800080'),
        new Color(249, 'm', 'saddle brown', '#8B4513'),
        new Color(250, 'm', 'sienna', '#A0522D'),
        new Color(251, 'm', 'chocolate', '#D2691E'),
        new Color(252, 'm', 'peru', '#CD853F'),
        new Color(253, 'm', 'sandy brown', '#F4A460'),
        new Color(254, 'm', 'slate gray', '#708090'),
        new Color(255, 'm', 'light slate gray', '#778899'),
        new Color(256, 'm', 'light steel blue', '#B0C4DE'),
        new Color(257, 'm', 'black', '#000000'),
        new Color(258, 'm', 'dim gray', '#696969'),
        new Color(259, 'm', 'gray', '#808080'),
        new Color(260, 'm', 'dark gray', '#A9A9A9'),
        new Color(261, 'm', 'silver', '#C0C0C0')
    ];

    private static _mainColors: Array<Color> =
    [
        // Main Colors
        new Color(1, 'm', 'Black', '#111111'),
        new Color(2, 'm', 'Gray', '#AAAAAA'),
        new Color(3, 'm', 'Silver', '#DDDDDD'),
        new Color(4, 'm', 'White', '#FFFFFF'),
        new Color(5, 'm', 'Fuchsia', '#F012BE'),
        new Color(6, 'm', 'Orange', '#FF851B'),
        new Color(7, 'm', 'Red', '#FF4136'),
        new Color(8, 'm', 'Maroon', '#85144b'),
        new Color(9, 'm', 'Purple', '#B10DC9'),
        new Color(10, 'm', 'Navy', '#001f3f'),
        new Color(11, 'm', 'Blue', '#0074D9'),
        new Color(12, 'm', 'Aqua', '#7FDBFF'),
        new Color(13, 'm', 'Teal', '#39CCCC'),
        new Color(14, 'm', 'Lime', '#01FF70'),
        new Color(15, 'm', 'Green', '#2ECC40'),
        new Color(16, 'm', 'Olive', '#3D9970'),
        new Color(17, 'm', 'Yellow', '#FFDC00')

    ];

    public static getColor(name: string | null): Color | null {
        if (!name) { name = 'Navy'; }
        for (const entry of Colors._femaleColors) {
            if (entry.name.trim().toLowerCase() === name.toLowerCase()) { return entry; }
        }
        for (const entry of Colors._maleColors) {
            if (entry.name.trim().toLowerCase() === name.toLowerCase()) { return entry; }
        }
        return null;
    }

    // public static getColorItem(name: string | null = null): DropDownItemDisplay {
    //     const c = Colors.getColor(name);
    //     if (c && name) {
    //         const O: DropDownItemDisplay = { name: "", icon: undefined };
    //         O.name = c.name;
    //         O.icon = new Icon('fa-circle', 'ib-collarColor-' + c.name);
    //         return O;
    //     } else {
    //         const O: DropDownItemDisplay = { name: "", icon: undefined };
    //         O.name = "None";
    //         O.icon = new Icon('fa-circle-thin', 'ib-collarColor-Navy');
    //         return O;
    //     }
    // }


    public static get colors(): Array<Color> {
        return Colors._femaleColors.concat(Colors._maleColors);
    }

    public static get femaleAssortment(): Array<Color> {
        if (!this._fa) {
            this._fa = new Array<Color>();
            this._fa.push(this._femaleColors[4]);
            this._fa.push(this._femaleColors[11]);
            this._fa.push(this._femaleColors[14]);
            this._fa.push(this._femaleColors[21]);
            this._fa.push(this._femaleColors[25]);
            this._fa.push(this._femaleColors[29]);
            this._fa.push(this._femaleColors[33]);
            this._fa.push(this._femaleColors[41]);
            this._fa.push(this._femaleColors[57]);
            this._fa.push(this._femaleColors[65]);
        }
        return this._fa;
    }
    private static _fa: Array<Color> | null = null;

    public static get maleAssortment(): Array<Color> {
        if (!this._ma) {
            this._ma = new Array<Color>();
            this._ma.push(this._maleColors[1]);
            this._ma.push(this._maleColors[4]);
            this._ma.push(this._maleColors[6]);
            this._ma.push(this._maleColors[13]);
            this._ma.push(this._maleColors[14]);
            this._ma.push(this._maleColors[19]);
            this._ma.push(this._maleColors[26]);
            this._ma.push(this._maleColors[37]);
            this._ma.push(this._maleColors[48]);
            this._ma.push(this._maleColors[49]);
        }
        return this._ma;
    }
    private static _ma: Array<Color> | null = null;
}

