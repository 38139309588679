
<div class="top-left clickable" (click)="onClick('top-left')">
	<i class="fa-solid fa-caret-up xxs fa-rotate-by tilt-left"></i>
</div>
<div class="top clickable" (click)="onClick('top')">
	<i class="fa-solid fa-caret-up xs"></i>
</div>
<div class="top-right clickable" (click)="onClick('top-right')">
	<i class="fa-solid fa-caret-up xxs fa-rotate-by tilt-right"></i>
</div>
<div class="middle-left clickable" (click)="onClick('middle-left')">
	<i class="fa-solid fa-caret-left xs"></i>
</div>
<div class="middle clickable" (click)="onClick('middle')">
	<i class="fa-solid fa-circle-small xxs"></i>
</div>
<div class="middle-right clickable" (click)="onClick('middle-right')">
	<i class="fa-solid fa-caret-right xs"></i>
</div>
<div class="bottom-left clickable" (click)="onClick('bottom-left')">
	<i class="fa-solid fa-caret-down xxs fa-rotate-by tilt-right"></i>
</div>
<div class="bottom clickable" (click)="onClick('bottom')">
	<i class="fa-solid fa-caret-down xs"></i>
</div>
<div class="bottom-right clickable" (click)="onClick('bottom-right')">
	<i class="fa-solid fa-caret-down xxs fa-rotate-by tilt-left"></i>
</div>

