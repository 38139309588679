// ----------------------------------------------------------------
// Toolbar
// ----------------------------------------------------------------
// show a toolbar of icons (menu items shown as icons)
// the click event returns the icon ID (from menuItem)

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ibIcon } from '@common/classes/icon';
import { IconList } from "@common/classes/iconList";
import { ibCoreService } from '@common/services/core-services/core.service';

@Component({
    selector: 'ib-toolbar',
    templateUrl: './ib-toolbar.component.html',
    styleUrls: [ './ib-toolbar.component.scss' ]
})
export class ibToolbarComponent implements OnInit {
    public icons: IconList;

    get items(): IconList | Array<ibIcon> {
        if (!this.icons) {
            this.items = new Array<ibIcon>();
        }
        return this.icons;
    }
    @Input() set items(value: IconList | Array<ibIcon> | undefined) {
		this.icons = new IconList();
		if (value && value instanceof IconList) {
            this.icons = value;
		} else if (value && Array.isArray(value) && value.length > 0) {
			value.forEach((i: ibIcon) => {
				i.caption.size = 'xs';
				i.caption.color = 'ib-color-menu-text';
				this.icons.add(i);
			});
		}
    }

    @Output() clicked: EventEmitter<string> = new EventEmitter<string>();
    @Output() iconClicked: EventEmitter<ibIcon> = new EventEmitter<ibIcon>();

    constructor(private coreService: ibCoreService
    ) {
        this.icons = new IconList();
    }

    ngOnInit(): void {
    }

    onToolbarClick(event: Event, item: ibIcon, i: number): void {
        if (event) {
            event.stopPropagation();
			item.isSelected = true;
			this.clicked.emit(item.id);
			this.iconClicked.emit(item);
		}
        // if (this.icons && i >= 0 && i < this.icons.length) {
        //     if (this.icons[i].state !== 'disabled' && this.icons[i].state !== 'hidden') {
        //         const newIcons = new Array<ibIcon>();
        //         for (const _icon of this.icons) {
        //             const clonedIcon = _icon.clone();
        //             clonedIcon.state = 'normal';
        //             newIcons.push(clonedIcon);
        //         }
        //         this.icons = newIcons;
        //         this.icons[i].state = 'selected';
        //         this.click.emit(this.icons[i].id);
        //         this.iconClick.emit(this.icons[i]);
        //     }
        // }
    }

    isClickable(id: string): boolean {
		const i = this.icons.get(id);
		if (i) {
			return i.isClickable;
		} else { return true; }
    }

    getIconID(index: number, icon: ibIcon): string | undefined {
        return icon.id;
    }

}
