// ---------------------------------------------------
// Search Bar state
// ---------------------------------------------------

import { BehaviorSubject, Observable } from "rxjs";


export type SearchStates = 'idle' | 'start' | 'pending' | 'complete' | 'error';

export class SearchBarState {

	public inputState: SearchInputState;

	constructor() {
		this.inputState = new SearchInputState();
	}
}

// the state of the search input - used to update UI
export class SearchInputState {

	private _changed$: BehaviorSubject<SearchInputState>
	private _searchTerm: string;
	private _state: SearchStates;

	constructor() {
		this._searchTerm = '';
		this._state = 'idle';
		this._changed$ = new BehaviorSubject<SearchInputState>(this);
	}

	public get searchTerm(): string { return this._searchTerm; }
	public set searchTerm(value: string) {
		this._searchTerm = value;
		this._changed$.next(this);
	}

	public get state(): SearchStates { return this._state; }
	public set state(value: SearchStates) {
		this._state = value;
		this._changed$.next(this);
	}

	public get changed$(): Observable<SearchInputState> {
		return this._changed$.asObservable();
	}

}
