
<div *ngIf='!item.badge' class='card clickable shadow' [style.width]='width' kendoTooltip [title]='item.tooltip'(click)='onMenu(item)'>
	<i class='{{item.image.classes("self")}}'></i>
	<span>{{item.caption.text}}</span>
</div>

<ib-badge *ngIf='item.badge' [config]='item.badge' (clicked)='onBadgeClick($event)'>
	<div class='card clickable shadow' kendoTooltip [title]='item.tooltip'(click)='onMenu(item)'>
		<i class='{{item.image.classes("self")}}'></i>
		<span>{{item.caption.text}}</span>
	</div>
</ib-badge>
