
//----------------------------------------------------------------------
// Icon List
//----------------------------------------------------------------------
// only general icons are cached

// !!!! the icon cache is not well thought out must be reviewed or eliminated ?
// problem is that icons in cache could be modified icons

import { Sizes, ToolbarItemTypes } from './types';
import { Icons } from './icons';
import { ibIcon, IconAnimationMode, IconSizes, TextSizes } from './icon';


export type DisableAction= 'disable' | 'enable' | 'switch';
export type ShowCaption = 'show' | 'hide' | 'perIcon';

export class IconList {

	private static _cache: Map<string, ibIcon>;

	public static fixedWidth(icon: ibIcon): ibIcon {
		if (!icon) { throw new Error('Icon Fixed Width icon parameter missing'); }
		icon.image.fixedWidth = true;
		return icon;
	}

	public static create(icons: Array<ToolbarItemTypes>, iconSize: IconSizes = 'none', showCaptions: ShowCaption = 'hide'): IconList {
		const result = new IconList(iconSize, 'none');
		result.fixedWidth(true);

		if (!IconList._cache) { IconList._cache = new Map<string, ibIcon>(); }
		if (!icons || icons.length < 1) { return result; }

		icons.forEach((iconName: ToolbarItemTypes) => {
			const icon =IconList.getIcon(iconName);
			result.add(icon);
		});
		return result;
	}

	public static getIcon(iconName: string): ibIcon {
		let confirm = false;
		if (iconName.endsWith('!')) {
			confirm = true;
			iconName = <ToolbarItemTypes>iconName.slice(0, iconName.length - 1);
		}
		let icon = IconList._cache.get(iconName);   // ?? how to handle modified icons
		if (!icon) {
			const i = Icons.generalIcon(iconName);
			if (!i || i.id === 'bugIcon') {
				throw new Error(`Unknown icon: \'${iconName}\'`);
			} else {
				icon = i;
				if (i.id === 'bugIcon') { IconList._cache.set(<string>icon.id, icon)};
			}
			// icon not added to general store
		}
		if (confirm) { icon.requiresConfirmation = true; }
		return icon;
	}

	private _store: Map<string, ibIcon>;
	private _iconSize: IconSizes = 'xl';
	private _textSize?: TextSizes = 'none';
	private _textColor: string = 'ib-color-text';
	private _fixedWidth: boolean = true;
	private _captionLengthThreshold?: Sizes;             // min size for long caption

	constructor(iconSize?: IconSizes, textSize?: TextSizes, textColor?: string) {
		this._store = new Map<string, ibIcon>();
		this._iconSize = (iconSize) ? this._iconSize = iconSize : 'none';
		if (textSize) { this._textSize = textSize; }
		if (textColor) { this._textColor = textColor; }
	}

	// id required does not add to cache - does not trigger error
	public add(icon: ibIcon): void {
		if (icon && icon.id && !this._store.has(icon.id)) {
			if (this._iconSize !== 'none') { icon.image.size = this._iconSize; }
			if (this._textSize) { icon.caption.size = this._textSize; }
			icon.caption.color = this._textColor;
			icon.image.fixedWidth = this._fixedWidth;
			this._store.set(icon.name, icon);
		}
	}

	public has(iconName: string): boolean {
		return this._store.has(iconName);
	}

	// does not use the global icon cache
	public get(iconName: string): ibIcon {
		// const icon = IconList.getIcon(iconName);   // no global cache used
		if (this._store.has(iconName)) {
			return this._store.get(iconName)!;
		} else {
			return ibIcon.getIcon('general','bug');
		}
	}

	public getClasses(iconName: string): string {
		const i = this.get(iconName);
		return i.imageClasses;
	}

	public get captionLengthThreshold(): Sizes | undefined { return this._captionLengthThreshold; }
	public set captionLengthThreshold(value: Sizes | undefined) {
		this._captionLengthThreshold = value;
	}

	public enableAnimation(id: string, mode: IconAnimationMode) {
		const i = this._store.get(id);
		if (i) { i.image.animationMode = mode; }
	}

	public fixedWidth(fixed: boolean = true) {
		this._store.forEach((i: ibIcon) => { i.image.fixedWidth = fixed; });
		this._fixedWidth = fixed;
	}

	// show text for one icon or all
	public showText(id?: string): void {
		if (!id) {
			this.list.forEach((value: ibIcon) => {
				if (value.caption) { value.caption.showCaption = true; }
			});
		} else {
			if (!id.endsWith('Icon')) { id = id + 'Icon'; }
			const i = this._store.get(id);
			if (i && i.caption) {
				i.caption.showCaption = true;
			}
		}
	}

	public hideText(id?: string): void {
		if (!id) {
			this.list.forEach((value: ibIcon) => {
				if (value.caption) { value.caption.showCaption = false; }
			});
		} else {
			if (!id.endsWith('Icon')) { id = id + 'Icon'; }
			const i = this._store.get(id);
			if (i && i.caption) {
				i.caption.showCaption = false;
			}
		}
	}

	public setIconColor(color: string): void {
		this.list.forEach((value: ibIcon) => {
			value.image.color = color;
		});
	}

	public setActive(id: string, active: boolean = true): void {
		this.list.forEach((value: ibIcon) => {
			value.isSelected = active;
		});
	}

	public disable(id: string, disable: DisableAction = 'disable') {
		if (!id) { return; }
		if (!id.endsWith('Icon')) { id = id + 'Icon'; }

		const i = this._store.get(id);
		if (i) {
			switch(disable) {
				case 'disable':
					i.isDisabled = true;
					break;
				case 'enable':
					i.isDisabled = false;
					break;
				case 'switch':
					i.isDisabled = !i.isDisabled;
					break;
			}
			i.isClickable = !i.isDisabled;
		}
	}

	public disableAll(disable: DisableAction = 'disable') {
		this._store.forEach((value: ibIcon) => {
			this.disable(value.id, disable);
		});
	}

	public get length(): number { return this._store.size; }

	public get list(): Array<ibIcon> {
		return Array.from(this._store.values());
	}

	public get toArray(): Array<ibIcon> {
		return [...this._store.values()]
	}

}

class captionLength {

	private _threshold: Sizes;

	constructor(threshold: Sizes) {
		this._threshold = threshold;
	}

}
