
// -------------------------------------------------
// ib Badge
// -------------------------------------------------

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { BadgeConfig, BadgeConfiguration } from "@common/classes/badgeConfig";
import { TooltipDirective } from "@progress/kendo-angular-tooltip";


@Component({
    selector: 'ib-badge',
    templateUrl: './ib-badge.component.html',
	styleUrls: ['./ib-badge.component.scss'],
	providers: [ TooltipDirective ]
})
export class ibBadgeComponent implements OnChanges{

	@Input() config: BadgeConfig = new BadgeConfig();
	@Input() value: string = '';
	@Output() clicked =new EventEmitter<string>()

	public configuration: BadgeConfiguration = new BadgeConfiguration();

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if ('config' in changes) {
			this.configuration = BadgeConfiguration.assign(this.config);
		}
	}

	public get clickableClass(): string { return (this.configuration.clickable) ? 'clickable' : ''; }

	onClick(e: Event) {
		e.stopPropagation();
		this.clicked.emit(this.configuration.key);
	}

}
