import { IKey } from '@common/classes/interfaces';
import { FavoriteCategories } from '@common/classes/types';
import { BreedStub } from './breed-stub';
import { BreederStub } from './breeder-stub';
import { LitterStub } from './litter-stub';
import { DogStub } from './dog-stub';
import { DogName } from './dog-names';

// Visitor Favorites
// ---------------------------------------------------
// Rating not implemented in version 1
// should be calculated automatically from visitor selections
// - count name occurrences in favorites and group in rating 0 to 4

export class Favorites {

	public static assign(source: Record<string, any>): Favorites {

		const favs = new Favorites();

		if (source.hasOwnProperty('breeds')) {
			const s = <Array<any>> (<any> source)['breeds'];
			s.forEach((v) => { favs.breeds.add(BreedStub.assign(v)); });
		}

		if (source.hasOwnProperty('breeders')) {
			const s = <Array<any>> (<any> source)['breeders'];
			s.forEach((v) => { favs.breeders.add(BreederStub.assign(v)); });
		}

		if (source.hasOwnProperty('dogs')) {
			const s = <Array<any>> (<any> source)['dogs'];
			s.forEach((v) => { favs.dogs.add(DogStub.assign(v)); });
		}

		if (source.hasOwnProperty('dogNames')) {
			const s = <Array<any>> (<any> source)['dogNames'];
			s.forEach((v) => { s.forEach((v) => { favs.dogNames.add(new DogName(v)); }); });
		}

		return favs;
	}

	constructor() {

		this.breeds = new FavoriteList<BreedStub>();
		this.breeders = new FavoriteList<BreederStub>();
		this.litters = new FavoriteList<LitterStub>()
		this.dogs = new FavoriteList<DogStub>();
		this.dogNames = new FavoriteList<DogName>();
	}

	readonly breeds: FavoriteList<BreedStub>;
	readonly breeders: FavoriteList<BreederStub>;
	readonly litters: FavoriteList<LitterStub>
	readonly dogs: FavoriteList<DogStub>;
	readonly dogNames: FavoriteList<DogName>;

}

export class FavoriteList<T extends string | IKey> {

	private _list: Map<string, FavoriteItem<T>>;

	constructor() {
		this._list = new Map<string, FavoriteItem<T>>();
	}

	public has(value: T): boolean {
		const key = (typeof value === 'string')? value : value.toKey();
		return this._list.has(key);
	}

	public get(value: T): FavoriteItem<T> | undefined {
		const key = (typeof value === 'string')? value : value.toKey();
		return this._list.get(key);
	}

	public add(value: T): void {
		const item = new FavoriteItem(<T>(value));
		this._list.set(item.key, item);
	}

	public delete(value: T) {
		const item = new FavoriteItem(<T>(value));
		this._list.delete(item.key);
	}

	public deleteAll(category: FavoriteCategories) {
		this._list.clear();
	}

	public get items(): Array<T> {
		return [...this._list.values()].map((v: FavoriteItem<T>) => { return v.value});
	}

	// resets the order
	public set items(value: Array<T>) {
		this._list.clear;
		value.forEach((v: T) => { this.add(v); });
	}

}

export class FavoriteItem <T extends string | IKey> {

	constructor(item: T) {
		if (typeof item === 'string') {
			this.key = item;
			this.value = item;
		} else {
			this.key = item.toKey();
			this.value = item;
		}
	}

	public key: string;
	public value: T;
}
