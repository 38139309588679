import { MailFormats } from '../../classes/enums';

	export class eMail {
		[x: string]: any;

		static assign(source: Record<string, any>, target: eMail | null = null): eMail {

			if (!source) { throw new Error('Source is missing'); }
			let t: eMail;

			if (!target) {
				t = new eMail();
			} else {
				t = target;
			}

			if ('name' in source) {
				t.name = (<any> source)['name'];
			} else { throw new Error('Name is required'); }

			if ('index' in source) {
				t.index = (<any> source)['index'];
			} else { t.index = -1; }

			if ('address' in source) {
				t.address = (<any> source)['address'];
			} else { throw new Error('address is required'); }

			if ('format' in source) {
				t.format = (<any> source)['format'];
			} else { t.format = MailFormats.HTML; }

			if ('comments' in source) {
				t.comments = (<any> source)['comments'];
			} else { t.comments = ''; }

			return t;
		}


		constructor() {
			this.index = -1;
			this.format = MailFormats.HTML;
		}

		public name: string = '';
		public index: number;
		public address: string = '';
		public format: MailFormats;
		public comments?: string;

	}
