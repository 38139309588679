import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { animate, query, style, transition, trigger } from '@angular/animations';

import { Defaults } from '@common/classes/defaults';
import { ibCoreService } from '@common/services/core-services/core.service';
import { ibIdentityService } from '@common/identity/identity.service';
import { ibError } from '@common/classes/ib-error';
import { SiteMode } from '@common/classes/types';
import { UserDocument } from '@common/identity/user-document';

import { ibiBreederFooterComponent } from '@iBreeder/chrome/footer/iBreeder-footer.component';
import { ibiBreederHeaderComponent } from '@iBreeder/chrome/header/iBreeder-header.component';
import { ibPublicFooterComponent } from '@public/chrome/footer/public-footer.component';
import { ibPublicHeaderComponent } from '@public/chrome/header/public-header.component';

@Component({
  selector: 'pub-root',
  templateUrl: './app.component.html',
  animations: [
		trigger('myAnimation', [
			transition('* => *', [
				query(
					':enter',
					[
						style({ opacity: 0.3 , transform: 'scaleY(0.3)'}), animate('0.15s', style({ opacity: 1, transform: 'scaleY(1)'}))
					],
					{ optional: true }
				)
			])
		])
	] 	// register animations
})
export class AppComponent implements OnInit, AfterViewInit {
	public title = 'iBreeder';
	private static _user?: UserDocument;

	public modeHeader: any;
	public modeFooter: any;

	constructor(private coreService: ibCoreService,
				private identityService: ibIdentityService,
				private router: Router,
				private injector: Injector) {

		Defaults.injectorInstance = this.injector;  			// instantiate a default injector

		// this.coreService.StartProgress();
		this.renewToken();
		// console.log('STARTING APP ----------------------------------------');
		// console.log('Api URL: ' + environment.apiUrl);

		this.router.events.subscribe((evt) => {
			if (evt instanceof NavigationEnd) {
				// if (evt.urlAfterRedirects.includes('/Breeder/Admin')) {
				// 	this.coreService.siteMode = 'breeder-admin';
				// } else if (evt.urlAfterRedirects.includes('/iBreeder')) {
				// 	this.coreService.siteMode = 'iBreeder-admin';
				// } else {
				// 	this.coreService.siteMode = 'public';
				// }

				// recalculate size variables on view changes
				this.coreService.getSizes(true);
				this.coreService.endProgress();
			}
		});

		this.identityService.currentUser$.subscribe((user?: UserDocument) => {
			AppComponent._user = user;
		});
	}

	ngOnInit() {

		this.coreService.siteMode$.subscribe((mode: SiteMode) => {
			switch(mode) {
				case 'iBreeder':
					this.modeHeader = ibiBreederHeaderComponent;
					this.modeFooter = ibiBreederFooterComponent;
					break;
				case 'breeder':
					this.modeHeader = ibPublicHeaderComponent;
					this.modeFooter = ibiBreederFooterComponent;
					break;
				case 'dog-owner':
					this.modeHeader = ibPublicHeaderComponent;
					this.modeFooter = ibiBreederFooterComponent;
					break;
				case 'visitor':
					this.modeHeader = ibPublicHeaderComponent;
					this.modeFooter = ibPublicFooterComponent;
					break;
				default:
					this.modeHeader = ibPublicHeaderComponent;
					this.modeFooter = ibPublicFooterComponent;
			}
		})

	}

	ngAfterViewInit(): void {
		this.coreService.getSizes(true);
	}

	public static get currentUser(): UserDocument | undefined { return AppComponent._user; }

	private renewToken(): void {
		const res = this.identityService.renew().then((value: boolean) => {
			// do nothing
		}).catch((reason: any) => {
			this.coreService.showError(ibError.create('Unable to connect with the server', reason));
		});
	}
}
