
<header>
	<ng-container *ngComponentOutlet = 'modeHeader'></ng-container>
</header>
<main [@myAnimation]="o.isActivated ? o.activatedRoute : ''">
	<router-outlet #o="outlet"></router-outlet>
</main>
<footer>
	<ng-container *ngComponentOutlet = 'modeFooter'></ng-container>
</footer>


