
// --------------------------------------
// Security - Role
// --------------------------------------

import { OwnerTypes } from "@common/entities/parts/owner";

export type UserType = 'guest' | 'visitor' | 'dog-owner' | 'breeder' | 'iBreeder';
export type Level = '' | 'user' | 'admin' | 'owner';


export class Roles {


	public static precedence(role: string): number {
		const r = Role.parse(role);
		return (r) ? r.precedenceIndex : 0;
	}

	private _store: Map<UserType, Role>

	constructor(roles?: Array<string>) {
		this._store = new Map<UserType, Role>();
		if (!roles || roles.length === 0) {
			this._store.set('guest', Role.parse('guest'));
		} else {
			roles.forEach((r: string) => {
				this.add(r);
			});
		}
	}

	public getRole(role: string): Role | undefined {
		const targetRole = Role.parse(role);
		return this._store.get(targetRole.userType);
	}

	// returns true if request top role has greater or equal precedence with this minimum role
	public isAuthorizedFor(queryRoles: Roles | Array<string>): boolean {
		const qRoles = (Array.isArray(queryRoles)) ? new Roles(queryRoles) : queryRoles;

		for(const resourceRole of this._store.values()) {
			for (const queryRole of qRoles._store.values()) {
				if (resourceRole.match(queryRole)) { return true; }
			}
		}
		return false;
	}

	public get topRole(): Role {
		let top: Role = new Role('guest');
		this._store.forEach((value: Role) => {
			if (value.precedenceIndex > top.precedenceIndex) { top = value;}
		});
		return top;
	}

	public get minimumRole(): Role {
		let minimum: Role = new Role('iBreeder', 'admin');
		this._store.forEach((value: Role) => {
			if (value.precedenceIndex < minimum.precedenceIndex) { minimum = value;}
		});
		return minimum;
	}

	public get length(): number { return this._store.size; }

	public add(role: string) {
		try {
			const r = Role.parse(role);
			if (!this._store.has(r.userType)) {
				this._store.set(r.userType, r);
			} else {
				const currentRole = <Role>this._store.get(r.userType);
				if (currentRole.precedenceIndex > r.precedenceIndex) { this._store.set(r.userType, r);}
			}

		} catch(ex) {
			throw new Error(`Error while adding role: ${ex} - the role cannot be added`);
		}
	}

	public remove(type: UserType): boolean {
		if (this._store.has(type)) {
			this._store.delete(type);
			return true;
		}
		return false;
	}

	public serialize(): Array<string> {
		const ret = new Array<string>();
		this._store.forEach((value: Role) => {
			ret.push(value.toString());
		});
		return ret;
	}

}

export class Role {

	private _userType: UserType;
	private _level: Level;

	public static version = '2.0';

	public static assign( source: Object): Role {
		if(!source) { throw new Error('Assign Role: The Source is required'); }
		const r = new Role('guest');

		if ('userType' in source) {
			r._userType = (<any>source)['userType']
		}

		if ('level' in source) {
			r._level = (<any>source)['level']
		} else {
			r._level = '';
		}

		return r;
	}

	public static parse(role: string): Role {
		const r = new Role('guest');
		if (!role) { return r; }
		const parts = role.split('.');

		const rTypes = 'guest|visitor|dog-owner|breeder|iBreeder';
		const rLevel = ' user|admin|owner';


		if (parts.length > 0) {
			let ut = rTypes.indexOf(parts[0]);
			if (ut < 0) {
				throw new Error(`Unexpected user Type: ${parts[0]} - the role cannot be parsed`);
			} else {
				r._userType = <UserType>parts[0];
			}
		}

		if (parts.length === 2) {
			let ul = rLevel.indexOf(parts[1]);
			if (ul < 0) {
				r._level = '';
			} else {
				r._level = <Level>parts[1];
			}
		} else {
			r._level = '';
		}
		return r;
	}

	public static ownerTypeRoles(type: OwnerTypes): Array<string> {
		const t = OwnerTypes[type];
		switch (t) {
			case 'iBreeder': return ['iBreeder.admin', 'iBreeder.user'];
			case 'breeder': return ['breeder.admin','breeder.user'];
			case 'visitor': return ['visitor'];
			default: return ['guest'];
		}
	}

	public static defaultRole(type: OwnerTypes): string {
		const t = OwnerTypes[type];
		switch (t) {
			case 'iBreeder': return 'iBreeder.user';
			case 'breeder': return 'iBreeder.user';
			case 'visitor': return 'visitor';
			default: return 'guest';
		}
	}

	constructor(type: UserType, level?: Level) {
		this._userType = type;
		this._level = (level) ? level : '';
	}

	public get userType(): UserType { return this._userType; }
	public get level(): Level { return this._level; }

	public get precedenceIndex(): number {
		let ul = [ ' ', 'user', 'admin', 'owner'].indexOf(this._level);
		return (ul < 0 ) ? 0 : ul;
	 }

	public toString(): string {
		return this.userType + (this.level) ? ' ' + this.level : '';
	}

	public equals(role: Role): boolean {
		return ( this.userType === this.userType && this.level === role.level)
	}

	// returns true if resource protected by this role can be accessed by queryRole
	public match(queryRole: Role): boolean {
		switch( queryRole.userType) {
			case 'iBreeder':
				return queryRole.precedenceIndex >= this.precedenceIndex;
			case 'breeder':
				if (this.userType === 'iBreeder') { return true;}		// all iBreeder users can access breeder resources
				if (this.userType === 'breeder') {
					return queryRole.precedenceIndex >= this.precedenceIndex;
				}
				return false;
			case 'dog-owner':
				if (this.userType === 'iBreeder') { return true;}		// iBreeder can access breeder resources
				if (this.userType === 'dog-owner') { return true}
				return false;
			case 'visitor':
				if (this.userType === 'iBreeder') { return true;}		// iBreeder can access breeder resources
				if (this.userType === 'visitor') { return true; }
				return false;
			default:
				return false;
		}
	}

}


