import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { ibCoreComponentsModule } from '@common/components/core-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ibHomeComponent } from './home/home.component';
import { ibMainMenuComponent } from './main-menu/main-menu.component';
import { ibPublicHeaderComponent } from './chrome/header/public-header.component';
import { ibPublicFooterComponent } from './chrome/footer/public-footer.component';
import { ibFooterAddressComponent } from './chrome/footer/address/footer-address.component';
import { ibFooterBottomComponent } from './chrome/footer/bottom/footer-bottom.component';
import { ibFooterBreederComponent } from './chrome/footer/breeder/footer-breeder.component';
import { ibFooterMainComponent } from './chrome/footer/main/footer-main.component';
import { ibFooterMenuComponent } from './chrome/footer/menu/footer-menu.component';
import { ibFooterTrackingComponent } from './chrome/footer/tracking/footer-tracking.component';


@NgModule({
	declarations: [
		// ibPublicHeaderComponent,
		// ibPublicFooterComponent,
		// ibFooterAddressComponent,
		// ibFooterBottomComponent,
		// ibFooterBreederComponent,
		// ibFooterMainComponent,
		// ibFooterMenuComponent,
		// ibFooterTrackingComponent,
		// ibSearchBarComponent,
		ibHomeComponent,
		ibMainMenuComponent
	],
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		RouterModule,
		HttpClientModule,
		ibCoreComponentsModule
	],
	exports: [
		// ibPublicHeaderComponent,
		// ibPublicFooterComponent,
		// ibFooterAddressComponent,
		// ibFooterBottomComponent,
		// ibFooterBreederComponent,
		// ibFooterMainComponent,
		// ibFooterMenuComponent,
		// ibFooterTrackingComponent,
		// ibSearchBarComponent,
		ibHomeComponent,
		ibMainMenuComponent
	]
})
export class ibPublicCoreComponentsModule { }
