// ======================================================
// Breed Service
// ======================================================
// Singleton
// ======================================================================================================

import { Injectable, OnDestroy } from '@angular/core';
import { of, Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { ibDBService } from '../data-services/db.service';
import { Subscriptions, ibCoreService } from '../core-services/core.service';
import { dbResult } from '@common/classes/data/dbUtil';
import { Tools } from '@common/tools';
import { Validate } from '@common/classes/data/validate';
import { BreedNameList, BreedName, ListMode } from '@entities/parts/breed-name';
import { BreedStub } from '@entities/parts/breed-stub';
import { BreedDocument } from '@common/entities/documents/breed-document';

@Injectable({ providedIn :'root' })
export class ibBreedService implements OnDestroy{

	private subscriptions: Subscriptions;

	private _breeds: Map<string, BreedDocument>;
	private _breedNames?: BreedNameList;
	private _breedStubs: Map<string, BreedStub>;
	private _currentBreed$: BehaviorSubject<BreedDocument | undefined>;

	constructor(private coreService: ibCoreService,
				private dbService: ibDBService) {

		this._breeds = new Map<string, BreedDocument>();
		this._breedStubs = new Map<string, BreedStub>();
		this._currentBreed$ = new BehaviorSubject<BreedDocument | undefined>(undefined);
		this.subscriptions = new Subscriptions();

		// retrieve stubs
		this.getBreedStubs()
		.then (( stubs: Map<string, BreedStub>) => {
			this._breedStubs = stubs;
		}).catch((reason: any) => {
			throw new Error('Breed Service - error initializing breed stubs: ' + reason);
		});

	}

	ngOnDestroy() {
		if (this.subscriptions) { this.subscriptions.unsubscribe(); }
	}

	private _loaded(): boolean { return false; }

	public get currentBreed$(): Observable<BreedDocument | undefined> {
		return this._currentBreed$.asObservable();
	}

	// get breed document by uid or breed name (cached)
	public async getBreed(key?: string | BreedName, refresh?: boolean): Promise<BreedDocument | undefined> {

		return new Promise((resolve, reject) => {
			if (!key) {
				if (this._currentBreed$.value) { resolve(this._currentBreed$.value); } else { reject('the breed has not been set'); }
			} else {
				// extract the uid
				let uid: string = '';
				if (key instanceof BreedName) {
					uid = key.breedUID;
				} else if (Validate.isGuid(key)) {
					uid = key;
				} else {
					const s = this.getBreedName(key);
					if (s) {
						uid = s.breedUID;
					} else {
						reject('Breed no found');
					}
				}

				if (!refresh) {
					if (this._currentBreed$.value && this._currentBreed$.value.breedID === uid) {
						resolve (this._currentBreed$.value);
					} else if (this._breeds.has(uid)) {
						this._currentBreed$.next(this._breeds.get(uid));
						resolve((this._currentBreed$.value) ? this._currentBreed$.value : undefined);
					}
				}
				// must retrieve breed from DB
				this.dbService.getDocument('BreedDocument', uid).pipe(
					map((res: dbResult) => {
						if (res.isSuccess  && res.value) {
							const doc = BreedDocument.assign(res.value);
							if (doc) {
								this._breeds.set(doc.id, doc);
								this._currentBreed$.next(doc);
							}
							resolve(doc);
						} else {
							reject('Breed not found');
						}
					})
				).subscribe();
			}
		});
	}

	public getBreedName(breedID: string, mode: ListMode = 'officialNames'): BreedName | undefined {
		try {
		if (this._breedNames) {
			return this._breedNames.getBreed(breedID, mode);
		} else {
			throw new Error('Breed Names not initialized')
		}
		} catch(err: any) {
			throw new Error("getBreedName: " + err);
		}
	}

	// get breed stub by breedID (cached)
	public getBreedStub(breedID: string): BreedStub | undefined {
		if (this._breedStubs) {
			return this._breedStubs.get(breedID);
		} else {
			throw new Error('Breed Service - getBreedStubs: breedStubs not initialized');
		}
	}

	// get breed stub by breedID (cached)
	public async getBreedStubs(refresh: boolean = false): Promise<Map<string, BreedStub>> {

		const qry = `SELECT
			c.id as breedUID,
			c.breedID,
			c.breedName,
			c.caption,
			c.symbol,
			c["rank"],
			c["group"],
			c["weight"],
			c.height
	 		FROM c WHERE c.DType = 'BreedDocument'`

		return new Promise((resolve, reject) => {

			if (!refresh && this._breedStubs && this._breedStubs.size > 0) {
				resolve(this._breedStubs);
			} else {
				try {
					this.coreService.startProgress();
					this._breedStubs.clear();
					this.dbService.getDocuments(qry).pipe(
						map((ret: dbResult) => {
							if (ret.isSuccess && ret.values) {
								ret.values.forEach((value: unknown) => {
									if (value && typeof value === "object") {
										const stub = BreedStub.assign(value);
										this._breedStubs.set(stub.breedID, stub);
									} else {
										throw new Error('getBreedStub Error: returned value is not an object');
									}
								})
								resolve(this._breedStubs);
							} else {
								reject('getBreedStub was unsuccessful');
							}
						}),
						catchError((err: any) => {
							throw new Error('getBreedStub Error: ' + err)
						}),
						finalize(() => { this.coreService.endProgress(); })
					);
				} catch(err: any) {
					reject(err);
				}
			}
		});
	}

	public async getRandomBreeds(count: number = -1, refresh: boolean = false): Promise<Array<BreedStub>> {
		this.coreService.startProgress();

		return new Promise<Array<BreedStub>>(async (resolve, reject) => {
			try {
				if (!this._breedStubs || refresh) { await this.getBreedStubs(); }

				const toStubs = new Array<BreedStub>();
				const fromStubs = (this._breedStubs) ? Array.from(this._breedStubs?.values()) : new Array<BreedStub>();
				const breedCount = fromStubs.length;

				if (count < 1) { count = breedCount; }
				for (let i = 0; i < count; i++) {
					const rnd = Tools.randomInteger(0, breedCount);
					const stub = fromStubs[rnd];
					toStubs.push(stub);
				}
				resolve(toStubs);
			} catch(err: any) { reject(err); }


		});

	}

	// add a breed (create an empty breed)
	// public AddBreed(): Observable<ibResult<BreedDocument>> {
	// 	const headers = HttpUtil.addJwt((this.jwt) ? this.jwt : '');

	// 	const obs = this.http.post<ibResult<BreedDocument>>(this._urlRoot + 'AddBreed', { headers }).pipe(
	// 		map((resp) => {
	// 			if (resp && resp.isSuccess) {
	// 				const rec  = BreedDocument.assign(<Record<string, any>> resp.value);
	// 				if (rec) { return ibResult.success<BreedDocument>(rec); }
	// 			}
	// 			return ibResult.faulted<BreedDocument>((resp && resp.message) ? resp.message : 'an error has occurred');
	// 		}),
	// 		catchError((err: any)  => of(ibResult.faulted<BreedDocument>(err.message))
	// 		)
	// 	);
	// 	return obs;
	// }

	// Remove a breed
	// public RemoveBreed(breedName: BreedName): Observable<boolean> {

	// 	let headers = new HttpHeaders().set('BreedName', breedName.breedID);
	// 	headers = HttpUtil.addJwt((this.jwt) ? this.jwt : '', headers);

	// 	const obs = this.http.post<ibResult<boolean>>(this._urlRoot + `RemoveList/${breedName.breedID}`, { headers }).pipe(
	// 		map((resp) => {
	// 			if (resp.isSuccess) {
	// 				return true;
	// 			} else {
	// 				return false;
	// 			}
	// 		})
	// 		,
	// 		catchError(() => { return of(false); }
	// 		)
	// 	);

	// 	return obs;
	// }

	// public get breedNames(): BreedNameList { return (this._breedNames) ? this._breedNames : new BreedNameList(); }

	// Returns a list of breedName (cached)
	public async getBreedNames(refresh: boolean = false): Promise<BreedNameList> {
		if (!refresh && this._breedNames && this._breedNames.officialNames.length > 0) { return of(this._breedNames).toPromise(); }

		const qryOfficial = `SELECT c.id as breedUID, c.breedID, c.breedName, c.breedName as name FROM c
							 WHERE c.DType = 'BreedDocument' Order By c.breedName`
		const qryOtherNames = `SELECT c.id as breedUID, c.breedID, c.breedName, name FROM c
							 JOIN name In c.otherNames
							 WHERE c.DType = 'BreedDocument' Order By c.breedName`

		return forkJoin([this._getBreedOfficialNames(qryOfficial), this._getBreedNames(qryOtherNames)]).pipe(
			map((value: [BreedName[], BreedName[]]) => {
				this._breedNames = new BreedNameList([...value[0], ...value[1]]);
				return this._breedNames;
			})).toPromise();
	}

	public async selectBreedName(breedName?: BreedName): Promise<BreedName | undefined> {

		return new Promise((resolve, reject) => {
			this.getBreedNames()
				.then((value: BreedNameList) => {
					resolve(value.select(breedName));
				})
				.catch((reason: any) => { reject(reason); });
		});
	}

	private _getBreedOfficialNames(qry?: string): Observable<Array<BreedName>> {
		if (this._breedStubs && this._breedStubs.size > 0) {
			const officialNames = new Array<BreedName>();
			for (let stub of this._breedStubs.values()) {
				officialNames.push(stub.toBreedName());
			}
			return of(officialNames);
		} else if (qry) {
			return this._getBreedNames(qry);
		} else {
			return of(new Array<BreedName>());
		}
	}

	private _getBreedNames(qry: string): Observable<Array<BreedName>> {
		return this.dbService.getDocuments(qry).pipe(
			map((ret: dbResult) => {
				const list = new Array<BreedName>();
				if (ret.isSuccess) {
					if (ret.values && ret.values.length > 0) {
						for (const n of ret.values) {
							if (n && typeof n === 'object') {
								const name = BreedName.assign(n);
								list.push(name);
							}
						}
					}
				} else {
					console.log('Error DB: ' + ret.message);
				}
				return list
			})
		);
	}

}
