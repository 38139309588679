// -----------------------------------------------------------
// Search related classes
// -----------------------------------------------------------
import { Validate } from './data/validate';

export type SearchModes = 'none' | 'byAccountID' | 'byName' | 'byPhone' | 'byMail' | 'byProximity' | 'byField';
export type SearchStates = 'empty' | 'partial' | 'complete';
export type SearchTargets = 'none' | 'breeds' | 'breeders' | 'litters' | 'dogs' | 'puppies' | 'clients';


// export enum BreederSearchFields {
// 	empty = 0,
// 	name = 1,
// 	accountID = 2,
// 	phone = 3,
// 	email = 4,
// 	contactName = 5,
// 	city = 6,
// 	postalCode = 7,
// 	coordinates = 8,
// 	address = 9,
// 	unknown = 99
// }

export class SearchQuery {

	constructor() {
		this.mode = 'none';
		this.state = 'empty';
		this.target = 'none';
		this.searchTerm = '';
		this.formattedSearchTerm = '';
	}

	static CreateFuzzy(mode: SearchModes, searchString: string, distance: number = 0) {
		const Q = new SearchQuery();
		Q.mode = mode;
		// Q.searchString = searchString;
		Q.distance = distance;
		return Q;
	}

	static CreateField(mode: SearchModes, field: string, searchString: string, formattedSearchString: string = '') {
		const Q = new SearchQuery();
		Q.mode = mode;
		Q.field = field;
		Q.searchTerm = searchString;
		Q.formattedSearchTerm = (formattedSearchString) ? formattedSearchString : searchString;
		Q.distance = 0;
		return Q;
	}

	mode: SearchModes;
	state: SearchStates;
	target: SearchTargets;
	isValid: boolean = false;
	field: string = '';
	searchTerm: string;
	formattedSearchTerm: string;
	distance: number = -1;

	public get isPartial(): boolean { return this.state !== 'complete'; }

}

export class BreederQuery {

	static CreateFuzzy(mode: SearchModes, searchString: string, distance: number = 0) {
		const Q = new BreederQuery();
		Q.mode = mode;
		// Q.searchString = searchString;
		Q.distance = distance;
		return Q;
	}

	static CreateField(mode: SearchModes, field: string, searchString: string, formattedSearchString: string = '') {
		const Q = new BreederQuery();
		Q.mode = mode;
		Q.field = field;
		Q.searchString = searchString;
		Q.formattedSearchString = (formattedSearchString) ? formattedSearchString : searchString;
		Q.distance = 0;
		return Q;
	}

	isValid: boolean = false;
	mode: SearchModes = 'none';
	isPartial: boolean = true;
	field: string = '';
	searchString: string = '';
	formattedSearchString: string = '';
	distance: number = -1;
}

export class BreederSearch {

	public distance: number;
	public query: BreederQuery | null;

	constructor(entry: string, distance: number = 0) {
		this.distance = distance;
		this.query = this.parse(entry);
	}

	private parse(value: string): BreederQuery | null {

		if (!value) { return null; }

		const parts = value.toLowerCase().split(' ');
		const target = parts[0];
		if (parts.length > 1)  { parts.splice(0, 1); }
		const term = parts.join(' ');

		switch (target) {
			case 'name': return this.byName(term);
			case 'accountid': return this.parseAccountID(term);
			case 'phone': return this.parsePhone(term);
			case 'email': return this.parseEmail(term);
			case 'contact': return this.parseContact(term);
			case 'address': return this.parseAddress(term);
			case 'city': return this.parseCity(term);
			case 'region':
			case 'state': return this.parseRegion(term);
			case 'code':
			case 'postal':
			case 'postalcode':
			case 'zip': return this.parsePostalCode(term);
			case 'country': return this.parseCountry(term);
			case 'coordinates': return this.parseCoordinates(term);
			default:
				return this.parseAny(term);
		}


	}

	private byName(value: string): BreederQuery {
		return BreederQuery.CreateFuzzy('byName', value, this.distance);
	}

	private parseAccountID(value: string): BreederQuery {
		return BreederQuery.CreateField('byField', 'accountID', value);
	}

	private parsePhone(value: string): BreederQuery {
		return BreederQuery.CreateFuzzy('byPhone', value, this.distance);
	}
	private parseEmail(value: string): BreederQuery {
		return BreederQuery.CreateFuzzy('byMail', value, this.distance);
	}

	private parseContact(value: string): BreederQuery {
		return BreederQuery.CreateField('byField', 'contact.name', value);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private parseAddress(value: string): BreederQuery {
		return new BreederQuery();
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private parseCity(value: string): BreederQuery {
		return new BreederQuery();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private parseRegion(value: string): BreederQuery {
		return new BreederQuery();
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private parsePostalCode(value: string): BreederQuery {
		return new BreederQuery();
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private parseCountry(value: string): BreederQuery {
		return new BreederQuery();
	}
	private parseCoordinates(value: string): BreederQuery {
		return BreederQuery.CreateFuzzy('byProximity', value, this.distance);

	}
	private parseAny(value: string): BreederQuery {

		const res = Validate.accountId(value);

		if (res.state === 'complete') {
			const formattedValue = res.formattedValue;
			const Q = BreederQuery.CreateField('byAccountID', 'accountID', value, formattedValue);
			Q.isPartial = false;
			Q.isValid = true;
			return Q;
		}5

		if (Validate.isPhone(value)) {
			console.log('Phone validated: ' + value);
		}

		if (Validate.iseMail(value)) {
			return new BreederQuery();
		}

		return BreederQuery.CreateFuzzy('byName', value, this.distance);
	}


}

