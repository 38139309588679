// -------------------------------------------------------------
//  iBreeder.Core.Utils       Parser   Typescript Version
// -------------------------------------------------------------
// The Tokenizer is very simple.
// Words can have letters, digits, dash (-) and when quoted whitespace (converted to one space)
// and the Single Or Double quote depending on the quote used to delineate the string
// All other characters are discarded
// The = sign is considered as a word
//
//
//
//
// -------------------------------------------------------------
// Version: 0.1        Date: 9/24/2016          Author: PH
// -------------------------------------------------------------
// © 2016 iBreeder Inc.
// -------------------------------------------------------------

// --------------------------------------------------------------------------------
    import { Buffer } from './buffer';


    export class Token {

        public static convert(word: string): Token {

            if (!word) {
                const t = new Token();
                t.type = 'invalid';
                t.value = '';
                return t;
            }

            const res = Number(word);
            if (res) { return this.newNumber(res); }
            if (word === '-' || word === ' ') { return this.newSeparator(word); }
            if (word === '"' ) { return this.newWord('"'); }

            return this.newWord(word);

        }

        public static convertAll(words: Array<string>): Array<Token> {
            const tokens = new Array<Token>();
            for (const word of words) {
                tokens.push(Token.convert(word));
            }
            return tokens;
        }

        public static newNumber(_number: number): Token {
            const t = new Token();
            t.type = 'number';
            t.value = _number;
            return t;
        }

        public static newWord(_string: string): Token {
            const t = new Token();
            t.type = 'string';
            t.value = _string;
            return t;
        }

        public static newSeparator(_separator: string): Token {
            const t = new Token();
            t.type = 'separator';
            t.value = _separator;
            return t;
        }

        public type: 'number' | 'separator' | 'string' | 'invalid' = 'string';
        public value: any;

    }

    export class Tokenizer {

        private _list = new Array<string>();

        /// <summary> Constructor </summary>
        public constructor(content?: string) {
            this.tokenize(content);
        }

        public tokenize(value: string | undefined, preserveCase: boolean = false): void {
            this._list = new Array<string>();
            if (!value) { return; }

            const buffer = new Buffer(value);
            let word = '';

            while (buffer.isEmpty === false) {
                const c = buffer.Take();

                if ( c >= '0' && c <= '9') { word += c; continue; }
                if ( c >= 'a' && c <= 'z') { word += c; continue; }
                if ( c >= 'A' && c <= 'Z') { word += (preserveCase) ? c : c.toLowerCase(); continue; }
                if ( c === '.' ) { word += c; continue; }

                if ( c === ' ' ) {
                    if (word) { this._list.push(word); }
                    word = '';
                    continue;
                }

                if ( c === '-' || c === '"' ) {
                    if (word) { this._list.push(word); }
                    this._list.push('-');
                    word = '';
                }

            }
            if (word) { this._list.push(word); }


        }

        public get list(): Array<string> { return this._list; }
    }
