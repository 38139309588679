<div id="wrapper">
    <picture (click)='onImageClick($event)'>
        <source media="(max-width:300px)" srcset="assets/images/common/home-xs.jpg">
        <source media="(max-width:400px)" srcset="assets/images/common/home-sm.jpg">
        <source media="(max-width:600px)" srcset="assets/images/common/home-md.jpg">
        <source media="(max-width:900px)" srcset="assets/images/common/home-lg.jpg">
        <source media="(min-width:901px)" srcset="assets/images/common/home-xl.jpg">
        <img src="assets/images/common/home-xl.jpg" alt="alt" class="img-fluid">
    </picture>

</div>
<!-- <ib-slider [slides]='slides' (selection)='onSelection($event)' (loaded)='onLoaded($event)'></ib-slider> -->



