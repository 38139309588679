
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ibIcon } from '@common/classes/icon';

@Component({
    selector: 'ib-menu-card',
    templateUrl: './ib-menu-card.component.html',
    styleUrls: [ './ib-menu-card.component.scss' ]
})
export class ibMenuCardComponent implements OnInit {

    @Input() item: ibIcon;
	@Input() width: string = 'auto';
    @Output() clicked = new EventEmitter<ibIcon>();
    @Output() badgeClick = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void {
		// console.log(this.item);
    }

    onMenu(item: ibIcon) {
        if (item) { this.clicked.emit(item); }
    }

	onBadgeClick(name: string) {
		this.badgeClick.emit(name);
	}

}
