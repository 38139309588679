// -------------------------------------------------------------------------------
// Geo location helper - info on a particular geographic point
// -------------------------------------------------------------------------------
// No dependency
// ibSupportedCountries provides info about countries and country subdivisions that are supported
// checked when handling accountIds

import { ibResult } from './result';


export type GeoTypes = 'POI' | 'Street' | 'Geography' | 'Point Address' | 'Address Range' | 'Cross Street';

export type EntityTypes = 'Country' | 'CountrySubdivision' | 'CountrySecondarySubdivision' | 'CountryTertiarySubdivision'
						 |'Municipality' | 'MunicipalitySubdivision' | 'Neighbourhood' | 'PostalCodeArea';

export class ibSupportedCountries {

	private static _store: Map<string, Country>;

	constructor() {

		if (!ibSupportedCountries._store) {
			ibSupportedCountries._store = new Map<string, Country>();

			const country = Country.create('United States', 'US', 'USA');
			country.addSubdivision('District of Columbia', 'DC', 'district', true);
			country.addSubdivision('American Samoa', 'AS', 'outlying territory', false);
			country.addSubdivision('Guam', 'GU', 'outlying territory', false);
			country.addSubdivision('Northern Mariana Islands', 'MP', 'outlying territory', false);
			country.addSubdivision('Puerto Rico', 'PR', 'outlying territory', false);
			country.addSubdivision('United States Minor Outlying Islands', 'UM', 'outlying territory', false);
			country.addSubdivision('Virgin Islands, U.S.', 'VI', 'outlying territory', false);

			country.addSubdivision('Alabama', 'AL');
			country.addSubdivision('Alaska', 'AK');
			country.addSubdivision('Arizona', 'AZ');
			country.addSubdivision('Arkansas', 'AR');
			country.addSubdivision('California', 'CA');
			country.addSubdivision('Colorado', 'CO');
			country.addSubdivision('Connecticut', 'CT');
			country.addSubdivision('Delaware', 'DE');
			country.addSubdivision('Florida', 'FL');
			country.addSubdivision('Georgia', 'GA');
			country.addSubdivision('Hawaii', 'HI');
			country.addSubdivision('Idaho', 'ID');
			country.addSubdivision('Illinois', 'IL');
			country.addSubdivision('Indiana', 'IN');
			country.addSubdivision('Iowa', 'IA');
			country.addSubdivision('Kansas', 'KS');
			country.addSubdivision('Kentucky', 'KY');
			country.addSubdivision('Louisiana', 'IL');
			country.addSubdivision('Maine', 'ME');
			country.addSubdivision('Maryland', 'MD');
			country.addSubdivision('Massachusetts', 'MA');
			country.addSubdivision('Michigan', 'MI');
			country.addSubdivision('Minnesota', 'MN');
			country.addSubdivision('Mississippi', 'MS');
			country.addSubdivision('Missouri', 'MO');
			country.addSubdivision('Montana', 'MT');
			country.addSubdivision('Nebraska', 'NE');
			country.addSubdivision('Nevada', 'NV');
			country.addSubdivision('New Hampshire', 'NH');
			country.addSubdivision('New Jersey', 'NJ');
			country.addSubdivision('New Mexico', 'NM');
			country.addSubdivision('New York', 'NY');
			country.addSubdivision('New Jersey', 'NJ');
			country.addSubdivision('North Carolina', 'North Carolina');
			country.addSubdivision('North Dakota', 'ND');
			country.addSubdivision('Ohio', 'OH');
			country.addSubdivision('Oklahoma', 'OK');
			country.addSubdivision('Oregon', 'OR');
			country.addSubdivision('Pennsylvania', 'PA');
			country.addSubdivision('"Rhode Island', 'RI');
			country.addSubdivision('South Carolina', 'SC');
			country.addSubdivision('South Dakota', 'SD');
			country.addSubdivision('Tennessee', 'TN');
			country.addSubdivision('Texas', 'TX');
			country.addSubdivision('Utah', 'UT');
			country.addSubdivision('Vermont', 'VT');
			country.addSubdivision('Texas', 'TX');
			country.addSubdivision('Virginia', 'VA');
			country.addSubdivision('Washington', 'WA');
			country.addSubdivision('West Virginia', 'WV');
			country.addSubdivision('Wisconsin', 'WI');
			country.addSubdivision('Wyoming', 'WY');

			ibSupportedCountries._store.set(country.code, country);
		}

	}

	public country(code: string): Country | undefined {
		return ibSupportedCountries._store.get(code);
	}

	public subdivision(countryCode: string, subDivisionCode: string): CountrySubdivision | undefined {
		const c = this.country(countryCode);
		if (!c) {
			return undefined;
		} else {
			return c.subdivisions.get(subDivisionCode);
		}
	}

	public isCountrySupported(code: string): boolean {
		return ibSupportedCountries._store.has(code);
	}

	public isSubdivisionSupported(countryCode: string, subDivisionCode: string) : boolean {
		const s = this.subdivision(countryCode, subDivisionCode);
		if (!s) {
			return false;
		} else {
			return s.supported;
		}
	}

}

export class SearchFuzzyResponse {

	public static assign(source: object): SearchFuzzyResponse {
		if (!source) { throw new Error('SearchFuzzyResponse assign - source must be specified'); }
		const resp = new SearchFuzzyResponse();

		if ('summary' in source) {
			resp.summary = true
		} else {throw new Error('SearchFuzzyResponse assign - field \'summary\ not found'); }

		if ('results' in source) {
			const r = (<any>source)['results'];
			if (Array.isArray(r)) {
				for(const item of r) {
					resp.results.push(SearchFuzzyResult.assign(item));
				}
			} else {throw new Error('SearchFuzzyResponse assign - results is not an array'); }
		}

		return resp;
	}

	constructor() {
		this.results = new Array<SearchFuzzyResult>();
	}

	public summary?: boolean;
	public results: Array<SearchFuzzyResult>;

	public getResult(geoType: GeoTypes): SearchFuzzyResult | undefined {
		const res = this.results.find((item: SearchFuzzyResult) => {
			return (item.type === geoType);
		});
		return res;
	}

}

class SearchFuzzyResult {


	public static assign(source: object): SearchFuzzyResult {
		if (!source) { throw new Error('SearchFuzzyResult assign - source must be specified'); }
		const res = new SearchFuzzyResult();

		if ('address' in source) {
			res.address = SearchResultAddress.assign((<any>source)['address']);
		}

		if ('addressRanges' in source) {
			res.addressRanges = SearchResultAddressRanges.assign((<any>source)['addressRanges']);
		}

		if ('dataSources' in source) {
			res.dataSources = DataSources.assign((<any>source)['dataSources']);
		}

		if ('entityType' in source) {
			res.entityType = (<any>source)['entityType'];
		}

		if ('value' in source) {
			res.value = (<any>source)['value'];
		}

		if ('info' in source) {
			res.info = (<any>source)['info'];
		}

		if ('position' in source) {
			res.position = Coordinates.assign((<any>source)['position']);
		}

		if ('score' in source) {
			res.score = (<any>source)['score'] as number;
		}

		if ('type' in source) {
			res.type = (<any>source)['type'] as GeoTypes;
		}

		if ('viewport' in source) {
			res.viewport = SearchResultViewPort.assign((<any>source)['viewport']);
		}

		return res;
	}

	public address: SearchResultAddress;
	public addressRanges: SearchResultAddressRanges;
	public dataSources: DataSources;
	public entityType: EntityTypes;
	public value: string;
	public info: string;
	public position: Coordinates;
	public score: number;
	public type: GeoTypes;
	public viewport: SearchResultViewPort;

}

class SearchResultAddress {


	public static assign(source: object): SearchResultAddress {
		if (!source) { throw new Error('SearchResultAddress assign - source must be specified'); }
		const addr =  new SearchResultAddress();

		if ('buildingNumber' in source) { addr.buildingNumber = (<any>source)['buildingNumber']; }
		if ('country' in source) { addr.country = (<any>source)['country']; }
		if ('countryCode' in source) { addr.countryCode = (<any>source)['countryCode']; }
		if ('countryCodeISO3' in source) { addr.countryCodeISO3 = (<any>source)['countryCodeISO3']; }
		if ('countrySecondarySubdivision' in source) { addr.countrySecondarySubdivision = (<any>source)['countrySecondarySubdivision']; }
		if ('countrySubdivision' in source) { addr.countrySubdivision = (<any>source)['countrySubdivision']; }
		if ('countrySubdivisionName' in source) { addr.countrySubdivisionName = (<any>source)['countrySubdivisionName']; }
		if ('countryTertiarySubdivision' in source) { addr.countryTertiarySubdivision = (<any>source)['countryTertiarySubdivision']; }
		if ('crossStreet' in source) { addr.crossStreet = (<any>source)['crossStreet']; }
		if ('extendedPostalCode' in source) { addr.extendedPostalCode = (<any>source)['extendedPostalCode']; }
		if ('freeformAddress' in source) { addr.freeformAddress = (<any>source)['freeformAddress']; }
		if ('localName' in source) { addr.localName = (<any>source)['localName']; }
		if ('municipality' in source) { addr.municipality = (<any>source)['municipality']; }
		if ('municipalitySubdivision' in source) { addr.municipalitySubdivision = (<any>source)['municipalitySubdivision']; }
		if ('postalCode' in source) { addr.postalCode = (<any>source)['postalCode']; }
		if ('routeNumbers' in source) { addr.routeNumbers = (<any>source)['routeNumbers']; }
		if ('street' in source) { addr.street = (<any>source)['street']; }
		if ('streetName' in source) { addr.streetName = (<any>source)['streetName']; }
		if ('streetNameAndNumber' in source) { addr.streetNameAndNumber = (<any>source)['streetNameAndNumber']; }
		if ('streetNumber' in source) { addr.streetNumber = (<any>source)['streetNumber']; }

		return addr;
	}

	constructor() {}

	buildingNumber: string;
	country: string;
	countryCode: string;
	countryCodeISO3: string;
	countrySecondarySubdivision: string;
	countrySubdivision: string;
	countrySubdivisionName: string;
	countryTertiarySubdivision: string;
	crossStreet: string;
	extendedPostalCode: string;
	freeformAddress: string;
	localName: string;
	municipality: string;
	municipalitySubdivision: string;
	postalCode: string;
	routeNumbers: string;
	street: string;
	streetName: string;
	streetNameAndNumber: string;
	streetNumber: string;

}

class SearchResultAddressRanges {

	// NOT IMPLEMENTED

	public static assign(source: object): SearchResultAddressRanges {
		return new SearchResultAddressRanges();
	}

	constructor() {}
}

class DataSources {

	// NOT IMPLEMENTED

	public static assign(source: object): DataSources {
		return new DataSources();
	}

	constructor() {}
}

class SearchResultViewPort {

	// NOT IMPLEMENTED

	public static assign(source: object): SearchResultViewPort {
		return new SearchResultViewPort();
	}

	constructor() {}
}

export class PostalCode {


	public constructor(geometry: SearchFuzzyResult | undefined) {
		if (!geometry || geometry.entityType !== 'PostalCodeArea') {
			this.coordinates = new Coordinates();
			this.country = new Country();
			this.region = new CountrySubdivision();
			this.isValid = false;
		} else {
			const addr =geometry.address;
			this.postalCode = addr.postalCode;
			this.coordinates = geometry.position;
			this.country = Country.create(addr.country, addr.countryCode, addr.countryCodeISO3);
			this.region = CountrySubdivision.create(addr.countrySubdivisionName, addr.countrySubdivision);
			this.regionSubdivision = addr.countrySecondarySubdivision;
			this.municipality = addr.municipality;
			this.freeForm = addr.freeformAddress;
			this.isValid = true;
		}
	}

	public postalCode: string;
	public coordinates: Coordinates;
	public country: Country;
	public region: CountrySubdivision;
	public regionSubdivision: string;
	public municipality: string;
	public freeForm: string;
	public isValid: boolean;

}

export class Coordinates {

	public static assign(source: any): Coordinates {
		if (!source) { throw new Error('Coordinates assign - source must be specified'); }
		const c = new Coordinates();

		if ('latitude' in source) {
			c.latitude = (<any>source)['latitude']
		} else if ('lat' in source) {
			c.latitude = (<any>source)['lat']
		} else {
			throw new Error('Coordinates assign - latitude or lat is missing');
		}

		if ('longitude' in source) {
			c.longitude = (<any>source)['longitude']
		} else if ('lon' in source) {
			c.latitude = (<any>source)['lon']
		} else if ('lng' in source) {
			c.latitude = (<any>source)['lng']
		} else {
			throw new Error('Coordinates assign - longitude or lon or lng is missing');
		}

		return c;
	}

	constructor(lat: number = 39.50, lng: number = -98.35) {
		this.latitude = lat;
		this.longitude = lng;
	}

	public latitude: number;
	public longitude: number;

	// returns distance between two points in miles
	public getDistance(coordinates: Coordinates): number {
		const R = 6372.8; // km
		const x1 = coordinates.latitude - this.latitude;
		const dLat = this.toRadians(x1);
		const x2 = coordinates.longitude - this.longitude;
		const dLng = this.toRadians(x2);
		// eslint-disable-next-line max-len
		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.toRadians(this.latitude)) * Math.cos(this.toRadians(coordinates.latitude)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		let d = (R * c) / 1.609344;
		d = Math.round(d / 10) * 10;
		return d ;
	}

	private toRadians(value: number): number {
		return value * Math.PI / 180;
	}

}

export class Country {

	static create(name: string, code: string, code3?: string) {
		const c = new Country();
		c.name = name;
		c.code = code;
		c.code3 = code3;
		return c;
	}

	constructor() {
		this.subdivisions = new Map<string, CountrySubdivision>();
	}

	public name: string;
	public code: string;
	public code3?: string;
	public subdivisions: Map<string, CountrySubdivision>;

	public addSubdivision(name: string, code: string, type?: string, supported?: boolean) {
		this.subdivisions.set(code, CountrySubdivision.create(name, code, type, supported));
	}

}

export class CountrySubdivision {

	public static create(name: string, code?: string, type?: string, supported?: boolean): CountrySubdivision {
		const s = new CountrySubdivision();
		s.name = name;
		s.code = code;
		s.type = (type) ? type : 'state';
		s.supported = (!supported) ? true : supported;
		return s;
	}

    name: string;
    code?: string;
	type?: string;
	supported: boolean;
}



// google map classes NOT USED

// import { Coordinates } from './lib';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import { GoogleMap } from '@angular/google-maps';
// declare const google: any;


// export enum AddressPartTypes {
// 	streetNumber = 1,
// 	route = 2,
// 	locality = 3,
// 	neighborhood = 4,
// 	area = 5,
// 	county = 6,
// 	state = 7,
// 	country = 8,
// 	postalCode = 9,
// 	postalCodeSuffix = 10
// }

// export enum AddressTypes {
// 	streetAddress = 1,
// 	route = 2,
// 	intersection = 3,
// 	country = 4,
// 	state = 5,
// 	county = 6,
// 	area = 7,
// 	colloquialArea = 8,
// 	locality = 9,
// 	neighborhood = 10,
// 	postalCode = 11,
// 	streetNumber = 12,
// 	unknown = 99
// }

// geoPoint is either a point coordinates or a parsable address
// represented by the results obtained from the google map geocoder
// a geoPoint has multiple string representations as well as address parts

// export class GeoPointInfo {

// 	static _geocoder: google.maps.Geocoder;

// 	constructor (value: Array<google.maps.GeocoderResult> | string) {

// 		if (!value || value.length === 0) { throw new Error('GeocoderResult missing or empty'); }
// 		this.addresses = new Map<GeoTypes, GeoAddress>();

// 		if (typeof value === 'string') {
// 			throw new Error('GeoPointInfo init from string not implemented');
// 		} else {
// 			// iterate through all the results
// 			for (const result of value) {
// 				const addr = new GeoAddress(result);
// 				this.addresses.set(addr.mainAddressType, addr);
// 			}
// 		}
// 	}

// 	public get mainAddress(): GeoAddress | undefined {
// 		if (this.addresses && this.addresses.size > 0) {
// 			return this.addresses.values().next().value;
// 		} else {
// 			return undefined;
// 		}
// 	}

// 	public addresses: Map<GeoTypes, GeoAddress>;

// }


// export class GeoAddress {

// 	private _addressTypes: Array<GeoTypes>;
// 	private _formattedAddress: string;
// 	private _coordinates: Coordinates;
// 	private _partialMatch: boolean;
// 	private _postcodeLocalities: string;
// 	private _parts: Map<GeoTypes, AddressPart>;

// 	constructor(value: google.maps.GeocoderResult) {
// 		this._addressTypes = GeoAddress.getAddressTypes(value.types);
// 		this._parts = this.parseParts(value.address_components);
// 		this._formattedAddress = value.formatted_address;
// 		this._coordinates = new Coordinates(value.geometry.location.lat(), value.geometry.location.lng());
// 		this._partialMatch = (value.partial_match) ? value.partial_match : false;
// 		// this._postcodeLocalities = (value.postcode_localities) ? value.postcode_localities.join() : this.parts.get('locality');
// 	}

// 	public get addressTypes(): Array<GeoTypes> {
// 		return this._addressTypes;
// 	}

// 	public get mainAddressType(): GeoTypes {
// 		if (this._addressTypes && this._addressTypes.length > 0) {
// 			return this._addressTypes[0];
// 		 } else {
// 			 return 'unknown';
// 		}
// 	}

// 	public get coordinates(): Coordinates {
// 		return this._coordinates;
// 	}

// 	public get formattedAddress(): string {
// 		return this._formattedAddress;
// 	}

// 	public get parts(): Map<GeoTypes, AddressPart> {
// 		return this._parts;
// 	}

// 	public get partialMatch(): boolean {
// 		return this._partialMatch;
// 	}

// 	public get postcodeLocalities(): string {
// 		return this._postcodeLocalities;
// 	}

// 	static getAddressTypes(types: Array<string>): Array<GeoTypes> {
// 		const res = new Array<GeoTypes>();

// 		if (!types || types.length < 1) { return res; }

// 		for (const type of types) {
// 			if (type && type !== 'political' && type !== 'unknown') {
// 				if (geoTypeList.includes(type)) {
// 					res.push(type);
// 				}
// 			}
// 		}
// 		return res;
// 	}

// 	private parseParts(components: Array<google.maps.GeocoderAddressComponent>): Map<GeoTypes, AddressPart> {
// 		const parts = new Map<GeoTypes, AddressPart>();
// 		if (components && components.length > 0) {
// 			for (const component of components) {
// 				const part = new AddressPart(component);
// 				parts.set(part.mainAddressType, part);
// 			}
// 		}
// 		return parts;
// 	}
// }

// export class AddressPart {

// 	public constructor(value: google.maps.GeocoderAddressComponent) {
// 		this.addressTypes = GeoAddress.getAddressTypes(value.types);
// 		this.long = value.long_name;
// 		this.short = value.short_name;
// 		if (!this.long && this.short) { this.long = this.short;	}
// 	}

// 	public addressTypes: Array<GeoTypes>;
// 	public long: string;
// 	public short?: string;

// 	public get mainAddressType(): GeoTypes {
// 		if (this.addressTypes && this.addressTypes.length > 0) {
// 			return this.addressTypes[0];
// 		} else {
// 			return 'unknown';
// 		}
// 	}
// }

