
<div class="card">
    <ib-image source="@public/address-w" alt="iBreeder Address" class="card-img-top img-fluid"></ib-image>
    <div class="card-body">
        <div class="card-title">
            <h5><strong>iBreeder Inc.</strong></h5>
		</div>
		<address class="text-sm ib-color-text">
			{{address.route}}<br />
			<div *ngIf='premise'>{{premise}}</div>
			{{address.cityStatePostalCode}}<br />
			{{info?.phone}}<br />
			{{info?.email('main')}}
		</address>

    </div>
</div>
