<div class='search-bar'>
	<ib-search-input id='free-search' [state] = "state.inputState"></ib-search-input>

	<div id='selection' class="input-group">
		<button
			id="btn-breeds"
			type='button'
			class="btn btn-outline-success selection"
		>
			<i [class]="icon('breed')"></i>
			<span class="title">My Breed(s)</span>
			<span class="caption">All Breeds</span>
		</button>
		<button
			id="btn-target"
			type='button'
			class="btn btn-outline-success selection"
		>
			<i [class]="icon('target')"></i>
			<span class="title">Looking for</span>
			<span class="caption">Puppy</span>
		</button>
		<button
			id="btn-where"
			type = 'button'
			class="btn btn-outline-success selection"
		>
			<i [class]="icon('location')"></i>
			<span class="title">My Location</span>
			<span class="caption">300mi from Coral Springs</span>
		</button>
	</div>
	<div id='views'>
		<ib-button id='btn-page' [icon]="icons.get('page')" (click)="onViewClick('page')"></ib-button>
		<ib-button id='btn-show' [icon]="icons.get('show')"></ib-button>
		<ib-button id='btn-list' [icon]="icons.get('list')"></ib-button>
		<ib-button id='btn-map' [icon]="icons.get('map')"></ib-button>
	</div>
</div>

	<!-- <div id="left">
		<ib-search id='free-search' *ngIf='size > 0' (searchChanged)="onSearchChanged($event)"></ib-search>
		<div id = 'search-buttons' class="btn-group">
			<button
				class='btn btn-outline-secondary btn-sm'
				(click)='onClick("Search/Breeds")'>
				<i *ngIf='size > 1' [class]='icon("breed")'  ></i>
				<span class="{{BreedColor}}">{{info.breedButtonCaption}}</span>
			</button>
			<button
				class='btn btn-outline-secondary btn-sm'
				(click)='onClick("Search/Target")'>
				<i *ngIf='size > 1' [class]='icon("target")' ></i>
				{{info.caption}}
			</button>
			<button
				class='btn btn-outline-secondary btn-sm'
				(click)='onClick("Search/Location")'>
				<i *ngIf='size > 1' class='{{searchLocationIcon}} no-padding' ></i>
				{{info.location}}
			</button>
		</div>
	</div>
	<div id="right"></div>
 -->

	<!-- <div class="btn-group ml-1" role="group">
			<button type="button"
				class="btn btn-light btn-sm" [ngClass]="isDisplayButtonActive('Page')"
				[attr.data-toggle]="'tooltip'" title="{{pageTooltip}}" [disabled]='isDisplayButtonDisabled("Page")'
				(click)='onDisplayClick("Page")'>
				<i [ngClass]='pageIcon'></i>
			</button>
			<button type="button"
				class="btn btn-light btn-sm" [ngClass]="isDisplayButtonActive('List')"
				[attr.data-toggle]="'tooltip'" title="{{listTooltip}}" [disabled]='isDisplayButtonDisabled("List")'
				(click)='onDisplayClick("List")'>
				<i [ngClass]='listIcon'></i>
			</button>
			<button type="button"
					class="btn btn-light btn-sm" [ngClass] ="isDisplayButtonActive('Show')"
					[attr.data-toggle]="'tooltip'" title="{{showTooltip}}" [disabled]='isDisplayButtonDisabled("Show")'
					(click)='onDisplayClick("Show")'>
				<i [ngClass]='showIcon'></i>
			</button>
			<button type="button"
					class="btn btn-light btn-sm" [ngClass] ="isDisplayButtonActive('Map')"
					[attr.data-toggle]="'tooltip'" title="{{mapTooltip}}" [disabled]='isDisplayButtonDisabled("Map")'
					(click)='onDisplayClick("Map")'>
				<i [ngClass]='mapIcon'></i>
			</button>
	</div> -->



<!-- <div *ngIf='size > 0' class='buttons'>
  <button 	class='btn btn-outline-secondary btn-sm'
		  	[attr.data-toggle]="'tooltip'"
		  	[attr.title]="searchBreedTooltip"
          	(click)='onClick("Search/Breeds")'>
    <i *ngIf='size > 1' class='{{searchBreedIcon}} no-padding'  ></i>
    <span class="{{BreedColor}}">{{info.breedButtonCaption}}</span>
  </button>
  <button 	class='btn btn-outline-secondary btn-sm'
			[attr.data-toggle]="'tooltip'"
			[attr.title]="searchTargetTooltip"
        	(click)='onClick("Search/Target")'>
    <i *ngIf='size > 1' class='{{searchTargetIcon}} no-padding' ></i>
    {{info.caption}}
  </button>
  <button 	class='btn btn-outline-secondary btn-sm'
			[disabled]='isSearchButtonDisabled("Location")'
        	[attr.data-toggle]="'tooltip'" [attr.title]="searchLocationTooltip"
        	(click)='onClick("Search/Location")'>
    <i *ngIf='size > 1' class='{{searchLocationIcon}} no-padding' ></i>
    {{info.location}}
  </button>
</div> -->

<!-- <div class="search-text">
	<span>{{info.description}}</span>
</div> -->
