// ----------------------------------------
// PersonDocument class
// ----------------------------------------

import { ibDocument } from './ib-document';

import { Owner, OwnerTypes } from '@entities/parts/owner';
import { GeoPoint } from '@entities/parts/geo-point';
import { Address } from '@entities/parts/address';
import { eMail } from '@entities/parts/email';
import { Phone } from '@entities/parts/phone';
import { Contact } from '@entities/parts/contact';
import { Property } from '@entities/parts/property';
import { Note } from '@entities/parts/note';
import { PersonTypes } from '@common/classes/types';

export class PersonDocument extends ibDocument {

	static assign(source: Record<string, any>): PersonDocument {

		let target: PersonDocument;
		let id: string = '';
		let o: Owner;

		if (source.hasOwnProperty('id')) {
			id = (<any> source)['id'];
		} else {
			throw new Error('id is required');
		}

		if (source.hasOwnProperty('owner')) {
			o = Owner.assign((<any> source)['owner']);
		} else {
			throw new Error('owner is required');
		}

		target = new PersonDocument(id, o);



		if (source.hasOwnProperty('name')) {
			target.name = (<any> source)['name'];
		} else {
			throw new Error('Name is required');
		}

		if (source.hasOwnProperty('description')) {
			target.description = (<any> source)['description'];
		}

		if (source.hasOwnProperty('type')) {
			target.type = ((<any> source)['type']);
		} else {
			throw new Error('The person type is required');
		}

		if (source.hasOwnProperty('accountID')) {
			target.accountID = (<any> source)['accountID'];
		} else {
			target.accountID = undefined;
		}

		if (source.hasOwnProperty('kennelName')) {
			target.kennelName = (<any> source)['kennelName'];
		}

		if (source.hasOwnProperty('ip')) {
			target.ip = (<any> source)['ip'];
		} else {
			throw new Error('Status is required');
		}

		if (source.hasOwnProperty('addresses')) {
			const s = <Array<any>> (<any> source)['addresses'];
			s.forEach((v) => { target.addresses.push(Address.assign(v)); });
		}

		if (source.hasOwnProperty('position')) {
			target.position = GeoPoint.assign((<any> source)['position']);
		}

		if (source.hasOwnProperty('emails')) {
			const s = <Array<any>> (<any> source)['emails'];
			s.forEach((v) => { target.eMails.push(eMail.assign(v)); });
		}

		if (source.hasOwnProperty('phones')) {
			const s = <Array<any>> (<any> source)['phones'];
			s.forEach((v) => { target.phones.push(Phone.assign(v)); });
		}

		if (source.hasOwnProperty('contacts')) {
			const s = <Array<any>> (<any> source)['contacts'];
			s.forEach((v) => { target.contacts.push(Contact.assign(v)); });
		}

		if (source.hasOwnProperty('webSite')) {
			target.webSite = (<any> source)['webSite'];
		}

		if (source.hasOwnProperty('properties')) {
			const s = <Array<any>> (<any> source)['properties'];
			s.forEach((v) => { target.properties.push(Property.assign(v)); });
		}

		if (source.hasOwnProperty('notes')) {
			const s = <Array<any>> (<any> source)['notes'];
			s.forEach((v) => {
				if (v) { target.notes.push(Note.assign(v)); }
			});
		}

		return target;
	}

	constructor(id: string, owner: Owner) {
		super('PersonDocument', id);
		this.name = '';
		this.owner = owner;
		this.description = OwnerTypes[owner.ownerType];
		this.type = 'prospect';
		this.accountID = undefined;
		this.kennelName = undefined;
		this.ip = undefined;
		this.addresses = new Array<Address>();
		this.position = undefined;
		this.eMails = new Array<eMail>();
		this.phones = new Array<Phone>();
		this.contacts = new Array<Contact>();
		this.webSite = undefined;
		this.properties = new Array<Property>();
		this.notes = new Array<Note>();
	}

	// name inherited
	owner: Owner;
	type: PersonTypes;
	description: string;
	accountID?: string;
	kennelName?: string | null;
	ip?: string;
	addresses: Array<Address>;
	position?: GeoPoint;
	eMails: Array<eMail>;
	phones: Array<Phone>;
	contacts: Array<Contact>;
	webSite?: string;
	properties: Array<Property>;
	notes: Array<Note>;


	public get mainAddress(): Address | null {
		if (!this.addresses || this.addresses.length < 1) { return null; }
		for (const addr of this.addresses) {
			if (addr.name.toLowerCase() === 'main') { return addr; }
		}
		return this.addresses.reduce((min: Address, current: Address): Address => {
			return (current.index < min.index) ? current : min;
		});
	}

	public get mainPhone(): Phone | null {
		if (!this.phones || this.phones.length < 1) { return null; }
		for (const phone of this.phones) {
			if (phone.name.toLowerCase() === 'main') { return phone; }
		}
		return this.phones.reduce((min: Phone, current: Phone): Phone => {
			return (current.index < min.index) ? current : min;
		});
	}

	public get maineMail(): eMail | null {
		if (!this.eMails || this.eMails.length < 1) { return null; }
		for (const mail of this.eMails) {
			if (mail.name.toLowerCase() === 'main') { return mail; }
		}
		return this.eMails.reduce((min: eMail, current: eMail): eMail => {
			return (current.index < min.index) ? current : min;
		});
	}


}
