<ib-title type="back" [icon]="titleIcon">Page Not Found</ib-title>
<div class='page'>
	<ib-image source="@common/page-not-found" alt="Page not found" class="img-fluid img-thumbnail rounded card-img-left"></ib-image>
	<div id="content">
		<h3 class="text-center">We seem to have lost our way!</h3>
		<h4 class="text-center">We cannot find the page that you requested.</h4>
		<p class="text-center">The page '{{badUrl}}' is not valid. Please check the spelling.</p>

		<div class="btn-group btn-large mt-4" role="group" aria-label="Basic example">
			<button *ngIf='canGoBack' class="btn btn-outline-secondary"  (click)="onClick('Back')">
				<i class="fal fa-arrow-alt-circle-left ib-color-icon"></i>Go Back
			</button>
			<button class="btn btn-outline-secondary"  (click)="onClick('Home')">
				<i class="fas fa-home ib-color-iBreeder"></i>Go Home
			</button>
		</div>

	</div>
</div>
