import { DogStatus, Gender } from 'common/classes/types';
import { GeoPoint } from './geo-point';
import { BreedStub } from './breed-stub';
import { IKey } from '@common/classes/interfaces';

export class DogStub implements IKey{

	static assign(source: Record<string, any>): DogStub {

		const d = new DogStub();
		if (!source) { return d; }

		if (source.hasOwnProperty('dogID')) {
			d.dogID = (<any> source)['dogID'];
		} else {
			throw new Error('DogStub Assign Error: the \'dogID\' is required');
		}

		if (source.hasOwnProperty('name')) {
			d.name = (<any> source)['name'];
		} else {
			throw new Error('DogStub Assign Error: the \'dog name\' is required');
		}

		if (source.hasOwnProperty('caption')) {
			d.caption = (<any> source)['caption'];
		} else {
			d.caption = undefined;
		}

		if (source.hasOwnProperty('gender')) {
			if ((<any> source)['gender'] === 'female') {
				d.gender = 'female';
			} else {
				d.gender = 'male';
			}
		} else {
			throw new Error('DogStub Assign Error: \'gender\' is required');
		}

		// if source doc is DogDocument
		if (source.hasOwnProperty('litter')) {
			const lit = <Record<string, any>> (<any> source)['litter'];
			if (lit.hasOwnProperty('name')) {
				d.litterID = (<any> lit)['name'];
			} else {
				throw new Error('DogStub Assign Error: the \'litter name\' is required');
			}

			if (lit.hasOwnProperty('breed')) {
				const breed = BreedStub.assign((<any> lit)['breed']);
				d.breedID = breed.breedID;
			} else {
				throw new Error('DogStub Assign Error: the \'breed\' is required');
			}

			if (lit.hasOwnProperty('dob')) {
				d.dob = new Date((<any> lit)['dob']);
			} else {
				throw new Error('DogStub Assign Error: \'dob\' is required');
			}
		} else {
			if (source.hasOwnProperty('litterID')) {
				d.litterID = (<any> source)['litterID'];
			} else {
				d.litterID = undefined;
			}

			if (source.hasOwnProperty('breedID')) {
				d.breedID = (<any> source)['BreedID'];
			} else {
				throw new Error('DogStub Assign Error: \'breedID\' is required');
			}

			if (source.hasOwnProperty('dob')) {
				d.dob = new Date((<any> source)['dob']);
			} else {
				throw new Error('DogStub Assign Error: \'dob\' is required');
			}

			if (source.hasOwnProperty('dogStatus')) {
				d.status = (<any> source)['dogStatus'];
			} else {
				throw new Error('DogStub Assign Error: \'dog status\' is required');
			}

		}

		if (source.hasOwnProperty('position')) {
			d.position = GeoPoint.assign((<any> source)['dogStatus']);
		} else {
			d.position = undefined;
		}

		if (source.hasOwnProperty('myListIndex')) {
			d.myListIndex = (<any> source)['myListIndex'];
		} else {
			d.myListIndex = -1;
		}

		if (source.hasOwnProperty('image')) {
			d.image = (<any> source)['image'];
		}

		if (source.hasOwnProperty('collarColor')) {
			d.collarColor = (<any> source)['collarColor'];
		}

		if (source.hasOwnProperty('key')) {
			d.key = (<any> source)['key'];
		}

		return d;
	}


	static createBlank(gender?: Gender): DogStub {
		const d = new DogStub();
		d.gender = (gender) ? gender : 'female';
		d.dogID = 'aaaa';
		d.name = 'blank';
		return d;
	}

	public dogID: string;
	public name: string;
	public caption?: string;
	public gender: Gender;
	public litterID?: string;
	public breedID?: string;
	public dob?: Date;
	public position?: GeoPoint;
	public status: DogStatus;
	public myListIndex: number;
	public image?: string;
	public collarColor?: string;
	public key?: string;

	constructor() {
		this.dogID = 'aaaa';
		this.name = 'New Dog';
		this.caption = undefined;
		this.gender = 'female';
		this.litterID = undefined;
		this.breedID = undefined;
		this.dob = undefined;
		this.status = 'pending';
		this.myListIndex = -1;
		this.image = undefined;
		this.collarColor = undefined;
		this.key = undefined;
	}

	public toKey(): string { return this.dogID; }

}
