import { ibIcon, IconSizes, IconTypes, TextSizes, IIconConfig, IconStores } from './icon';


export class Icons {

    private static _store: Map<IconStores, Map<string, ibIcon>>;

    public static generalIcon(
        id: string,
        type: IconTypes = 'regular',
        size: IconSizes = 'lg',
        textSize: TextSizes = 'xs'): ibIcon {
        // if (!this.isLoaded('general')) { } //do nothing icons loaded on demand
        return this.icon('general', id, type, size, textSize);
    }

    public static iBreederIcon(
        id: string,
        type: IconTypes = 'regular',
        size: IconSizes = 'xl',
        textSize: TextSizes = 'xs'): ibIcon {

            return this.icon('iBreeder', id, type, size, textSize);
    }

	public static breederIcon(
        id: string,
        type: IconTypes = 'regular',
        size: IconSizes = 'xl',
        textSize: TextSizes = 'xs'): ibIcon {

            return this.icon('breeder', id, type, size, textSize);
    }

    public static publicIcon(
        id: string,
        type: IconTypes = 'regular',
        size: IconSizes = 'xl',
        textSize: TextSizes = 'xs'): ibIcon {
            return this.icon('public', id, type, size, textSize);
    }

    public static designIcon(
        id: string,
        type: IconTypes = 'regular',
        size: IconSizes = 'xl',
        textSize: TextSizes = 'xs'): ibIcon {

            return this.icon('design', id, type, size, textSize);

    }

    public static authIcon(
        id: string,
        type: IconTypes = 'regular',
        size: IconSizes = 'xl',
        textSize: TextSizes = 'xs'): ibIcon {

            return this.icon('auth', id, type, size, textSize);
    }

    public static setColor(icon: ibIcon, color: string): ibIcon {
        if (icon) {
            icon.image.color = color;
        }
        return icon;
    }

    // load icon from the proper store (loads icons if required)
    private static icon(
        store: IconStores,
        id: string,
        type: IconTypes = 'regular',
        size: IconSizes = 'xl',
        textSize: TextSizes = 'xs'): ibIcon {

        // create store is not already initialized
        if (!this._store) { this.init(); }
        if (!id.endsWith('Icon')) { id += 'Icon'; }

        // get the right store
        const typedStore = <Map<string, ibIcon>>this._store.get(store);

        // general icons loaded on demand
        if (store === 'general' && !typedStore.has(id)) {
            const settings = (<any>Icons)['__' + id];
            if (settings) {
                const icon = new ibIcon(settings);
                if (!icon.id) { icon.id = id; }
                typedStore.set(icon.id, icon);
            }
        }

        // stores are loaded on demand if empty
        if (store !== 'general' && typedStore.size < 1) {
            (async () => {
                switch(store) {
                    case 'iBreeder':
                    	await this.loadiBreederIcons();
                        break;
					case 'breeder':
						await this.loadBreederIcons();
							break;
                    case 'public':
                        await this.LoadPublicIcons();
                        break;
                    case 'design':
                        await this.loadDesignIcons();
                        break;
                    case 'auth':
                        await this.loadAuthIcons();
                        break;
                }
            })();
        }

        let icon = typedStore.get(id)?.clone() as ibIcon;

        if (!icon) {
            icon = Icons.generalIcon('bug', type, size);
            if (!icon) throw new Error('Did not find the \'bug\' icon !');
        }
        icon.image.type = type;
        icon.image.size = size;
        if (textSize !== 'none') {
			icon.caption.size = textSize
		} else {
			icon.caption.showCaption = false;
		};
        return icon;
    }

    public static add(store: IconStores, icon: ibIcon | Array<ibIcon>) {
        if (!this._store) { this.init(); }
        const typedStore = <Map<string, ibIcon>>this._store.get(store);

        if (!Array.isArray(icon)) {
            const saved = icon;
            icon = new Array<ibIcon>();
            icon.push(saved);
        }

        icon.forEach((value: ibIcon) => {
            if (!value.id) { throw new Error('the Icon cannot be stored: the id is missing'); }
            typedStore.set(value.id, value);
        });
    }

    public static isLoaded(store: IconStores): boolean {
        if (!this._store) { this.init(); }
        const typedStore = <Map<string, ibIcon>>this._store.get(store);
        return typedStore.size > 0;
    }

    private static loadiBreederIcons(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (Icons.isLoaded('iBreeder')) { resolve(); return; }

            const template: IIconConfig = {
                id: 'impersonateIcon',
                iconName: 'fa-user-secret',
                iconColor: 'ib-color-impersonate',
                iconType: 'light',
                iconSize: 'xl',
				showCaption: true,
                captionText: 'Impersonate',
                captionColor: 'ib-color-menu-text',
                captionSize: 'sm',
                tooltip: 'Impersonate a breeder',
            };
            Icons.add('iBreeder', new ibIcon(template));

            template.id = 'listIcon';
            template.tooltip = 'List Management ';
            template.iconName = 'fa-list-ul';
            template.iconColor = 'ib-color-navy';
            template.iconType = 'light';
            template.captionText = 'Lists';
            template.captionColor = 'ib-color-menu-text',
            template.captionSize = 'sm',
            Icons.add('iBreeder', new ibIcon(template));

            template.id = 'cliIcon';
            template.tooltip = 'CLI ';
            template.iconName = 'fa-toolbox';
            template.iconColor = 'ib-color-gray1';
            template.iconType = 'light';
            template.captionText = 'CLI';
            template.captionColor = 'ib-color-menu-text',
            template.captionSize = 'sm',
            Icons.add('iBreeder', new ibIcon(template));

            template.id = 'geoMappingIcon';
            template.tooltip = 'Geo Mapping ';
            template.iconName = 'fa-map-pin';
            template.iconColor = '.ib-color-green';
            template.iconType = 'light';
            template.captionText = 'Geo Mapping';
            template.captionColor = '.ib-color-gray-500',
            template.captionSize = 'sm',
            Icons.add('iBreeder', new ibIcon(template));

            template.id = 'healthIcon';
            template.tooltip = 'Health Information ';
            template.iconName = 'fa-h-square';
            template.iconColor = 'ib-color-red';
            template.iconType = 'light';
            template.captionText = 'Health Info';
            template.captionColor = 'ib-color-menu-text',
            template.captionSize = 'sm',
            Icons.add('iBreeder', new ibIcon(template));

            template.id = 'analyticsIcon';
            template.tooltip = 'Analytics';
            template.iconName = 'fa-analytics';
            template.iconColor = 'ib-color-google';
            template.iconType = 'regular';
            template.captionText = 'Analytics';
            Icons.add('iBreeder', new ibIcon(template));

            template.id = 'accountingIcon';
            template.tooltip = 'Accounting';
            template.iconName = 'fa-abacus';
            template.iconColor = 'ib-color-accounting';
            template.iconType = 'solid';
            template.captionText = '';
            Icons.add('iBreeder', new ibIcon(template));

            template.id = 'userErrorIcon';
            template.tooltip = 'User Error';
            template.iconName = 'fa-user-slash';
            template.iconColor = 'ib-color-error';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('iBreeder', new ibIcon(template));

            template.id = 'lockedIcon';
            template.tooltip = 'Locked';
            template.iconName = 'fa-lock';
            template.iconColor = 'ib-color-success';
            template.captionText = '';
            Icons.add('iBreeder', new ibIcon(template));

			template.id = 'similarBreedsIcon';
            template.tooltip = 'Similar Breeds';
            template.iconName = 'fa-shapes';
            template.iconColor = 'ib-color-breed';
            template.captionText = '';
            Icons.add('iBreeder', new ibIcon(template));

			template.id = 'traitsIcon';
            template.tooltip = 'Dog Traits';
            template.iconName = 'fa-star-sharp';
            template.iconColor = 'ib-color-success';
            template.captionText = '';
            Icons.add('iBreeder', new ibIcon(template));

            template.id = 'unlockedIcon';
            template.tooltip = 'Unlocked';
            template.iconName = 'fa-lock-open';
            template.iconColor = 'ib-color-gray-400';
            template.captionText = '';
            Icons.add('iBreeder', new ibIcon(template));

            template.id = 'usersIcon';
            template.tooltip = '';
            template.iconName = 'fa-users';
            template.iconColor = 'ib-color-iBreeder';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('iBreeder', new ibIcon(template));

            template.id = 'onBoardingIcon';
            template.tooltip = 'onboarding';
            template.iconName = 'fa-chevron-circle-right';
            template.iconColor = 'ib-color-onboarding';
            template.captionText = '';
            Icons.add('iBreeder', new ibIcon(template));

            resolve();

        });
    }

	private static loadBreederIcons(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (Icons.isLoaded('breeder')) { resolve(); return; }

            const template: IIconConfig = {
                id: 'breederIcon',
                iconName: 'fa-paw',
                iconColor: 'ib-color-breeder',
                iconType: 'solid',
                iconSize: 'xl',
				showCaption: true,
                captionText: 'Breeder',
                captionColor: 'ib-color-menu-text',
                captionSize: 'sm',
                tooltip: 'Breeder',
            };
            Icons.add('breeder', new ibIcon(template));

			template.id = 'closeSaleIcon';
            template.tooltip = 'Close the sale';
            template.iconName = 'fa-handshake';
            template.iconColor = 'ib-color-black';
            template.iconType = 'light';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'creditCardIcon';
            template.tooltip = 'Pricing';
            template.iconName = 'fa-credit-card';
            template.iconColor = '--ib-color-credit-card';
            template.iconType = 'light';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'dataCollectionIcon';
            template.tooltip = 'Data Collection';
            template.iconName = 'fa-pen-field';
            template.iconColor = 'ib-color-black';
            template.iconType = 'light';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'featuresIcon';
            template.tooltip = 'Plan Features';
            template.iconName = 'fa-list-tree';
            template.iconColor = 'ib-color-navy';
            template.iconType = 'thin';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'genderIcon';
            template.tooltip = 'gender';
            template.iconName = 'fa-mars-and-venus';
            template.iconColor = 'ib-color-gender';
            template.iconType = 'thin';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'iBreederEngineIcon';
            template.tooltip = 'iBreeder Engine';
            template.iconName = 'fa-gears';
            template.iconColor = 'ib-color-black';
            template.iconType = 'light';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'impersonationIcon';
            template.tooltip = 'Impersonation';
            template.iconName = 'fa-people-arrows-left-right';
            template.iconColor = 'ib-color-warning';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'informIcon';
            template.tooltip = 'Inform you customers';
            template.iconName = 'fa-square-info';
            template.iconColor = 'ib-color-black';
            template.iconType = 'light';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'kennelIcon';
            template.tooltip = 'kennel';
            template.iconName = 'fa-house-heart';
            template.iconColor = 'ib-color-breeder';
            template.iconType = 'solid';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'misleadingIcon';
            template.tooltip = 'Misleading Information';
            template.iconName = 'fa-smile-wink';
            template.iconColor = 'ib-color-black';
            template.iconType = 'light';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'publishIcon';
            template.tooltip = 'Publish Content';
            template.iconName = 'fa-rss';
            template.iconColor = 'ib-color-black';
            template.iconType = 'light';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'reachIcon';
            template.tooltip = 'Reach your customers';
            template.iconName = 'fa-hand-holding-heart';
            template.iconColor = 'ib-color-black';
            template.iconType = 'light';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'scrollIcon';
            template.tooltip = 'Terms and Policies';
            template.iconName = 'fa-scroll';
            template.iconColor = 'ib-color-scroll';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'scamIcon';
            template.tooltip = 'Scams';
            template.iconName = 'fa-scarecrow';
            template.iconColor = 'ib-color-danger';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

			template.id = 'shieldIcon';
            template.tooltip = '';
            template.iconName = 'fa-shield';
            template.iconColor = 'ib-color-trust';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

			template.id = 'spamIcon';
            template.tooltip = 'Spam';
            template.iconName = 'fa-bullhorn';
            template.iconColor = 'ib-color-warning';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));


			template.id = 'triggerIcon';
            template.tooltip = 'trigger';
            template.iconName = 'fa-thumbs-up';
            template.iconColor = 'ib-color-black';
            template.iconType = 'light';
            template.captionText = '';
            Icons.add('breeder', new ibIcon(template));

            resolve();

        });
    }

    private static LoadPublicIcons(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (Icons.isLoaded('public')) { resolve(); return; }

            const template: IIconConfig = {
                id: 'homeIcon',
                iconName: 'fa-home',
                iconColor: 'ib-color-home',
                iconSize: 'giga',
                iconType: 'solid',
                iconFixedWidth: true,
				showCaption: true,
                captionText: 'Home',
                captionColor: 'ib-color-home',
                captionSize: 'xl',
                tooltip: 'return to the home page',
                isSelectable: true,
                url: '/home'
            };
            Icons.add('public', new ibIcon(template));

            template.id = 'aboutUsIcon';
            template.captionText = 'About Us';
            template.tooltip = 'About iBreeder and it\'s team';
            template.iconName = 'fa-users';
            template.iconColor = 'ib-color-iBreeder';
            template.iconType = 'regular';
            template.url = '/Main/AboutUs';
            Icons.add('public', new ibIcon(template));

            template.id = 'breedIcon';
            template.captionText = 'Breed';
            template.tooltip = 'Explore dog breeds';
            template.iconName = 'fa-paw';
            template.iconColor = 'ib-color-breed';
            template.iconType = 'solid';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'breederIcon';
            template.captionText = 'Breeder';
            template.tooltip = 'Explore breeders';
            template.iconName = 'fa-paw';
            template.iconColor = 'ib-color-breeder';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'breederSpaceIcon';
            template.captionText = 'Present your kennel';
            template.tooltip = 'Present your kennel & purebreds';
            template.iconName = 'fa-globe';
            template.iconColor = 'ib-color-globe';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'breedingIcon';
            template.captionText = 'Responsible Breeding';
            template.tooltip = 'Responsible Breeding';
            template.iconName = 'fa-dog-leashed';
            template.iconColor = 'ib-color-breeding';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'buyerIcon';
            template.captionText = 'Dog Buyer';
            template.tooltip = 'dog buyer';
            template.iconName = 'fa-family';
            template.iconColor = 'ib-color-text';
            template.iconType = 'light';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'checkListIcon';
            template.captionText = 'Your CheckList';
            template.tooltip = 'A check list of important steps when looking for a purebred';
            template.iconName = 'fa-clipboard-list-check';
            template.iconColor = 'ib-color-check-list';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

			template.id = 'clientIcon';
            template.captionText = 'Client';
            template.tooltip = 'breeder client';
            template.iconName = 'fa-family';
            template.iconColor = 'ib-color-client';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'clubIcon';
            template.captionText = 'Club Icon';
            template.tooltip = 'Dog Club';
            template.iconName = 'fa-file-certificate';
            template.iconColor = 'ib-color-text';
            template.iconType = 'light';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'commitmentIcon';
            template.captionText = 'Breeder Commitment';
            template.tooltip = 'Breeder Commitment';
            template.iconName = 'fa-handshake-alt';
            template.iconColor = 'ib-color-commitment';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'connectIcon';
            template.captionText = 'Connect';
            template.tooltip = 'Connect with your clients';
            template.iconName = 'fa-tower-broadcast';
            template.iconColor = 'ib-color-iBreeder';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

			template.id = 'contactUsIcon';
            template.captionText = 'Contact Us';
            template.tooltip = 'Contact us';
            template.iconName = 'fa-envelope';
            template.iconColor = 'ib-color-contact-us';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'contentIcon';
            template.captionText = 'Content';
            template.tooltip = 'Content';
            template.iconName = 'fa-folders';
            template.iconColor = 'ib-color-black';
            template.iconType = 'light';
            template.url = '';
            Icons.add('public', new ibIcon(template));

			template.id = 'dueDiligenceIcon';
            template.captionText = 'Due Diligence';
            template.tooltip = 'Due Diligence';
            template.iconName = 'fa-eyes';
            template.iconColor = 'ib-color-iBreeder';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'documentsIcon';
            template.captionText = 'Documents';
            template.tooltip = 'Practice Document';
            template.iconName = 'fa-files-medical';
            template.iconColor = 'ib-color-documents';
            template.url = '/practice/documents';
            Icons.add('public', new ibIcon(template));

            template.id = 'dogIcon';
            template.captionText = 'Dog';
            template.tooltip = 'dog';
            template.iconName = 'fa-paw';
            template.iconColor = 'ib-color-dog';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'favoriteIcon';
            template.captionText = 'favorite';
            template.tooltip = 'favorite';
            template.iconName = 'fa-heart-circle';
            template.iconColor = 'ib-color-iBreeder';
            template.iconType = 'light';
            template.url = '';
            Icons.add('public', new ibIcon(template));

			template.id = 'favoritesIcon';
            template.captionText = 'Favorites';
            template.tooltip = 'Favorites';
            template.iconName = 'fa-face-smile-hearts';
            template.iconColor = 'ib-color-iBreeder';
            template.iconType = 'light';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'getStartedIcon';
            template.captionText = 'My Dogs';
            template.tooltip = 'let\'s start tracking!';
            template.iconName = 'fa-play';
            template.iconColor = 'ib-color-get-started';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'iBreederIcon';
            template.captionText = 'iBreeder';
            template.tooltip = 'iBreeders';
            template.iconName = 'fa-paw-alt';
            template.iconColor = 'ib-color-iBreeder';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            // template.id = 'impersonateIcon';
            // template.captionText = 'Impersonate';
            // template.tooltip = 'Get Access to provider or patient portal';
            // template.iconName = 'fa-people-arrows';
            // template.iconColor = 'ib-color-impersonate';
            // template.url = '/practice/impersonate';
            // Icons.add('public', new ibIcon(template));

            template.id = 'kennelIcon';
            template.captionText = 'Kennel';
            template.tooltip = 'kennel';
            template.iconName = 'fa-house-blank';
            template.iconColor = 'ib-color-breeder';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'litterIcon';
            template.captionText = 'Litter';
            template.tooltip = 'litter';
            template.iconName = 'fa-paw';
            template.iconColor = 'ib-color-litter';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

			template.id = 'locationIcon';
            template.tooltip = 'Location ';
            template.iconName = 'fa-map-pin';
            template.iconColor = '.ib-color-green';
            template.iconType = 'light';
            template.captionText = 'Location';
            template.captionColor = '.ib-color-gray-500',
            template.captionSize = 'sm',
            Icons.add('public', new ibIcon(template));

            template.id = 'missionIcon';
            template.captionText = 'Our Mission';
            template.tooltip = 'Our Mission';
            template.iconName = 'fa-bullseye';
            template.iconColor = 'ib-color-iBreeder-complement';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            Icons.add('public', Icons.generalIcon('myAccount'));

            template.id = 'myDogsIcon';
            template.captionText = 'My Dogs';
            template.tooltip = 'my favorite dogs';
            template.iconName = 'fa-heart';
            template.iconColor = 'ib-color-favorites';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'namesIcon';
            template.captionText = 'names';
            template.tooltip = 'names';
            template.iconName = 'fa-line-columns';
            template.iconColor = 'ib-color-text';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));


            template.id = 'notificationsIcon';
            template.captionText = 'Notifications';
            template.tooltip = 'notifications';
            template.iconName = 'fa-bell';
            template.iconColor = 'ib-color-notifications';
            template.iconType = 'light';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'profileIcon';
            template.captionText = 'Profile';
            template.tooltip = 'profile';
            template.iconName = 'fa-memo-circle-info';
            template.iconColor = 'ib-color-profile';
            template.iconType = 'light';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'publicIcon';
            template.captionText = 'Public';
            template.tooltip = 'track dogs';
            template.iconName = 'fa-user-circle';
            template.iconColor = 'ib-color-public';
            template.iconType = 'solid';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'questionnairesIcon';
            template.captionText = 'Questionnaires';
            template.tooltip = 'Practice Questionnaires';
            template.iconName = 'fa-question-circle';
            template.iconColor = 'ib-color-questionnaires';
            template.url = '/practice/questionnaires';
            Icons.add('public', new ibIcon(template));

            template.id = 'reportsIcon';
            template.captionText = 'Reports';
            template.tooltip = 'Reports';
            template.iconName = 'fa-table';
            template.iconColor = 'ib-color-report';
            template.url = '/practice/reports';
            Icons.add('public', new ibIcon(template));

            template.id = 'saleInfoIcon';
            template.captionText = 'Sale Info';
            template.tooltip = 'Sales information';
            template.iconName = 'fa-badge-dollar';
            template.iconColor = 'ib-color-info';
            template.url = '';
            Icons.add('public', new ibIcon(template));

			template.id = 'searchesIcon';
            template.captionText = 'Searches';
            template.tooltip = 'List of previous searches';
            template.iconName = 'fa-folder-magnifying-glass';
            template.iconColor = 'ib-color-search';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            // should be transferred to auth
            template.id = 'securityIcon';
            template.captionText = 'Security';
            template.tooltip = 'security';
            template.iconName = 'fa-lock';
            template.iconColor = 'ib-color-security';
            template.url = '/practice/security';
            Icons.add('public', new ibIcon(template));

            template.id = 'serviceIcon';
            template.captionText = 'Our Service';
            template.tooltip = 'An overview of iBreeder services';
            template.iconName = 'fa-hands-holding-diamond';
            template.iconColor = 'ib-color-service';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'settingsIcon';
            template.captionText = 'Account Settings';
            template.tooltip = 'Your settings';
            template.iconName = 'fa-cog';
            template.iconColor = 'ib-color-settings';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

			template.id = 'storageIcon';
            template.captionText = 'Cloud Storage';
            template.tooltip = 'Cloud Storage';
            template.iconName = 'fa-box-archive';
            template.iconColor = 'ib-color-black';
            template.iconType = 'light';
            template.url = '';
            Icons.add('public', new ibIcon(template));

			template.id = 'starsIcon';
            template.captionText = '';
            template.tooltip = '';
            template.iconName = 'fa-stars';
            template.iconColor = 'ib-color-star';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'targetIcon';
            template.captionText = 'Target';
            template.tooltip = 'Target';
            template.iconName = 'fa-bullseye-pointer';
            template.iconColor = 'ib-color-target';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

			template.id = 'teamIcon';
            template.captionText = 'Our Team';
            template.tooltip = 'Our Team';
            template.iconName = 'fa-chess-knight';
            template.iconColor = 'ib-color-iBreeder';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'testsIcon';
            template.captionText = 'Tests';
            template.tooltip = 'Tests and Inventories';
            template.iconName = 'fa-ballot-check';
            template.iconColor = 'ib-color-tests';
            template.url = '/practice/tests';
            Icons.add('public', new ibIcon(template));

            template.id = 'technologyIcon';
            template.captionText = 'Technology';
            template.tooltip = 'Our Technology';
            template.iconName = 'fa-wifi';
            template.iconColor = 'ib-color-wifi';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'termsIcon';
            template.captionText = 'Terms';
            template.tooltip = 'Terms and Conditions?';
            template.iconName = 'fa-gavel';
            template.iconColor = 'ib-color-terms';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'trackingIcon';
            template.captionText = 'Tracking';
            template.tooltip = 'Tracking puppies?';
            template.iconName = 'fa-paw-claws';
            template.iconColor = 'ib-color-tracking';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'trainerIcon';
            template.captionText = 'Dog Trainer';
            template.tooltip = 'dog trainer';
            template.iconName = 'fa-dog-leashed';
            template.iconColor = 'ib-color-text';
            template.iconType = 'light';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'trustIcon';
            template.captionText = 'Trust & Ethics';
            template.tooltip = 'Trust & Ethic are fundamental';
            template.iconName = 'fa-shield-alt';
            template.iconColor = 'ib-color-trust';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

			template.id = 'unlimitedIcon';
            template.captionText = 'Unlimited Storage';
            template.tooltip = 'Unlimited Storage';
            template.iconName = 'fa-infinity';
            template.iconColor = 'ib-color-black';
            template.iconType = 'light';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'vetIcon';
            template.captionText = 'Veterinarian';
            template.tooltip = 'vet';
            template.iconName = 'fa-hand-holding-medical';
            template.iconColor = 'ib-color-text';
            template.iconType = 'light';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'webinarIcon';
            template.captionText = 'Demos & Webinars';
            template.tooltip = 'Demos and webinars';
            template.iconName = 'fa-webcam';
            template.iconColor = 'ib-color-webinar';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            template.id = 'whyPurebredsIcon';
            template.captionText = 'Why Purebreds';
            template.tooltip = 'Why choose a purebred puppy?';
            template.iconName = 'fa-balance-scale-right';
            template.iconColor = 'ib-color-dog';
            template.iconType = 'regular';
            template.url = '';
            Icons.add('public', new ibIcon(template));

            resolve();
        });
    }

    private static loadAuthIcons(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (Icons.isLoaded('auth')) { resolve(); return; }

            const template: IIconConfig = {
                id: 'signInIcon',
                iconName: 'fa-sign-in',
                iconColor: 'ib-color-user',
                iconType: 'light',
                iconSize: 'xl',
				showCaption: true,
                captionText: 'Sign In',
                captionColor: 'ib-color-menu-text',
                captionSize: 'sm',
                tooltip: 'sign into your account',
            };
            Icons.add('auth', new ibIcon(template));

			template.id = 'keyIcon';
            template.tooltip = 'password';
            template.iconName = 'fa-key';
            template.iconColor = 'ib-color-key';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));


			template.id = 'lockedIcon';
            template.tooltip = 'Locked';
            template.iconName = 'fa-lock';
            template.iconColor = 'ib-color-success';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));

            template.id = 'unlockedIcon';
            template.tooltip = 'Unlocked';
            template.iconName = 'fa-lock-open';
            template.iconColor = 'ib-color-gray-400';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));

            template.id = 'onBoardingIcon';
            template.tooltip = 'onboarding';
            template.iconName = 'fa-chevron-circle-right';
            template.iconColor = 'ib-color-onboarding';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));

			template.id = 'passwordIcon';
            template.tooltip = 'password';
            template.iconName = 'fa-chevron-circle-right';
            template.iconColor = 'ib-color-onboarding';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));

            template.id = 'registrationIcon';
            template.tooltip = 'register with iBreeder ';
            template.iconName = 'fa-user-plus';
            template.iconColor = 'ib-color-register';
            template.iconType = 'light';
            template.captionText = 'Registration';
            template.captionColor = 'ib-color-menu-text',
            template.captionSize = 'sm',
            Icons.add('auth', new ibIcon(template));

            template.id = 'registeringIcon';
            template.tooltip = 'registering user ';
            template.iconName = 'fa-user-plus';
            template.iconColor = 'ib-color-register';
            template.iconType = 'light';
            template.captionText = 'Registering';
            template.captionColor = 'ib-color-menu-text',
            template.captionSize = 'sm',
            Icons.add('auth', new ibIcon(template));

            template.id = 'renewIcon';
            template.tooltip = 'auto login ';
            template.iconName = 'fa-user-unlock';
            template.iconColor = '.ib-color-gray-600';
            template.iconType = 'light';
            template.captionText = 'Authenticating...';
            template.captionColor = '.ib-color-gray-500',
            template.captionSize = 'sm',
            Icons.add('auth', new ibIcon(template));

            template.id = 'signOutIcon';
            template.tooltip = 'Sign Out ';
            template.iconName = 'fa-sign-out';
            template.iconColor = 'ib-color-user';
            template.iconType = 'light';
            template.captionText = 'Sign Out';
            template.captionColor = 'ib-color-menu-text',
            template.captionSize = 'sm',
            Icons.add('auth', new ibIcon(template));

            template.id = 'userAdminIcon';
            template.tooltip = 'Admin user';
            template.iconName = 'fa-user-shield';
            template.iconColor = 'ib-color-user-admin';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));

            template.id = 'userGeneralIcon';
            template.tooltip = 'General user';
            template.iconName = 'fa-user';
            template.iconColor = 'ib-color-user-general';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));

            template.id = 'userIcon';
            template.tooltip = 'User';
            template.iconName = 'fa-user';
            template.iconColor = 'ib-color-user';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));

            template.id = 'userCircleIcon';
            template.tooltip = 'User';
            template.iconName = 'fa-user-circle';
            template.iconColor = 'ib-color-account';
            template.iconType = 'solid';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));

            template.id = 'userErrorIcon';
            template.tooltip = 'User Error';
            template.iconName = 'fa-user-slash';
            template.iconColor = 'ib-color-error';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));

            template.id = 'usernameIcon';
            template.tooltip = 'User Name';
            template.iconName = 'fa-signature';
            template.iconColor = 'ib-color-user';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));

            template.id = 'usersIcon';
            template.tooltip = '';
            template.iconName = 'fa-users';
            template.iconColor = 'ib-color-iBreeder';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));

            template.id = 'userOwnerIcon';
            template.tooltip = 'Owner';
            template.iconName = 'fa-house-user';
            template.iconColor = 'ib-color-user-owner';
            template.iconType = 'regular';
            template.captionText = '';
            Icons.add('auth', new ibIcon(template));



            resolve();

        });
    }

    // private static loadPatientIcons() {}

    private static loadDesignIcons(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (Icons.isLoaded('design')) { resolve(); return; }

            const template: IIconConfig = {
                id: 'designIcon',
                iconName: 'fa-pencil-paintbrush',
                iconColor: 'ib-color-design',
                iconSize: 'giga',
                iconType: 'solid',
                iconFixedWidth: true,
				showCaption: true,
                captionText: 'Design',
                captionColor: 'ib-color-home',
                captionSize: 'xl',
                tooltip: 'return to the home page',
                isSelectable: true,
                url: '/design'
            };
            Icons.add('design', new ibIcon(template));

            template.id = 'unknownIcon';
            template.captionText = 'Unknown';
            template.tooltip = 'State unknown';
            template.iconName = 'fa-question-square';
            template.iconColor = 'ib-color-icon';
            Icons.add('design', new ibIcon(template));

            template.id = 'draftIcon';
            template.captionText = 'draft';
            template.tooltip = 'State draft';
            template.iconName = 'fa-file';
            template.iconColor = 'ib-color-partial';
            Icons.add('design', new ibIcon(template));

            template.id = 'completeIcon';
            template.captionText = 'complete';
            template.tooltip = 'State complete';
            template.iconName = 'fa-file-alt';
            template.iconColor = 'ib-color-complete';
            Icons.add('design', new ibIcon(template));

            template.id = 'checkedIcon';
            template.captionText = 'checked';
            template.tooltip = 'State checked';
            template.iconName = 'fa-check-circle';
            template.iconColor = 'ib-color-success';
            Icons.add('design', new ibIcon(template));

            template.id = 'publishedIcon';
            template.captionText = 'published';
            template.tooltip = 'State published';
            template.iconName = 'fa-cloud-upload';
            template.iconColor = 'ib-color-published';
            Icons.add('design', new ibIcon(template));

            resolve();

        });



    }

    private static init() {
        this._store = new Map<IconStores, Map<string, ibIcon>>();
        this._store.set('general', new Map<string, ibIcon>());
        this._store.set('iBreeder', new Map<string, ibIcon>());
        this._store.set('breeder', new Map<string, ibIcon>());
        this._store.set('public', new Map<string, ibIcon>());
        this._store.set('design', new Map<string, ibIcon>());
        this._store.set('auth', new Map<string, ibIcon>());
    }

    // private static waitUntil(conditionFunction: ()=>{}) {

    //     const poll = (resolve: any) => {
    //       if(conditionFunction()) resolve();
    //       else setTimeout(() => poll(resolve), 400);
    //     }

    //     return new Promise(poll);
    //   }


    // General Icons

    private static __addIcon = {
        id: 'addIcon',
        iconName: 'fa-plus-circle',
        iconColor: 'ib-color-plus',
        captionText: 'add',
        tooltip: 'add an item'
    };

    private static __addressIcon = {
        id: 'addressIcon',
        iconName: 'fa-address-book',
        iconColor: 'ib-color-location',
        captionText: '',
        tooltip: 'address'
    };

	private static __backIcon = {
        id: 'backIcon',
        iconName: 'fa-arrow-circle-left',
        iconColor: 'ib-color-title-icon',
        tooltip: 'back!'
    };

	private static __barcodeIcon = {
        id: 'barcodeIcon',
        iconName: 'fa-barcode',
        iconColor: 'ib-color-location',
        tooltip: 'barcode!'
    };

	private static __breederIcon = {
        id: 'breederIcon',
        iconName: 'fa-paw',
        iconColor: 'ib-color-breeder',
        tooltip: 'breeder'
    };

    private static __bugIcon = {
        id: 'bugIcon',
        iconName: 'fa-bug',
        iconColor: 'ib-color-red',
        tooltip: 'Missing Icon!'
    };

    private static __buildingIcon = {
        id: 'buildingIcon',
        iconName: 'fa-warehouse-alt',
        iconColor: 'ib-color-text',
        tooltip: 'Kennel!'
    };

    private static __businessIcon = {
        id: 'businessIcon',
        iconName: 'fa-briefcase',
        iconColor: 'ib-color-text',
        tooltip: 'business'
    };

	private static __caretUpIcon = {
        id: 'caretUpIcon',
        iconName: 'fa-caret-up',
        iconColor: 'ib-color-icon',
        tooltip: ''
    };
    private static __caretDownIcon = {
        id: 'caretDownIcon',
        iconName: 'fa-caret-down',
        iconColor: 'ib-color-icon',
        tooltip: ''
    };
    private static __caretLeftIcon = {
        id: 'caretLeftIcon',
        iconName: 'fa-caret-left',
        iconColor: 'ib-color-icon',
        tooltip: ''
    };
    private static __caretRightIcon = {
        id: 'caretRightIcon',
        iconName: 'fa-caret-right',
        iconColor: 'ib-color-icon',
        tooltip: ''
    };

	private static __centerIcon = {
        id: 'centerIcon',
        iconName: 'fa-circle-dot',
        iconColor: 'ib-color-icon',
        tooltip: 'Center'
    };


    private static __checkIcon = {
        id: 'checkIcon',
        iconName: 'fa-check',
        iconColor: 'ib-color-check',
        tooltip: 'checked'
    };

    private static __checklistIcon = {
        id: 'checklistIcon',
        iconName: 'fa-clipboard-list-check',
        iconColor: 'ib-color-check-list',
        tooltip: 'check list'
    };

    private static __checkQuickIcon = {
        id: 'checkQuickIcon',
        iconName: 'fa-comment-alt-check',
        iconColor: 'ib-color-check',
        tooltip: 'check list'
    };

    private static __closeIcon = {
        id: 'closeIcon',
        iconName: 'fa-times',
        iconColor: 'ib-color-icon',
        tooltip: 'close'
    };

	private static __countryIcon = {
        id: 'countryIcon',
        iconName: 'fa-flag',
        iconColor: 'ib-color-location',
        tooltip: 'country'
    };

	private static __copyrightIcon = {
        id: 'copyrightIcon',
        iconName: 'fa-copyright',
        iconColor: 'ib-color-icon',
        tooltip: 'copyright notice'
    };

	private static __coverIcon = {
        id: 'coverIcon',
        iconName: 'fa-grid-5',
        iconColor: 'ib-color-iBreeder',
        tooltip: 'Cover Images'
    };

	private static __currencyDollarIcon = {
        id: 'currencyDollarIcon',
        iconName: 'fa-dollar-sign',
        iconColor: 'ib-color-dollar',
        tooltip: 'US Dollar'
    };


	private static __dangerIcon = {
        id: 'dangerIcon',
        iconName: 'fa-exclamation-triangle',
        iconColor: 'ib-color-danger',
        tooltip: 'warning'
    };

    private static __dataIcon = {
        id: 'dataIcon',
        iconName: 'fa-database',
        iconColor: 'ib-color-data',
        tooltip: 'data source'
    };

    private static __dayIcon = {
        id: 'dayIcon',
        iconName: 'fa-calendar-day',
        iconColor: 'ib-color-calendar',
        tooltip: 'day'
    };

    private static __deleteIcon = {
        id: 'deleteIcon',
        iconName: 'fa-minus-circle',
        iconColor: 'ib-color-minus',
        tooltip: 'delete the selected item'
    };

	private static __deleteAccountIcon = {
        id: 'deleteAccountIcon',
        iconName: 'fa-circle-trash',
        iconColor: 'ib-color-minus',
		captionText: 'Delete Account',
        tooltip: 'delete the account'
    };

	private static __detailsIcon = {
        id: 'detailsIcon',
        iconName: 'fa-rectangle-list',
        iconColor: 'ib-color-list',
        tooltip: 'detail list'
    };

	private static __dogOwnerIcon = {
        id: 'dogOwnerIcon',
        iconName: 'fa-dog-leashed',
        iconColor: 'ib-color-dog-owner',
        tooltip: 'Dog Owner'
    };

	private static __dotIcon = {
        id: 'dotIcon',
        iconName: 'fa-circle-small',
        iconColor: 'ib-color-icon',
        tooltip: ''
    };

	private static __downloadIcon = {
        id: 'downloadIcon',
        iconName: 'fa-cloud-arrow-down',
        iconColor: 'ib-color-icon',
        tooltip: 'download to cloud'
    };

    private static __editIcon = {
        id: 'editIcon',
        iconName: 'fa-edit',
        iconColor: 'ib-color-edit',
        tooltip: 'edit the selected item'
    };

	private static __editTextIcon = {
        id: 'editTextIcon',
        iconName: 'fa-input-text',
        iconColor: 'ib-color-edit',
        tooltip: 'edit the text of selected item'
    };

	private static __editNumberIcon = {
        id: 'editNumberIcon',
        iconName: 'fa-input-numeric',
        iconColor: 'ib-color-edit',
        tooltip: 'edit the number in the selected item'
    };

	private static __ellipsisIcon = {
        id: 'ellipsisIcon',
        iconName: 'fa-ellipsis-h',
        iconColor: 'ib-color-text',
        tooltip: ''
    };

    private static __emailIcon = {
        id: 'emailIcon',
        iconName: 'fa-at',
        iconColor: 'ib-color-email',
        tooltip: 'email address'
    };

    private static __errorIcon = {
        id: 'errorIcon',
        iconName: 'fa-exclamation-circle',
        iconColor: 'ib-color-danger',
        tooltip: 'error'
    };

    private static __exclamationIcon = {
        id: 'exclamationIcon',
        iconName: 'fa-exclamation-square',
        iconColor: 'ib-color-danger',
        tooltip: 'warning !'
    };

    private static __favoriteIcon = {
        id: 'favoriteIcon',
        iconName: 'fa-heart',
        iconColor: 'ib-color-red',
        tooltip: 'favorite'
    };

	private static __favoritesIcon = {
        id: 'favoritesIcon',
        iconName: 'fa-circle-heart',
        iconColor: 'ib-color-red',
        tooltip: 'favorite'
    };

	private static __fieldIcon = {
        id: 'field',
        iconName: 'fa-input-pipe',
        iconColor: 'ib-color-input',
        tooltip: 'field'
    };


	private static __folderIcon = {
        id: 'folder',
        iconName: 'fa-folder',
        iconColor: 'ib-color-folder',
        tooltip: 'folder'
    };

	private static __folderOpenedIcon = {
        id: 'folderOpened',
        iconName: 'fa-folder-open',
        iconColor: 'ib-color-folder',
        tooltip: 'folder'
    };

	private static __folderImageIcon = {
        id: 'folderImage',
        iconName: 'fa-folder-open',
        iconColor: 'ib-color-folder',
        tooltip: 'image folder'
    };

	private static __folderPlusIcon = {
        id: 'folderPlus',
        iconName: 'fa-folder-plus',
        iconColor: 'ib-color-folder',
        tooltip: 'add to folder'
    };

	private static __folderHeartIcon = {
        id: 'folderHeart',
        iconName: 'fa-folder-heart',
        iconColor: 'ib-color-folder',
        tooltip: 'favorites folder'
    };

	private static __genderFemaleIcon = {
        id: 'genderFemaleIcon',
        iconName: 'fa-venus',
        iconColor: 'ib-color-female',
        tooltip: 'female'
    };

    private static __genderMaleIcon = {
        id: 'genderMaleIcon',
        iconName: 'fa-mars',
        iconColor: 'ib-color-male',
        tooltip: 'male'
    };

	private static __genderBothIcon = {
        id: 'genderBothIcon',
        iconName: 'fa-venus-mars',
        iconColor: 'ib-color-female-male',
        tooltip: 'female and/or male'
    };

	private static __genderlessIcon = {
        id: 'genderlessIcon',
        iconName: 'fa-genderless',
        iconColor: 'ib-color-genderless',
        tooltip: 'gender'
    };







    private static __globeIcon = {
        id: 'globeIcon',
        iconName: 'fa-globe',
        iconColor: 'ib-color-globe',
        tooltip: 'globe'
    };

    private static __guideIcon = {
        id: 'guideIcon',
        iconName: 'fa-book',
        iconColor: 'ib-color-guide',
        tooltip: 'guide'
    };

    private static __helpIcon = {
        id: 'helpIcon',
        iconName: 'fa-question-circle',
        iconColor: 'ib-color-help',
        tooltip: 'help'
    };

    private static __homeIcon = {
        id: 'homeIcon',
        iconName: 'fa-home',
        iconColor: 'ib-color-home',
        tooltip: 'home',
        captionText: 'Home'
    };

	private static __iBreederIcon = {
        id: 'iBreederIcon',
        iconName: 'fa-paw-alt',
        iconColor: 'ib-color-iBreeder',
        tooltip: 'iBreeder',
    };

	private static __idIcon = {
        id: 'idIcon',
        iconName: 'fa-passport',
        iconColor: 'ib-color-id',
        tooltip: 'id'
    };

    private static __identifierIcon = {
        id: 'identifierIcon',
        iconName: 'fa-tag',
        iconColor: 'ib-color-text',
        tooltip: 'identifier'
    };

	private static __imageIcon = {
        id: 'imageIcon',
        iconName: 'fa-image-landscape',
        iconColor: 'ib-color-image',
        tooltip: 'image'
    };

    private static __imagesIcon = {
        id: 'imagesIcon',
        iconName: 'fa-images',
        iconColor: 'ib-color-image',
		captionText: 'Images',
        tooltip: 'images'
    };

	private static __infoIcon = {
        id: 'infoIcon',
        iconName: 'fa-info-circle',
        iconColor: 'ib-color-info',
		captionText: 'Info',
        tooltip: 'Info'
    };

	private static __listIcon = {
        id: 'listIcon',
        iconName: 'fa-list-ul',
        iconColor: 'ib-rectangle-list',
		captionText: 'list',
        tooltip: 'list'
    };

    private static __listsIcon = {
        id: 'listsIcon',
        iconName: 'fa-list-ul',
        iconColor: 'ib-color-list',
		captionText: 'lists',
        tooltip: 'lists'
    };

	private static __magicWandIcon = {
        id: 'magicWandIcon',
        iconName: 'fa-wand-magic',
        iconColor: 'ib-color-magic',
        tooltip: 'magic wand'
    };

	private static __mapIcon = {
        id: 'mapIcon',
        iconName: 'fa-map',
        iconColor: 'ib-color-location',
		captionText: 'map',
        tooltip: 'map'
    };

	private static __mediaIcon = {
        id: 'mediaIcon',
        iconName: 'fa-photo-film',
        iconColor: 'ib-color-media',
        tooltip: 'images or video clips'
    };

    private static __menuIcon = {
        id: 'menuIcon',
        iconName: 'fa-bars',
        iconColor: 'ib-color-menu-icon',
        captionText: 'Menu',
        captionColor: 'ib-color-menu-text',
        captionSize: 'sm',
        tooltip: 'main menu',
        state: 'normal'
    };

    private static __messagesIcon = {
        id: 'messagesIcon',
        iconName: 'fa-comments',
        iconColor: 'ib-color-message',
        captionText: 'Messages',
        captionColor: 'ib-color-menu-text',
        captionSize: 'xs',
        tooltip: 'my messages',
        state: 'normal'
    };

    private static __mobileIcon = {
        id: 'mobileIcon',
        iconName: 'fa-mobile-alt',
        iconColor: 'ib-color-phone',
        tooltip: 'SMS capable'
    };

    private static __myAccountIcon = {
        id: 'myAccountIcon',
        iconName: 'fa-user-circle',
        iconColor: 'ib-color-account',
        captionText: 'My Account',
        captionColor: 'ib-color-menu-text',
        captionSize: 'xs',
        state: 'normal'
    };

	private static __nameIcon = {
        id: 'nameIcon',
        iconName: 'fa-id-badge',
        iconColor: 'ib-color-user:',
        tooltip: 'name'
    };

	private static __navigateIcon = {
        id: 'navigateIcon',
        iconName: 'fa-fa-location-arrow',
        iconColor: 'ib-color-navigate:',
        tooltip: 'navigate'
    };

	private static __namesIcon = {
        id: 'namesIcon',
        iconName: 'fa-line-columns',
        iconColor: 'ib-color-user:',
        tooltip: 'name list'
    };

    private static __neutralIcon = {
        id: 'neutralIcon',
        iconName: 'fa-square',
        iconColor: 'ib-color-gray-700',
        tooltip: 'optional'
    };

	private static __notesIcon = {
        id: 'notesIcon',
        iconName: 'fa-notes',
        iconColor: 'ib-color-note',
        tooltip: 'notes'
    };

    private static __pageIcon = {
        id: 'pageIcon',
        iconName: 'fa-file',
        iconColor: 'ib-color-info',
		captionText: 'info page',
        tooltip: 'page'
    };

    private static __passwordIcon = {
        id: 'passwordIcon',
        iconName: 'fa-lock-keyhole',
        iconColor: 'ib-color-lock',
        tooltip: 'Password'
    };

	private static __passwordOpenIcon = {
        id: 'passwordOpenIcon',
        iconName: 'fa-lock-keyhole-open',
        iconColor: 'ib-color-lock',
        tooltip: 'Invalid Password'
    };

    private static __pawIcon = {
        id: 'pawIcon',
        iconName: 'fa-paw',
        iconColor: 'ib-color-red',
        tooltip: ''
    };

	private static __pennantIcon = {
        id: 'pennantIcon',
        iconName: 'fa-pennant',
        iconColor: 'ib-brand-success',
        tooltip: ''
    };

	private static __phoneIcon = {
        id: 'phoneIcon',
        iconName: 'fa-phone',
        iconColor: 'ib-color-phone',
        tooltip: 'Not SMS capable'
    };

    private static __playIcon = {
        id: 'playIcon',
        iconName: 'fa-play-circle',
        iconColor: 'ib-color-red',
        tooltip: 'get started'
    };

    private static __previewIcon = {
        id: 'previewIcon',
        iconName: 'fa-tv',
        iconColor: 'ib-color-preview',
        tooltip: 'preview'
    };

	private static __pricingIcon = {
        id: 'pricingIcon',
        iconName: 'fa-badge-dollar',
        iconColor: 'ib-color-dollar',
        tooltip: 'pricing'
    };

    private static __printIcon = {
        id: 'printIcon',
        iconName: 'fa-print',
        iconColor: 'ib-color-icon',
        tooltip: 'print'
    };

	private static __privacyIcon = {
        id: 'privacyIcon',
        iconName: 'fa-user-lock',
        iconColor: 'ib-color-privacy',
        tooltip: 'privacy'
    };

    private static __publishIcon = {
        id: 'publishIcon',
        iconName: 'fa-paper-plane',
        iconColor: 'ib-color-publish',
        tooltip: 'publish'
    };

	private static __requiredIcon = {
        id: 'requiredIcon',
        iconName: 'fa-star',
        iconColor: 'ib-color-required',
        tooltip: 'required'
    };

	private static __resizeIcon = {
        id: 'resizeIcon',
        iconName: 'fa-minimize',
        iconColor: 'ib-color-icon',
        tooltip: 'resize'
    };

	private static __reviewsIcon = {
        id: 'reviewsIcon',
        iconName: 'fa-thumbs-up',
        iconColor: 'ib-color-reviews',
        tooltip: 'reviews'
    };

    private static __saveIcon = {
        id: 'saveIcon',
        iconName: 'fa-save',
        iconColor: 'ib-color-save',
        tooltip: 'save item'
    };

    private static __searchIcon = {
        id: 'searchIcon',
        iconName: 'fa-search',
        iconColor: 'ib-color-orange',
		captionText: 'Search',
        tooltip: 'search'
    };

	private static __shareIcon = {
        id: 'shareIcon',
        iconName: 'fa-share-from-square',
        iconColor: 'ib-color-icon',
		captionText: 'Share',
        tooltip: 'share'
    };

    private static __showIcon = {
        id: 'showIcon',
        iconName: 'fa-tv',
        iconColor: 'ib-color-iBreeder',
		captionText: 'dog iShow',
        tooltip: 'on-line dog show'
    };

    private static __spinnerIcon = {
        id: 'spinnerIcon',
        iconName: 'fa-spinner',
        iconColor: 'ib-color-gray-600',
        tooltip: 'working'
    };

    private static __sortByDateIcon = {
        id: 'sortByDateIcon',
        iconName: 'fa-calendar',
        iconColor: 'ib-color-sort',
        tooltip: 'sort by date'
    };

    private static __sortByLocationIcon = {
        id: 'sortByLocationIcon',
        iconName: 'fib-color-sort',
        tooltip: 'sort by location'
    };

    private static __sortAlphaIcon = {
        id: 'sortAlphaIcon',
        iconName: 'fa-sort-alpha-up',
        iconColor: 'ib-color-sort',
        tooltip: 'sort alpha'
    };

    private static __sortIndexIcon = {
        id: 'sortIndexIcon',
        iconName: 'fa-sort-numeric-up',
        iconColor: 'ib-color-sort',
        tooltip: 'sort by index'
    };

    private static __supportIcon = {
        id: 'supportIcon',
        iconName: 'fa-life-ring',
        iconColor: 'ib-color-support',
        tooltip: 'iBreeder Support'
    };

	private static __toggleOffIcon = {
        id: 'toggleOffIcon',
        iconName: 'fa-toggle-off',
        iconColor: 'ib-color-input',
        tooltip: 'toggle off'
    };

	private static __toggleOnIcon = {
        id: 'toggleOnIcon',
        iconName: 'fa-toggle-on',
        iconColor: 'ib-color-input',
        tooltip: 'toggle on'
    };

	private static __trashIcon = {
        id: 'trashIcon',
        iconName: 'fa-trash',
        iconColor: 'ib-color-gray-300',
        tooltip: 'Remove'
    };


	private static __uploadIcon = {
        id: 'uploadIcon',
        iconName: 'fa-cloud-arrow-up',
        iconColor: 'ib-color-cloud',
        tooltip: 'upload to cloud'
    };

	private static __videosIcon = {
        id: 'videosIcon',
        iconName: 'fa-film',
        iconColor: 'ib-color-video',
        tooltip: 'list of videos'
    };

	private static __visitorIcon = {
        id: 'visitorIcon',
        iconName: 'fa-universal-access',
        iconColor: 'ib-color-user',
        tooltip: 'visitor'
    };


	private static __xmarkIcon = {
        id: 'xmarkIcon',
        iconName: 'fa-xmark',
        iconColor: 'ib-color-gray-500',
        tooltip: 'close'
    };

    private static __yearIcon = {
        id: 'yearIcon',
        iconName: 'fa-calendar-day',
        iconColor: 'ib-color-calendar',
        tooltip: 'year'
    };

	private static __zipCodeIcon = {
        id: 'zipCodeIcon',
        iconName: 'fa-rectangle-barcode',
        iconColor: 'ib-color-gray-700',
        tooltip: 'zip code'
    };



}
