import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Sizes } from '@common/classes/types';
import { ibCoreService, ResizeData } from '@common/services/core-services/core.service';


@Component({
  selector: 'ib-footer-bottom',
  templateUrl: './footer-bottom.component.html',
  styleUrls: ['./footer-bottom.component.scss']
})
export class ibFooterBottomComponent implements OnInit {

    public year: string;
    private size: Sizes = 'md';

    constructor( private router: Router,
                 private coreService: ibCoreService ) {
        this.year = (new Date()).getFullYear().toString();
    }

  ngOnInit() {
      this.coreService.resizeInfo$.subscribe((info: ResizeData) => {
          if (info) { this.size = info.size; }
      });
  }

  onClick(e: string) {
    const url = this.getUrl(e);
    if (url) { this.router.navigate([url]); }
  }

  get copyright(): string {
    	return (this.size <= 'sm') ? `\u00A9 20012-${this.year} iBreeder Inc.` : `\u00A9 20012-${this.year} iBreeder Inc. or its affiliates`;
  }
  get terms(): string { return (this.size <= 'md') ? "Terms" : "Conditions of Use"; }
  get map(): string { return (this.size <= 'md') ? "Map" : "Site map"; }

  private getUrl(path: string): string {
    switch (path.toLowerCase().trim()) {
        case 'copyright': return 'Main/Copyright';
        case 'privacy': return 'Main/Privacy';
        case 'terms': return 'Main/Terms';
        case 'sitemap': return 'Main/SiteMap';
        case 'opportunities': return 'Main/Relations';
        default: return '';
    }

  }

}
