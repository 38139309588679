import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common'

import { ibCoreService, Subscriptions } from '@common/services/core-services/core.service';
import { ibIdentityService } from '@common/identity/identity.service';
import { ibIcon } from '@common/classes/icon';
import { Icons } from '@common/classes/icons';
import { ibError } from '@common/classes/ib-error';
import { Roles } from '@common/identity/role';
import { Sizes } from '@common/classes/types';
import { IconList } from '@common/classes/iconList';

@Component({
  selector: 'ib-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss']
})
export class ibPublicHeaderComponent implements OnInit, OnDestroy {

	private subscriptions: Subscriptions
	public size: Sizes;
	public headerHeight: string;
	public isAuthenticated: boolean = false;
	public progressState = '';

	public menuItems: IconList;
	private myDogsIcon: ibIcon;
	public status: string;

	constructor(private router: Router,
				private location: Location,
				private identityService: ibIdentityService,
				private coreService: ibCoreService) {

		this.subscriptions = new Subscriptions();
		this.size = 'md';
		this.buildToolbar();
		this.status = 'loading...';
	}

	ngOnInit(): void {

		// monitor Identity Changes
		this.subscriptions.addSubscription('identity', this.identityService.isAuthenticated$.subscribe((authenticated: boolean) => {
			this.isAuthenticated = authenticated;
			this.buildToolbar();
		}));

		// monitor and update resize  TO BE CHECKED
		// this.subscriptions.add('resize', this.coreService.resizeInfo$.subscribe((data: ResizeData) => {
		// 	if (data && this.myDogsIcon) { this.myDogsIcon.isHidden = (data.width < 571); }
		// 	this.headerHeight = `${data.headerHeight}px`;
		// }));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

  	onLogoClick() {
		this.router.navigate(['/Home']);   // clicking on logo returns to Home
	}

	onToolbarClick(event: string): void {

		let url = '';
		let skipLocation = false;
		if (!event) { return; }
		event = event.replace('Icon', '');
		switch (event) {
			case 'menu':
				if (this.router.url === '/Menu') {
					let url = this.coreService.previousUrl;
					if (!url) {
						this.router.navigate([ '/home' ]);
					} else if (url.includes('/home')) {
						this.router.navigate([ url ]);
					} else if (url.endsWith('design')) {
						this.router.navigate([ '/admin/home' ]);
					} else if (url==='/') {
						this.router.navigate([ '/Home' ]);
					} else if (this.coreService.returnUrl) {
						url = this.coreService.returnUrl;
						this.coreService.returnUrl = '';
						this.router.navigate([ url ]);
					} else {
						this.location.back();
					}
					return;
				} else {
					skipLocation = true;
					url='/Menu';
				}
				break;
			case 'registration':
				url = '/Identity/Registration';
				break;
			case 'signIn':
				url = '/Identity/SignIn'; // + '?returnUrl=/';
				break;
			case 'myDogs':
				if (this.identityService.isInRole(['visitor'])) {
					url = '/Favorites/MyDogs';
				} else if (this.identityService.isInRole(['visitor'])) {
					url = 'Account/MyDogs';
				}
				break;
			case 'messages':
				url = '/Comms/Messages';
				break;
			case 'myAccount':
				const user = this.identityService.currentUser;
				if (!user) {
					url='Identity/SignIn';
				} else {
					const topRole = user.roles.topRole.precedenceIndex;

					if (topRole >= Roles.precedence('iBreeder.owner')) {
						url = 'iBreeder/Home';
					} if (topRole >= Roles.precedence('iBreeder.admin')) {
						url = 'iBreeder/Home';
					} if (topRole >= Roles.precedence('iBreeder.user')) {
						url = 'iBreeder/Home';
					} if (topRole >= Roles.precedence('breeder.owner')) {

					} if (topRole >= Roles.precedence('breeder.admin')) {

					} if (topRole >= Roles.precedence('breeder.user')) {

					}
					if (topRole >= Roles.precedence('visitor')) {
						// url = 'Visitor/Home'
					} if (topRole >= Roles.precedence('dog-owner')) {

					}
					if (this.identityService.isInRole(['visitor'])) {
						url = 'Visitor/Home';
					}

					const type = user.roles.topRole.userType;
					switch(type) {
						case 'iBreeder':
							this.coreService.setSiteMode('iBreeder');
							break;
						case 'breeder':
							this.coreService.setSiteMode('breeder');
							break;
						case 'dog-owner':
							this.coreService.setSiteMode('dog-owner');
							break;
						case 'visitor':
							this.coreService.setSiteMode('visitor');
							break;
						default:
							this.coreService.setSiteMode('guest');
					}
				}
				break;
			default:
				alert('not found: ' + event);
				url='';
		}

		if (url) {
			this.coreService.startProgress();
			this.router.navigate([url], { skipLocationChange: skipLocation }).catch((reason: any) => {
				this.coreService.showError(ibError.create(`Error navigation to ${url}: ${reason}`))
			}).finally(() => {
				this.coreService.endProgress();
			});
		}

	}

	onMenuClick() {
 		this.router.navigate(['Menu'], { skipLocationChange: true });
 	}

	onSearchChange(event: any) {
		// navigation to lists - no history
		// this.router.navigate([event], { skipLocationChange: true });
		this.router.navigate([event]);
	}

	showToolbar(): boolean { return this.size > 'sm'; }
	showSearch(): boolean { return this.size > 'sm'; }


	private buildToolbar(): void {

		this.menuItems = new IconList('xxl', 'xs');
		const menuIcon = Icons.generalIcon('menu', 'solid', 'xxl', 'xs');

		if (!this.isAuthenticated) {

			const registerIcon = Icons.authIcon('registration', 'light', 'xl');
			const loginIcon = Icons.authIcon('signIn', 'light', 'xl');

			this.menuItems.add(registerIcon);
			this.menuItems.add(loginIcon);

		} else {

			this.myDogsIcon = Icons.publicIcon('myDogs', 'regular', 'lg');
			const messagesIcon = Icons.generalIcon('messages', 'regular', 'lg');
			const myAccountIcon = Icons.generalIcon('myAccount', 'solid', 'lg');
			myAccountIcon.tooltip = this.identityService.currentUser?.fullName;
			this.menuItems.add(this.myDogsIcon);
			this.menuItems.add(messagesIcon);
			this.menuItems.add(myAccountIcon);
		}

		this.menuItems.add(menuIcon);

	}
}



// import { Component, OnDestroy, OnInit } from '@angular/core';
// import { Router } from '@angular/router';

// import { ibIdentityService } from '../../../common/identity/identity.service';
// // import { ibProgressService } from '../../../common/services/core-services/progress.service';
// // import { ibResizeService, ResizeData, Sizes } from '../../../common/services/core-services/resize.service';

// import { IdentityState, User } from '../../../common/identity/types';
// import { ibCoreService, Subscriptions, ResizeData } from '../../../common/services/core-services/core.service';
// import { IconSizes, IconTypes } from '../../../common/classes/ui';
// import { Role, RoleEnum } from '../../../common/identity/role';
// import { ibIcon } from 'common/classes/icon';
// import { TextSizes, Sizes } from 'common/classes/enums';



// @Component({
//   selector: 'ib-public-header',
//   templateUrl: './public-header.component.html',
//   styleUrls: ['./public-header.component.scss']
// })
// export class PublicHeaderComponent implements OnInit, OnDestroy {

// 	public subs: Subscriptions;
// 	public isAuthenticated: boolean | null;
// 	public progressState = '';
// 	public size: Sizes;
// 	public menuItems: Array<ibIcon>;
// 	public progressIcon: ibIcon;
// 	public menuIcon: ibIcon;

// 	constructor(
// 		private identityService: ibIdentityService,
// 		private coreService: ibCoreService,
// 		// private resizeService: ibResizeService,
// 		private router: Router
// 	) {
// 		this.subs = new Subscriptions();
// 		this.isAuthenticated = null;
// 		this.size = Sizes.md;
// 		this.menuItems = new Array<ibIcon>();

// 		this.menuIcon = new ibIcon({
// 			id: 'menu',
// 			iconName: 'fa-bars',
// 			iconColor: 'ib-color-navy',
// 			iconType: IconTypes.regular,
// 			iconSize: IconSizes.xxl,
// 			captionText: 'menu',
// 			captionColor: 'ib-color-menu-text',
// 			captionSize: TextSizes.sm,
// 			tooltip: 'main menu',
// 			state: 'normal'
// 		});

// 		this.progressIcon = new ibIcon({
// 			id: 'progress',
// 			iconName:'fa-spinner',
// 			iconColor: 'ib-color-gray-400',
// 			iconSize: IconSizes.xl,
// 			iconCanSpin: true,
// 			tooltip: 'loading...'
// 		});
// 	}

// 	ngOnInit() {

// 		// monitor Identity Changes
// 		this.subs.add('identity', this.identityService.state.subscribe((state: IdentityState) => {
// 			if (this.isAuthenticated === null || (state && state.isAuthenticated() !== this.isAuthenticated)) {
// 				this.isAuthenticated = state.isAuthenticated();
// 				this.buildToolbar();
// 			}
// 		}));

// 		// monitor and update progress
// 		this.subs.add('progress', this.coreService.progressChange.subscribe(
// 			((e: string) => {
// 			this.progressState = e;
// 			})
// 		));

// 		// monitor and update resize
// 		this.subs.add('resize', this.coreService.resizeInfo.subscribe((data: ResizeData) => {
// 			if (data) { this.size = data.size; }
// 		}));

// 		this.buildToolbar();

// 	}

// 	ngOnDestroy() {
// 		this.subs.unsubscribe();
// 	}

// 	// goLogin() {
// 	//     this.router.navigateByUrl('Login?returnUrl=/');
// 	// }

// 	// logout() {
// 	//    this.securityService.logoutAsync();
// 	// }



// 	onLogoClick() {
// 		this.router.navigate(['/Home']);
// 	}

// 	onToolbarClick(event: string) {
// 		let url = '';

// 		switch (event) {
// 			case 'Register':
// 				url = event;
// 				break;
// 			case 'SignIn':
// 				url = event; // + '?returnUrl=/';
// 				break;
// 			case 'MyDogs':
// 				url = 'Main/MyDogs';
// 				break;
// 			case 'MyMessages':
// 				url = 'Main/MyMessages';
// 				break;
// 			case 'MyAccount':
// 				const user: User | null = this.identityService.user;
// 				if (!user) {
// 					url = 'Register';
// 				} else {
// 					if (user.isInRole(new Role(RoleEnum.iBreederUser))) {
// 						url = 'iBreeder/Account';
// 					}  else
// 					if (user.isInRole(new Role(RoleEnum.iBreederAdmin))) {
// 						url = 'iBreeder/Account';
// 					}  else
// 					if (user.isInRole(new Role(RoleEnum.iBreederOwner))) {
// 						url = 'iBreeder/Account';
// 					}  else
// 					if (user.isInRole(new Role(RoleEnum.BreederUser))) {
// 						url = 'Breeder/Admin/Home';
// 					} else
// 					if (user.isInRole(new Role(RoleEnum.BreederAdmin))) {
// 						url = 'Breeder/Admin/Home';
// 					} else
// 					if (user.isInRole(new Role(RoleEnum.BreederOwner))) {
// 						url = 'Breeder/Admin/Home';
// 					} else
// 					if (user.isInRole(new Role(RoleEnum.Visitor))) {
// 						url = 'Main/MyAccount';
// 					} else {
// 						url = '';
// 					}
// 				}
// 				break;
// 			default:
// 		}

// 		// Progress caused problems when loading tree in image viewer
// 		// this.progressService.StartProgress();
// 		this.router.navigate([url]).then(() => {
// 			// setInterval(() => { this.progressService.EndProgress(); }, 50);
// 		}).catch((err) => {
// 			throw new Error(err);
// 		});
// 		// .finally(() => {
// 		// 	setInterval(() => { this.progressService.EndProgress(); }, 50);
// 		// });
// 	}

// 	onMenuClick(e: any) {
// 		this.router.navigate(['MainMenu'], { skipLocationChange: true });
// 	}

// 	onSearchChange(event: string) {
// 		// navigation to lists - no history
// 		// this.router.navigate([event], { skipLocationChange: true });
// 		this.router.navigate([event]);
// 	}

// 	showToolbar(): boolean { return this.size > Sizes.md; }

// 	private buildToolbar(): void {

// 		this.menuItems = new Array<ibIcon>();

// 		if (!this.isAuthenticated) {

// 			const registerIcon = new ibIcon({
// 				id: 'Register',
// 				iconName: 'fa-user-plus',
// 				iconColor: 'ib-color-venice',
// 				iconType: IconTypes.light,
// 				iconSize: IconSizes.xxl,
// 				captionText: 'Join Us',
// 				captionColor: 'ib-color-menu-text',
// 				captionSize: TextSizes.sm,
// 				tooltip: 'register with iBreeder',
// 				state: 'normal'
// 			});

// 			const loginIcon = new ibIcon({
// 				id: 'SignIn',
// 				iconName: 'fa-sign-in',
// 				iconColor: 'ib-color-red',
// 				iconType: IconTypes.light,
// 				iconSize: IconSizes.xxl,
// 				captionText: 'Sign In',
// 				captionColor: 'ib-color-menu-text',
// 				captionSize: TextSizes.sm,
// 				tooltip: 'sign in',
// 				state: 'normal'
// 			});

// 			this.menuItems.push(registerIcon);
// 			this.menuItems.push(loginIcon);

// 		} else {

// 			const myDogsIcon = new ibIcon({
// 				id: 'MyDogs',
// 				iconName: 'fa-heart',
// 				iconColor: 'ib-color-iBreeder',
// 				iconType: IconTypes.solid,
// 				iconSize: IconSizes.xl,
// 				captionText: 'My Dogs',
// 				captionColor: 'ib-color-menu-text',
// 				captionSize: TextSizes.xs,
// 				tooltip: 'my favorites',
// 				state: 'normal'
// 			});

// 			const messagesIcon = new ibIcon({
// 				id: 'MyMessages',
// 				iconName: 'fa-comments',
// 				iconColor: 'ib-color-green',
// 				iconType: IconTypes.light,
// 				iconSize: IconSizes.xl,
// 				captionText: 'Messages',
// 				captionColor: 'ib-color-menu-text',
// 				captionSize: TextSizes.xs,
// 				tooltip: 'my messages',
// 				state: 'normal'
// 			});

// 			const id = this.identityService.userOrAccountName;
// 			const myAccountIcon = new ibIcon({
// 				id: 'MyAccount',
// 				iconName: 'fa-user-circle',
// 				iconColor: 'ib-color-navy',
// 				iconType: IconTypes.solid,
// 				iconSize: IconSizes.xl,
// 				captionText: 'Account',
// 				captionColor: 'ib-color-menu-text',
// 				captionSize: TextSizes.xs,
// 				tooltip: `Account - ${id}`,
// 				state: 'normal'
// 			});


// 			this.menuItems.push(myDogsIcon);
// 			this.menuItems.push(messagesIcon);
// 			this.menuItems.push(myAccountIcon);

// 			this.coreService.EndProgress();

// 		}
// 	}
// }

