// Social media Info


// Holds the user login information
export class SocialMediaInfo {

	public static assign(source: Record<string, any>): SocialMediaInfo {

		const t = new SocialMediaInfo();

		if ('name' in source) {
			t.name = (<any> source)['name'];
		} else {
			throw Error('\'name\' is required');
		}

		if ('username' in source) {
			t.name = (<any> source)['usernameName'];
		} else {
			throw Error('\'username\' is required');
		}

		if ('password' in source) {
			t.name = (<any> source)['password'];
		} else {
			throw Error('\'password\' is required');
		}

		return t;
	}

	public name: string = '';
	public username: string = '';
	public password: string = '';
}
