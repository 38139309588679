// ======================================================
// tree loader Service
// ======================================================
// Singleton
// ======================================================================================================
// provides functions used to retrieve data and return a list of tree nodes

import { Injectable, OnDestroy } from "@angular/core";
import { Defaults } from "@common/classes/defaults";
import { ibNode } from "@common/classes/ui/trees/ibTree";
import { BreedName, BreedNameList } from "@common/entities/parts/breed-name";
import { ibBreedService } from "../entity-services/breed.service";

@Injectable({ providedIn :'root' })
export class ibTreeLoader {


	constructor() {
	}

	public load(node: ibNode, ...args: string[]): Promise<Array<ibNode>> {
		const name = node.fetch;
		if (name && name in this) {
			const fn = (<any>this)[name];
			return fn(node, args);
		} else {
			throw new Error('ibTreeLoader load - "name" not found');
		}
	}

	private getBreeds(node: ibNode, ...args: string[]): Promise<Array<ibNode>> {
		return new Promise((resolve, reject) => {
			const list = new Array<ibNode>();
			const breedService = Defaults.injectorInstance.get<ibBreedService>(ibBreedService);
			breedService.getBreedNames()
			.then((nameList: BreedNameList) => {
				nameList.officialNames.forEach((name: BreedName) => {
					list.push(name.toNode(node));
				});
				resolve(list);
			})
			.catch((error: any) => {
				reject('Get Breeds Error: ' + error);
			});
		});

	}

}

