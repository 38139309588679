import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ibBreederService } from '@common/services/entity-services/breeder.service';
import { Defaults } from 'common/classes/defaults';
import { BreederDocument } from '../documents/breeder-document';
import { BreedStub } from './breed-stub';
import { LocationSummary } from './address';
import { AccountStatuses, BreederCommitmentLevel, BreederRating } from 'common/classes/enums';
import { Coordinates } from 'common/classes/lib';
import { IKey } from '@common/classes/interfaces';

export class BreederStub implements IKey {

	static assign(source: Record<string, any>, target: BreederStub | null = null): BreederStub {

		let id: string;

		if ('id' in source) {
			id = (<any> source)['id'];
		} else {
			throw new Error('id is required');
		}

		let accountID: string;
		if ('accountID' in source) {
			accountID = (<any> source)['accountID'];
		} else {
			throw new Error('AccountID is required');
		}

		let name: string;
		if ('name' in source) {
			name = (<any> source)['name'];
		} else {
			throw new Error('Name is required');
		}

		if (!target) {
			target = new BreederStub(id, accountID, name);
		} else {
			target.id = id;
		}

		if ('shortName' in source) {
			target.shortName = (<any> source)['shortName'];
		}

		if ('kennelName' in source) {
			target.kennelName = (<any> source)['kennelName'];
		}

		if ('status' in source) {
			target.status = (<any> source)['status'];

		} else {
			throw new Error('Status is required');
		}
		if ('defaultImage' in source) {
			target.defaultImage = (<any> source)['defaultImage'];
		}

		return target;
	}

	constructor(id: string, accountID: string, name: string) {
		this.id = id;
		this.accountID = accountID;
		this.name = name;
		this.shortName = '';
		this.kennelName = '';
		this.status = '';
		this.defaultImage = '';
	}


	public id: string;
	public accountID: string;
	public name: string;
	public shortName: string | null;
	public kennelName: string | null;
	public status: string | null;
	public defaultImage: string | null;

	public toKey(): string { return this.accountID; }

}


export class BreederListItem {

	private static breederService: ibBreederService;

	// public static create(accountID: string): Observable<BreederListItem | undefined> {
	// 	if (!BreederListItem.breederService) {
	// 		BreederListItem.breederService = Defaults.injectorInstance.get<ibBreederService>(ibBreederService);
	// 	}
	// 	const res = BreederListItem.breederService.getBreeder(accountID).pipe(
	// 		take(1),
	// 		map((doc: BreederDocument | null) => {
	// 		if (doc) { return new BreederListItem(doc); } else { return undefined; }
	// 	}));

	// 	return res;
	// }

	// public static fromStub(stub: BreederStub): Observable<BreederListItem | undefined> {

	// 	if (!BreederListItem.breederService) {
	// 		BreederListItem.breederService = Defaults.injectorInstance.get<ibBreederService>(ibBreederService);
	// 	}
	// 	const res = BreederListItem.breederService.getBreeder(stub.accountID).pipe(
	// 		take(1),
	// 		map((doc: BreederDocument | null) => {
	// 			if (doc) { return new BreederListItem(doc); } else { return undefined; }
	// 	}));

	// 	return res;

	// }

	public static assign(source: Record<string, any>): BreederListItem {

		const item = new BreederListItem();

		if ('accountID' in source) {
			item.accountID = (<any> source)['accountID'];
		} else {
			throw new Error('AccountID is required');
		}

		if ('name' in source) {
			item.name = (<any> source)['name'];
		} else {
			throw new Error('Name is required');
		}

		if ('shortName' in source) {
			item.shortName = (<any> source)['shortName'];
		}

		if ('kennelName' in source) {
			item.kennelName = (<any> source)['kennelName'];
		}

		if ('image' in source) {
			item.image = (<any> source)['image'];
		}

		// breeds
		if ('breeds' in source) {
			const s = <Array<any>> (<any> source)['breeds'];
			s.forEach((v) => { item.breeds.push(BreedStub.assign(v)); });
		}

		if ('status' in source) {
			item.status = (<any> source)['status'];
		} else {
			item.status = AccountStatuses.Unknown;
		}

		if ('distance' in source) {
			item.location.distance = (<any> source)['distance'];
		}

		if ('city' in source) {
			item.location.city = (<any> source)['city'];
		}

		if ('region' in source) {
			item.location.region = (<any> source)['region'];
		}

		if ('coordinates' in source) {
			const c: Array<number> =  (<any> source)['coordinates'];
			if (c && Array.isArray(c) && c.length === 2) {
				item.location.coordinates = new Coordinates(c[0], c[1]);
			} else {
				throw new Error('Invalid coordinates');
			}
		}

		return item;
	}



	constructor(breeder?: BreederDocument) {
		if (breeder) {
			this.accountID = breeder.accountID;
			this.document = breeder;
			this.name = breeder.name;
			this.shortName = (breeder.shortName) ? breeder.shortName : undefined;
			this.kennelName = (breeder.kennelName) ? breeder.kennelName : undefined;
			this.image = undefined;
			this.breeds = breeder.breeds;
			this.status = breeder.status;
			// this.location = (breeder.mainAddress) ? LocationSummary.create(breeder.mainAddress) : new LocationSummary();
			this.location = new LocationSummary();
			// ToDo complete creation
		} else {
			this.status = AccountStatuses.Unknown;
			this.breeds = new Array<BreedStub>();
			this.location = new LocationSummary();
		}
		this.active = false;
	}

	public accountID: string = '';
	public document?: BreederDocument;
	public name: string = '';
	public shortName?: string;
	public kennelName?: string;
	public image?: string;
	public breeds: Array<BreedStub>;
	public status: AccountStatuses;
	public location: LocationSummary;
	public isChampion: boolean = false;
	public acceptsQCheck: boolean = false;
	public commitmentLevel: BreederCommitmentLevel = BreederCommitmentLevel.noCommitment;
	public rating: BreederRating = BreederRating.notRated;
	public availableMales: number = 0;
	public availableFemales: number = 0;
	public availableLitters: number = 0;
	public active: boolean;

}
