// import { Banner, BreederBanner, BreedBanner, DogBanner, LitterBanner } from 'common/classes/banner';
import { BreedName } from '../parts/breed-name';
import { Defaults, SpecialValuesGUID } from '../../classes/defaults';
import { Tools } from '../../tools';
import { ImageSizes, ImageStatus } from '../../classes/enums';
import { Property } from '../parts/property';
import { ibDocument } from './ib-document';
import { OwneriBreeder, OwnerTypes } from '../parts/owner';
import { BreedStub } from '@entities/parts/breed-stub';
import { of, Observable } from 'rxjs';
import { BreederStub } from '../parts/breeder-stub';
import { DogDocument } from './dog-document';
import { ibBreedService } from '../../services/entity-services/breed.service';
import { Banner, BreedBanner } from '@common/classes/banner';

export enum ImageExtensions {
		jpg = 100,           // jpeg image
		png = 101            // png image
}

export class ImageMetaDocument extends ibDocument {

	// assign JSon Object to a typed ImageMeta class
	// only properties existing in Breeder Class are assigned - there is no type check
	static assign(source: object, target: ImageMetaDocument | null = null, full: boolean = true): ImageMetaDocument {

		let id = Defaults.SpecialGuid(SpecialValuesGUID.Empty);
		if ('id' in source) {
			id = (<any> source)['id'];
		} else if ('imageUID' in source) {
			id = (<any> source)['imageUID'];
		}

		if (!target) {
			target = new ImageMetaDocument(id);
			target.imageUID = id;
		}

		if ('ownerType' in source) {
			target.ownerType = (<any> source)['ownerType'];
		} else {
			if (full) { throw new Error('ownerType is required'); } else { target.ownerType = OwnerTypes.Unknown; }
		}

		if ('ownerUID' in source) {
			target.ownerUID = (<any> source)['ownerUID'];
		} else {
			if (full) { throw new Error('ownerUID is required'); } else { target.ownerUID = ''; }
		}

		if ('ownerID' in source) {
			target.ownerID = (<any> source)['ownerID'];
		} else {
			if (full) { throw new Error('ownerID is required'); } else { target.ownerID = ''; }
		}

		if ('accountType' in source) {
			target.accountType = (<any> source)['accountType'];
		} else {
			if (full) { throw new Error('accountType is required'); } else { target.accountType = 0; }
		}

		if ('accountID' in source) {
			target.accountID = (<any> source)['accountID'].trim();
		} else {
			if (full) { throw new Error('accountID is required'); } else { target.accountID = ''; }
		}

		if ('category' in source) {
			target.category = (<any> source)['category'];
		} else {
			target.category = 'self';
		}

		if ('imageName' in source) {
			target.imageName = (<any> source)['imageName'];
		} else {
			if (full) { throw new Error('ImageName is required'); } else { target.imageName = ''; }
		}

		if ('extension' in source) {
			const ext = <string> (<any> source)['extension'].trim();
			target.extension = ext.replace('.', '');
		} else {
			if (full) { throw new Error('extension is required'); } else { target.extension = ImageExtensions[ImageExtensions.jpg]; }
		}

		if ('index' in source) {
			target.index = (<any> source)['index'];
		} else {
			target.index = -1;
		}

		if ('rating' in source) {
			target.rating = (<any> source)['rating'];
		} else {
			target.rating = -1;
		}

		if ('favorite' in source) {
			target.favorite = (<any> source)['favorite'];
		} else {
			target.favorite = false;
		}

		if ('breedName' in source) {
			target.breedName = (<any> source)['breedName'];
		} else {
			target.breedName = '';
		}

		if ('breedID' in source) {
			target.breedID = (<any> source)['breedID'];
		} else {
			target.breedID = '';
		}

		if ('caption' in source) {
			target.caption = (<any> source)['caption'];
		} else {
			target.caption = '';
		}

		if ('description' in source) {
			target.description = (<any> source)['description'];
		} else {
			target.description = '';
		}

		if ('alt' in source) {
			target.alt = (<any> source)['alt'];
		} else {
			target.alt = '';
		}

		if ('location' in source) {
			target.location = (<any> source)['location'];
		} else {
			target.location = '';
		}

		if ('author' in source) {
			target.author = (<any> source)['author'];
		} else {
			target.author = '';
		}

		if ('copyright' in source) {
			target.copyright = (<any> source)['copyright'];
		} else {
			target.copyright = '';
		}

		if ('keywords' in source) {
			target.keywords = (<any> source)['keywords'];
		} else {
			target.keywords = new Array<string>();
		}

		if ('isPuppy' in source) {
			target.isPuppy = <boolean> (<any> source)['isPuppy'];
		} else {
			target.isPuppy = false;
		}

		if ('isMultipleDogs' in source) {
			target.isMultipleDogs = <boolean> (<any> source)['isMultipleDogs'];
		} else {
			target.isMultipleDogs = false;
		}

		if ('headPositionX' in source) {
			target.headPositionX = (<any> source)['headPositionX'];
		} else {
			target.headPositionX = -1;
		}

		if ('headPositionY' in source) {
			target.headPositionY = (<any> source)['headPositionY'];
		} else {
			target.headPositionY = -1;
		}

		if ('ratio' in source) {
			target.ratio = (<any> source)['ratio'];
		} else {
			target.ratio = 1;
		}

		if ('path' in source) {
			target.path = (<any> source)['path'];
		} else {
			target.path = '';
		}

		if ('bannerData' in source) {
			target.bannerData = (<any> source)['bannerData'];
		} else {
			target.bannerData = '';
		}

		if ('properties' in source) {
			target.properties = Property.create((<any> source)['properties']);
		}

		if ('publish' in source) {
			target.publish = (<any> source)['publish'];
		} else {
			target.publish = true;
		}

		if ('isDefault' in source) {
			target.isDefault = (<any> source)['isDefault'];
		} else {
			target.isDefault = false;
		}

		if ('status' in source) {
			target.status = (<any> source)['status'];
		} else {
			target.status = ImageStatus.Unknown;
		}

		return target;

	}

	constructor(id: string) {
		super('ImageDocument', id);
		this.keywords = new Array<string>();
		this.properties = new Array<Property>();
	}

	public imageUID: string = '';
	public ownerType: OwnerTypes = OwnerTypes.Blank;
	public ownerUID: string = '';
	public ownerID: string = '';
	public accountType: number = 0;
	public accountID: string = '';
	public category: string = '';
	public imageName: string = '';
	public extension: string = 'jpg';
	public index: number = 0;
	public rating?: number;
	public favorite?: boolean;
	public breedName?: string;
	public breedID?: string;
	public caption?: string;
	public description?: string;
	public alt?: string;
	public location?: string;
	public author?: string;
	public copyright?: string;
	public keywords: Array<string>;
	public isPuppy?: boolean;
	public isMultipleDogs?: boolean;
	public headPositionX?: number;
	public headPositionY?: number;
	public ratio: number = 1;
	public path?: string;
	public bannerData?: string;
	public properties: Array<Property>;
	public publish: boolean = false;
	public isDefault: boolean = false;
	public status: ImageStatus = ImageStatus.Unknown;
	public changedBy: string = 'system';

	public setImageName(newName: string) {
		this.imageName = newName;
		this.path = this.url(ImageSizes.master);
		this.changed = true;
	}

	public toStub(): string {
		const o = new Object();
		(<any> o)['ownerType'] = this.ownerType;
		(<any> o)['ownerUID'] = this.ownerUID;
		(<any> o)['ownerID'] = this.ownerID;
		(<any> o)['accountType'] = this.accountType;
		(<any> o)['accountID'] = this.accountID;
		(<any> o)['category'] = this.category;
		(<any> o)['breedName'] = this.breedName;
		(<any> o)['author'] = this.author;
		(<any> o)['copyright'] = this.copyright;
		(<any> o)['keywords'] = this.keywords;
		return JSON.stringify(o);
	}

	public blobPath(): string {
		if (!this.accountID) { return ''; }
		const aty = OwnerTypes[this.accountType].toLowerCase();
		const acc = (this.accountID.toLowerCase() === Defaults.iBreederAccountID.toLowerCase()) ? '' : this.accountID.trim().toLowerCase();
		const cat = this.category.trim().toLowerCase();
		const own = Tools.toPrettyUrl(this.ownerID);

		if (this.accountType === OwnerTypes.iBreeder) {
			if (cat === 'self') {
				return `${aty}/images/`;
			} else if (cat === 'cover') {
				return `${aty}/${cat}/images/`;
			} else {
				return `${aty}/${cat}/${own}/images/`;
			}
		} else if (this.accountType === OwnerTypes.Breeder) {
			if (cat === 'self') {
			return `${aty}/${acc}/images/`;
			} else {
			return `${aty}/${acc}/${cat}/${own}/images/`;
			}
		}
		return '';
	}

	rootName(): string {
		if (!this.accountID) { return 'picture'; }
		// const aty = OwnerTypes[this.accountType];
		// const acc = (this.accountID.toLowerCase() === Defaults.iBreederAccountID.toLowerCase())
		// ? '' : this.accountID.trim().toLowerCase();
		const cat = this.category.trim().toLowerCase();
		const own = Tools.toPrettyUrl(this.ownerID);

		if (this.accountType === OwnerTypes.iBreeder) {
			if (cat === 'self') {
				return 'picture';
			} else if (cat === 'cover') {
				if (this.breedID) {
					return this.breedID;
				} else {
					return (this.imageName) ? `${Tools.toPrettyUrl(this.imageName)}` : 'breedName';
				}
			} else {
				return `${own}`;
			}
		} else if (this.accountType === OwnerTypes.Breeder) {
			if (cat === 'self') {
			return `picture`;
			} else {
			return `${own}`;
			}
		}
		return 'picture';
	}

	public url(size: ImageSizes): string {
		const path = this.blobPath();
		let url = 'https://' + Defaults.BlobRoot + 'media/' + path + this.imageName + '-' + ImageSizes[size] + '.' + this.extension;
		url = url.toLowerCase();
		return url;
	}

	public getCopyright() {
		return '\u00a9 ' + this.copyright;
	}


}

export class ImageStub {

	static assign(source: object, target: ImageStub | null = null): ImageStub {

		if (!target) {
			target = new ImageStub();
		}

		if ('id' in source) {
			target.id = (<any> source)['id'];
		} else {
			throw new Error('image id is required');
		}

		if ('index' in source) {
			target.index = (<any> source)['index'];
		} else {
			target.index = -1;
		}

		if ('isDefault' in source) {
			target.isDefault = (<any> source)['isDefault'];
		} else {
			target.isDefault = false;
		}

		if ('ownerType' in source) {
			target.ownerType = (<any> source)['ownerType'];
		} else {
			throw new Error('ownerType is required');
		}

		if ('ownerUID' in source) {
			target.ownerUID = (<any> source)['ownerUID'];
		} else {
			throw new Error('ownerUID is required');
		}

		if ('ownerID' in source) {
			target.ownerID = (<any> source)['ownerID'];
		} else {
			target.ownerID = '';
		}

		if ('path' in source) {
			target.path = (<any> source)['path'];
		} else {
			throw new Error('path is required');
		}

		if ('caption' in source) {
			target.caption = (<any> source)['caption'];
		} else {
			target.caption = '';
		}

		if ('alt' in source) {
			target.alt = (<any> source)['alt'];
		} else {
			target.alt = '';
		}

		if ('headPositionX' in source) {
			target.headPositionX = (<any> source)['headPositionX'];
		} else {
			target.headPositionX = -1;
		}

		if ('headPositionY' in source) {
			target.headPositionY = (<any> source)['headPositionY'];
		} else {
			target.headPositionY = -1;
		}

		if ('ratio' in source) {
			target.ratio = Number((<any> source)['ratio']);
		} else {
			target.ratio = 1.0;
		}

		// if (source.hasOwnProperty('bannerData')) {
		//     target.bannerData = (<any>source)['bannerData'];
		// } else {
		//     target.bannerData = '';
		// }

		return target;
	}

	private breedService: ibBreedService;
	private _bannerData?: Banner;

	constructor() {
		this.breedService = Defaults.injectorInstance.get<ibBreedService>(ibBreedService);
	}

	public id: string = Defaults.SpecialGuid(SpecialValuesGUID.Empty);
	public index: number = 0;
	public isDefault: boolean = false;
	public ownerType: OwnerTypes = OwnerTypes.Blank;
	public ownerUID: string = Defaults.SpecialGuid(SpecialValuesGUID.Empty);
	public ownerID: string = '';
	public path?: string;
	public caption?: string;
	public alt?: string;
	public headPositionX: number = 0;
	public headPositionY: number = 0;
	public ratio: number = 1;

	public get bannerData(): Observable<Banner | undefined> {
		if (this._bannerData) { return of(this._bannerData); }
		switch (this.ownerType) {
			case OwnerTypes.Breed:
				const stub = this.breedService.getBreedStub(this.ownerID);
				if (stub) {
					const breedBanner = new BreedBanner();
					breedBanner.OwnerType = this.ownerType;
					breedBanner.OwnerUID = this.ownerUID;
					breedBanner.OwnerID = this.ownerID;
					breedBanner.breedName = stub.breedName;
					breedBanner.caption = stub.caption;
					breedBanner.group = stub.group;
					breedBanner.rank = stub.rank;
					this._bannerData = breedBanner;
					return of(breedBanner);

				}
				return of(this._bannerData);

			case OwnerTypes.Breeder:
				// const breederBanner = new BreederBanner();
				break;
			case OwnerTypes.Dog:
				// const dogBanner = new DogBanner();
				break;
			case OwnerTypes.Litter:
				// const litterBanner = new LitterBanner();
				break;
			default:
				return of(undefined);
		}

		return of(undefined);


	}
}


export class DefaultImageMeta {

	// Returns an ImageMeta class initialized for the owner being a breed (always belong to iBreeder account)
	// returns iBreeder images for the breed
	static iBreeder(): ImageMetaDocument {

		const m = new ImageMetaDocument(Defaults.SpecialGuid(SpecialValuesGUID.Empty));
		const y = (new Date()).getFullYear();
		const owner = new OwneriBreeder();

		m.imageUID = m.id;
		m.ownerType = owner.ownerType;
		m.ownerUID = owner.uid;
		m.ownerID = owner.id;
		m.accountType = OwnerTypes.iBreeder;
		m.accountID = Defaults.iBreederAccountID;
		m.category = 'self';
		m.imageName = '';
		m.extension = ImageExtensions[ImageExtensions.jpg];
		m.index = -1;
		m.rating = -1;
		m.favorite = false;
		m.breedName = '';
		m.breedID = '';
		m.caption = '';
		m.description = '';
		m.alt = 'iBreeder Image';
		m.author = 'iBreeder Inc., affiliates, assigns or licensors';
		m.isPuppy = false;
		m.isMultipleDogs = false;
		m.copyright = `2010 - ${y} iBreeder, Inc. and affiliates, assigns and licensors. All rights reserved.`;
		m.keywords = ['iBreeder', 'breeder', 'purebred', 'breed', 'bred', 'canine', 'dog', 'puppy', 'litter', 'sire', 'dam', 'stud'];
		m.headPositionX = -1;
		m.headPositionY = -1;
		m.ratio = 1;
		// m.path: string;
		// m.bannerData: string;
		// m.properties: string;
		m.publish = true;
		m.status = ImageStatus.Unknown;

		return m;
	}

	static Cover(): ImageMetaDocument {
		const m = DefaultImageMeta.iBreeder();
		m.category = 'cover';
		return m;
	}

	static Breed(breed: BreedName): ImageMetaDocument {

		const m = DefaultImageMeta.iBreeder();

		m.ownerType = OwnerTypes.Breed;
		m.ownerUID = breed.breedUID;
		m.ownerID = breed.breedID;
		m.category = 'breeds';
		m.breedName = breed.breedName;
		m.breedID = breed.breedID;
		m.caption = `${breed.breedName} image`;
		m.alt = `${breed.breedName} image`;
		m.keywords.push(breed.breedName);

		return m;
	}

	static Breeder(breeder: BreederStub, breed?: BreedStub): ImageMetaDocument {

		const m = DefaultImageMeta.iBreeder();

		m.ownerType = OwnerTypes.Breeder;
		m.ownerUID = breeder.id;
		m.ownerID = breeder.accountID;
		m.category = 'breeds';
		m.breedName = (breed) ? breed.breedName : '';
		m.breedID = (breed) ? breed.breedID : '';
		m.caption = `${breeder.name} image`;
		m.alt = `${breeder.name} image`;
		m.keywords.push(breeder.name);
		if (breeder.kennelName) { m.keywords.push(breeder.kennelName); }

		return m;
	}

	static Dog(dog: DogDocument): ImageMetaDocument {

		if (!dog || !dog.owner) { throw new Error('The dog document is missing or invalid'); }
		const m = DefaultImageMeta.iBreeder();

		m.accountType = dog.owner.ownerType;
		m.accountID = dog.owner.id;
		m.category = 'dogs';
		m.ownerID = dog.dogID;
		if (dog && dog.litter && dog.litter.breed) {
			m.breedName = dog.litter.breed.breedName;
			m.breedID = dog.litter.breed.breedID;
		}
		m.caption = `${dog.name} image`;
		m.alt = `${dog.name} image`;
		if (dog.identification && dog.identification.kennelName) {
			m.keywords.push(dog.identification.kennelName);
		}
		return m;
	}



}


