// --------------------------------------------------------
// Search Bar component
// --------------------------------------------------------
// direct search selection buttons and view button

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ibCoreService, Subscriptions } from '@common/services/core-services/core.service';
import { SearchInfo } from '@public/search/search-info';
import { DisplayTypes } from '@common/classes/types';
import { ibIcon, IconStores } from '@common/classes/icon';
// import { SearchQuery } from '@common/classes/search';
import { IconList } from '@common/classes/iconList';
import { SearchBarState, SearchInputState } from './search-bar-status';
import { ibSearchInputComponent } from '@public/search/search input/search-input.component';
import { ibCoreComponentsModule } from '@common/components/core-components.module';

@Component({
	standalone: true,
	selector: 'ib-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss'],
	imports: [ibSearchInputComponent, ibCoreComponentsModule]
})
export class ibSearchBarComponent implements OnInit, OnDestroy {

	@Output() changed: EventEmitter<string> = new EventEmitter<string>();

	private subscriptions: Subscriptions;

	public icons: IconList;
	public info = new SearchInfo();
	public searchIcon: ibIcon;
	public state: SearchBarState;


	constructor(private coreService: ibCoreService) {

		this.subscriptions = new Subscriptions();
		this.icons = new IconList('md', 'sm');

	    this.addIcon('public', 'breed', 'breed', 'Select one or more breeds', 'ib-color-iBreeder-complement' );
	    this.addIcon('public', 'target', 'target', 'Search for ...', 'ib-color-iBreeder' );
	    this.addIcon('public', 'location', 'where', 'Where' );
	    this.addIcon('general', 'page', 'page/detail page', 'Show details' );
	    this.addIcon('general', 'show', 'iShow/iDogShow', 'on line dog iShow' );
	    this.addIcon('general', 'list', 'list', 'List the search results' );
	    this.addIcon('general', 'map', 'map', 'Show search results on a map' );
	    this.addIcon('general', 'search', 'search','Search' );

		this.icons.setIconColor('ib-color-brown');
		this.icons.setActive('show');

		this.state = new SearchBarState();
	}

  	public ngOnInit() {

		this.state.inputState.changed$.subscribe((state: SearchInputState) => {
			if (!state.searchTerm) { return; }
			if (state.state === 'start') {
				alert('start');
			}
			console.log('INPUT CHANGES ===============================');
			console.log(state);
		})


		// monitor changes in search info
		// this.subs.add('info', this.searchService.info.subscribe((_info: SearchInfo) => {
		// 	this.info = _info;
		// 	console.log('IN SEARCH BAR');
		// 	console.log(JSON.stringify(_info, null, 4));
		// 	if (this.info && this.info.action) { this.change.emit(this.info.action); }
		// }));
	}

	public ngOnDestroy() {
		if (this.subscriptions) { this.subscriptions.unsubscribe(); }
	}

	public onClick(event: string) {
		// navigation to lists handled by header
		this.changed.emit(event);
	}

	// // search input has changed and input is valid
	// public onSearchInputChanged(q: SearchInputState) {
	// 	console.log("Search Input change -----------------------");
	// 	console.log(q);
	// 	// this.searchService.setQuery(Q);
	// }

	public onViewClick(type: DisplayTypes) {
		if (type !== this.info.displayType) {
			// this.searchService.setDisplayType(type);
		}
	}

	public isDisplayButtonActive(buttonName: string): string {
		return (this.info.displayType === buttonName) ? 'active' : '';
	}

	public isDisplayButtonDisabled(buttonName: string): boolean {
		switch (buttonName) {
			case 'DogShow':
			case 'List':
				return false;
			case 'Map':
				return (this.info && this.info.location.enabled) ? false : true;
			default:
				return false;
		}
	}

	public isSearchButtonDisabled(): boolean {
		return (this.info && this.info.location.enabled) ? false : true;
	}

	public icon(id: string): string {
		const i = this.icons.get(id);
		if(i) {
			i.image.size = 'md';
			i.image.type = 'solid'
		}
		return (i) ? i.imageClasses : 'fa fa-bug ib-color-red';
	}

	// public viewIcon(id: string): ibIcon {
	// 	const i = this.icons.get(id);
	// 	if(i) {
	// 		i.image.size = 'sm';
	// 		i.image.type = 'solid';
	// 		i.caption.size = 'sm';
	// 	}
	// 	return (i) ? i : Icons.generalIcon('bug', 'solid', 'sm');
	// 	;
	// }


	public get BreedColor(): string {
		return (this.info && this.info.breed && (<string> this.info.breed.breedName).toLowerCase() ===
			SearchInfo.NoBreedString.toLowerCase())
				? 'ib-color-text' : 'ib-color-navy' ;
	}

	public get searchText(): string { return this.info.searchText; }


	private addIcon(store: IconStores, id: string, caption?: string, tooltip?: string, color?: string): void {
		const i = ibIcon.getIcon(store, id);
		if (!i) { throw new Error(`${store}/${id} icon not found - check spelling or store name`); }
		i.tooltip = tooltip;
		i.caption.showCaption = true;
		i.caption.longTextFrom = 'lg';
		i.caption.text = caption;
		if (color) { i.image.color = color; }
		this.icons.add(i);
	}

}
