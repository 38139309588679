<div id='header-container'>
	<div id='logo' class='clickable' (click)='onLogoClick()'>
		<img src=".\assets\images\ibreeder-logo-2.0.svg" data-toggle='tooltip' title='Home' >
		<div>
			<h6>The best <strong class='ib-color-green-dark'>purebreds </strong></h6>
			<h6 class='last'>directly from the best breeders</h6>
			<h6 class='last-s'>directly from their breeder</h6>
		</div>
	</div>
	<div id='search'><ib-search-bar></ib-search-bar></div>
	<div id='toolbar' *ngIf='menuItems && menuItems.length > 0'>
		<ib-toolbar [items]='menuItems' (clicked)='onToolbarClick($event)'></ib-toolbar>
	</div>
	<div id='progress'>
		<ib-progress></ib-progress>
	</div>
	<div id='status'>
		{{status}}
	</div>
</div>


<!-- for debug purposes only
<div id='size-display'>{{resizeData.sizeString}}</div>
-->
