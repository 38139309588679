


export class Property {

	public static create(source: Array<Record<string, any>>): Array<Property> {
		const props = new Array<Property>();
		for (const s of source) {
			if ('name' in s) {
				const name: string = (<any> source)['name'];
				if (name) {
					const p = Property.assign(s);
					props.push(p);
				}
			}
		}
		return props;
	}

	public static assign(source: Record<string, any>): Property {

		const p = new Property();

		if ('name' in source) {
			p.name = (<any> source)['name'];
		} else {
			throw new Error('The name is required');
		}

		if ('value' in source) {
			p.value = (<any> source)['value'];
		} else {
			p.value = undefined;
		}


		return p;
	}


	public name: string = '';
	public value: string | undefined;

}
