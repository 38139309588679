// ---------------------------------------------------------------------------
// View
// ---------------------------------------------------------------------------
// acts like a node: a view can contain views
// Views have 3 related properties: name, caption and url
// name is required and automatically formatted as a url
// if caption is not specified the name is used, formatted as a caption
// if url is not specified:
// 		if no parent - url used the name
//      if parent and parent has url the url is parentUrl/name
//		if parent and parent has no url the url is parentName/name
// by default the url is formatted in TitleCase




import { ibDocument } from "@common/entities/documents/ib-document";
import { Tools } from "@common/tools";
import { BehaviorSubject } from "rxjs";
import { ibUrl } from "./ibUrl";
import { ibIcon, IconSizes, TextSizes } from "./icon";
import { DisableAction, IconList } from "./iconList";


export class View {

	private _name: string = '';
	private _caption: string = '';
	private _rightCaption?: string;
	private _date?: string;
	private _tooltip?: string;
	private _icon?: ibIcon;
	private _tag?: any;
	private _isChecked: boolean = false;
	private _url?: string;
	private _canNavigate: boolean;
	private _active: boolean = false;
	private _highlight: boolean = false;

	private _views: Array<View>;
	private _parent?: View;
	private _defaultView?: View;
	private _toolbar: IconList;
	private _showCaption: boolean =true;
	private _isDefaultView: boolean = false;

	private _disabled: boolean = false;

	public selectionChange$: BehaviorSubject<View | undefined> = new BehaviorSubject<View | undefined>(undefined);

	constructor(config: ViewConfig) {
		this._views = new Array<View>();
		this._toolbar = new IconList();
		this._canNavigate = true;
		this.updateView(config);
		// if (this.defaultView && this.views.length <= 0) {
		// 	this._views.push(this.defaultView);
		// }
	}

	getView(name: string): View | undefined {
		if (!this.views || this.views.length < 1) { return undefined; }
		for (const v of this._views) {
			if (v._name === name) { return v; }
		}
		return undefined;
	}

	addView(config: ViewConfig, index: number = -1): View {
		// remove default view if present the new view is not selected!
		for (let i = 0; i < this.views.length; i++) {
			if (this.views[i].isDefaultView) {
				this.views.splice(i, 1);
				break;
			}
		}
		const view = new View(config);
		view._parent = this;
		if (index < 0 || index >= this.views.length) {
			this._views.push(view);
		} else {
			this._views.splice(index, 0, view);
		}
		return view;
	}

	removeView(view: View) {
		// for (let i = 0; i < this.views.length; i++) {
		// 	if (this.views[i].name === view.name) {
		// 		this.views.splice(i, 1);
		// 		if (i >= this.views.length) { i--; }
		// 		if (i >= 0) { this.select(this.views[i]); }
		// 	}
		// }
		// if (this.defaultView && this.views.length <= 0) {
		// 	this._views.push(this.defaultView);
		// }
	}

	clearViews() {
		this.views.length = 0;
		if (this.defaultView && this.views.length <= 0) {
			this._views.push(this.defaultView);
		}
	}

	// apply config to existing view
	updateView(config: ViewConfig) {

		if ('name' in config) {
			this.name = <string> (config['name']);  // use property to check validity
		} else {
			throw new Error('Name is required');
		}

		if ('showCaption' in config) {
			this._showCaption = <boolean> config['showCaption'];
		} else {
			this._showCaption = true;
		}

		if ('caption' in config) {
			this._caption = <string> config['caption'];
		} else {
			this._caption = <string> config['name'];
		}

		if ('rightCaption' in config) {
			this._rightCaption = <string> config['rightCaption'];
		} else {
			this._rightCaption = this._caption;
		}

		if ('date' in config) {
			this._date = <string> config['date'];
		}

		if ('tooltip' in config) {
			this._tooltip = <string> config['tooltip'];
		} else {
			this._tooltip = '';
		}

		if ('icon' in config) {
			this._icon = <ibIcon> config['icon'];
		} else {
			this._icon = undefined;
		}

		if ('highlight' in config) {
			this._highlight = <boolean> config['highlight'];
		} else {
			this._highlight = false;
		}

		if ('disabled' in config) {
			this._disabled = <boolean> config['disabled'];   // need side effect from property
		} else {
			this.disabled = false;
		}

		if ('isChecked' in config) {
			this._isChecked = <boolean> config['isChecked'];
		} else {
			this._isChecked = false;
		}

		if ('url' in config) {
			this._url = <string> config['url'];
		} else {
			this._url = '';
		}

		if ('canNavigate' in config) {
			this._canNavigate = <boolean> config['canNavigate'];
		} else {
			this._canNavigate = true;
		}

		if ('tag' in config) {
			this._tag = config['tag'];
		} else {
			this._tag = null;
		}

		// // if ('toolbar' in config) {
		// // 		this._toolbar = <Array<MenuItem>> config['toolbar'];
		// // } else {
		// // 	this._toolbar = new Array<MenuItem>();
		// // }

		if ('defaultView' in config) {
			this._defaultView = <View> config['defaultView'];
			this._defaultView._isDefaultView = true;
		} else {
			this._defaultView = undefined;
		}
	}

	deselect(withEvent: boolean = false): void {
		for (const view of this._views) {
			view.active = false;
		}
		if (withEvent) { this.selectionChange$.next(undefined); }
	}

	selectFirst(): View | undefined {
		this.deselect();
		if (this._views.length > 0) {
			this._views[0]._active = true;
			this.selectionChange$.next(this._views[0]);
			return this._views[0];
		} else {
			return undefined;
		}
	}

	selectPrevious(): View | undefined {
		let sel: View | undefined = undefined;
		if (this.views.length <= 0) { return undefined; }
		const i = this.getSelectedIndex() - 1;
		if (i >= 0) {
			sel = this.select(this.views[i]);
		} else {
			sel = this.select(this.views[0]);
		}
		this.selectionChange$.next(sel);
		return sel;
	}

	selectNext(): View | undefined {
		let sel: View | undefined = undefined;
		if (this.views.length <= 0) { return undefined; }
		const i = this.getSelectedIndex() + 1;
		if (i < this.views.length) {
			sel = this.select(this.views[i]);
		} else {
			sel = this.select(this.views[i]);
		}
		this.selectionChange$.next(sel);
		return sel;
	}

   // item = main View Name / View to select Name
	select(item?: string | View): View | undefined {
		let _selected: View | undefined = undefined;

		if (!item) {
			for (const view of this._views) {
				if (view.active === true) {
					_selected = view;
					this.selectionChange$.next(_selected);
					return _selected;
				}
			}
			if (this._views && this._views.length > 0) {
				_selected = this.selectFirst();
			} else {
				_selected = undefined;
			}
			this.selectionChange$.next(_selected);
			return _selected;
		}

		if (typeof item === 'string') {
			this.deselect();

			// check that last segment of url matches a view
			const url = item.split('/');
			let key = (url && url.length > 0) ? url.pop() : '';
			if (key) {
				key = key.toLowerCase().trim();
				for (const view of this._views) {
					// if (view.name.toLowerCase().trim() === key) {
					// 	view.active = true;
					// 	_selected = view;
					// 	this.selectionChange.next(_selected);
					// 	return _selected;
					// }
				}
			}
			_selected = this.selectFirst();
			this.selectionChange$.next(_selected);
			return _selected;
		}

		// item is a view
		const v = <View> item;
		this.deselect();
		const i = this._views.indexOf(v);
		if (i >= 0) {
			this._views[i].active = true;
			_selected = this._views[i];
		} else {
			_selected = this.selectFirst();
		}
		this.selectionChange$.next(_selected);
		return _selected;
	}

	find(name: string): View | undefined {
		for (const v of this._views) {
			if (name === v.name) { return v; }
		}
		return undefined;
	}

	get name(): string { return this._name; }
	set name(value: string) {
		if (ibUrl.isUrl(value)) {
			this._name = ibUrl.formatUrl(value);
		} else {
			throw new Error(`${value} is not a valid url`);
		}
		this._name = value;
	}

	get parent(): View | undefined { return this._parent; }

	get path(): string {
		const parent = (this._parent) ? Tools.toPrettyUrl(this._parent._name) + '/' : '';
		return parent + Tools.toPrettyUrl(this._name);
	}

	get showCaption(): boolean { return this._showCaption; }
	set showCaption(value: boolean) { this._showCaption = value; }

	get caption(): string {
		if (this._caption) {
			return Tools.toCaption(this.caption)
		} else {
			return Tools.toCaption(this._name);
		}
	}
	set caption(value: string) { this._caption = value; }

	get rightCaption(): string | undefined { return this._rightCaption; }
	set rightCaption(value: string | undefined) { this._rightCaption = value; }

	get date(): string | undefined { return this._date; }
	set date(value: string | undefined) { this._date = value; }

	get tooltip(): string | undefined { return this._tooltip; }
	set tooltip(value: string | undefined) { this._tooltip = value; }

	get icon(): ibIcon | undefined { return this._icon; }
	set icon(value: ibIcon | undefined) { this._icon = value; }

	get disabled(): boolean { return this._disabled; }
	set disabled(value: boolean) {
		this._disabled = value;
		if (this._toolbar) {
			for (const t of this._toolbar.list) {
				t.isDisabled = (value);
			}
		}
	}

	get isChecked(): boolean { return this._isChecked; }
	set isChecked(value: boolean) { this._isChecked = value; }

	get highlight(): boolean { return this._highlight; }
	set highlight(value: boolean) { this._highlight = value; }

	get url(): string | undefined { return this._url; }
	set url(value: string | undefined) { this._url = value; }

	get canNavigate(): boolean { return this._canNavigate; }
	set canNavigate(value: boolean) { this._canNavigate = value; }

	// url ready to use for navigation
	get renderedUrl(): string {
		const pUrl = (this._parent?.renderedUrl) ? this._parent.renderedUrl + '/' : '';
		const vUrl = ibUrl.toTitleCase((this._url) ? this._url : this._name);
		return pUrl + vUrl;
	}

	get tag(): any { return this._tag; }
	set tag(value: any) { this._tag = value; }

	get active(): boolean { return this._active; }
	set active(value: boolean) { this._active = value; }

	get toolbar(): IconList { return this._toolbar; }
	set toolbar(value: IconList) { this._toolbar = value; }

	get iconClasses(): string { return (this._icon) ? this._icon.imageClasses : ''; }

	get views(): Array<View> {
		return this._views;
	}

	get subViewCount(): number { return (this._views) ? this._views.length : 0; }

	get defaultView(): View | undefined { return this._defaultView; }
	set defaultView(value: View | undefined) {
		this._defaultView = value;
		if (this._defaultView) {
			this._defaultView._isDefaultView = true;
			this._parent = this;
		}
	}

	get isDefaultView(): boolean { return this._isDefaultView; }

	get documents(): Array<ibDocument> {
		const docs = new Array<ibDocument>();
		if (this.views && this.views.length > 0) {
			for (const doc of this.views) {
				if (doc.tag) { docs.push(doc.tag); }
			}
		}
		return docs;
	}

	get selected(): View | undefined {
		for (const v of this._views) {
			if (v._active) { return v; }
		}
		return undefined;
	}

	hasIcon(): boolean {
		return (this._icon !== null);
	}

	resizeSubViewIcons(iconSize: IconSizes, textSize: TextSizes): void {
		if (this.views && this.views.length> 0) {
			for (const v of this.views) {
				if (v._icon) {
					v._icon.image.size = iconSize;
					v._icon.caption.size = textSize;
				}
			}
		}
	}

	private getSelectedIndex(): number {
		for (let i = 0; i < this.views.length; i++) {
			if (this.views[i]._active) { return i; }
		}
		return -1;
	}


	// disableToolbarItem(id: string, state: boolean = false) {
	// 	if (this.toolbar) {
	// 		const item = this.toolbar.find((i) => i.iconDescriptor.id === id);
	// 		if (item) { item.disabled = state; }
	// 	}
	// }

	// activateToolbarItem(id: string, state: boolean = false) {
	// 	if (this.toolbar) {
	// 		const item = this.toolbar.find((i) => i.iconDescriptor.id === id);
	// 		if (item) { item.active = state; }
	// 	}
	// }

	// getToolbarItem(id: string): MenuItem | undefined {
	// 	if (this.toolbar) {
	// 		return this.toolbar.find((i) => i.iconDescriptor.id === id);
	// 	} else { return undefined; }

	// }

	disableToolbarItem(id: string, disable: DisableAction = 'disable') {
		if (this.toolbar) { this.toolbar.disable(id, disable); }
	}

	disableToolbarItems(disable: DisableAction = 'disable') {
		if (this.toolbar) { this.toolbar.disableAll(disable); }
	}

}

export class ViewConfig {
	name?: string;
	caption?: string;
	rightCaption?: string;
	date?: string;
	tooltip?: string;
	icon?: ibIcon;
	disabled?: boolean;
	isChecked?: boolean;
	highlight?: boolean;
	url?: string;
	canNavigate?: boolean;
	param?: Record<string, any>;
	state?: Record<string, any>;
	tag?: any;
	toolbar?: IconList;
	showCaption?: boolean;
	defaultView?: View;
}
