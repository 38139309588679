import { Tools } from 'common/tools';
import { Defaults } from '@common/classes/defaults';

// class Owner


export enum OwnerTypes {
	iBreeder = 0,
	Breed = 1,
	Breeder = 2,
	Litter = 3,
	Dog = 4,
	Visitor = 5,
	Guest = 6,
	Club = 7,
	Professional = 8,
	Product = 9,
	EntityOwner = 10,           // dog or litter owner
	DirectoryBreeder = 100,
	DirectoryDog = 101,
	DirectoryClient = 102,
	Blank = 254,
	Unknown = 255
}

export class Owner {

	public static assign(source: Record<string, any>, target: Owner | null = null): Owner {

		if (!target) {
			target = new Owner(OwnerTypes.Unknown, Tools.newGuid());
		}

		if ('uid' in source) {
			target.uid = (<any> source)['uid'];
		} else {
			throw Error('\'uid\' is required');
		}

		if ('ownerType' in source) {
			const val: any = (<any> source)['ownerType'];
			if (typeof val === 'number') {
				target.ownerType = <OwnerTypes> val;
			} else {
				target.ownerType = (<any> OwnerTypes)[val];
			}
		} else {
			throw Error('\'ownerType\' is required');
		}

		if ('id' in source) {
			target.id = (<any> source)['id'];
		}

		// if (source.hasOwnProperty('namingPolicies')) {
		//    target.namingPolicies = (<any>source)['namingPolicies'];
		// }

		return target;

	}


	constructor(type: OwnerTypes, uid: string, id: string | null = '') {
		this.ownerType = type;
		this.uid = uid;
		this.id = (id) ? id : '';
	}

	public ownerType: OwnerTypes;
	public uid: string;
	public id: string;

	public get canHaveUsers(): boolean {
		return (this.ownerType === OwnerTypes.iBreeder || this.ownerType === OwnerTypes.Breeder) ? true : false;
	}

}

export class OwneriBreeder extends Owner {

	constructor() {
		super(OwnerTypes.iBreeder, '00000000-0000-0000-0001-000000000000', 'iBreeder');
	}

}

export class OwnerBlank extends Owner {

	constructor() {
		super(OwnerTypes.Blank, '00000000-0000-0000-0000-000000000000', 'blank');
	}
}

export class OwnerGuest extends Owner {

	constructor() {
		super(OwnerTypes.Guest, '00000000-0000-0000-0000-000000000000', '');
	}
}


export class OwnerVisitor extends Owner {

	constructor() {
		super(OwnerTypes.Visitor, Tools.newGuid(), '');
	}
}

export class UserVisitor extends Owner {

	constructor(uid: string, id: string | null = '') {
		super(OwnerTypes.Guest, uid, id);
	}

}


export class OwnerBreed extends Owner {

	constructor(uid: string, id: string | null = '') {
		super(OwnerTypes.Breed, uid, id);
	}

}

export class OwnerBreeder extends Owner {

	static createBlank(): OwnerBreeder {
		const b = new OwnerBreeder(Defaults.BlankUID, '@@0000');
		return b;
	}

	constructor(uid: string, accountId: string | null = '') {
		super(OwnerTypes.Breeder, uid, accountId);
	}

}

export class OwnerLitter extends Owner {

	constructor(uid: string, id: string | null = '') {
		super(OwnerTypes.Litter, uid, id);
	}

}
export class OwnerDog extends Owner {

	constructor(uid: string, id: string | null = '') {
		super(OwnerTypes.Dog, uid, id);
	}

}

export class OwnerDogOwner extends Owner {

	constructor(uid: string, id: string | null = '') {
		super(OwnerTypes.EntityOwner, uid, id);
	}

}
export class OwnerLitterOwner extends Owner {

	constructor(uid: string, id: string | null = '') {
		super(OwnerTypes.EntityOwner, uid, id);
	}

}
