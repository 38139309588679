// Sale Information

import { DateUtil } from '@common/classes/dateUtil';
import { SaleStages } from '@common/classes/types';


export class SaleInformationUtil {
	static assign(source: Record<string, any>, target: SaleInformation | null = null): SaleInformation {


		if (!target) {
			target = new SaleInformation();
		}

		// if (source.hasOwnProperty('stage')) {
		// 	target.stage = (<any> DogSaleStages)[(<any> source)['stage']];
		// } else {
		// 	target.stage = DogSaleStages.notForSale;
		// }


		if ('status' in source) {
			target.status = (<any> source)['status'];
		} else {
			target.status = 'unknown';
		}

		if ('saleStart' in source) {
			target.saleStart = DateUtil.parseDateFromCosmosDb((<any> source)['saleStart']);
		} else {
			target.saleStart = undefined;
		}

		if ('saleEnd' in source) {
			target.saleEnd = DateUtil.parseDateFromCosmosDb((<any> source)['saleEnd']);
		} else {
			target.saleEnd = undefined;
		}


		if ('price' in source) {
			target.price = (<any> source)['price'];
		} else {
			target.price = 0;
		}

		if ('reservationPolicy' in source) {
			target.reservationPolicy = (<any> source)['reservationPolicy'];
		}

		if ('firstChoicePolicy' in source) {
			target.firstChoicePolicy = (<any> source)['firstChoicePolicy'];
		}

		if ('shippingPolicy' in source) {
			target.reservationPolicy = (<any> source)['shippingPolicy'];
		}

		return target;
	}

}
export class SaleInformation {


	constructor(feeRate: number = 6, maxFee: number = 75) {
		this.stage = 'notForSale';

		this._feeRate = feeRate;
		this._maxFee = maxFee;
	}


	public stage: SaleStages;

	private _feeRate: number;
	private _maxFee: number;

	public status: string = 'unknown';
	public saleStart?: Date;
	public saleEnd?: Date;

	public get price(): number { return this._price; }
	public set price(value: number) {
		this._price = value;
	}
	private _price: number = 0;

	public get fee(): number {
		let res = (this._feeRate / 100) * this.price;
		if (this._maxFee > 0 && res > this._maxFee) { res = this._maxFee; }
		return res;
	}

	public reservationPolicy?: string;
	public firstChoicePolicy?: string;
	public shippingPolicy?: string;
}
