<ib-title type="close" [icon]="titleIcon">Menu</ib-title>
<div id="wrapper">
	<div class='card' id='head'>
		<ib-menu-item id='play' *ngIf='showGetStarted()'
			[icon]='getIcon(menuItemsBottom, "play")'
			[noBottomSpacing]='true'
			[noFixedWidth]='true'
			(selected)='onMenuClick($event)'>
		</ib-menu-item>
		<ib-menu-item id='signOut' *ngIf='authenticated' [icon]='getIcon(menuItemsBottom, "signOut")' (selected)='onMenuClick($event)'></ib-menu-item>
		<ib-menu-item id='signIn' *ngIf='!authenticated' [icon]='getIcon(menuItemsBottom, "signIn")' (selected)='onMenuClick($event)'></ib-menu-item>
	</div>
	<div id='col1'>
		<ib-icon-menu [items]='menuItemsLeft' columns= '2' (selectionChanged)='onMenuClick($event)'></ib-icon-menu>
	</div>
	<div id='col2'>
		<ib-icon-menu [items]='menuItemsRight' columns= '2' (selectionChanged)='onMenuClick($event)'></ib-icon-menu>
	</div>

	<div id='col3'>
		<ib-image source='@public/menuDog' alt='Main menu' class='img-fit-contain'></ib-image>
	</div>
	<div id='footer'>
		<ib-menu-item [icon]='getIcon(menuItemsBottom, "aboutUs")' (selected)='onMenuClick($event)'></ib-menu-item>
		<ib-menu-item [icon]='getIcon(menuItemsBottom, "contactUs")' (selected)='onMenuClick($event)'></ib-menu-item>
		<ib-menu-item [icon]='getIcon(menuItemsBottom, "help")' (selected)='onMenuClick($event)'></ib-menu-item>
		<ib-menu-item [icon]='getIcon(menuItemsBottom, "support")' (selected)='onMenuClick($event)'></ib-menu-item>
	</div>
</div>

