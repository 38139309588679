
<kendo-badge-container [class]='clickableClass'>
	<ng-content></ng-content>
	<kendo-badge
		[position]="configuration.position"
		[align] = "configuration.alignment"
		[themeColor]="configuration.color"
		(click)='onClick($event)' [innerHTML]='configuration.content'>
	</kendo-badge>
  </kendo-badge-container>
