// ibDraggable Directive

import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { ibDragService } from '@common/services/core-services/drag.service';

@Directive({
    selector: '[ibDraggable]'
})
export class DraggableDirective {

    private data: DraggableData;

    constructor(private dragService: ibDragService) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        this.data = new DraggableData();
    }

    @Output() ibDragStart = new EventEmitter<boolean>();


    @HostBinding('draggable')
    get draggable(): boolean {
        return true;
    }

    @Input()
    set ibDraggable(data: any) {
        if (data) {
            this.data = new DraggableData(data);
        }
    }


    @HostListener('dragstart', [ '$event' ])
    onDragStart(event: DragEvent): void {
        if (event.dataTransfer) {
            event.dataTransfer.setData('text/plain', this.data.toString());
            event.dataTransfer.dropEffect = 'move';
            this.dragService.startDrag(this.data);
        }
        this.ibDragStart.emit(true);
    }
}
export interface IDraggableData {
    source?: string;
    target?: string;
    id?: string;
    data?: any;
}

export class DraggableData {
    static toObject(value: string): DraggableData {
        return JSON.parse(value);
    }
    constructor(data?: IDraggableData) {
        if (data) {
            if ('source' in data) {
                this.source = (<any> data)['source'];
            }
            if ('target' in data) {
                this.target = (<any> data)['target'];
            }
            if ('id' in data.hasOwnProperty) {
                this.index = (<any> data)['id'];
            }
            if ('data' in data) {
                this.data = (<any> data)['data'];
            }
        }
    }

    public source = '';
    public target = '';
    public index = '';
    public data = '0';

    public toString(): string {
        return JSON.stringify(this);
    }
}
