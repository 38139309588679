import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { DocumentTypes, DBChangeType } from '@common/classes/types';
import { UserDocument } from '@identity/user-document';
import { ibCoreService } from '@services/core-services/core.service';
import { NameValue } from '@common/tools';
import { ibDocument } from '@common/entities/documents/ib-document';
import { ibResult } from '@common/classes/result';
import { Defaults } from '@common/classes/defaults';
import { ibIcon } from '@common/classes/icon';
import { Icons } from '@common/classes/icons';
import { dbResult } from '@common/classes/data/dbUtil';
import { BreedDocument } from '@common/entities/documents/breed-document';


@Injectable()
export class WindowRefService {
}

@Injectable( { providedIn: 'root'})
export class ibDBService {

    private _dbChange: Subject<DBChange>;
    private urlRoot = Defaults.apiUrl;
	private saveIcon: ibIcon;

    public static assign(source: object): object | null {
        if (!source || !("DType" in source)) return null;
        const DType = <DocumentTypes>(<any>source)["DType"];
        switch(DType) {
            case 'UserDocument': return UserDocument.assign(source);
			case 'BreedDocument': return BreedDocument.assign(source);
            default:
                throw new Error(`dbService error: cannot assign type <${DType}>`);
        }
    }

    constructor(
        private http: HttpClient,
        private coreService: ibCoreService
    ) {
        this._dbChange = new Subject<DBChange>();
		this.saveIcon = Icons.generalIcon('save', 'solid', 'sm');
		this.saveIcon.caption.text = 'Document was saved';
		this.saveIcon.caption.color = 'ib-color-success';
    }

    public get changes(): Observable<DBChange> {
        return this._dbChange.asObservable();
    }

	public getDocument(type: string, uid: string): Observable<dbResult> {

		const body = {
			"deployment": Defaults.deployment,
			"partitionKey": type,
			"id": uid,
		}

		const h = {
			iBreeder: Defaults.apiKey
		}

		this.coreService.startProgress(this.saveIcon, 750);
		const obs = this.http.post<string>(this.urlRoot + 'db-get', body, { 'headers': new HttpHeaders(h) }).pipe(
			map((resp: Object) => {
				const result = dbResult.success(resp);
				return result;
			}),
			catchError((err: HttpErrorResponse) => {
				const res = dbResult.faulted(err.message);
				 return of(res);
			}),
			finalize(() => 	{
				this.coreService.endProgress();
			})
		);

		return obs;
	}


    public getDocuments(query: string, parameters?: Array<NameValue>): Observable<dbResult> {
        // extract DType from Query - must be present
        const idx1 = query.indexOf('DType');
        if (idx1 < 1) {
			return of(dbResult.faulted("Invalid query: required DType is missing"));
		}
        const r =  query.substring(idx1).match(/"([^\"]+)\"|'([^']+)'|\\S+/g);
        if (!r || r.length < 1) {
			return of(dbResult.faulted("Invalid query: required DType value is missing"));
		}
        const DType = r[0].substring(1, r[0].length -1);

		const h = {
			iBreeder: Defaults.apiKey
		}

        const req = new getRequest(<DocumentTypes>DType);
        req.query = query;
        req.parameters = (parameters) ? parameters : new Array<NameValue>();

        const obs = this.http.post(this.urlRoot + "db-get", req.toJson(), { 'headers': new HttpHeaders(h) }).pipe(
            map((resp: any) => {
				const result = dbResult.assign(resp);
               return result;
            }),
            catchError((err: HttpErrorResponse) => {
                return of(dbResult.faulted(err.message));
            })
        );
        return obs;
    }

    // overwrites or create document based on id match
    public putDocument<T>(doc: ibDocument): Observable<ibResult<T>> {
        try {
            if (!doc) { return of((ibResult.faulted("Invalid request: document is required"))); }
            const DType = doc.DType;

            const body = {
                "deployment": Defaults.deployment,
                "partitionKey": DType,
                "document": doc,
            }

            const h = {
                iBreeder: Defaults.apiKey
            }

            const obs = this.http.post<string>(this.urlRoot + 'db-put', body, { 'headers': new HttpHeaders(h) }).pipe(
                map((resp: any) => {
                    const result = new ibResult<T>(resp);
                    this._dbChange.next(new DBChange('put', doc));
					this.coreService.startProgress(this.saveIcon, 750);
                    return result;
                }),
                catchError((err: HttpErrorResponse) => {
                    const res = ibResult.faulted<T>(err.message);
					 return of(res);
                })
            );
            return obs;
        } catch (ex) {
            const res = ibResult.faulted<T>(ex as string);
            return of(res);
        }
    }

    public delDocuments(query: string, parameters?: Array<NameValue>): Observable<any> {
        // extract DType from Query - must be present
        const idx1 = query.indexOf('DType');
        if (idx1 < 1) { return of("Invalid query: required DType is missing"); }
        const r =  query.substring(idx1).match(/"([^\"]+)\"|'([^']+)'|\\S+/g);
        if (!r || r.length < 1) { return of("Invalid query: required DType value is missing"); }
        const DType = r[0].substring(1, r[0].length -1);

        const req = new getRequest(<DocumentTypes>DType);
        // req.query = query;
        req.parameters = (parameters) ? parameters : new Array<NameValue>();
        const obs = this.http.post(this.urlRoot + "db_del", req.toJson()).pipe(
            map((resp: any) => {
                return resp;
            }),
            catchError((err: HttpErrorResponse) => {
                return of(err.message); })
        );
        return obs;
    }
}

export class DBChange {
    public static put(doc: ibDocument): DBChange {
        return new DBChange('put', doc);
    }

    public static delete(doc: ibDocument): DBChange {
        return new DBChange('delete', doc);
    }

    constructor(change: DBChangeType, doc: ibDocument) {
        this.changeType = change;
        this.dType = doc.DType;
        this.document = doc;
        this.timeStamp = Date.now();
    }

    changeType: DBChangeType;
    dType: DocumentTypes;
    document: ibDocument;
    timeStamp: number;
}


export class DBRequest {

    public databaseName: string;
    public containerName: string;
    public partitionKey: string;

    constructor(DType: DocumentTypes) {
        if (DType) {
            this.databaseName = Defaults.apiDatabaseName;
            this.containerName = Defaults.apiContainerName;
            this.partitionKey = DType.trim();
        } else {
            throw new Error("partition key \'DType\') is required");
        }
    }

    toJson(): string {
        const val = JSON.stringify(this);
        return val;
    }
}

export class getRequest extends DBRequest{

    constructor(DType: DocumentTypes) {
        super(DType);
        this.parameters = new Array<NameValue>();
    }

    public query: string;
    public parameters: Array<NameValue>;
}
export class putRequest extends DBRequest{
    constructor(DType: DocumentTypes) {
        super(DType);
    }

    // public document: ibDocument;
}

export class dbResponse<T> {

    constructor(source?: object) {

        if (!source || typeof source === 'string') {

            this._rid = undefined;
            // this.count = 0;
            this.documents = new Array<T>();
            // this.id = '';
            this.err= (source) ? source : 'no source';
            return;
        }

        if (source && "_rid" in source) {
            this._rid = (<any>source)['_rid'];
        }

        if (source?.hasOwnProperty("_count")) {
            // this.count = parseInt((<any>source)['_count']);
        }

        this.documents = new Array<T>();
        if (source && "Documents" in source) {
            const docs = (<any>source)['Documents'];
            if (docs && Array.isArray(docs)) {
                for(const item of docs) {
                    if (DatabaseID.isID(item)) {
                        // this.id = item.id;
                    } else {
                        const tDoc = <T><unknown>ibDBService.assign(item);
                        this.documents.push(tDoc);
                    }
                }
            }
        }
    }

    public _rid?: string;
    // public count: number;
    public documents: Array<T>;
    // public id: string;
    public err?: string

}

export class DatabaseID {

    public static isID(source: object): boolean {
        if (!source) { return false; }
        if (Object.keys(source).length !== 1) { return false; }
        return source.hasOwnProperty("id");
    }

    // Object is db if it has 1 property id
    public static assign(source: object): DatabaseID | null {
        if (!source) { return null;}
        if (Object.keys(source).length !== 1) { return null; }
        if (source.hasOwnProperty("id")) {
            const dbID = new DatabaseID();
            // dbID.id = (<any>source)['id'];
            return dbID;
        } else {
            return null;
        }
    }

    // public id: string;
}

