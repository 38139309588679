// -----------------------------------------------
// TYPES
// -----------------------------------------------

export type AccountTypes = 'visitor' | 'dogOwner' | 'breeder' | 'iBreeder';

export type AssetImageSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type AssetImageCategories = 'admin' | 'common' | 'public' | undefined;

export type AuthRequests = 'sign-in' | 'sign-out' | 'upsert-user' | 'get-user' | 'set-password' | 'renew' | 'get-key'

export type Case = 'uppercase' |  'lowercase';

export type CertificateTypes = 'license' | 'certification' | 'trophy' | 'award' | 'any';

export type DBChangeType = 'new' | 'put' | 'delete';

export type DisplayTypes = 'show' | 'list' | 'map' | 'page';

export type DocumentTypes = 'BreedDocument' |
	'BreederDocument' |
	'DogDocument' |
	'HealthIssueDocument' |
	'ImageDocument' |
	'ListItemDocument' |
	'LitterDocument' |
	'MessageDocument' |
	'NoteDocument' |
	'PageDocument' |
	'PersonDocument' |
	'TreeDocument' |
	'VisitorDataDocument' |
	'UserDocument';

	export const DocumentTypesList = [
	'BreedDocument',
	'BreederDocument',
	'DogDocument',
	'HealthIssueDocument',
	'ImageDocument',
	'ListItemDocument',
	'LitterDocument',
	'MessageDocument',
	'NoteDocument',
	'PageDocument',
	'PersonDocument',
	'TreeDocument',
	'VisitorDataDocument',
	'UserDocument'
]

export type DogCategoriesTemplates = 'basic' | 'standard' | 'professional' | 'custom';

export type DogOwnerTypes = 'self' | 'client' | 'breeder';

// export type DogStatus = 'active' | 'forSale' | 'reserved' | 'retired' | 'departed' | 'pending';
export type DogStatus = 'pending' | 'active' | 'retired' | 'departed' ;

// replaced by media
export type ImageExtensions = 'jpg' | 'png' | 'webp' | 'svg' | undefined;
// export const ImageExtensionList = new Set(['jpg', 'png', 'webp', 'svg']);

export type FavoriteCategories = 'breeds' | 'breeders' | 'litters' | 'dogs' | 'dogNames';

export const geoTypeList = [
	'street_address',
	'route',
	'intersection',
	'political',
	'country',
	'administrative_area_level_1',
	'administrative_area_level_2',
	'administrative_area_level_3',
	'administrative_area_level_4',
	'administrative_area_level_5',
	'colloquial_area',
	'locality city or town',
	'sublocality',
	'neighborhood',
	'premise',
	'subpremise',
	'postal_code',
	'natural_feature',
	'airport',
	'park',
	'point_of_interest',
	'floor',
	'establishment',
	'point_of_interest',
	'parking',
	'post_box',
	'postal_town',
	'room',
	'street_number',
	'bus_station',
	'train_station',
	'transit_station',
	'unknown'];

export type Gender = 'female' | 'male';
export type GenderEx = 'female' | 'male' | 'any';

export type GeoTypes = typeof geoTypeList[number];

export type ibViewModes = 'full' | 'icon' | 'collapsed';

// export type IdentityActions = "getUser" | "putUser" | "signIn" | "signOut" | "autoSignIn" | "resetPassword"; replaced by AuthRequest

export type IdentityTypes = 'none' | 'credentials' | 'facebook' | 'google' | 'twitter';

export type InputTypes =
			'text' | 'number' | 'integer' | 'price' | 'boolean' |
			'denomination'| 'eAddress' | 'accountId' | 'id' | 'zip' |
			'username' | 'password' |
			'email' | 'phone' |
			'checkbox' |'custom' | 'unknown';
export type InputCompletionState = 'complete' | 'partial' | 'empty' | 'error';
export type InputControlSizes= 'sm' | 'md' | 'lg' | 'xl' | undefined;
export type InputLayout= 'row' | 'column';

export type Meridiem = 'am' | 'pm';

export type NameFilters = 'official' | 'all';

export type NumberTypes = 'number' | 'integer' | 'price';

export type PersonTypes = 'prospect' | 'client' | 'breeder' | 'team' | 'other';

export type PhoneNumberInputState = 'complete' | 'partial' | 'error';

export const PhoneTypesArray = [ 'mobile', 'landline', 'fax', 'phone' ];
export type PhoneTypes = 'mobile' | 'landline' | 'fax' | 'phone';

export type ProfileTargets = 'profile' | 'personal' | 'dog' | 'breeder';

export type QuestionDataTypes = 'string' | 'numeric' | 'bool' | 'list'  | 'postalCode';

export type RemoveDirection = 'Delete' | 'Backspace';

export type SaleStages = 'notForSale' | 'forSale' | 'reserved' | 'pending' | 'sold' | 'paid' | 'delivered';

export type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'hd' | '4k';

export type SizeComparison = 'shorter' | 'equal' | 'longer';

export type Show = 'not-available' | 'under-construction';

export type SiteMode = 'guest' | 'visitor' | 'dog-owner' | 'breeder' | 'iBreeder';

export type Statuses = 'unknown' | 'draft' | 'incomplete' | 'complete' | 'checked' | 'published';

export type StorageTypes = 'localStorage' | 'sessionStorage';

export type ToolbarItemTypes =
	'add' | 'add!' |
	'close' | 'close!' |
	'data' |
	'delete' | 'delete!' |
	'edit' | 'Edit!' |
	'error' |
	'favorite' |
	'guide' |
	'help' |
	'image' |
	'images' |
	'page' |
	'preview' |
	'print' |
	'publish' |
	'resize' |
	'save' | 'save!' |
	'share' |
	'show' |
	'sortByDate' |
	'sortByLocation' |
	'sortIndex' |
	'sortAlpha' |
	'trash' |
	'upload';

export type UploadTypes = 'image' | 'video' | 'pdf' | 'document';

export type UsernameMode = 'phone' | 'sms-phone' | 'email' | 'text' | 'username';

export type UsernameState = 'complete' | 'partial' | 'error';

export type ValidationResult = 'valid' | 'partial' | 'invalid' | 'empty';

export type VisitorContactModes = 'auto' | 'manual' | 'none';
