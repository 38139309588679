import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeModule, LoaderModule } from "@progress/kendo-angular-indicators";

import { CoreDirectivesModule } from '@common/directives/core-directives.module';
import { ibBadgeComponent } from './core-components/ib-badge/ib-badge.component';
import { ibButtonComponent } from './core-components/ib-button/ib-button.component';
import { ibHCardComponent } from './core-components/ib-hcard/ib-hcard.component';
import { ibHelpViewerComponent } from './core-components/ib-help-viewer/ib-help-viewer.component';
import { ibIconComponent } from './core-components/ib-icon/ib-icon.component';
import { ibIconListComponent } from './core-components/ib-icon-list/ib-icon-list.component';
import { ibIconMenuComponent } from './core-components/ib-icon-menu/ib-icon-menu.component';
import { ibImageComponent } from './core-components/ib-image/ib-image.component';
import { ibMenuComponent } from './core-components/ib-menu/ib-menu.component';
import { ibMenuCardComponent } from './core-components/ib-menu-card/ib-menu-card.component';
import { ibMenuItemComponent } from './core-components/ib-menu-item/ib-menu-item.component';
import { ibProgressComponent } from './core-components/ib-progress/ib-progress.component';
import { ibTitleComponent } from './core-components/ib-title/ib-title.component';
import { ibToolbarComponent } from './core-components/ib-toolbar/ib-toolbar.component';
import { ibLoaderComponent } from './core-components/ib-loader/ib-loader.component';
import { ibHelpButtonComponent } from './core-components/ib-help-button/ib-help-button.component';
import { ibPanComponent } from './core-components/ib-pan/ib-pan.component';


@NgModule({
    declarations: [
        ibBadgeComponent,
        ibButtonComponent,
        ibHCardComponent,
        ibHelpViewerComponent,
        ibHelpButtonComponent,
        ibIconComponent,
        ibIconListComponent,
        ibIconMenuComponent,
        ibImageComponent,
        ibLoaderComponent,
        ibMenuComponent,
        ibMenuCardComponent,
        ibMenuItemComponent,
        ibPanComponent,
        ibProgressComponent,
        ibTitleComponent,
        ibToolbarComponent
    ],
    imports: [
        CommonModule,
        CoreDirectivesModule,
        LoaderModule,
        BadgeModule
    ],
    exports: [
        CommonModule,
        ibBadgeComponent,
        ibButtonComponent,
        ibHCardComponent,
        ibHelpViewerComponent,
        ibHelpButtonComponent,
        ibIconComponent,
        ibIconListComponent,
        ibIconMenuComponent,
        ibImageComponent,
        ibLoaderComponent,
        ibMenuComponent,
        ibMenuCardComponent,
        ibMenuItemComponent,
        ibPanComponent,
        ibProgressComponent,
        ibTitleComponent,
        ibToolbarComponent
    ],
    providers: []
})
export class ibCoreComponentsModule { }
