import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ib-pan',
  templateUrl: './ib-pan.component.html',
  styleUrls: ['./ib-pan.component.scss'],
})
export class ibPanComponent {

	@Output() pan = new EventEmitter<string>();

	public topLeftIcon: string;
	public topIcon: string;
	public topRightIcon: string;
	public middleLeftIcon: string;
	public middleIcon: string;
	public middleRightIcon: string;
	public bottomLeftIcon: string;
	public bottomIcon: string;
	public bottomRightIcon: string;


    constructor() {

		this.topLeftIcon = '<i class="fa-solid fa-caret-up fa-rotate-by" style="--fa-rotate-angle: 45deg;"></i>';
		this.topIcon = '<i class="fa-solid fa-caret-up fa-rotate-by" style="--fa-rotate-angle: 45deg;"></i>';
		this.topRightIcon = '<i class="fa-solid fa-caret-up fa-rotate-by" style="--fa-rotate-angle: 45deg;"></i>';
		this.middleLeftIcon = '<i class="fa-solid fa-caret-up fa-rotate-by" style="--fa-rotate-angle: 45deg;"></i>';
		this.middleIcon = '<i class="fa-solid fa-caret-up fa-rotate-by" style="--fa-rotate-angle: 45deg;"></i>';
		this.middleRightIcon = '<i class="fa-solid fa-caret-up fa-rotate-by" style="--fa-rotate-angle: 45deg;"></i>';
		this.bottomLeftIcon = '<i class="fa-solid fa-caret-up fa-rotate-by" style="--fa-rotate-angle: 45deg;"></i>';
		this.bottomIcon = '<i class="fa-solid fa-caret-up fa-rotate-by" style="--fa-rotate-angle: 45deg;"></i>';
		this.bottomRightIcon = '<i class="fa-solid fa-caret-up fa-rotate-by" style="--fa-rotate-angle: 45deg;"></i>';
	}


    public onClick(btn: string) {
        this.pan.emit(btn);

    }

}

