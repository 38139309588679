// --------------------------------------------------------------
// Class DogDocument
// --------------------------------------------------------------

import { ibDocument } from './ib-document';
import { IAge, DateUtil } from '@common/classes/dateUtil';
import { Colors } from '@entities/parts/colors';
import { BreederDocument } from './breeder-document';
import { Owner, OwnerBlank } from '@entities/parts/owner';
import { LitterStub } from '@entities/parts/litter-stub';
import { SaleInformation, SaleInformationUtil } from '@entities/parts/sale-information';
import { Tools } from '@common/tools';
import { Property } from '@entities/parts/property';
import { GeoPoint } from '@entities/parts/geo-point';
import { DogStatus, Gender } from '@common/classes/types';
import { Registration } from '@entities/parts/registrations';
import { ibIcon } from '@common/classes/icon';

export interface DropDownItemDisplay {
	name: string;
	icon: ibIcon | undefined;
}

export class DogDocumentUtil {


	public static age(dog: DogDocument): IAge {
		if (!dog || !dog.litter || !dog.litter.owner) { return { years: 0, months: 0, days: 0, dayCount: 0 }; }
		return DateUtil.getAge(dog.litter.dob);
	}

	public static getAge(age: IAge, puppyMaxAge: number = 2): Array<string> {
		const a = DateUtil.getAgeString(age, puppyMaxAge);
		return a;
	}

	public genderStyle(dog: DogDocument): string {
		return dog.gender;
	}


	public static getGender(dog: DogDocument): DropDownItemDisplay {
		if (dog.gender === 'female') {
			return DogDocumentUtil.FemaleDisplay;
		} else {
			return DogDocumentUtil.MaleDisplay;
		}
	}

	public static getColorFromDog(dog: DogDocument): DropDownItemDisplay {
		return DogDocumentUtil.getColor(dog.identification.collarColor);
	}
	public static getColor(color: string | null = null): DropDownItemDisplay {
		const c = Colors.getColor(color);
		if (c && color) {
			const O: DropDownItemDisplay = { name: '', icon: undefined };
			O.name = c.name;
			// O.icon = new Icon('fa-circle', 'ib-collarColor-' + c.name);
			return O;
		} else {
			const O: DropDownItemDisplay = { name: '', icon: undefined };
			O.name = 'None';
			// O.icon = new Icon('fa-circle-thin', 'ib-collarColor-Navy');
			return O;
		}
	}

	public static get MaleDisplay(): DropDownItemDisplay {
		const O: DropDownItemDisplay = { name: '', icon: undefined };
		O.name = 'Male';
		// O.icon = new Icon('fa-mars', 'ib-color-male');
		return O;
	}

	public static get FemaleDisplay(): DropDownItemDisplay {
		const O: DropDownItemDisplay = { name: '', icon: undefined };
		O.name = 'Female';
		// O.icon = new Icon('fa-venus', 'ib-color-female');
		return O;
	}


	// public static favoriteIcon(dog: DogDocument): string {
	// 	return (dog.favorite) ? 'fa-heart' : 'fa-heart-o';
	// }

	// public static favoriteIconColor(dog: DogDocument): string {
	// 	return (dog.favorite) ? 'ib-color-red' : 'ib-color-blue';
	// }

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public static notificationIcon(dog: DogDocument): string {
		const hasNotifications = false;
		return (hasNotifications) ? 'fa-exclamation-triangle' : 'fa-exclamation-circle';
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public static notificationIconColor(dog: DogDocument): string {
		const hasNotifications = false;
		return (hasNotifications) ? 'ib-color-red' : 'ib-color-blue1';
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public static forSaleIconColor(dog: DogDocument): string {
		const isForSale = false;
		return (isForSale) ? 'ib-color-money' : 'ib-color-gray-light';
	}

	public static categories(dog: DogDocument, breeder: BreederDocument): string {

		let ret = '';

		// short list
		// if (dog.favorite) {
		// 	ret += 'Favorite / ';
		// }

		// owned - the owner is the breeder
		if (dog.owner) {
			if (dog.owner.uid === breeder.id) {
				ret += 'Owned / ';
			}
		}

		// bred - the litter is owned by the breeder
		// if (!!dog.litter && dog.litter.owner) {
		//     if (dog.litter.owner.uid === breeder.id) {
		//         ret += 'Bred / ';
		//     }
		// }

		// puppy - calculated age of puppy
		ret += DogDocumentUtil.getAge(DogDocumentUtil.age(dog), 2)[0] + ' / ';

		// Parent
		// check all liters where sire or dam uid is this dog

		// Stud
		// if (!!dog.owner && !!dog.litter && !!dog.litter.owner) {
		//     // if (dog.owner.uid !== breeder.id && dog.litter.owner.uid !== breeder.id) {
		//     //     ret += 'Stud / ';
		//     // }
		// }

		// retired
		if (dog.dod) {
			ret += 'Departed / ';
		} else {
			// if (!dog.active) {
			// 	ret += 'Retired / ';
			// }
		}

		// Sale status


		return ret;
	}

}
export  class DogDocument extends ibDocument {

	public static assign(source: Record<string, any>): DogDocument {

		let id: string;
		let dogID: string;

		if ('id' in source) {
			id = (<any> source)['id'];
		} else {
			throw new Error('id is required');
		}

		if ('dogID' in source) {
			dogID = (<any> source)['dogID'];
		} else {
			throw new Error('Dog Document Assign Error: \'dogId\' is required');
		}
		const target = new DogDocument(dogID, id);

		if ('name' in source) {
			target.name = (<any> source)['name'];
		} else {
			throw new Error('Dog Document Assign Error: \'name\' is required');
		}

		if ('kennelName' in source) {
			target.kennelName = (<any> source)['kennelName'];
		}

		if ('gender' in source) {
			target.gender = (<any> source)['gender'];
		} else {
			throw new Error('Dog Document Assign Error: \'gender\' is required');
		}

		if ('caption' in source) {
			target.caption = (<any> source)['caption'];
		}

		if ('description' in source) {
			target.description = (<any> source)['description'];
		}

		if ('category' in source) {
			target.category = (<any> source)['category'];
		}

		if ('owner' in source) {
			target.owner = Owner.assign((<any> source)['owner']);
		} else {
			throw new Error('Dog Document Assign Error: \'owner\' is required');
		}

		if ('manager' in source) {
			target.manager = Owner.assign((<any> source)['manager']);
		} else {
			target.manager = target.owner;
		}

		if ('identification' in source) {
			target.identification = DogIdentification.assign((<any> source)['identification']);
		}

		if ('defaultImage' in source) {
			target.defaultImage = (<any> source)['defaultImage'];
		} else {
			target.defaultImage = '';
		}

		if ('litter' in source) {
			const v = (<any> source)['litter'];
			target.litter = (v) ? LitterStub.assign(v) : new LitterStub();
			if (target.name === 'new dog') { target.name =  `new ${target.litter.breed.breedName}`; }
			if (!target.caption) { target.caption = target.litter.breed.caption; }
		} else {
			throw new Error('Dog Document Assign Error: \'litter\' is required');
		}

		if ('keywords' in source) {
			const s = <Array<any>> (<any> source)['keywords'];
			s.forEach((w) => { target.keywords.push(w.toLowerCase().trim()); });
		}

		if ('saleInformation' in source) {
			target.saleInformation = SaleInformationUtil.assign((<any> source)['saleInformation']);
		} else {
			target.saleInformation = new SaleInformation();
		}

		if ('status' in source) {
			target.status = (<any> source)['status'];
		} else { target.status = 'pending'; }


		if ('myListIndex' in source) {
			target.myListIndex = (<any> source)['myListIndex'];
		} else { target.myListIndex = -1; }

		if ('publish' in source) {
			target.publish = (<any> source)['publish'];
		} else { target.publish = false; }

		if ('dod' in source) {
			target.dod = (<any> source)['dod'];
		} else {
			target.dod = undefined;
		}

		if ('documents' in source) {
			// const v = (<any> source)['documents'];
			// target.litter = (v) ? LitterStub.Assign(v) : new LitterStub();
		} else {
			// throw ("'documents' is required");
		}

		if ('notes' in source) {
			// const v = (<any> source)['notes'];
			// target.litter = (v) ? LitterStub.Assign(v) : new LitterStub();
		} else {
			// throw ("'notes' is required");
		}

		return target;

	}

	constructor(dogID?: string, id?: string) {

		if (!id) { id = Tools.newGuid(); }
		super('DogDocument', id);

		this.dogID = (dogID) ? dogID : 'aaaa';
		this.name = 'new dog';
		this.gender = 'female';
		this.caption = '';
		this.description = '';
		this.defaultImage = '';
		this.category = '';
		this.owner = new OwnerBlank();
		this.manager = new OwnerBlank();
		this.identification = new DogIdentification();
		this.litter = new LitterStub();
		this.dod = undefined;
		this.saleInformation = new SaleInformation();
		this.health = new Array<string>();
		this.events = new Array<string>();
		this.notes = Array<string>();
		this.properties = Array<Property>();
		this.keywords = new Array<string>();
		this.status = 'pending';
		this.myListIndex = -1;
		this.publish = false;
	}

	public dogID: string;
	public name: string;
	public kennelName?: string;
	public gender: Gender;
	public caption?: string;
	public description?: string;
	public defaultImage?: string;
	public category?: string;
	public owner: Owner;
	public manager: Owner;
	public identification: DogIdentification;
	public litter: LitterStub;
	public dod?: Date;
	public position?: GeoPoint;
	public saleInformation: SaleInformation;
	public health: Array<string>;
	public events: Array<string>;
	public notes: Array<string>;
	public properties: Array<Property>;
	public keywords: Array<string>;
	public status: DogStatus;
	public myListIndex: number;
	public publish: boolean;

	get genderValue(): Gender { return this.gender; }
	set genderValue(value: Gender) { this.gender; }

}

export class DogIdentification {

	static assign(source: Record<string, any>, target: DogIdentification | null = null): DogIdentification {

		if (!target) {
			target = new DogIdentification();
		}

		if ('nickName' in source) {
			target.nickName = (<any> source)['nickName'];
		}

		if ('kennelName' in source) {
			target.kennelName = (<any> source)['kennelName'];
		}

		if ('litterKey' in source) {
			target.litterKey = (<any> source)['litterKey'];
		}

		if ('collarColor' in source) {
			target.collarColor = (<any> source)['collarColor'];
		}

		if ('tagNumber' in source) {
			target.tagNumber = (<any> source)['tagNumber'];
		}

		if ('tattooNumber' in source) {
			target.tattooNumber = (<any> source)['tattooNumber'];
		}

		if ('microchipNumber' in source) {
			target.microchipNumber = (<any> source)['microchipNumber'];
		}

		if ('registrations' in source) {
			target.registrations = (<any> source)['registrations'];
		}

		return target;
	}


	constructor() {
		this.nickName = null;
		this.kennelName = null;
		this.litterKey = null;
		this.collarColor = null;
		this.tagNumber = null;
		this.tattooNumber = null;
		this.microchipNumber = null;
		this.registrations = new Array<Registration>();
	}

	public nickName: string | null;
	public kennelName: string | null;
	public litterKey: string | null;
	public collarColor: string | null;
	public tagNumber: string | null;
	public tattooNumber: string | null;
	public microchipNumber: string | null;
	public registrations: Array<Registration>;


}



// obsolete - can be removed
export class DogRegistration33 {

	static assign(source: Record<string, any>): DogRegistration33 {

		const target = new DogRegistration33();

		if ('registrarName' in source) {
			target.registrarName = (<any> source)['registrarName'];
		} else {
			throw new Error('DogRegistration Assign Error: \'registrarName\' is required');
		}

		if ('registrationNumber' in source) {
			target.registrationNumber = (<any> source)['registrationNumber'];
		} else {
			throw new Error('DogRegistration Assign Error: \'registrationNumber\' is required');
		}

		if ('dogName' in source) {
			target.dogName = (<any> source)['dogName'];
		}

		if ('kennelName' in source) {
			target.kennelName = (<any> source)['kennelName'];
		}

		if ('registrationDate' in source) {
			target.registrationDate = (<any> source)['registrationDate'];
		} else {
			throw new Error('registrationDate is required');
		}

		if ('comment' in source) {
			target.kennelName = (<any> source)['comment'];
		}

		return target;

	}


	constructor() {
		this.registrarName = 'registrar';
		this.registrationNumber = '';
		this.kennelName = '';
		this.dogName = '';
		this.registrationDate = -1;
		this.comment = '';
	}

	public registrarName: string;
	public registrationNumber: string;
	public dogName: string;
	public kennelName: string;
	public registrationDate: number;
	public comment: string;



}
