// -------------------------------------------
// ibChrome Module
// -------------------------------------------
// declares components used in header and footer

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ibCoreComponentsModule } from '@common/components/core-components.module';
import { ibiBreederFooterComponent } from '@iBreeder/chrome/footer/iBreeder-footer.component';
import { ibiBreederHeaderComponent } from '@iBreeder/chrome/header/iBreeder-header.component';
import { ibFooterAddressComponent } from '@public/chrome/footer/address/footer-address.component';
import { ibFooterBottomComponent } from '@public/chrome/footer/bottom/footer-bottom.component';
import { ibFooterBreederComponent } from '@public/chrome/footer/breeder/footer-breeder.component';
import { ibFooterMainComponent } from '@public/chrome/footer/main/footer-main.component';
import { ibFooterMenuComponent } from '@public/chrome/footer/menu/footer-menu.component';
import { ibPublicFooterComponent } from '@public/chrome/footer/public-footer.component';
import { ibFooterTrackingComponent } from '@public/chrome/footer/tracking/footer-tracking.component';
import { ibPublicHeaderComponent } from '@public/chrome/header/public-header.component';
import { ibSearchBarComponent } from '@public/chrome/header/search-bar/search-bar.component';


@NgModule({
	declarations: [
		ibiBreederHeaderComponent,
		ibiBreederFooterComponent,
		ibPublicHeaderComponent,
		ibPublicFooterComponent,
		ibFooterAddressComponent,
		ibFooterBottomComponent,
		ibFooterBreederComponent,
		ibFooterMainComponent,
		ibFooterMenuComponent,
		ibFooterTrackingComponent,
	],
	imports: [
		// CommonModule,
		// BrowserAnimationsModule,
		RouterModule,
		// HttpClientModule,
		ibCoreComponentsModule,
		ibSearchBarComponent
	],
	exports: [
		ibCoreComponentsModule,
		// ibSearchModule
	]
})
export class ibChromeModule { }
