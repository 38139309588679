import { GeoPoint } from './geo-point';
import { AddressStatuses } from '@common/classes/enums';
import { Coordinates } from '@common/classes/lib';
import { Tools } from 'common/tools';



export class Address {

	static assign(source: Record<string, any>): Address {

		const b = Address.CreateNew();

		if ('name' in source) {
			b.name = (<any> source)['name'];
		} else {
			b.name = 'Main';
		}

		if ('index' in source) {
			b.index = (<any> source)['index'];
		} else {
			b.index = -1;
		}

		if ('address1' in source) {
			b.address1 = (<any> source)['address1'];
		} else {
			throw new Error('Address1 is required');
		}

		if ('address2' in source) {
			b.address2 = (<any> source)['address2'];
		} else {
			b.address2 = '';
		}

		if ('city' in source) {
			b.city = (<any> source)['city'];
		} else {
			throw new Error('City is required');
		}

		if ('region' in source) {
			b.region = (<any> source)['region'];
		} else {
			throw new Error('Region is required');
		}

		if ('postalCode' in source) {
			b.postalCode = (<any> source)['postalCode'];
		} else {
			throw new Error('PostalCode is required');
		}

		if ('country' in source) {
			b.country = (<any> source)['country'];
		} else {
			b.country = 'USA';
		}

		if ('position' in source) {
			b.position = GeoPoint.assign((<any> source)['position']);
		} else {
			b.position = undefined;
		}

		if ('locationInfo' in source) {
			b.locationInfo = LocationInfoUtil.assign((<any> source)['locationInfo']);
		}

		return b;

	}

	static CreateNew(name: string = 'Main'): Address {
		const p = new Address();
		p.name = name;
		p.status = AddressStatuses.Active;
		return p;
	}

	constructor() {
		this.index = -1;
		this.region = 'FL';
		this.country = 'US';
		this.status = AddressStatuses.Inactive;
	}

	name: string = '';
	index: number;
	address1: string = '';
	address2: string = '';
	city: string = '';
	region: string;
	postalCode: string = '';
	country: string;
	position: GeoPoint | undefined;
	locationInfo?: LocationInfo;
	status: AddressStatuses;
	comments?: string;
	directions?: string;

	public equals(address: Address): boolean {
		if (this.address1 !== address.address1) { return false; }
		if (this.address2 !== address.address2) { return false; }
		if (this.city !== address.city) { return false; }
		if (this.postalCode !== address.postalCode) { return false; }
		if (this.country !== address.country) { return false; }
		return true;
	}

	public toString(): string  {
		let addr: string = this.address1;
		addr += (this.address2) ? ' ' + this.address2 : '';
		addr += (this.city) ? ' ' + this.city : '';
		addr += (this.region) ? ' ' + this.region : '';
		addr += (this.postalCode) ? ', ' + this.postalCode : '';
		return addr;
	}
}

export abstract class LocationInfoUtil {


	static assign(source: Record<string, any>): LocationInfo {

		if (!source) { source = {}; }

		const l = LocationInfoUtil.createNew();

		if ('latitude' in source) {
			l.latitude = <number> (<any> source)['latitude'];
		} else {
			l.latitude = 0;
		}

		if ('longitude' in source) {
			l.longitude = <number> (<any> source)['longitude'];
		} else {
			l.longitude = 0;
		}

		if ('addressLine' in source) {
			l.addressLine = (<any> source)['addressLine'];
		}

		if ('adminDistrict' in source) {
			l.adminDistrict = (<any> source)['adminDistrict'];
		}

		if ('adminDistrict2' in source) {
			l.adminDistrict2 = (<any> source)['adminDistrict2'];
		}


		if ('country' in source) {
			l.country = (<any> source)['country'];
		}

		if ('locality' in source) {
			l.locality = (<any> source)['locality'];
		}

		if ('postalCode' in source) {
			l.postalCode = (<any> source)['postalCode'];
		}

		if ('formattedAddress' in source) {
			l.formattedAddress = (<any> source)['formattedAddress'];
		}

		return l;

	}

	static createNew(): LocationInfo {
		const l = <LocationInfo> new Object();
		return l;
	}

}


export interface LocationInfo {

	latitude: number;
	longitude: number;
	addressLine: string;
	adminDistrict: string;
	adminDistrict2: string;
	country: string;
	locality: string;
	postalCode: string;
	formattedAddress: string;
}

export class LocationSummary {

	public static create(address: Address): LocationSummary {
		const s = new LocationSummary();
		s.city = address.city;
		s.region = address.region;
		if (address.position) {	s.coordinates = new Coordinates(address.position.latitude, address.position.longitude);	}
		return s;
	}

	public city?: string;
	public region?: string;
	public distance?: number;
	public coordinates?: Coordinates;

	public toString(): string {
		let ret = (this.distance && this.distance > 0) ? `(${Tools.MetersToMiles(this.distance)} miles) ` : '';
		if (this.city && !this.region) { ret += this.city; }
		if (!this.city && this.region) { ret += this.region; }
		if (this.city && this.region) { ret += `${this.city}, ${this.region}`; }
		return ret;
	}

	public setDistance(coordinates: Coordinates) {
		if (this.coordinates && coordinates) {
			this.distance = this.coordinates.getDistance(coordinates);
		} else {
			this.distance = 0;
		}
	}
}

