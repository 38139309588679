import { DocumentTypes } from 'common/classes/types';

export class DocumentUtil {
	static Assign(source: Record<string, any>, target: ibDocument|null = null) {

		let id: string;

		if ('id' in source) {
			id = (<any> source)['id'];
		} else {
			throw new Error('id is required');
		}

		// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
		let DType: DocumentTypes;
		if ('DType' in source) {
			DType = (<any> source)['DType'];
		} else {
			throw new Error('id is required');
		}

		if (!target) {
			target = new ibDocument(DType, id);
		} else {
			target.DType = DType;
			target.id = id;
		}
		// const changed = false;


		if ('name' in source) {
			target.name = (<any> source)['name'];
		}

		return target;

	}
}


export class ibDocument {

	constructor(DType: DocumentTypes, id: string) {
		this.DType = DType;
		this.id = id;
		this.name = '';
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
	public DType: DocumentTypes;
	public version: string = '1.0';
	public id: string;
	public name: string;
	public _rid: string = '';
	public _self: string = '';
	public _etag: string = '';
	public changed: boolean = false;
	public canDelete: boolean = true;
	public created: Date = new Date();
	public modified: Date = new Date();
	public changedBy: string = 'system';
	public _attachments: string = '';
	public _ts: number = 0;

	public update(source: ibDocument) {
		if (this.DType !== source.DType) { throw new Error('source document DType much match  '); }
		if (this.id !== source.id) { throw new Error('source document id much match '); }
		// Iterate master doc properties
		for (const key of Object.keys(this)) {
			if (key in source) {
				const sourceValue = (<any> source)[key];
				if ((<any> this)[key] !== sourceValue) {
					(<any> this)[key] = sourceValue;
				}
			}
		}
	}

}
