<div class="card">
	<div class='left'>
		<picture>
			<source media="(min-width:576px)" srcset="assets/images/public/breeder-sm.jpg">
			<source media="(min-width:768px)" srcset="assets/images/public/breeder-md.jpg">
			<source media="(min-width:992px)" srcset="assets/images/public/breeder-lg.jpg">
			<source media="(min-width:1200px)" srcset="assets/images/public/breeder-xl.jpg">
			<img src="assets/images/public/breeder-xs.jpg" alt="Breeder">
		</picture>

	</div>
	<div class='card-body right'>
			<div class="card-title">
				<h5 class="text-center">Built For <strong>Breeders</strong></h5>
				<h6 class= "text-center">Presenting your dogs becomes a pleasure!</h6>
			</div>
			<p class="card-text text-sm">A comprehensive framework to manage and market your dogs</p>
			<div class="btn-group btn-group-sm btn-block" role="group">
				<button class="btn btn btn-outline-secondary" (click)="onClick('Learn')">Learn More ...</button>
				<button class="btn btn btn-outline-secondary pl-2" (click)="onClick('Try')">Try Free</button>
			</div>
	</div>
</div>
