import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IconSizes, IconTypes } from '@common/classes/icon';
import { Icons } from '@common/classes/icons';


@Component({
  selector: 'ib-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss']
})
export class ibFooterMenuComponent implements OnInit {

	aboutUsIcon: string;
	contactUsIcon: string;
	teamIcon: string;
	guidesIcon: string;
	helpIcon: string;
	supportIcon: string;

	constructor(private router: Router) {
		this.aboutUsIcon = Icons.publicIcon('aboutUs', 'regular', 'sm').image.classes()
		this.contactUsIcon = Icons.publicIcon('contactUs', 'regular', 'sm').image.classes();
		this.guidesIcon = Icons.generalIcon('guide', 'regular', 'sm').image.classes();
		this.helpIcon = Icons.generalIcon('help', 'regular', 'sm').image.classes();
		this.supportIcon = Icons.generalIcon('support', 'regular', 'sm').image.classes();
	}

  ngOnInit() {
  }

  onClick(e: string) {
		let dest = '';
		switch (e) {
		case 'AboutUs':
			dest = 'Main/AboutUs';
			break;
		case 'ContactUs':
			dest = 'Main/ContactUs';
			break;
		case 'OurTeam':
			dest = 'Main/Team';
			break;
		case 'Guides':
			dest = 'Main/Guides';
			break;
		case 'Help':
			dest = 'Main/Help';
			break;
		case 'Support':
			dest = 'Main/Support';
			break;
		default:
			return;
		}
		this.router.navigate([dest]);
  }


}
