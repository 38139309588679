// -------------------------------------------------------
// Search Input Component
// -------------------------------------------------------
// input to global search


import { forwardRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChange, SimpleChanges, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SearchQuery } from 'common/classes/search';
import { ibSearchService } from '../search.service';
import { ibIcon } from '@common/classes/icon';
import { Icons } from '@common/classes/icons';
import { SearchInputState } from '@public/chrome/header/search-bar/search-bar-status';
import { Subscriptions } from '@common/services/core-services/core.service';

@Component({
	standalone: true,
	selector: 'ib-search-input',
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss'],
	providers: [
			{
				provide: NG_VALUE_ACCESSOR,
				useExisting: forwardRef(() => ibSearchInputComponent),
				multi: true
			}
	]
})
export class ibSearchInputComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit  {

	@Input() searchTerm: string = '';
	@Input() state?: SearchInputState;
	@Output() searchChanged = new EventEmitter<SearchInputState>();

	@ViewChild('sInput') sInput: ElementRef;

	// private searchState: SearchInputState
	private subscriptions: Subscriptions;
	private inputElement: HTMLInputElement;
	public _doSearchIcon: ibIcon;
	public isEmpty: boolean;
	public isFocused: boolean;
	public searchIcon: string;


	constructor(private searchService: ibSearchService) {
		this.subscriptions = new Subscriptions();
		this._doSearchIcon = ibIcon.getIcon('general', 'search');
		this._doSearchIcon.image.size = 'sm';
		this.searchIcon = this.getIcon().imageClasses;
	}

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('state' in changes) {
			if (this.state) {
				this.subscriptions.addSubscription('state', this.state.changed$.pipe(
					debounceTime(400)
				).subscribe((state: SearchInputState) => {
					this.searchChanged.emit(this.state);
				}));
			}
		}

		if ('searchTerm' in changes) {
			if (this.state) {
				this.state.searchTerm = changes.searchTerm.currentValue;
				this.state.state = 'idle'
			}
		}
	}

	ngAfterViewInit() {
		this.inputElement = this.sInput.nativeElement as HTMLInputElement;
		if (this.inputElement) {
			fromEvent(this.inputElement,'focus').subscribe((evt: Event) => {
				this.isFocused = true;
			  });

			fromEvent(this.inputElement,'blur').subscribe((evt: Event) => {
				this.isFocused = false;
			  });

			fromEvent(this.inputElement, 'keyup').subscribe((evt: Event) => {
				this.isEmpty = (!this.inputElement.value || this.inputElement.value.length < 1) as boolean;
				if (evt instanceof KeyboardEvent) {
					switch(evt.key) {
						case 'Enter':
							if (this.state) { this.state.state = 'start'; }
							break;
						case 'Escape':
							this.inputElement.value = '';
							break;
						default:
							if (this.state) { this.state.searchTerm = this.inputElement.value; }
					}
				}
			});
			setTimeout(()=>{
				this.isEmpty = (!this.inputElement.value || this.inputElement.value.length < 1) as boolean;
			},200)
		}
	}

	ngOnDestroy() {
		if (this.subscriptions) { this.subscriptions.unsubscribe(); }
	}

	// Obsolete could be call from key event
	// onInput(e: KeyboardEvent) {
	// 	const t = <HTMLInputElement> e.target;
	// 	let value = '';
	// 	if (e.key === 'Backspace') {
	// 		const parts = Validate.parsePhone(t.value);
	// 		let digits = '';
	// 		if (parts && parts.length === 3) {
	// 			if (parts[2] && parts[2].length > 0) {
	// 				digits = parts[2].replace(/\D/g, '');
	// 				digits.slice(0, -1);
	// 			} else if (parts[1] && parts[1].length > 0) {
	// 				parts[1].slice(0, -1);
	// 			}
	// 			const international = (parts[1]) ? parts[1] + ' ' : '';
	// 			value = (parts) ? international + digits : '';
	// 		} else {
	// 			value = t.value;
	// 		}
	// 		} else {
	// 			value = t.value;
	// 	}
	// 	// this.searchTermChanged.next(value);
	// }

	public doSearch() {
		if (this.state) { this.state.state = 'start'; }
	}

	public get doSearchIcon(): string {
		if (this.isEmpty) {
			this._doSearchIcon.image.color = (this.isFocused) ? 'ib-color-search-button-empty' : 'ib-color-gray-300' ;
			this._doSearchIcon.image.type='light';
		} else {
			this._doSearchIcon.image.color = (this.isFocused) ? 'ib-color-red' : 'ib-color-search-button';
			this._doSearchIcon.image.type='solid';
		}
		return this._doSearchIcon.imageClasses;
	}

	// obsolete
	private getIcon(Q: SearchQuery | null = null): ibIcon {

		// if (!Q) {
		// 	return Icon.createSolid('fa-bullseye-pointer fa-fw', 'ib-color-gray-100', IconSizes.md);
		// }

		// let color: string = 'ib-color-gray-600';
		// switch (Q.state) {
		// 	case SearchStates.empty:
		// 		color = 'ib-color-lightblue';
		// 		break;
		// 	case SearchStates.partial:
		// 		color = 'ib-color-lightblue';
		// 		break;
		// 	case SearchStates.complete:
		// 		color = 'ib-color-success';
		// 		break;
		// 	default:
		// 		color = 'ib-color-gray-600';
		// }

		// switch (Q.target) {
		// 	case SearchTargets.none:
		// 		return Icon.createSolid('fa-bullseye-pointer fa-fw', 'ib-color-gray-600', IconSizes.md);
		// 	case SearchTargets.breeds:
		// 	return Icon.createSolid('fa-paw fa-fw', color, IconSizes.md);
		// 	case SearchTargets.breeders:
		// 		switch (Q.mode) {
		// 			case SearchModes.byAccountID:
		// 				return Icon.createLight('fa-id-badge  fa-fw', color, IconSizes.md);
		// 			case SearchModes.byName:
		// 				return Icon.createLight('fa-id-card fa-fw', color, IconSizes.md);
		// 			case SearchModes.byPhone:
		// 				return Icon.createRegular('fa-phone fa-fw', color, IconSizes.md);
		// 			case SearchModes.byMail:
		// 				return Icon.createSolid('fa-at fa-fw', color, IconSizes.md);
		// 			case SearchModes.byProximity:
		// 				return Icon.createSolid('fa-map-marker-alt fa-fw', 'ib-color-dog', IconSizes.md);
		// 			default:
		// 				return Icon.createSolid('fa-paw fa-fw', 'ib-color-breeder', IconSizes.md);
		// 		}
		// 	case SearchTargets.dogs:
		// 		return Icon.createSolid('fa-paw fa-fw', 'ib-color-dog', IconSizes.md);
		// 	case SearchTargets.litters:
		// 		return Icon.createSolid('fa-paw fa-fw', 'ib-color-litter', IconSizes.md);
		// 	case SearchTargets.puppies:
		// 		return Icon.createSolid('fa-paw fa-fw', 'ib-color-dog', IconSizes.md);
		// 	case SearchTargets.clients:
		// 		return Icon.createSolid('fa-user-friends fa-fw', 'ib-color-client', IconSizes.md);
		// }
		// if ( target === 'contact' ) {
		//     switch (state) {
		//         case 'empty' : return Icon.createLight("fa-rss fa-fw", "ib-color-gray-600", IconSizes.md);
		//         case 'phonePartial' : return Icon.createLight("fa-phone fa-fw fa-flip-horizontal", "ib-color-gray-800", IconSizes.md);
		//         case 'phone' : return Icon.createSolid("fa-phone fa-fw fa-flip-horizontal", "ib-color-success", IconSizes.md);
		//         case 'eMailPartial' : return Icon.createLight("fa-at fa-fw", "ib-gray-800", IconSizes.md);
		//         case 'eMail' : return Icon.createSolid("fa-at fa-fw", "ib-color-success", IconSizes.md);
		//         default : return Icon.createRegular("fa-rss fa-fw", "ib-color-gray-800", IconSizes.md);
		//     }
		// }

		// if ( target === 'name' ) {
		//     switch (state) {
		//         case 'empty' : return Icon.createLight("fa-id-badge fa-fw", "ib-color-gray-600", IconSizes.md);
		//         case 'entered' : return Icon.createLight("fa-id-badge fa-fw", "ib-color-gray-800", IconSizes.md);
		//         case 'validated' : return Icon.createSolid("fa-id-badge fa-fw", "ib-color-success", IconSizes.md);
		//         case 'duplicate' : return Icon.createRegular("fa-id-badge fa-fw", "ib-color-warning", IconSizes.md);
		//         default : return Icon.createRegular("fa-id-badge fa-fw", "ib-color-gray-600", IconSizes.md);
		//     }
		// }

		// if ( target === 'verify') {
		//     switch (state) {
		//         case 'empty' : return Icon.createLight("fa-lock fa-fw", "ib-color-gray-600", IconSizes.md);
		//         case 'entered' : return Icon.createLight("fa-unlock fa-fw", "ib-color-gray-800", IconSizes.md);
		//         case 'validated' : return Icon.createSolid("fa-lock-open fa-fw", "ib-color-success", IconSizes.md);
		//         default : return Icon.createRegular("fa-lock fa-fw", "ib-color-gray-600", IconSizes.md);
		//     }
		// }

		return Icons.generalIcon('paw');
		// return Icon.createSolid('fa-bullseye-pointer fa-fw', 'ib-color-gray-600', IconSizes.md);
	}




}
