<div id='wrapper'>
	<div class="k-loading-panel">
		<div class="k-loading-panel-mask"></div>
		<div class="k-loading-panel-wrapper">
			<kendo-loader
				type="infinite-spinner"
				themeColor="primary"
				size="large">
			</kendo-loader>
			<div class="k-loading-panel-text">Loading...</div>
		</div>
	</div>
	<div class="example-text">Example Container</div>
</div>
