<div class="card">
    <ib-image source="@public/girl-puppy" alt="Girl with puppy" class="card-img-top img-fluid"></ib-image>
    <div class="card-body">
        <div class="card-title">
            <h5>The purest <strong>joy</strong></h5>
            <p class="card-text text-right text-sm">Carefully bred puppies guaranty breed
                standards, eliminate genetic problems, and insure a beautifully fit with your family
            </p>

        </div>
        <div class="menu">
            <i class="{{aboutUsIcon}}"></i>
            <a id='about' [routerLink]="[]" (click)="onClick('AboutUs')">About us</a>

            <a id='guide' [routerLink]="[]" (click)="onClick('Guides')">Guides</a>
            <i class="{{guidesIcon}}"></i>

            <i class="{{contactUsIcon}}"></i>
            <a id='contact' [routerLink]="[]" (click)="onClick('ContactUs')">Contact Us</a>

            <a id='help' [routerLink]="[]" (click)="onClick('Help')">Help</a>
            <i class="{{helpIcon}}"></i>
        </div>
    </div>
</div>
