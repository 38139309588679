
	<div class="grid">
		<ib-footer-tracking></ib-footer-tracking>
		<ib-footer-main></ib-footer-main>
		<ib-footer-menu></ib-footer-menu>
		<ib-footer-address></ib-footer-address>
		<ib-footer-breeder></ib-footer-breeder>
	</div>
	<ib-footer-bottom></ib-footer-bottom>


