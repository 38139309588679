import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Subscriptions } from '@common/services/core-services/core.service';
import { SearchInfo, SearchTargetScope } from './search-info';
import { DisplayTypes } from '@common/classes/types';
import { SearchQuery, SearchTargets } from '@common/classes/search';
import { Validate } from '@common/classes/data/validate';
import { SearchSelection } from './search-selection';


// import { SearchModes, SearchStates, SearchTargets } from 'common/classes/enums';
// import { SearchQuery } from 'common/classes/search';
// import { Validate } from 'common/tools';
// import { BreedName } from 'common/entities/breed-name';
// import { BreedStub } from 'common/entities/breed-stub';
// import { DisplayTypes } from 'common/classes/types';
// import { ibBreedService } from 'common/services/entity-services/breed.service';

@Injectable({ providedIn: 'root' })
export class ibSearchService implements OnDestroy {

	private subs: Subscriptions;
	private _selection: BehaviorSubject<SearchSelection>;
	private _info: BehaviorSubject<SearchInfo>;

	constructor() {
		this.subs = new Subscriptions();
		this._selection = new BehaviorSubject<SearchSelection>(new SearchSelection());
		this._info = new BehaviorSubject<SearchInfo>(new SearchInfo());

		// get search info from storage
		this.subs.addSubscription('storage', SearchInfo.fromStorage().subscribe((i: SearchInfo) => {
			this.saveInfo(i);
		}));
	}

	public get selection(): Observable<SearchSelection> {
		return this._selection.asObservable();
	}

	public get info(): Observable<SearchInfo> {
		return this._info.asObservable();
	}

	public ngOnDestroy() {
		this.subs.unsubscribe();
	}

	// public get breed(): BreedStub | undefined  {
	// 	if (this.info && this.info.value) {
	// 		return this.info.value.breed;
	// 	} else {
	// 		return undefined;
	// 	}
	// }

	// public set breed(value: BreedStub | undefined) {
	// 	if (this.info && this.info.value) {
	// 		const i = this.info.value;
	// 		const currentID = (i.breed) ? i.breed.breedID : '';
	// 		if (value && value.breedID !== currentID) { i.breed = value; } else { i.breed = undefined; }

	// 		this.saveInfo(i);
	// 	}
	// }

	// public setBreedFromID(breedID?: string) {
	// 	if (breedID) {
	// 		this.breedService.getBreedStub(breedID).pipe(take(1)).subscribe((stub: BreedStub) => {
	// 			this.breed = stub;
	// 			// this.saveInfo();
	// 			// this.breed = (!this.breed || (this.breed && this.breed.breedID !== stub.breedID)) ? stub : this.resetBreed();
	// 		});
	// 	} else {
	// 		this.breed = undefined;
	// 		// this.saveInfo();
	// 	}
	// }

	// public setBreedFromName(breedName?: BreedName) {
	// 	if (breedName) {
	// 		this.setBreedFromID(breedName.breedID);
	// 	} else {
	// 		this.setBreedFromID();
	// 	}
	// }

	public get target(): SearchTargets {
		return this._info.value.target;
	}

	public set target(target: SearchTargets) {
		if (this._info && this._info.value) {
			const i = this._info.value;
			i.target = target;
			this.saveInfo(i);
		}
	}

	public setDisplayType(type: DisplayTypes) {
		if (this._info && this._info.value) {
			const i = this._info.value;
			i.displayType = type;
			this.saveInfo(i);
		}
	}

	public setBreeder(id: string) {
		if (this._info && this._info.value) {
			const i = this._info.value;
			i.target = 'breeders';
			i.targetScope = SearchTargetScope.info;
			i.targetID = id;
			i.displayType = 'page';
		}
	}

	public setQuery(Q: SearchQuery) {
		if (this._info && this._info.value) {
			const i = this._info.value;
			i.displayType = 'page';
			i.setTarget(Q.target, SearchTargetScope.info, Q.formattedSearchTerm);
			this.saveInfo(i);
		}
	}

	public get SearchText(): string { return this._info.value.searchText; }
	public set SearchText(value: string) {
		this._info.value.searchText = value;
	}

	public parse(searchTerm: string, distance: number = 0): SearchQuery {
		const Q = new SearchQuery();

		if (!searchTerm) { return Q; }

		const parts = searchTerm.toLowerCase().split(' ');
		const target = parts[0];
		if (parts.length > 1)  { parts.splice(0, 1); }
		const term = parts.join(' ');

		switch (target) {
			case 'name': return this.byName(term, distance);
			case 'accountid': return this.parseAccountID(term);
			case 'phone': return this.parsePhone(term, distance);
			case 'email': return this.parseEmail(term, distance);
			case 'contact': return this.parseContact(term, distance);
			case 'address': return this.parseAddress(term);
			case 'city': return this.parseCity(term, distance);
			case 'region':
			case 'state': return this.parseRegion(term);
			case 'code':
			case 'postal':
			case 'postalcode':
			case 'zip': return this.parsePostalCode(term);
			case 'country': return this.parseCountry(term);
			case 'coordinates': return this.parseCoordinates(term);
			default:
				return this.parseAny(term);
		}
	}

	private byName(value: string, distance: number = 0): SearchQuery {
		return SearchQuery.CreateFuzzy('byName', value, distance);
	}

	private parseAccountID(value: string): SearchQuery {
		return SearchQuery.CreateField('byField', 'accountID', value);
	}

	private parsePhone(value: string, distance: number = 0): SearchQuery {
		return SearchQuery.CreateFuzzy('byPhone', value, distance);
	}
	private parseEmail(value: string, distance: number = 0): SearchQuery {
		return SearchQuery.CreateFuzzy('byMail', value, distance);
	}

	private parseContact(value: string, distance: number = 0): SearchQuery {
		return SearchQuery.CreateField('byField', 'contact.name', value);
	}

	private parseAddress(value: string): SearchQuery {
		return new SearchQuery();
	}
	private parseCity(value: string, distance: number = 0): SearchQuery {
		return new SearchQuery();
	}

	private parseRegion(value: string): SearchQuery {
		return new SearchQuery();
	}
	private parsePostalCode(value: string): SearchQuery {
		return new SearchQuery();
	}
	private parseCountry(value: string): SearchQuery {
		return new SearchQuery();
	}
	private parseCoordinates(value: string, distance: number = 1000): SearchQuery {
		return SearchQuery.CreateFuzzy('byProximity', value, distance);

	}
	private parseAny(value: string, target: SearchTargets = 'breeders'): SearchQuery {

		let Q: SearchQuery = new SearchQuery();
		Q.searchTerm = value;

		if (!value) {
			Q.mode = 'none';
			Q.state = 'empty';
			Q.target = 'none';

			return Q;
		}

		const res = Validate.accountId(value);
		if (res.state === 'complete') {
			const formattedValue = res.formattedValue;
			Q = SearchQuery.CreateField('byAccountID', 'accountID', value, formattedValue);
			Q.target = 'breeders';      // target is always breeder
			Q.state = 'complete';
			Q.isValid = true;
			console.log('Parse Any: VALID  \'' + Q.formattedSearchTerm + '\'');

			return Q;
		}

		const parsed = Validate.parsePhone(value);
		if (parsed) {
			Q = SearchQuery.CreateFuzzy('byPhone', value);
			Q.target = target;
			Q.state = 'partial';
			const digits = parsed[2].replace(/\D/g, '');
			const l = digits.length;
			if (l === 10) {
				Q.state = 'complete';
				Q.isValid = Validate.isPhone(digits);
				Q.formattedSearchTerm = (Q.isValid) ? Validate.formatPartialPhoneNumber(value) : value;
			} else {
				Q.state = 'partial';
				Q.isValid = Validate.isPhone((digits + '0000000000').substr(0, 10));
				Q.formattedSearchTerm = Validate.formatPartialPhoneNumber(value);
			}
			return Q;
		}

		if (Validate.iseMail(value)) {
			Q.target = target;
			Q.mode = 'byMail';
			Q.isValid = Validate.iseMail(value);
			if (Q.isValid) {
				Q.state = 'complete';
				Q.formattedSearchTerm = Q.searchTerm;
			} else {
				if (Validate.isPartialEMail(value)) {
					Q.state = 'partial';
					Q.formattedSearchTerm = Q.searchTerm;
				} else {
					Q.mode = 'byName';
					Q.state = 'partial';
					Q.formattedSearchTerm = '';
				}
			}
			return Q;
		}

		Q = SearchQuery.CreateFuzzy('byName', value);
		Q.target = target;
		Q.state = 'complete';
		return Q;
	}


	private saveInfo(info?: SearchInfo): SearchInfo {

		if (!info) { info = this._info.value; }

		if (info.breed && info.breed.breedID !== 'blank') {
			switch (info.target) {
				case 'none':
				case 'breeds':
					info.target = 'breeds';
					info.targetScope = SearchTargetScope.info;
					info.action = `Breed/${info.breed.breedID}`;
					break;
				case 'breeders':
					break;
				case 'litters':
				case 'puppies':
				case 'clients':
				default:
					info.action = 'Home';
			}

		} else {
			info.breed = undefined;
			info.target = 'breeds';
			info.targetScope = SearchTargetScope.all;
			info.location.enabled = false;
			if (info.displayType === 'map') { info.displayType = 'show'; }
			if (info.displayType === 'show') { info.action = 'home'; } else { info.action = 'Search/Breeds'; }
		}

		info.toStorage();
		this._info.next(info);


		return info;
	}


}
