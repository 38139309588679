import { map } from 'rxjs/operators';
import { forkJoin, from, of, Observable } from 'rxjs';
import { BreedStub } from '@entities/parts/breed-stub';
import { ibSearchRange } from '@common/classes/lib';
import { DisplayTypes, NameFilters } from '@common/classes/types';
import { Slide } from '@common/classes/slide';
import { ImageQuery } from '@common/classes/imageQuery';
import { OwnerTypes } from '@entities/parts/owner';
import { ImageStub } from '@common/entities/documents/image-meta-document';
import { Tools } from '@common/tools';
import { SearchTargets } from '@common/classes/search';


// search info
// ----------------------------------------------
// keeps the state of the search
// ----------------------------------------------
// version 1 - cannot search breeders with no breed

export enum SearchTargetScope {
	none = 0,
	now = 1,
	soon = 2,
	info = 3,
	all = 100
}


export class SearchInfo {

	// private static breedService: ibBreedService;
	private _breed: BreedStub | Array<BreedStub> | undefined;
	private _target?: SearchTargets;
	private _targetScope: SearchTargetScope = SearchTargetScope.none;
	private _targetID?: string;
	private _location?: ibSearchRange;
	private _displayType?: DisplayTypes;
	private _nameFilter?: NameFilters;
	private _action?: string;

	constructor() {
		// if (!SearchInfo.breedService) { SearchInfo.breedService = Defaults.injectorInstance.get<ibBreedService>(ibBreedService); }
	}

 	public searchText: string = '';


	// Breed --------------------------
	public get breed(): BreedStub | undefined {
		if (this._breed) {
			if (Array.isArray(this._breed)) {
				return (this._breed.length > 0) ? this._breed[0] : undefined;
			} else {
				return this._breed;
			}
		} else {
			return undefined;
		}
	}

	public set breed(value: BreedStub | undefined) {
		this._breed = value;
		this.evaluate();
	}



	// Target --------------------------
	public get target(): SearchTargets {
		if (!this._target) { this._target = 'breeds'; }
		return this._target;
	}
	public set target(value: SearchTargets) {
		this._target = value;
		this.evaluate();
	}

	public get targetScope(): SearchTargetScope {
		if (!this._targetScope) { this.targetScope = SearchTargetScope.all; }
		return this._targetScope;
	}
	public set targetScope(value: SearchTargetScope) {
		this._targetScope = value;
		this.evaluate();
	}

	public get targetID(): string {
		return this._targetID + '';
	}
	public set targetID(value: string) {
		this._targetID = value;
	}

	public setTarget(target: SearchTargets, scope: SearchTargetScope, id?: string) {
		this._target = target;
		this._targetScope = scope;
		if (id) { this.targetID = id; }
		this.evaluate();
	}

	// Location ----------------------------
	public get location(): ibSearchRange {
		if (!this._location) { this._location = ibSearchRange.createDisabled(); }
		return this._location;
	}
	public set location(value: ibSearchRange) {
		this._location = value;
	}

	// Display Type ------------------------
	public get displayType(): DisplayTypes { return (this._displayType) ? this._displayType : 'show'; }
	public set displayType(value: DisplayTypes) {
		this._displayType = value;
	}

	// Name Filter -------------------------
	public get nameFilter(): NameFilters { return (this._nameFilter) ? this._nameFilter : 'official'; }
	public set nameFilter(value: NameFilters) { this._nameFilter = value; }

	// action (url for search state)
	public get action(): string { return this._action + ''; }
	public set action(value: string) { this._action = value; }

	private evaluate() {
		if (this.breed) {
			switch (this._target) {
				case 'none':
					this._target = 'breeds';
					this._targetScope = SearchTargetScope.info;

					switch (this._displayType) {
						case 'show':
							this._action = `breedShow`;
							break;
						case 'list':
							this._action = `Breed/${this.breed.breedID}`;
							break;
						case 'map':
						default:
							this._action = `Breed/${this.breed.breedID }`;
					}
					break;
				case 'breeds':
					this._targetScope = SearchTargetScope.info;

					switch (this._displayType) {
						case 'show':
							this._action = `breedShow`;
							break;
						case 'list':
							this._action = `Breed/${this.breed.breedID}`;
							break;
						case 'map':
						default:
							this._action = `Breed/${this.breed.breedID}`;
					}
					break;
				case 'breeders':
					switch (this._targetScope) {
						case SearchTargetScope.info:
							if (this.displayType === 'page') {
								this._action = this._targetID;
							}
							break;
						default:
							this._action = `Main/Breeders`;


					}
					break;

				case 'litters':
				case 'puppies':
				case 'clients':
				default:
					this._action = 'breedShow';
			}
		} else {
			this._target = 'breeds';
			this._targetScope = SearchTargetScope.all;
			this.location.enabled = false;
			if (this.displayType === 'map') { this.displayType = 'show'; }
			if (this.displayType === 'show') { this._action = 'home'; } else { this._action = 'Search/Breeds'; }
		}

	}

	// execute a change in search
	// public execute(): Observable<SearchItems> {

	// 	if (this.displayType === 'List') {
	// 		alert('search for list');

	// 	} else if (this.displayType === 'Show') {
	// 		alert('search for show');

	// 	} else if (this.displayType === 'Map') {
	// 		alert('search for map');

	// 	}

	// 	return of(new SearchItems(this));
	// }

	public getSlides(): Observable<Array<Slide>> {

		switch (this._target) {
			case 'breeds':
				// eslint-disable-next-line no-case-declarations
				const imageQuery = new ImageQuery();
				imageQuery.ownerType = OwnerTypes.Breed;
				if (!this.breed) {
					imageQuery.ownerID = 20;
					imageQuery.names = 3;
				} else {
					imageQuery.ownerID = <string> this.breed.breedID;
					imageQuery.names = -1;
				}

				return from(imageQuery.run()).pipe(
					map((res: Array<ImageStub>) => {
						let s: Array<Slide> = new Array<Slide>();
						Slide.FromImageStubs(res).subscribe((val: Array<Slide>) => {
							s = val;
						});
						return s;
					}));

			case 'breeders':
				return of(new Array<Slide>());
			case 'litters':
				return of(new Array<Slide>());
			case 'dogs':
				return of(new Array<Slide>());
			case 'puppies':
				return of(new Array<Slide>());
			case 'clients':
				return of(new Array<Slide>());
			case 'none':
				return of(new Array<Slide>());
			default:
				return of(new Array<Slide>());
		}
	}

	public getBreederQuery(): string {
		const breedIDClause = (this.breed) ? `AND breed.breedID = '${this.breed.breedID}'` : '';
		let distanceClause = '';
		if (this.location.distance < 12000000) { this.location.distance = 12000000; }
		if (this.location && this.location.distance > 0) {
			distanceClause = `AND ST_DISTANCE(breeder.position, {
				'type': 'Point',
				'coordinates': [${ this.location.longitude }, ${ this.location.latitude }]
			}) < ${ this.location.distance }`;
		}

		return `
		SELECT
			breeder.accountID,
			breeder.name,
			breeder.shortName,
			breeder.kennelName,
			breeder.breeds,
			breeder.status,
			ST_DISTANCE(breeder.position,
				{ 'type': 'Point', 'coordinates': [${this.location.longitude}, ${this.location.latitude}] }) as distance,
			loc.address.city,
			loc.address.region,
			loc.address.position.coordinates
		FROM breeder
		JOIN breed IN breeder.breeds
		JOIN loc IN breeder.contactInfo.locations
		where breeder.DType = 'BreederDocument'
		AND loc.name = 'Main'
		${ breedIDClause }
		${ distanceClause}`;
	}


	public get breedButtonCaption(): string {
		if (!this.breed) {
			 return SearchInfo.NoBreedString;
		} else {
			return this.breed.breedName;
		}
	}


	public get description(): string {
		let d = 'search for';
		d = d + '...';
		return d;
	}


	// public get breedString(): string {
	//     if (!this.breeds || this.breeds === SearchInfo.NoBreedString) {
	//         return 'any breed';
	//     } else if (Array.isArray(this.breeds)) {
	//         if (this)
	//     } else {
	//          return this.breeds;
	//     }
	// }

	public get caption(): string {
		if (this.targetScope === SearchTargetScope.all) {
			return `${Tools.toTitleCase(SearchTargetScope[this.targetScope])} ${this.target}`;
		} else {
			return `${Tools.toTitleCase(this.target)} ${SearchTargetScope[this.targetScope]}`;
		}
	}

	public get targetString(): string {
		return `${this.target}-${SearchTargetScope[this.targetScope]}`;
	}

	public set targetString(value: string) {
		const parts = value.split('-');
		if (parts.length !== 2) { throw new Error('Invalid target string'); }
		const t = parts[0] as SearchTargets;
		const q: SearchTargetScope = (<any> SearchTargetScope)[parts[1]];
		this.target = (t) ? t : 'none';
		this.targetScope = (t) ? q : SearchTargetScope.none;
	}

	public static get NoBreedString(): string { return 'Select a breed'; }


	public toStorage(): void {
		const info = new SearchItems(this);
		localStorage.setItem('searchInfo', JSON.stringify(info));
	}

	public static fromStorage(): Observable<SearchInfo> {
		const promise = new Promise<SearchInfo>((resolve, reject) => {
			try {

				const info = new SearchInfo();
				const ret = localStorage.getItem('searchInfo');
				if (ret) {
					const i = <SearchItems> JSON.parse(ret);
					info.target = i.target;
					info.targetScope = i.targetScope;
					info.location = i.location;
					info.displayType = i.displayType;
					if (i.breedID.length === 0) {
						info._breed = undefined;
						resolve(info);
					} else if (i.breedID.length === 1) {
						// SearchInfo.breedService.getBreedStub(i.breedID[0]).pipe(take(1)).subscribe((stub: BreedStub) => {
						// 	info._breed = stub;
						// 	resolve(info);
						// });
					} else {
						const observables = new Array<Observable<BreedStub>>();
						// i.breedID.forEach((id: string) => {
						// 	observables.push(SearchInfo.breedService.getBreedStub(id));
						// });
						forkJoin(observables).subscribe((stubs: Array<BreedStub>) => {
							info._breed = stubs;
							resolve(info);
						});
					}
				} else {
					resolve(info);
				}

			} catch (err) {
				reject((err instanceof Error) ? err.message : err);
			}
		 });
		 return from (promise);
	}
}



export class SearchItems {

	public breedID: Array<string>;
	public target: SearchTargets;
	public targetScope: SearchTargetScope;
	public location: ibSearchRange = new ibSearchRange();
	public displayType: DisplayTypes;

	constructor (info?: SearchInfo) {

		if (info) {
			this.breedID = new Array<string>();
			if (info.breed && info.breed instanceof BreedStub) {
				this.breedID.push(info.breed.breedID);
			} else if (info.breed && Array.isArray(info.breed)) {
				(<Array<BreedStub>> info.breed).forEach((item: BreedStub) => { this.breedID.push(item.breedID); });
			}
			this.target = info.target;
			this.targetScope = info.targetScope;
			this.displayType = info.displayType;

		} else {
			this.breedID = new Array<string>();
			this.target = 'none';
			this.targetScope  = SearchTargetScope.none;
			this.location = new ibSearchRange();
			this.displayType = 'show';
		}
	}
}
