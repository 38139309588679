import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ib-footer-breeder',
  templateUrl: './footer-breeder.component.html',
  styleUrls: ['./footer-breeder.component.scss']
})
export class ibFooterBreederComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onClick(e: string) {
	let dest = '';
	switch (e) {
		case 'Learn':
		dest = '/Main/BreederSpace/Welcome';
		break;
		case 'Try':
		dest = 'Breeder/Register';
		break;
		default: return;
	}

	this.router.navigate([dest]);
  }

}
