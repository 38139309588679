// ======================================================
// SHARED IMAGE COMPONENT
// ======================================================
// Selects the proper sized image automatically when view port is resized
//
// Usage provide src url, alternative text and any classes to apply to the image


import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AssetImageSource } from '@common/classes/imageSource';
import { AssetImageCategories, AssetImageSizes, ImageExtensions } from '@common/classes/types';


@Component({
    selector: 'ib-image',
    templateUrl: './ib-image.component.html'
})
export class ibImageComponent implements OnInit, OnChanges {
    private _HostClasses: string;
	public urls: Map<AssetImageSizes, string>;

    @Input() source: string;
    @Input() alt: string;

    constructor(private host: ElementRef) {

		// const h: HTMLElement = this.host.nativeElement; // get additional classes on element
        // this._HostClasses = h.classList.toString();
        // console.log('ib-image: ' + this._HostClasses);
        // h.className = '';
		this._HostClasses = 'img-fluid';

		this.urls = new Map<AssetImageSizes,string>();

    }

    ngOnInit(): void {
        const h: HTMLElement = this.host.nativeElement; // get additional classes on element
        this._HostClasses = h.classList.toString();
        h.className = '';

    }

	ngOnChanges(simpleChanges: SimpleChanges) {

		if ('source' in simpleChanges) {

			if (!this.source) {
				this.urls.clear;
			} else {
				if (this.source.startsWith('@')) {
					this.source = this.source.substring(1);
					this.loadFromAssets();
				} else {
					this.loadFromBlob();
				}
			}
		}
	}

	private loadFromAssets() {

		let name: string = '';
		let category: AssetImageCategories = undefined;
        let subPath: string = '';
		let extension: ImageExtensions = 'jpg';

		const _parts = this.source.split('.');
		let _link = '';
        // main path
		if (_parts.length > 0) {
			_link = this.cleanUrl(_parts[0]);
			let _subParts = _link.split('/');
            if (!_subParts) { throw new Error('Invalid source: the source is empty'); }

            // possible formats cat/[path]/name
            // extract category
            const c = <AssetImageCategories>_subParts[0];
            category = (c === 'admin' || c=== 'common' || c==='public') ? c : undefined;
            if (category) { _subParts = _subParts.slice(1)}                                 // remove category
            if (_subParts.length < 1) { throw new Error('Invalid Source: invalid path'); }
            name = <string>_subParts.pop();
            subPath = _subParts.join('/');

			// if (!_subParts || _subParts.length > 2) {
			// 	throw new Error('Invalid source: too many parts');
			// } else if (_subParts.length === 2) {
			// 	const c = <AssetImageCategories>_subParts[0];
			// 	category = (c === 'admin' || c=== 'common' || c==='public') ? c : undefined;
			// 	name = _subParts[1]
			// } else {
			// 	category = undefined
			// 	name = _subParts[0];
			// }
		}
        // extension
		if (_parts.length > 1) {
			const e = <ImageExtensions>_parts[1];
			extension = (e === 'jpg' || e=== 'png' || e==='webp' || e==='svg') ? e : 'jpg';
		}

		this.urls = AssetImageSource.getUrls(name, category, subPath, extension)

	}

	private loadFromBlob() {}






    // public get SizedSource(): string {
    //     return this._SizedSource;
    // }


    // public set SizedSource(size: string) {
    //     // Calculate the sized image name
    //     if (this.source) {
    //         const _parts = this.source.split('.');
    //         let _ext = '.jpg';
    //         let _link = '';
    //         if (_parts.length > 0) {
    //             _link = this.cleanUrl(_parts[0]);
    //         }
    //         if (_parts.length > 1) {
    //             _ext = '.' + _parts[1];
    //         }
    //         this._SizedSource = 'assets/images/' + _link + '-' + size + _ext;
    //     } else {
    //         this._SizedSource = '';
    //     }
    // }

    public get HostClasses(): string {
        return this._HostClasses;
    }

    // -----------------------------------
    // Clean url
    // -----------------------------------
    // converts backslashes to forward slashes - trim slashes
    cleanUrl(value: string): string {
        value = value.trim();
        value = value.split('\\').join('/');
        value = value.split('//').join('/');

        if (value[0] === '/') {
            value = value.substring(1);
        }

        if (value[value.length - 1] === '/') {
            value = value.substring(0, value.length - 1);
        }

        return value;
    } // end clean string
}
