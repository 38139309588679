// -----------------------------------------
// GeoPoint
// -----------------------------------------
// compatible with CosmosDB Longitude first then latitude

export class GeoPoint {


	public static assign(source: Record<string, any>): GeoPoint {

		// if (!source) { throw new Error('The source is missing'); }

		const gp = new GeoPoint();

		if (source) {
			if (source.hasOwnProperty('type')) {
				gp.type = (<any> source)['type'];
			} else {
				throw new Error('The Geo Point type is required and should be \'Point\'');
			}

			if (source.hasOwnProperty('coordinates')) {
				gp.coordinates = <Array<number>> (<any> source)['coordinates'];
			} else {
				throw new Error('The Geo Point coordinates are required and should be \'Point\'');
			}
		}

		return gp;

	}


	constructor() {
		this.type = 'Point';
		this.coordinates = new Array<number>();
		this.coordinates[0] = -98.35;
		this.coordinates[1] = 39.50;
	}

	public type: 'Point' | '';
	public coordinates: Array<number>;

	public get longitude(): number { return this.coordinates[0]; }
	public get latitude(): number { return this.coordinates[1]; }

	public toString() {
		return `latitude: ${this.latitude} longitude: ${this.longitude}`;
	}

}

