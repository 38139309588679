import { PhoneTypes } from '@common/classes/enums';
import { ValidationResult } from '@common/classes/types';
import { Format, Tools } from '@common/tools';
import { timeStamp } from 'console';

	export class Phone {

		static assign(source: Record<string, any>, target: Phone | null = null): Phone {

			if (!source) {throw new Error('source is missing'); }
			let t: Phone;

			if (!target) {
				t = new Phone();
			} else {
				t = target;
			}

			if ('name' in source) {
				t.name = (<any> source)['name'];
			} else { throw new Error('Name is required'); }

			if ('index' in source) {
				t.index = (<any> source)['index'];
			} else { t.index = -1; }

			if ('type' in source) {
				t.type = (<any> source)['type'];
			} else { t.type = PhoneTypes.LandLine; }

			if ('number' in source) {
				t.number = (<any> source)['number'];
			} else { throw new Error('Number is required'); }

			if ('extension' in source) {
				t.extension = (<any> source)['extension'];
			} else { t.extension = ''; }

			if ('comments' in source) {
				t.comments = (<any> source)['comments'];
			} else { t.comments = ''; }

			return t;
		}

		constructor(name: string = '') {
			if (name) { this.name = name; }
			this.index = -1;
			this.type = PhoneTypes.LandLine;
			this.smsEnabled = false;
		}

		public name: string = '';
		public index: number;
		public type: PhoneTypes;
		public number: string ='';
		public extension?: string;
		public comments?: string;
		public smsEnabled: boolean = false;

	}

