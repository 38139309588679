// ====================================================
// pattern
// ====================================================
// matches a given pattern and gives result: match empty, partial, complete and error
// #: digit x: lowercase X: uppercase a: alpha $: symbol (: left parent
// ) right parent -dash space

// NOT COMPLETE only phone handled 3/19/2022 expects only digits

import { InputState } from "@common/components/input-components/classes/input-state";
import { InputCompletionState } from "../types";
import { Validate } from "./validate";

// will replace format in tools

export type Patterns = 'USPhone';

export class Pattern {

	public static get usPhonePattern(): string { return '(###) ###-####'; }

	private _pattern: Array<string>;

	public constructor(pattern: string | Pattern = 'usPhone') {
		this.pattern = <string>pattern;
	}

	public get pattern(): string { return this._pattern.join(''); }
	public set pattern(value: string) {
		if (value === 'usPhone') {
			this._pattern = [...Pattern.usPhonePattern];
		} else {
			this._pattern = [...value];
		}
	}

	// evaluate completion state of input for value
	public match(value: string): InputCompletionState {
		if (!value) { return 'empty'; }
		if (value.length > this._pattern.length) { return 'error'; }
		if (value.length > 0 && value[0] !== '(') { value = '(' + value; }
		const v = [...value];
		let match = 0;
		for (let i = 0; i < v.length; i++) {
			if (v[i] === this._pattern[i]) {
				match++;
			} else if (Validate.isDigit(v[i])) {
				if (this._pattern[i] === '#') { match++ } else { break; }
			} else {
				match = 0;
				break;
			}
		}

		let res: InputCompletionState = 'empty';
		if (match < 1 || v.length > match) {
			res = 'error';
		} else if (match === this._pattern.length) {
			res = 'complete';
		} else {
			res = 'partial'
		}
		return res;
	}

	// formats or reformats string as per template
	public format(value: string): string {
		if (!value) { return ''; }
		const buffer = new  Array<string>();
		const raw = this.toRawArray(value);							// insure input is raw

		for ( const char of this._pattern) {
			if (!raw || raw.length < 1) { continue;}
			if (char !== '#') {
				buffer.push(char);
			} else {
				const c = raw.shift();
				if (c) { buffer.push(c); }
			}
		}
		return buffer.join('');
	}

	// process user input for phone
	public process(e: HTMLInputElement, state: InputState, key?: string): InputState {
		if (!state.value) {
			state.completionState = 'empty';
			return state;
		}
		if (key?.startsWith('Arrow')) { return state; }
		if (key === 'Backspace' || key === 'Delete') {
			state = this.remove(e, state, key);
			if (state.field) {
				state.field.resetErrors();
				state.field.runValidation();
			}
			return state;
		}
		let start = 0;
		const value = state?.value + '';
		if (state?.value && state?.value.length <= this._pattern.length) {
			if (!state.selection.isSelected) {
				state.selection.start = state.value.length;
				state.selection.end = state.selection.start;
			}
			const raw = this.toRaw(value);
			start = (value) ? this.toRawPosition(value, state.selection.start) : 0;

			state.value = this.format(raw);
			state.selection.start = this.toFormattedPosition(start);
			state.selection.end = state.selection.end;
			state.completionState = this.match(state.value);

			e.value = state.value;
			e.selectionStart = state.selection.start;
			e.selectionEnd = e.selectionStart;
		}
		return state;
	}

	// Remove all placeholders / symbols - only for all digits patterns Return array
	public toRawArray(value: string | Array<string>): Array<string> {
		let raw = (typeof value === 'string') ? [ ...value ] : value;
		return raw.filter((v: string) => Validate.isDigit(v));
	}

	// Remove all placeholders / symbols - only for all digits patterns Return string
	public toRaw(value: string | Array<string>): string {
		return this.toRawArray(value).join('');
	}

	// calculate the raw position from formatted position
	public toRawPosition(value: string, formattedPosition?: number | null): number {
		if (!formattedPosition) { return 0; }
		const formattedLeftSide = value.substring(0, formattedPosition);
		const rawLeftSide = this.toRaw(formattedLeftSide);
		return rawLeftSide.length;
	}

	public toFormattedPosition(rawIndex: number): number {
		if (!this._pattern || rawIndex < 0) { return -1; }
		let patternPosition: number = -1;
        let rawPosition: number = -1;
        for (const v of this._pattern) {
			patternPosition++;
			if (v === '#') {
				rawPosition++;
				if (rawPosition === rawIndex) {
					return patternPosition;
				}
			}
        }
		return patternPosition + 1;
	}

	public remove(e: HTMLInputElement, state:InputState, key: string): InputState {
		if (! state.value || !state.selection.isSelected) { return state}
		const value = state.value + '';
		const offset = (key === 'Backspace') ? -1 : 0;
		const rawValue = this.toRaw(value);
		const rawStart = this.toRawPosition(state.value, state.selection.start!);
		const rawEnd = this.toRawPosition(state.value, state.selection.end!);
		const start = rawStart + offset;
		const end = (offset) ? rawEnd : rawEnd + 1;

		const startSegment = rawValue.slice(0, start);
		// const removeSegment = rawValue.slice(start, rawEnd);
		const endSegment = rawValue.slice(end);

		state.value = this.format(startSegment + endSegment);
		state.selection.start = (state.value) ? this.toFormattedPosition(start) : 0;
		state.selection.end = state.selection.start;
		state.completionState = this.match(state.value);

		e.value = state.value;
		e.selectionStart = state.selection.start;
		e.selectionEnd = e.selectionStart;

		return state;
	}
}

