import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ibCoreService, ResizeData } from '@common/services/core-services/core.service';
import { SimpleAddress, iBreederInfo } from '@common/classes/iBreederInfo';

@Component({
  selector: 'ib-footer-address',
  templateUrl: './footer-address.component.html',
  styleUrls: ['./footer-address.component.scss']
})
export class ibFooterAddressComponent implements OnInit {

	public size: string = 'md';
	public info?: iBreederInfo;
	public address: SimpleAddress;

	constructor(
		private coreService: ibCoreService,
		private router: Router) {
		this.address = new SimpleAddress();
	}

	ngOnInit() {

		this.coreService.getIBreederInfo().pipe(take(1)).subscribe((info: iBreederInfo | undefined) => {
			if (info) {
				this.info = info;
				if (this.info.address) { this.address = this.info.address; }
			}
		});

		this.coreService.resizeInfo$.subscribe((sizeData: ResizeData) => {
			if (sizeData) { this.size = sizeData.size; }
		});

	}

	get premise(): string {
		return (this.address.premise) ? this.address.premise : '';
	}

	onClick() {
		this.router.navigate(['Main/ContactUs']);
	}

}
