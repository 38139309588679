
// ------------------------------------------------------------------------------
// data sent to and received from server for user registration and authentication
// ------------------------------------------------------------------------------

import { Owner, OwnerVisitor } from '@common/entities/parts/owner';
import { Roles } from './role';
import { Credentials, User } from './types';
import { AuthRequests, IdentityTypes, UsernameMode } from '@common/classes/types';
import { environment } from 'environments/environment';
import { UserDocument } from './user-document';

export interface ILoginData {
	username: string;
	email: string;
	password: string;
}

export class IdentityRequest {

    public databaseName: string;
    public containerName: string;
    public partitionKey?: string;
    public action: AuthRequests;
    public credentials: Credentials = new Credentials();
    public data: IdentityData;


    constructor(action: AuthRequests, credentials: Credentials = new Credentials) {
            this.databaseName =environment.apiDatabaseName;
            this.containerName = environment.apiContainerName;
            this.partitionKey = 'UserDocument';
            this.action = action;
            this.credentials = credentials;
    }

    toJson(): string {
        const val = JSON.stringify(this);
        return val;
    }

}

export class IdentityData {

    jwt: string | undefined;
    expiration: number;
    persistent: boolean = false;

    constructor() {
        const _jwt = localStorage.getItem("jwt");
        const _expiration = localStorage.getItem("expiration");
        const _persistent = localStorage.getItem('persistent');

        this.jwt = (_jwt) ? _jwt : '';
        this.expiration = (_expiration) ? parseInt(_expiration) : Date.now() - 60000;
        this.persistent = <boolean>(_persistent && _persistent === 'true');
    }
}
export class RegistrationRequest implements ILoginData {

	private _verifyPassword: string;

	public static createVisitor(): RegistrationRequest {
		const req = new RegistrationRequest();
		req.identityType = 'credentials';
		req.owner = new OwnerVisitor();
		req.roles = new Roles(['visitor']);
		return req;
	}

	constructor() {
		this.owner = null;
		this.username = '';
		this.email = '';
		this.phone = '';
		this.roles = new Roles(['guest']);
		this.password = '';
		this.identityType = 'credentials';
		this.usernameTypes = 'text';
	}

	public owner: Owner | null;
	public username: string;
	public email: string;
	public phone: string;
	public roles: Roles;
	public password: string;
	public identityType: IdentityTypes;
	public usernameTypes: UsernameMode;

	public get verifyPassword(): string { return this._verifyPassword; }
	public set verifyPassword(value: string) { this._verifyPassword = value; }

	public get arePasswordsMatching(): boolean {
		return (this.password && this.password === this._verifyPassword) ? true : false
	}

	// credentials(persistent: boolean = false): Credentials {
	// 	const name = (this.email) ? this.email : this.username;
	// 	return Credentials.create(name, this.password, persistent);
	// }

}

export class RegistrationResponse {

	private _isRegistered: boolean;
	private _user: User | null;
	private _errors: Array<string>;

	static assign(source: object, target: RegistrationResponse | null= null): RegistrationResponse {

		if (!target) {
			target = new RegistrationResponse();
		}

		if ('isRegistered' in source) {
			target._isRegistered = (<any> source)['isRegistered'];
		} else {
			throw new Error('isRegistered is required');
		}

		if ('user' in source) {
			target._user = User.assign((<any> source)['user']);
		} else {
			target._user = null;
		}

		if ('errors' in source) {
			const errors = <Array<string>> (<any> source)['errors'];
			if (errors) {
				errors.forEach((err) => { if (target) { target.errors.push(err); } });
			}
		}

		return target;
	}

	static faulted(error: string): RegistrationResponse {
		const r = new RegistrationResponse();
		r._errors.push(error);
		r._isRegistered = false;
		r._user = null;
		return r;
	}

	constructor() {
		this._isRegistered = false;
		this._user = null;
		this._errors = new Array<string>();
	}

	public get isRegistered(): boolean { return this._isRegistered; }
	public get user(): User | null { return this._user; }
	public get errors(): Array<string> { return this._errors; }
	public get hasErrors(): boolean { return (this._errors && this.errors.length > 0); }

	public get message(): string {
		let msg = 'Error: ';
		if (this.errors && this.errors.length > 0) {
			for (const e of this.errors) {
				msg += e + ' ';
			}
		}
		return msg;
	}


}

// expects data from source to be a user document
export class AuthenticationResponse {

	static assign(source: object): AuthenticationResponse {

		const target = new AuthenticationResponse();

		if ('isAuthenticated' in source) {
			target.isAuthenticated = (<any> source)['isAuthenticated'];
		} else {
			throw new Error('isAuthenticated is required');
		}

		if ('user' in source) {
			target.user = User.assign((<any> source)['user']);
		} else {
			target.user = null;
		}

		return target;
	}

	public constructor(isAuthenticated: boolean = false) {
		this.isAuthenticated = isAuthenticated;
		this.user = null;
		this.errors = new Array<string>();
	}

	public isAuthenticated: boolean;
	public user: User | null;
	public errors: Array<string>;

}

export class getUserResponse {


	public static assign(source: object): getUserResponse {
		const u = new getUserResponse();

		if ('statusCode' in source) {
			u.statusCode = parseInt((<any>source)['statusCode']);
		} else {
			throw new Error('Error assigning getUserResponse: \'statusCode\' is required')
		}

		if ('error' in source) { u.message = (<any>source)['error']; } else { u.message = undefined}

		if ('data' in source) {
			const o = (<any>source)['data'];
			if (o && typeof o === 'object') { u.user= UserDocument.assign(o); } else { u.user = undefined; }

		} else {
			u.user = undefined;
		}

		return u;
	}

	statusCode: number;
	message?: string;
	user?: UserDocument;
}
export class getUsersResponse {


	public static assign(source: object): getUsersResponse {
		const u = new getUsersResponse();

		if ('statusCode' in source) {
			u.statusCode = parseInt((<any>source)['statusCode']);
		} else {
			throw new Error('Error assigning getUserResponse: \'statusCode\' is required')
		}

		if ('error' in source) { u.message = (<any>source)['error']; } else { u.message = undefined}

		if ('data' in source) {
			const o = (<any>source)['data'];
			if (Array.isArray(o)) {
				o.forEach((item: object) => {
					u.users.push(UserDocument.assign(item));
				});
			} else {
				throw new Error('Error assigning getUserResponse: \'data\' is not an array')
			}
		}

		return u;
	}

	constructor() {
		this.users = new Array<UserDocument>();
	}

	statusCode: number;
	message?: string;
	users: Array<UserDocument>;
}

