import { Extensions, ImageSizes } from "./enums";
import { AssetImageCategories, AssetImageSizes, ImageExtensions } from "./types";
// -------------------------------------------------------------------------------
// provides a sized image url for either a blob or an asset Image
// -------------------------------------------------------------------------------


class ImageSource {

	protected static _url: string;

	public static getUrl(size: ImageSizes): string { return this._url; };


	protected toAssetSize(size: ImageSizes): AssetImageSizes {
		switch( size) {
			case ImageSizes.extraLarge: return 'xl';
			case ImageSizes.extraSmall: return 'xs';
				case ImageSizes.large: return 'lg';
				case ImageSizes.master: return 'xl';
				case ImageSizes.medium: return 'md';
				case ImageSizes.small: return 'sm';
				case ImageSizes.thumbnail: return 'xs';
				case ImageSizes.vignette: return 'xs';
				default: return 'md';
		}
	}

}

// Retrieve images from serve assets
export class AssetImageSource extends ImageSource {

	public static getUrls(name: string, category?: AssetImageCategories, subPath?: string, extension?: ImageExtensions): Map<AssetImageSizes, string> {
		const list = new Map<AssetImageSizes, string>();
		let path = (category) ? category + '/' : '';
		path += (subPath?.endsWith('/')) ? subPath : subPath + '/';
		path += name;
		const ext = (extension) ? extension : 'jpg';
		list.set('xs', `assets/images/${path}-xs.${ext}`);
		list.set('sm', `assets/images/${path}-sm.${ext}`);
		list.set('md', `assets/images/${path}-md.${ext}`);
		list.set('lg', `assets/images/${path}-lg.${ext}`);
		list.set('xl', `assets/images/${path}-xl.${ext}`);
		return list
	};

}

// retrieve images from blob storage
export class BlobImageSource extends ImageSource{

}
