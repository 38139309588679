import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'ib-footer-main',
  templateUrl: './footer-main.component.html',
  styleUrls: ['./footer-main.component.scss']
})
export class ibFooterMainComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onLogoClick() {
		this.router.navigate(['Home']);
  }

  onClick(e: string) {
		let dest = 'OurMission';
		switch (e) {
			case 'OurMission':
				dest = 'Main/OurMission';
				break;
			case 'Commitment':
				dest = 'Main/BreederCommitment';
				break;
				case 'WhyPurebreds':
				dest = 'Main/WhyPurebreds';
				break;
			case 'ResponsibleBreeding':
				dest = 'Main/ResponsibleBreeding';
				break;
			default:
				return;
		}
		this.router.navigate([dest]);


  }

}
