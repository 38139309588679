// ---------------------------------------------------------
// ib help viewer
// ---------------------------------------------------------
// Displays help content given help topic from getHelp coreService


import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ibCoreService } from '@common/services/core-services/core.service';


@Component({
	selector: 'ib-help-viewer',
	templateUrl: './ib-help-viewer.component.html',
	styleUrls: ['./ib-help-viewer.component.scss']
})
export class ibHelpViewerComponent {


	@Input() set helpTopic(value: string) {
		value = (value) ? value : '';
		const parts= value.split('#');
		if (parts && parts.length > 0) { this._helpTopic = parts[0]; }
		if (parts && parts.length > 1) { this._section = parts[1]; }

		if (this._helpTopic) {
			this.coreService.help(this._helpTopic, this._section).subscribe((resp: string) => {
				this.helpString = resp;
			});
		}
	}
	@Input() title: string = 'Help';
	@Output() closeHelp = new EventEmitter<string>();

	@HostListener('document:click', ['$event'])
	public handleClick(event: Event): void {
		if (event.target instanceof HTMLAnchorElement) {
			const element = event.target as HTMLAnchorElement;
			const url = element.getAttribute('href');
			if (url) {
				event.preventDefault();
				this.coreService.navigate(url);
			}
		}
	}

	private _helpTopic: string = '';
	private _section: string = '';
	public helpString: string;

	constructor(private coreService: ibCoreService) {
		this.helpString = '';
	}

	public get HelpString(): string { return this._helpTopic; }

	onCloseHelp(e: any) {
		this.closeHelp.emit(this.helpTopic);
	}


}
