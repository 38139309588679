<div class="card title">
    <ib-title type="back" [icon]="titleIcon">Error</ib-title>
    <div class="card-block">
        <ib-image source="@common/error" alt="Error" class="img-fluid card-img-left"></ib-image>
        <div id="content">
            <h2 class="text-center">{{error.title}}</h2>
            <h6 class="text-center">Something has gone wrong.</h6>
            <p class="text-center">{{error.message}}</p>
            <p class="text-center text-xs ib-color-gray-600">{{error.reason}}</p>

            <div class="btn-group btn-large mt-4" role="group" aria-label="Basic example">
                <!-- <button class="btn btn-secondary"  (click)="onClick('Back')">
                    <i class="fal fa-arrow-alt-circle-left ib-color-icon"></i>Go Back
                </button> -->
                <button class="btn btn-secondary"  (click)="onClick('Home')">
                    <i class="fas fa-home ib-color-iBreeder"></i>Go Home
                </button>
            </div>
        </div>
    </div>
</div>
