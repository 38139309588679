<button [class]='hostClasses()' [disabled]='isDisabled()' [attr.title]="tooltip" (click)="onClick($event)">
	<i id='icon' [ngClass]='iconClasses()'></i>
	<div class="sm">
		<span #textContent><ng-content></ng-content></span>
		<span *ngIf='showText'>{{icon.caption.text}}</span>
	</div>
	<i id='help'
		*ngIf="helpTopic"
		class="fas fa-question ib-color-gray-500 sm pointer-highlight"
		(click)="onHelp($event);">
	</i>
</button>
