// -----------------------------------------------------
// Types used in the client side security implementation
// ------------------------------------------------------

import { HttpErrorResponse } from '@angular/common/http';

import { Owner, OwnerBlank } from '@entities/parts/owner';
import { Role } from './role';
import { UserDocument } from './user-document';

export class Credentials {

	static assign(source: Object): Credentials {
		const c = Credentials.createDefault();

		if ('username' in source) {
			c.username = (<any>source)['username'];
		} else {
			throw new Error('Credentials \'username\' is required');
		};

		if ('password' in source) {
			c.password = (<any>source)['password'];
		} else {
			throw new Error('Credentials \'password\' is required');
		};

		if ('persistent' in source) {
			c.persistent = (<any>source)['persistent'];
		} else {
			c.persistent = true;
		};

		return c;
	}

	static create(username: string, password: string | null = null, persistent: boolean = false): Credentials {
		const cred = new Credentials();
		cred.username = username;
		cred.password = (password) ? password : '';
		cred.persistent = persistent;
		return cred;
	}

	static createDefault() {
		const defaultUsername = localStorage.getItem('currentUserName');
		const cred = new Credentials();
		cred.username = (defaultUsername) ? defaultUsername : '';
		cred.password = '';
		cred.persistent = true;
		return cred;
	}

	constructor() {
		this.username = '';
		this.password = '';
		this.persistent = true;
	}

	public username: string;
	public password: string;
	public persistent: boolean;
}

export class SignInResponse {

	static assign(source: any): SignInResponse {

		if ('body' in source) { source = (<any>source).body; }

		let code = 500;

		if ('statusCode' in source) {
			code = parseInt((<any>source)['statusCode']);
		}

		if (code >= 200 && code < 300) {
			code = 200;
			if ('data' in source) {
				try {
					const user = UserDocument.assign((<any>source)['data']);
					return SignInResponse.success(user);
				} catch (err) {
					return SignInResponse.faulted('An Error occurred during sign-in', `User data parsing error: ${err}`);
				}
			} else {
				return SignInResponse.faulted('An Error occurred during sign-in', 'security server did not return any data');
			}
		} else {
			let msg: string = '';
			if ('error' in source) {
				msg = (<any>source)['error']
				return SignInResponse.faulted('An Error occurred during sign-in', msg);
			}
			if ('data' in source) {
				return SignInResponse.faulted(`An Error occurred during sign-in: ${(<any>source)['data']}`);
			} else {
				return SignInResponse.faulted('An Error occurred during sign-in',  'Internal error');
			}
		}
	}

    static success(user: UserDocument): SignInResponse {
        const sir = new SignInResponse();
        sir.statusCode = 200;
        sir.statusText = "Authorized";
        sir.user = user;
        return sir;
    }

    static faulted(err: HttpErrorResponse | string, reason?: string): SignInResponse {
        const sir = new SignInResponse();
        if (err instanceof HttpErrorResponse) {
			sir.statusCode = err.status;
			sir.statusText = err.statusText;
			sir.reason = reason;
		} else {
			sir.statusCode = 500;
			sir.statusText = err;
			sir.reason = reason;
		}
        return sir;
    }

    static badRequest(reason?: string): SignInResponse {
        const sir = new SignInResponse();
        sir.statusCode = 400;
        sir.statusText = "Bad Request";
		sir.reason = reason;
        return sir;
    }

	static TooManyAttempts(): SignInResponse {
        const sir = new SignInResponse();
        sir.statusCode = 429;
        sir.statusText = "Too many attempts to sign-in";
		sir.reason = "Please retry later or contact support"
        return sir;
    }


    public statusCode: number = 200;
    public statusText: string = '';
	public reason?: string;
    public user?: UserDocument;

    public get isAuthorized(): boolean { return (this.statusCode === 200);}
}

export class AuthResponse {

	public static assign(source: object): AuthResponse {
		const r = new AuthResponse();

		if ('body' in source) { source = (<any>source).body; }

		if ('statusCode' in source) {
			r.statusCode =  parseInt((<any>source)['statusCode']);
		} else {
			r.statusCode = 500;
		}

		if ('data' in source) { r.data = (<any>source)['data']; }
		if ('error' in source) { r.error = (<any>source)['error']; }

		return r;
	}

	public statusCode: number;
	public data?: any;
	public error?: string;

	public get isSuccess(): boolean {
		return (this.statusCode >= 200 && this.statusCode <= 299);
	}

}


// 6/1/2021 May Be obsolete - use UserDocument
export class User {

	// locked values: 0 -> unlocked 1 -> locked 2 -> permanently locked

	// assign JSon Object to a typed User class
	// only properties existing in User Class are assigned - there is no type check
	public static assign(source: object, target: User| null= null): User {

		let id: string;

		if ('id' in source) {
			id = (<any> source)['id'];
		} else {
			throw new Error('id is required');
		}

		if (!target) {
			target = new User(id);
		} else {
			target.id = id;
		}


		if ('name' in source) {
			target.name = (<any> source)['name'];
		} else {
			throw new Error('name is required');
		}

		if ('owner' in source) {
			target.owner = Owner.assign((<any> source)['owner']);
			target.name = (<any> source)['name'];
		} else {
			throw new Error('name is required');
		}

		if ('username' in source) {
			target.username = (<any> source)['username'];
		} else {
			target.username = target.name;
		}

		if ('eMail' in source) {
			target.eMail = (<any> source)['eMail'];
		} else {
			target.eMail = '';
		}

		if ('isEMailConfirmed' in source) {
			target.isEMailConfirmed = (<any> source)['isEMailConfirmed'];
		} else {
			target.isEMailConfirmed = false;
		}

		if ('firstName' in source) {
			target.firstName = (<any> source)['firstName'];
		} else {
			target.firstName = '';
		}

		if ('lastName' in source) {
			target.lastName = (<any> source)['lastName'];
		} else {
			target.lastName = '';
		}

		if ('phoneNumber' in source) {
			target.phoneNumber = (<any> source)['phoneNumber'];
		} else {
			target.phoneNumber = '';
		}

		if ('isPhoneNumberConfirmed' in source) {
			target.isPhoneNumberConfirmed = (<any> source)['isPhoneNumberConfirmed'];
		} else {
			target.isPhoneNumberConfirmed = false;
		}

		// if ('roles' in source) {
		// 	const addSource = <Array<Role>> (<any> source)['roles'];
		// 	target.roles = Roles.assign((<any> source)['roles']);
		// 	for (const r of addSource) {
		// 		target.roles.add(RoleUtil.assign(r));
		// 	}
		// }

		// if ('locked' in source) {
		// 	target.locked = (<any> source)['locked'];
		// 	if (target.locked < 0) { target.locked = 0; }
		// 	if (target.locked > 2) { target.locked = 2; }
		// } else {
		// 	target.locked = 0;
		// }


		if ('persistent' in source) {
			target.persistent = (<any> source)['persistent'];
		} else {
			target.persistent = false;
		}

		if ('jwt' in source) {
			target.jwt = (<any> source)['jwt'];
		} else {
			target.jwt = '';
		}

		return target;
	}

	public static createGuest(): User {
		const u = new User();
		u.name = 'Guest';
		u.roles = [new Role('guest')];
		return u;
	}

	constructor(id: string = '') {
		this.id = id;
		this.roles = new Array<Role>();
	}

	public id: string;
	public name: string = '';
	public owner: Owner = new OwnerBlank();
	public username: string = '';
	public eMail?: string;
	public isEMailConfirmed: boolean = false;
	public firstName?: string;
	public lastName?: string;
	public phoneNumber?: string;
	public isPhoneNumberConfirmed: boolean = false;
	public roles: Array<Role>;
	public locked: boolean = false;
	public persistent: boolean = true;
	public jwt: string = '';

	public topRole = (): Role => {
		if (!this.roles || this.roles.length === 0) {
			return new Role('guest');
		} else {
			let max = new Role('guest');
			// this.roles.forEach((item: Role) => {
			// 	if (item.type >= max.type || (item.type === max.type && item.level > max.level)) {
			// 		max = item;
			// 	}
			// });
			return max;
		}
	}

	public isInRole = (requestedRole: Role): boolean => {

		// if (this.roles) {
		// 	for (const role of this.roles) {
		// 		if (this.isRoleAuthorized(requestedRole, role)) { return true; }
		// 	}
		// 	return false;
		// } else {
			return false;
		// }

	}

	public get isValid(): boolean { return !(!this.id || !this.username); }

	// private isRoleAuthorized(requestedRole: Role, userRole: Role): boolean {

	// 	if (requestedRole.TypeAsEnum === UserTypes.Guest) {
	// 		return true;                                                // all authorized for guest
	// 	}

	// 	if (requestedRole.TypeAsEnum === UserTypes.Visitor) {
	// 		return (userRole.TypeAsEnum !== UserTypes.Guest);           // all except guest authorized
	// 	}

	// 	if (requestedRole.TypeAsEnum === UserTypes.Buyer) {
	// 		return (userRole.TypeAsEnum === UserTypes.Buyer
	// 				|| userRole.TypeAsEnum === UserTypes.iBreeder);     // Buyer && iBreeder
	// 	}

	// 	if (requestedRole.TypeAsEnum === UserTypes.Breeder) {
	// 		if (userRole.TypeAsEnum !== UserTypes.Breeder
	// 			&& userRole.TypeAsEnum !== UserTypes.iBreeder) { return false; }     // Breeder && iBreeder
	// 		return (requestedRole.LevelAsEnum >= userRole.LevelAsEnum);
	// 	}

	// 	if (requestedRole.TypeAsEnum === UserTypes.Club) {
	// 		if (userRole.TypeAsEnum !== UserTypes.Club
	// 			&& userRole.TypeAsEnum !== UserTypes.iBreeder) { return false; }     // Club && iBreeder
	// 		return (requestedRole.LevelAsEnum >= userRole.LevelAsEnum);
	// 	}

	// 	if (requestedRole.TypeAsEnum === UserTypes.Professional) {
	// 		if (userRole.TypeAsEnum !== UserTypes.Professional
	// 			&& userRole.TypeAsEnum !== UserTypes.iBreeder) { return false; }     // Pro && iBreeder
	// 		return (requestedRole.LevelAsEnum >= userRole.LevelAsEnum);
	// 	}

	// 	if (requestedRole.TypeAsEnum === UserTypes.iBreeder) {
	// 		if (userRole.TypeAsEnum !== UserTypes.iBreeder) { return false; }       // iBreeder && iBreeder
	// 		return (requestedRole.LevelAsEnum >= userRole.LevelAsEnum);
	// 	}





				//             if (RoleType === UserTypes.iBreeder) { found = true; break; }
		//             if (RoleType !== UserTypes.Breeder) { found = false; break; }
		//             found = (RoleLevel >= requestedRoleLevel);



		// return false;

}




// Identity State
// ---------------------------------------------------------------------
// User is logged in when a jwt value is provided
// ---------------------------------------------------------------------
export class IdentityState  {

	private _user: User | null;
	private _lastUpdated: number | null;

	constructor() {
		this._user = new User();
		const id = localStorage.getItem('currentUserUid');
		const username = localStorage.getItem('currentUserName');
		this._user.id = (id) ? id : '';
		this._user.username = (username) ? username : '';
		this._user.persistent = (id) ? true : false;
		this._lastUpdated = Date.now();
	}

	public isAuthenticated(user: User | null = null): boolean {
		if (!user) { user = this._user; }
		if (!user || !user.jwt) { return false; }
		return (user.jwt.length > 0);
	}

	public get user(): User | null { return this._user; }
	public get lastUpdated(): number { return (this._lastUpdated) ? this._lastUpdated : 0; }
	public get jwt(): string { return (this._user && this._user.jwt) ? this._user.jwt : ''; }

	// Update the Identity State when user changes
	// if passed user is null we have a log out and we store current user id otherwise we store new user
	public update(user: User | null): void {
		if (!user) {
			localStorage.setItem('currentUserUid', '');
			localStorage.setItem('currentUserName', '');
			this._user = null;
		} else {
			if (user && this.isAuthenticated(user) && user.persistent) {
				localStorage.setItem('currentUserUid', user.id);
				localStorage.setItem('currentUserName', user.username);
			} else {
				localStorage.setItem('currentUserUid', '');
				localStorage.setItem('currentUserName', '');
			}
			this._user = User.assign(user);
		}
		this._lastUpdated = Date.now();
	}
}


// Guard Data
export class GuardData {

	public static assign(source: object | string | undefined | null): GuardData {
		const gd = new GuardData(['guest']);
		let obj: object;

		if (!source) return gd;
		obj = (typeof source === 'string') ? obj = JSON.parse(source): source;

		if ('roles' in obj) {
			const a = (<any>obj)['roles'];
			if (Array.isArray(a)) {
				gd.roles = [...a];
			} else { throw new Error('GuardData Assign - roles missing or invalid');}
		}

		if ('message' in obj) {
			gd.message = (<any>source)['message'];
		}

		if ('accessUrl' in obj) {
			gd.message = (<any>source)['message'];
		}

		if ('rejectionUrl' in obj) {
			gd.message = (<any>source)['message'];
		}

		return gd;
	}

	constructor(roles: [string], message?: string, successUrl?: string, rejectionUrl?: string) {
		this.roles = [...roles];
		this.message = message;
		this.successUrl = successUrl;
		this.rejectionUrl = successUrl;
		}

	public roles: Array<string>;
	public message?: string;
	public successUrl?: string;
	public rejectionUrl?: string;
}








