
import { ibDocument } from './ib-document';
// import { Tools } from '@common/tools';
import { Measure } from '@common/classes/lib';
import { Statuses } from '@common/classes/types';
import { BreedStub } from '@entities/parts/breed-stub';
import { RegistryEntry } from '../parts/registry-entry';
import { AccountStatuses } from '@common/classes/enums';

import { Tools } from '@common/tools';

export class BreedDocument extends ibDocument {


	static assign(source: Record<string, any>, target: BreedDocument | null = null): BreedDocument {

		if (!source) { throw new Error('A source is required') }
		let t: BreedDocument;

		if (!target) {
			const id = ('id' in source) ? (<any> source)['id'] : Tools.newGuid();
			target = new BreedDocument(id);
			t = target;
		} else {
			t = target;
		}


		if ('breedID' in source) {
			t.breedID = (<any> source)['breedID'];
		} else { throw new Error('breedID is required'); }

		if ('breedName' in source) {
			t.breedName = (<any> source)['breedName'];
		} else { throw new Error('breedName is required'); }

		if ('symbol' in source) {
			t.symbol = (<any> source)['symbol'];
		}

		if ('otherNames' in source) {
			const oNames: Array<string> = (<any> source)['otherNames'];
			oNames.forEach((name) => { t.otherNames.push(name); });
		}

		if ('caption' in source) {
			t.caption = (<any> source)['caption'];
		}

		if ('description' in source) {
			t.description = (<any> source)['description'];
		}

		// handling registries

		if ('registries' in source) {
			const s = (<any> source)['registries'];
			if (s && Array.isArray(s)) {
				for (const item of s) {
					const r = RegistryEntry.assign(item);
					t.registries.push(r);
				}
			}
		}

		if ('registry' in source) {
			// only take 1st
			const s = (<any> source)['registry'];
			if (s && Array.isArray(s)) {
				const r = new RegistryEntry();
				r.registryID = s[0];
				if (r.registryID === 'AKC') { r.registryName = 'American kennel Club'; }
				if ('registryLink' in source) {
					r.registryLink = (<any> source)['registryLink'];
				}
				t.registries.push(r);
			}
		}

		if ('rank' in source) {
			t.rank = (<any> source)['rank'];
		} else { t.rank = -1; }

		if ('summaryTraits' in source) {
			const traits: Array<string> = (<any> source)['summaryTraits'];
			traits.forEach((name) => { t.summaryTraits.push(name); });
		}

		if ('similarBreeds' in source) {
			const breeds: Array<string> = (<any> source)['similarBreeds'];
			breeds.forEach((name) => { t.similarBreeds.push(name); });
		}

		if ('healthIssues' in source) {
			const issues: Array<string> = (<any> source)['healthIssues'];
			issues.forEach((name) => { t.healthIssues.push(name); });
		}

		if ('group' in source) {
			t.group = (<any> source)['group'];
		}

		if ('functions' in source) {
			const functions: Array<string> = (<any> source)['functions'];
			functions.forEach((name) => { t.functions.push(name); });
		}

		if ('height' in source) {
			t.height = (<any> source)['height'];
		}

		if ('heightCategory' in source) {
			t.heightCategory = (<any> source)['heightCategory'];
		} else {
			t.heightCategory = (t.height) ? Measure.parse(t.height).category : 'unknown';
		}

		if ('weight' in source) {
			t.weight = (<any> source)['weight'];
		}

		if ('weightCategory' in source) {
			t.weightCategory = (<any> source)['weightCategory'];
		} else {
			t.weightCategory = (t.weight) ? Measure.parse(t.weight).category : 'unknown';
		}

		if ('lifespan' in source) {
			t.lifespan = (<any> source)['lifespan'];
		}

		if ('lifespanCategory' in source) {
			t.lifespanCategory = (<any> source)['lifespanCategory'];
		} else {
			t.lifespanCategory = (t.lifespan) ? Measure.parse(t.lifespan).category : 'unknown';
		}

		if ('aspect' in source) {
			t.aspect = (<any> source)['aspect'];
		}

		if ('sponsored' in source) {
			t.sponsored = (<any> source)['sponsored'];
		}

		if ('changed' in source) {
			t.changed = (<any> source)['changed'];
		} else { t.changed = false; }


		if ('properties' in source) {
			const props: Array<string> = (<any> source)['properties'];
			props.forEach((name) => { t.properties.push(name); });
		}

		if ('notes' in source) {
			const notes: Array<string> = (<any> source)['notes'];
			notes.forEach((name) => { t.notes.push(name); });
		}

		if ('publishStatus' in source) {
			const notes: Array<string> = (<any> source)['notes'];
			notes.forEach((name) => { t.notes.push(name); });
		}

		if ('status' in source) {
			const s = <string> (<any> source)['status'];
				t.status = (s) ? <Statuses> s.toLowerCase() : 'unknown';
		 } else { t.status = 'unknown'; }

		 return t;
	}

	constructor(id: string) {
		super('BreedDocument', id);
		this.otherNames = new Array<string>();
		this.summaryTraits = new Array<string>();
		this.similarBreeds = new Array<string>();
		this.registries = new Array<RegistryEntry>();
		this.healthIssues = new Array<string>();
		this.functions = new Array<string>();
		this.notes = new Array<string>();
		this.properties = new Array<string>();
		this.publishStatus = new Array<string>();
	}

	public breedID: string = '';
	public breedName: string = '';
	public symbol?: string;
	public otherNames: Array<string>;
	public caption?: string;
	public description?: string;
	public registries: Array<RegistryEntry>;
	public rank: number = 999;
	public summaryTraits: Array<string>;
	public similarBreeds: Array<string>;
	public healthIssues: Array<string>;
	public group: string = 'unknown';
	public functions: Array<string>;
	public height?: string;
	public heightCategory?: string;
	public weight?: string;
	public weightCategory?: string;
	public lifespan?: string;
	public lifespanCategory?: string;
	public aspect?: string;
	public sponsored?: boolean;
	public properties: Array<string>;
	public notes: Array<string>;
	public publishStatus: Array<string>;
	public status: Statuses = 'unknown';

	public toStub(): BreedStub {

		const stub = new BreedStub();
		stub.breedUID = this.id;
		stub.breedID = this.breedID;
		stub.breedName = this.breedName;
		stub.caption = this.caption + '';
		stub.symbol = this.symbol + '';
		return stub;

	}
}










