// -----------------------------------------------
// Document factory
// -----------------------------------------------

import { ibDocument } from '@common/entities/documents/ib-document';
import { DocumentTypes } from './types';
import { BreedDocument } from '@common/entities/documents/breed-document';
import { DogDocument } from '@common/entities/documents/dog-document';
import { ImageMetaDocument } from '@common/entities/documents/image-meta-document';
import { LitterDocument } from '@common/entities/documents/litter-document';
import { PersonDocument } from '@common/entities/documents/person-document';
import { VisitorDataDocument } from '@common/entities/documents/visitor-data-document';


// Not sure we need this ?


export class DocumentFactory {

    public static  create(source: object | Record<string, unknown> | ibDocument): ibDocument {

        if (!source) {
            throw new Error('Document Factory Error: Required source is missing');
        }
        if ('DType' in source) {
            const DType = source['DType'] as DocumentTypes;
            if (!DType) { throw new Error('Document Factory Error: Required property [DType] is empty ');}
            switch (DType) {
                case 'BreedDocument':
                    return BreedDocument.assign(source) as ibDocument;
                // case 'BreederDocument':
                //     return BreederDocument.assign(source);
                case 'DogDocument':
                    return DogDocument.assign(source);
                // case 'HealthIssueDocument':
                //     return HealthIssueDocument.
                case 'ImageDocument':
                    return ImageMetaDocument.assign(source);
                // case 'ListItemDocument':
                //     return List
                case 'LitterDocument':
                    return LitterDocument.assign(source);
                // case 'MessageDocument':
                //     return Message
                // case 'NoteDocument':
                //     return Note
                // case 'PageDocument':
                //     return P
                case 'PersonDocument':
                    return PersonDocument.assign(source);
                case 'VisitorDataDocument':
                    return VisitorDataDocument.assign(source);
                default:
                    throw new Error('Document Factory Error: Required property [DType] is invalid ');
            }
        } else {
            throw new Error('Document Factory Error: Required property [DType] not found in source');
        }
    }
}

