
// ======================================================
// Image Service
// ======================================================
// singleton
// getBreedImage & getBreederImage provide an image URL
// ------------------------------------------------------
// https://ibreeder.blob.core.windows.net/breed-akita/images/akita-0001-thumbnail.jpg
// https://ibreeder.blob.core.windows.net/breeder-USFL0033/images/flirt1/flirt-0001-thumbnail.jpg
// ======================================================================================================


// "path": "https://ibreeder1.blob.core.windows.net/media/ibreeder/breeds/rhodesian-ridgeback/images/picture-021-extralarge.jpg"

import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of,  throwError as observableThrowError ,  Observable } from 'rxjs';
import { ibDBService } from '../data-services/db.service';
import { Defaults } from '@common/classes/defaults';
import { ImageMetaDocument, ImageStub } from '@common/entities/documents/image-meta-document';
import { HttpUtil } from '@common/classes/httpUtil';
import { ibResult } from '@common/classes/result';
import { SimpleImageQuery } from '@common/classes/imageQuery';
import { ImageSizes } from '@common/classes/enums';
import { Tools } from '@common/tools';
import { Subscriptions } from '../core-services/core.service';
import { dbResult } from '@common/classes/data/dbUtil';
import { AccountTypes } from '@common/classes/types';
import { ibNode } from '@common/classes/ui/trees/ibTree';
import { Icons } from '@common/classes/icons';
import { ibIcon, IconSizes, IconTypes, IIconStoreQuery } from '@common/classes/icon';
import { ibUrl } from '@common/classes/ibUrl';

@Injectable({ providedIn :'any' })
export class ibImageService implements OnDestroy {


	private _defaultMeta?: ImageMetaDocument;
	private _urlRoot: string;
	private _icons: Map<AccountTypes, ibIcon>;
	private subscriptions: Subscriptions;

	constructor(private http: HttpClient,
				private dbService: ibDBService) {
		this._urlRoot =  Defaults.apiUrl;
		this.subscriptions = new Subscriptions();

		this._icons = new Map<AccountTypes, ibIcon>();
		this._icons.set('iBreeder', ibIcon.StoreIcon({store: 'general', iconName: 'iBreeder'}))
		this._icons.set('breeder', ibIcon.StoreIcon({store: 'public', iconName: 'breeder'}))
		this._icons.set('dogOwner', ibIcon.StoreIcon({store: 'general', iconName: 'dogOwner'}))
		this._icons.set('visitor', ibIcon.StoreIcon({store: 'general', iconName: 'visitor'}))
	}

	public getNodes(source: Array<IIconStoreQuery>): Promise<Array<ibNode>> {
		return new Promise<Array<ibNode>>((resolve, reject) => {
			const nodes = new Array<ibNode>();

			if (source && Array.isArray(source)) {
				try {
					source.forEach((query) => {
						const icon = ibIcon.StoreIcon(query);
						icon.caption.size = 'none';
						const caption = (query.captionText) ? query.captionText : query.iconName;
						const value = (query.id) ? query.id : query.iconName;
						// const node = ibNode.CreateWithPlaceHolder(caption, value, icon);
						// nodes.push(node);
					});

				} catch(reason: any) {
					reject('getNodes Error - source array: ' + reason);
				}
			}

			resolve(nodes);
		});

	}

	// create a tree with nodes for each accountType requested
	getMediaTreeRoot(accountTypes: Array<AccountTypes>): ibNode {
		const t = new ibNode();
		t.caption = 'Media';
		// t.icon = Icons.generalIcon('media', 'regular', 'md');
		// t.value ='root';
		if (accountTypes && accountTypes.length > 0) {
			accountTypes.forEach((type: AccountTypes) => {
				const i = this._icons.get(type)
				// const item = t.addChildWithPlaceHolder(ibUrl.toCaption(type),type, i);
			});
		}
		console.log(t);
		return t;
	}

	getIBreederTreeItems(parent: ibNode): Array<ibNode> {
		const nodes = new Array<ibNode>();
		const items: Array<IIconStoreQuery> = [
			{ store: 'general', iconName: 'iBreeder', captionText: 'Self', id: 'self' },
			{ store: 'public', iconName: 'breed', captionText: 'Breeds', id: 'breeds' },
			{ store: 'general', iconName: 'cover', captionText: 'Cover', id: 'cover' },
		]
		items.forEach((query) => {
			const icon = ibIcon.StoreIcon(query);
			icon.caption.size = 'none';
			const caption = (query.captionText) ? query.captionText : query.iconName;
			const value = (query.id) ? query.id : query.iconName;
			// const node = ibNode.CreateWithPlaceHolder(caption, value, icon);
			// nodes.push(node);
		});
		return nodes;
	}

	getBreederTreeItems(): Array<ibNode> {
		const nodes = new Array<ibNode>();
		const items: Array<IIconStoreQuery> = [
			{ store: 'public', iconName: 'breeder', captionText: 'Self', id: 'self' },
			{ store: 'public', iconName: 'kennel', captionText: 'Kennel', id: 'kennel' },
			{ store: 'public', iconName: 'litter', captionText: 'Litters', id: 'litters' },
			{ store: 'public', iconName: 'dog', captionText: 'Dogs', id: 'dogs' },
			{ store: 'public', iconName: 'client', captionText: 'Clients', id: 'clients' },
		]
		items.forEach((query) => {
			const icon = ibIcon.StoreIcon(query);
			icon.caption.size = 'none';
			const caption = (query.captionText) ? query.captionText : query.iconName;
			const value = (query.id) ? query.id : query.iconName;
			// nodes.push(ibNode.CreateWithPlaceHolder(caption, value, icon));
		});
		return nodes;
	}

	getBreederBreedsTreeItems(): Array<ibNode> {
		const nodes = new Array<ibNode>();


		return nodes;
	}


	getTestTree() {
		const t = new ibNode();
		t.caption = 'Media';
		// t.icon = Icons.generalIcon('media', 'regular', 'md');
		// t.value ='root';

		// let node = ibNode.Create('A');
		// node.parent = t;
		// let subNode = node.addChild('Aa');
		// subNode.addChild('Aa1')
		// subNode.addChild('Aa2')
		// subNode.addChild('Aa3')
		// subNode = node.addChild('Ab');
		// subNode.addChild('Ab1')
		// subNode.addChild('Ab2')
		// subNode.addChild('Ab3')
		// node.addChild('Ac');
		// t.items.push(node);
		// node = ibNode.Create('B');
		// node.addChild('Ba');
		// node.addChild('Bb');
		// node.addChild('Bc');
		// t.items.push(node);
		// node = ibNode.Create('C');
		// node.addChild('Ca');
		// node.addChild('Cb');
		// node.addChild('Cc');
		// t.items.push(node);
		// console.log(t);
		// return t;


	}

	public getImagePaths(filter: string) {

		const q = "SELECT value c.path FROM c where c.DType='ImageDocument' order by c.path";
		this.subscriptions.addSubscription('getPaths', this.dbService.getDocuments(q).pipe(
			map((res: dbResult) => {
				if (res.isSuccess) { console.log(res.values); }
			})

		).subscribe());

	}

	public ngOnDestroy() {
		if (this.subscriptions) { this. subscriptions.unsubscribe(); }
	}

	// get breed list and breed names in BreedData and instantiate
	public get defaultMeta(): Observable<ImageMetaDocument> {
		if (this._defaultMeta) {
			return of(this._defaultMeta);
		} else {
			return this.load();
		}
	}

	// Query db and returns the ImageMeta record for the Image_UID
	public getImage(uid: string): Observable<ImageMetaDocument | null> {
		const url = this._urlRoot + 'Image/GetImage';
		const headers = HttpUtil.createHeaders([{ name: 'uid', value: uid}]);
		// return this.http.get<ibResult<ImageMetaDocument>>(url, { headers: new HttpHeaders().set('uid', uid) }).pipe(
		return this.http.get<ibResult<ImageMetaDocument>>(url, { headers }).pipe(
			map((resp: ibResult<ImageMetaDocument>) => {
				if (resp && resp.isSuccess && resp.value) {
					return ImageMetaDocument.assign(resp.value, null, true);
				} else { return null; }
			})
		);
	}

	// Query db and returns an array of ImageMeta records
	public Query(Q: SimpleImageQuery): Observable<Array<ImageStub>> {
		const url = this._urlRoot + 'Image/GetImages';
		const q = JSON.stringify(Q);
		const headers = HttpUtil.createHeaders([{name: 'query', value: q}]);
		return this.http.get<ibResult<Array<ImageStub>>>(url, { headers }).pipe(
			map((resp: ibResult<Array<ImageStub>>) => {
				const lst = new Array<ImageStub>();
				if (resp.isSuccess && resp.value) {
					for (const e of resp.value) {
						lst.push(ImageStub.assign(e));
					}
				}
				return lst;
			})
		);
	}

	// Query db and returns an array of ImageStub records
	public async SQLQuery(Q: string): Promise<Array<ImageStub>> {
		return new Promise((resolve, reject) => {
			this.dbService.getDocuments(Q).pipe(
				map((ret: dbResult) => {
					const lst = new Array<ImageStub>();
					if (ret.isSuccess && ret.values) {
						ret.values.forEach((value: unknown) => {
							if (value && typeof value === 'object') { lst.push(ImageStub.assign(value))}
						})
					} else {
						reject(ret.message);
					}
					return lst
				})
			).subscribe((res: Array<ImageStub>) => {
				resolve(res);
			});
		});
		// const url = this._urlRoot + 'Image/GetImages';
		// const h = HttpUtil.createHeaders([{ name: 'sql-query', value: Q }]);
		// // return this.http.get<ibResult<Array<ImageStub>>>(url, { headers: new HttpHeaders().set('sql-query', Q) }).pipe(
		// return this.http.get<ibResult<Array<ImageStub>>>(url, { headers: h }).pipe(
		// 	map((resp: ibResult<Array<ImageStub>>) => {
		// 		const lst = new Array<ImageStub>();
		// 		if (resp.isSuccess && resp.value) {
		// 			for (const e of resp.value) {
		// 				lst.push(ImageStub.assign(e));
		// 			}
		// 		}
		// 		return lst;
		// 	})
		// );
	}

	public async QueryByPath(path: string): Promise<Array<ImageStub>> {
		const Q = `SELECT * FROM c WHERE c.DType='ImageDocument' AND c.BlobPath='${path}`;
		return this.SQLQuery(Q);
	}

	public TakePerBreed(source: Array<ImageStub>, count: number = 1, randomized: boolean = true): Array<ImageStub> {
		const res = new Array<ImageStub>();
		const buffer = new Map<string, Array<ImageStub>>();

		if (!source) { return res; }

		// distribute images by breed
		for (const img of source) {
			// console.log(img.ownerID);
			if (!buffer.has(img.ownerID)) { buffer.set(img.ownerID, new Array<ImageStub>());}
			const lst = buffer.get(img.ownerID);
			if (lst) { lst.push(img); }
		}

		// pluck images
		const keys = Array.from(buffer.keys());
		for (const key of keys) {
			let breedImages = (buffer.get(key));
			if (breedImages) {
				if (randomized) { breedImages = Tools.ArrayScramble(breedImages); }
				for (let i=0; i < breedImages.length && i < count; i++) {
					res.push(breedImages[i]);
				}
			}
		}
		const ret = (randomized) ? Tools.ArrayScramble(res) : res;
		return ret;
	}


	// return the next image name with index incremented
	public NextImageName(Q: SimpleImageQuery): Observable<string | null> {
		const url = this._urlRoot + 'Image/GetNextImageName';
		const q = JSON.stringify(Q);
		const headers = HttpUtil.createHeaders([{ name: 'query', value: q }]);

		// return this.http.get(url, { responseType: 'text' as 'text', headers: new HttpHeaders().set('query', q) }).pipe(
		return this.http.get(url, { responseType: 'text' as const, headers }).pipe(
			map((result: any) => {
				const res = JSON.parse(result);
				if (res.isSuccess) {
					return res.value;
				} else {
					return null;
				}
			})
		);
	}

	public RemoveImage(uid: string): Observable<boolean> {
		const url = this._urlRoot + 'Image/DeleteImage';
		const headers = HttpUtil.createHeaders([{ name: 'uid', value: uid }]);

		// return this.http.delete(url, { headers: new HttpHeaders().set('uid', uid), responseType: 'text' }).pipe(
		return this.http.delete(url, { headers, responseType: 'text' }).pipe(
			map((resp: any) => {
				const res = ibResult.success(resp);
				return (res && res.isSuccess);
				}),
			catchError(() => of(false))
		);
	}

	public RenameImage(uid: string, newName: string): Observable<boolean> {
		console.log(`${uid}  -  ${newName}`);
		alert('Not Implemented');
		return of(false);
		// const url = this._url_root + "Image/RenameImage";
		// const h = new HttpHeaders().set('uid', uid).set('newName', newName);
		// return this.http.post<ibResult<ImageMetaDocument>>(url, '', { headers: h }).pipe(
		//     map((resp: Object) => {
		//         const res1 = new ibResult(resp);
		//         return (res1 && res1.isSuccess );
		//         }),
		//     catchError(err => {
		//         alert('rename image ' + JSON.stringify(err));
		//         return of(false); })
		// );
	}



	private load(): Observable<ImageMetaDocument> {
		const url = '/content/data/imageMetaDefaults.json';
		const ret = this.http.get<ImageMetaDocument>(url);
		ret.pipe(
			map((r) => {
				this._defaultMeta = ImageMetaDocument.assign(r, null, true);
				return of(this._defaultMeta);
			}),
			catchError((error: any) => {
				console.error(error);
				return observableThrowError(error);
			})
		);

		return ret;
	}




	// public ProgressStyles: string;


	// private rootUrl = 'https://ibreeder.blob.core.windows.net/';


	// constructor() {
	// }

	// public getBreedCoverImageURL(breedName: string, imageSize: string= 'thumbnail'): string {
	//    if (!breedName) { breedName = 'noImage'; }
	//    imageSize = this.checkSize(imageSize);
	//    return this.rootUrl + 'breed/cover/' + breedName + '-' + imageSize + '.jpg';
	// }

	// public getBreedImageURL(breedName: string, imageName: string, imageSize: string= 'thumbnail'): string {
	//    imageSize = this.checkSize(imageSize);
	//    return this.rootUrl + 'breed-' + breedName + '/images/' + imageName + '-' + imageSize + '.jpg';
	// }

	// public getBreederImageURL(accountID: string, path: string, imageName: string, imageSize: string= 'thumbnail'): string {
	//    imageSize = this.checkSize(imageSize);
	//    const index = imageName.lastIndexOf('.');
	//    const ext = (index >= 0) ? imageName.slice(index) : '.jpg';
	//    let name = imageName.slice(0, index);
	//    // name = Tools.toPrettyUrl(name);
	//    const url = (this.rootUrl + 'breeder-' + accountID + '/' + path + '/images/' + name + '-' + imageSize + ext).toLowerCase();
	//    return url;
	// }

	// public getDogImageURL(accountID: string, dogId: string, imageName: string, imageSize: string = 'thumbnail'): string {
	//    imageSize = this.checkSize(imageSize);
	//    const index = imageName.lastIndexOf('.');
	//    const ext = (index >= 0) ? imageName.slice(index) : '.jpg';
	//    let name = imageName.slice(0, index);
	//    // name = Tools.toPrettyUrl(name);
	//    dogId = dogId.toLocaleLowerCase();
	//    const url = (this.rootUrl + 'breeder-' + accountID + '/dogs/' + dogId + '/images/' + name + '-' + imageSize + ext).toLowerCase();
	//    return url;
	// }

	public resizeImage(url: string, size: ImageSizes | undefined) {
		return Tools.resizeImage(url, size);
	}

	// OBSOLETE see to remove
	public resize1(url: string | null, imageSize: string = 'default'): string {
		if (!url) { throw new Error('Resize - source image url is null or empty'); }
		imageSize = this.checkSize(imageSize);
		const dot = url.lastIndexOf('.');
		const dash = url.lastIndexOf('-');
		const s = url.substr(0, dash + 1) + imageSize + url.substr(dot);
		return s;
	}

	// OBSOLETE see to remove
	private checkSize(size: string): string {
		size = size.toLowerCase().trim();
		switch (size) {
			case 'vignette': return size;
			case 'thumbnail': return size;
			case 'xs': return 'extrasmall';
			case 'sm': return 'small';
			case 'md': return 'medium';
			case 'lg': return 'large';
			case 'xl': return 'extralarge';
			case 'default': return 'large';
			case 'master': return 'master';
			default:
				throw (new Error('Unknown image size: ' + size));
		}
	}


}
