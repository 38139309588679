// Utility functions for handling http requests
// -----------------------------------------------
// PH. Adapted from original to handle httpClient
// -----------------------------------------------

import { HttpHeaders, HttpErrorResponse } from "@angular/common/http";


// import { Observable } from 'rxjs';
// import { catchError, map } from 'rxjs/operators';
// import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
// import { ibResultTyped } from '../entities/ib-result';

export class HttpUtil {


	// public static processHttp<T>(obs: Observable<Response>): Observable<ibResultTyped<T>> {
	// 	return obs.pipe(
	// 		map<Response, ibResultTyped<T>>(HttpUtil.extractData),
	// 		catchError(HttpUtil.handleError)
	// 	);
	// }

	public static createHeaders(headers?: Array<IHttpHeader>): HttpHeaders {
		let _headers = new HttpHeaders();
		_headers = _headers.set('ib-apicall', 'true');
		// _headers = _headers.set('withCredentials', 'true');
		if (headers && headers.length > 0) {
			headers.forEach(((h: IHttpHeader) => {
				_headers = _headers.set(h.name, h.value);
			}));
		}
		return _headers;
	}

	public static addJwt(jwt: string | null, headers?: HttpHeaders): HttpHeaders {
		if (!headers) { headers = HttpUtil.createHeaders(); }
		headers = headers.set('Authorization', 'Bearer ' + <string> jwt);
		return headers;
	}

	public static getErrorResponse(err: HttpErrorResponse, message: string): HttpErrorResponse {
		const url = (err.url) ? err.url : undefined;
		return new HttpErrorResponse({ error: err, headers: err.headers, status: err.status, statusText: message, url});
	}

	public static mailTo(toAddress: string, subject?: string, body?: string): string {
		let resp = `mailto: ${toAddress}`;
		if (subject) {
			subject = encodeURIComponent(subject);
			resp += `?subject=${subject}`;
		}
		if (body) {
			body = (subject) ? '&body=' + encodeURIComponent(body) : '?body=' + encodeURIComponent(body) ;
			resp += body;
		}
		return resp;
	}
}

export interface IHttpHeader {
	name: string;
	value: string;
}
