<div class="strip">
	<div id="sLeft1" class="btn btn-light" (click)="scrollLeft()">
		<i class="fas fa-angle-left"></i>
	</div>
	<ul #imageContainer id="images" ibDropTarget='images, img' (ibDrop)="onDrop()">
		<li [attr.id]="'img-' + i"
			*ngFor="let item of list.list; let i = index"
			[class.active]="isActive(item)"
			(click)="onClick(item)"
		>
			<ib-icon [icon]='item'
			data-toggle='tooltip'
			[title]='item.tooltip'></ib-icon>
		</li>
	</ul>
 	<div id="sRight1" class="btn btn-light" (click)="scrollRight()">
		<i class="fas fa-angle-right"></i>
	</div>
</div>


<!-- [ibDraggable]="{source: 'img', id: i}" -->
