// ------------------------------------------------------------------
// Visitor Data Document
// ------------------------------------------------------------------

import { Tools } from '../../tools';
import { ibDocument } from './ib-document';
import { Favorites } from '@entities/parts/favorites';
import { Location } from '@entities/parts/contact-info'
import { ProfileTargets, VisitorContactModes } from 'common/classes/types';
import { Answer, Question } from 'common/classes/question';
import { UserDocument } from '@common/identity/user-document';


export class VisitorDataDocument extends ibDocument {

	public static assign(source: Record<string, any>): VisitorDataDocument {

		if (!source) { throw new Error('source is required'); }
		const id = ('id' in source) ? (<any> source)['id'] : Tools.newGuid();
		const c = new VisitorDataDocument(id);

		if ('userUID' in source) {
			c.userUID = (<any> source)['userUID'];
		} else { throw new Error('The \'userUID\' is required'); }

		if ('username' in source) {
			c.username = (<any> source)['username'];
			c.name = c.username;
		} else { throw new Error('The \'username\' is required'); }

		if ('postalCode' in source) {
			c.postalCode = (<any> source)['postalCode'];
		} else { c.postalCode = undefined; }

		if ('country' in source) {
			c.country = (<any> source)['country'];
		} else { c.country = undefined; }

		if ('contactMode' in source) {
			c.contactMode = (<any> source)['contactMode'];
		} else { c.contactMode = 'auto'; }

		if ('favorites' in source) {
			c.favorites = Favorites.assign((<any> source)['favorites']);
		}

		if ('location' in source) {
			c.location = Location.assign((<any> source)['location']);
		}

		// if (source.hasOwnProperty('profile')) {
		// 	c.profile = UserProfile.assign((<any> source)['profile']);
		// } else {
		// 	c.profile = new UserProfile();
		// }


		return c;
	}

	// create a data document for user
	public static create(user: UserDocument): VisitorDataDocument {
		if (!user) { throw new Error('user is required'); }
		const d = new VisitorDataDocument();
		d.userUID = user.id;
		d.location
		d.name = user.fullName;
		d.postalCode = undefined;
		d.username = user.username;
		d.version = '1.0';

		if (user.phoneNumber) { d.location.phones.push(user.phoneNumber); }
		if (user.eMail) { d.location.emails.push(user.eMail); }

		return d;
	}

	constructor(id: string = Tools.newGuid()) {
		super('VisitorDataDocument', id);

		this.contactMode = 'manual';
		this.country = 'US';
		this.favorites = new Favorites();
		this.profile = new VisitorProfile();
		this.location = new Location('main');
	}

	public userUID: string = '';
	public username: string = '';
	public location: Location;
	public postalCode?: string;
	public country?: string;
	public contactMode: VisitorContactModes;
	public favorites: Favorites;
	public profile?: VisitorProfile;

}

export class VisitorProfile {

}

export class VisitorProfileTemplate {

	public static assign(source: Record<string, any>): VisitorProfileTemplate {

		const p = new VisitorProfileTemplate();

		if ('profile' in source) {
			p.profile = ProfileTarget.assign((<any> source)['profile']);
		} else {
			p.profile = new ProfileTarget();
		}

		if ('personal' in source) {
			p.personal = ProfileTarget.assign((<any> source)['personal']);
		} else {
			p.personal = new ProfileTarget();
		}

		if ('dog' in source) {
			p.dog = ProfileTarget.assign((<any> source)['dog']);
		} else {
			p.dog = new ProfileTarget();
		}
		if ('personal' in source) {
			p.breeder = ProfileTarget.assign((<any> source)['breeder']);
		} else {
			p.breeder = new ProfileTarget();
		}
		console.log(JSON.stringify(p, null, 4));

		return p;
	}

	constructor() {
		this.profile = new ProfileTarget();
		this.personal = new ProfileTarget();
		this.dog = new ProfileTarget();
		this.breeder = new ProfileTarget();
	}

	public profile: ProfileTarget;
	public personal: ProfileTarget;
	public dog: ProfileTarget;
	public breeder: ProfileTarget;

	public getAnswer(questionID: string): Answer | undefined {
		const id = new QuestionID(questionID);
		const target = <ProfileTarget> (<any> this)[id.target];
		if (id.topicIndex >= 0 && id.topicIndex < target.topics.length) {
			const topic = target.topics[id.topicIndex];
			if (id.questionIndex >= 0 && id.questionIndex < topic.questions.length) {
				const question = topic.questions[id.questionIndex];
				return new Answer(question.id, question.value);
			} else {
				return undefined;
			}
		} else {
			return undefined;
		}
	}

	public setAnswer(answer: Answer): void {
		if (!answer || !answer.key) { throw new Error('The answer or the answer key are required'); }
		const id = new QuestionID(answer.key);
		const target = <ProfileTarget> (<any> this)[id.target];
		if (id.topicIndex >= 0 && id.topicIndex < target.topics.length) {
			const topic = target.topics[id.topicIndex];
			if (id.questionIndex >= 0 && id.questionIndex < topic.questions.length) {
				const question = topic.questions[id.questionIndex];
				question.value = answer.value;
			} else {
				throw new Error(`Question not found in topic ${topic.caption}`);
			}
		} else {
			throw new Error(`Topic not found in Answer key ${answer.key}`);
		}
	}
}

export class ProfileTarget {

	public static assign(source: Record<string, any>): ProfileTarget {

		const t = new ProfileTarget();

		if ('target' in source) {
			t.target = (<any> source)['target'];
		} else { t.target = undefined; }

		if ('caption' in source) {
			t.caption = (<any> source)['caption'];
		} else { t.caption = ''; }

		if ('smallCaption' in source) {
			t.smallCaption = (<any> source)['smallCaption'];
		}

		if ('description' in source) {
			t.description = (<any> source)['description'];
		}

		if ('icon' in source) {
			t.icon = (<any> source)['icon'];
		}

		if ('topics' in source) {
			const a = (<any> source)['topics'];
			if (Array.isArray(a)) {
				a.forEach((value) => {t.topics.push(ProfileTopic.assign(value)); });
			}
		}


		return t;
	}

	constructor() {
		this.topics = new Array<ProfileTopic>();
	}

	public target?: ProfileTargets;
	public caption: string ='';
	public smallCaption?: string;
	public description?: string;
	public icon?: string;
	public topics: Array<ProfileTopic>;

}

export class ProfileTopic {

	static assign(source: Record<string, any>): ProfileTopic {
		const t = new ProfileTopic();

		if ('caption' in source) {
			t.caption = (<any> source)['caption'];
		} else { t.caption = ''; }

		if ('description' in source) {
			t.description = (<any> source)['description'];
		}

		if ('icon' in source) {
			t.icon = (<any> source)['icon'];
		}

		if ('questions' in source) {
			const a = (<any> source)['questions'];
			if (Array.isArray(a)) {
				a.forEach((value) => { t.questions.push(Question.assign(value)); });
			}
		}

		return t;
	}

	constructor() {
		this.questions = new Array<Question>();
	}

	public caption: string = '';
	public description?: string;
	public icon?: string;
	public questions: Array<Question>;

}

export class QuestionID {

	public constructor (value: string) {
		if (!value) { throw new Error('The ID is required'); }
		const parts = value.split('.');
		if (parts.length !== 3) { throw new Error('Invalid ID'); }

		switch (parts[0]) {
			case '1':
				this.target = 'personal';
				break;
			case '2':
				this.target = 'dog';
				break;
			case '3':
				this.target = 'breeder';
				break;
			default: throw new Error('Invalid Target Index');
		}

		this.topicIndex = parseInt(parts[1], 10);
		this.questionIndex = parseInt(parts[2], 10);
	}

	public target: string;
	public topicIndex: number;
	public questionIndex: number;
}
