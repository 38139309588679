
// -------------------------------------------------
// ib h card
// -------------------------------------------------

import { Component, ElementRef, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';

@Component({
    selector: 'ib-hcard',
    templateUrl: './ib-hcard.component.html',
    styleUrls: [ './ib-hcard.component.scss' ]
})
export class ibHCardComponent implements OnInit, OnChanges {

    private _HostClasses: string;

    @Input() imageSource: string = '';
    @Input() imageAlt: string = '';
    @Input() direction: 'left' | 'right' = 'left';
    @Output() selection = new EventEmitter<'img' | 'txt'>();

    constructor(private host: ElementRef) {
        const h: HTMLElement = this.host.nativeElement; // get additional classes on element
        this._HostClasses = h.classList.toString();
        h.className = '';
    }

    public ngOnInit() {}

    public ngOnChanges(changes: SimpleChanges) {

    }

    public onClick(target: 'img' | 'txt') {
        this.selection.emit(target)
    }

    public get hostClasses(): string {
        return this._HostClasses;
    }





}
