// Public class Notification

export enum NotificationTypes {
	Success,
	Info,
	Warning,
	Danger
}

export class ibNotification {

	public static NotificationSuccess(message: string, duration: number = 2500): ibNotification {
		const n = new ibNotification();
		n.type = NotificationTypes.Success;
		n.message = message;
		n.delay = duration;
		n.side = 'right';
		n.show();
		return n;
	}

	public static NotificationInfo(message: string, duration: number = 3500): ibNotification {
		const n = new ibNotification();
		n.type = NotificationTypes.Info;
		n.message = message;
		n.delay = duration;
		n.side = 'right';
		n.show();
		return n;
	}

	public static NotificationWarning(message: string, duration: number = 5000): ibNotification {
		const n = new ibNotification();
		n.type = NotificationTypes.Warning;
		n.message = message;
		n.delay = duration;
		n.side = 'right';
		n.show();
		return n;
	}

	public static NotificationDanger(message: string, duration: number = 5000): ibNotification {
		const n = new ibNotification();
		n.type = NotificationTypes.Danger;
		n.message = message;
		n.delay = duration;
		n.side = 'right';
		n.show();
		return n;
	}

	public static LNotificationSuccess(message: string, duration: number = 2500): ibNotification {
		const n = ibNotification.NotificationSuccess(message, duration);
		n.side = 'left';
		return n;
	}

	public static LNotificationInfo(message: string, duration: number = 3500): ibNotification {
		const n = ibNotification.NotificationInfo(message, duration);
		n.side = 'left';
		return n;
	}
	public static LNotificationWarning(message: string, duration: number = 5000): ibNotification {
		const n = ibNotification.NotificationWarning(message, duration);
		n.side = 'left';
		return n;
	}
	public static LNotificationDanger(message: string, duration: number = 5000): ibNotification {
		const n = ibNotification.NotificationDanger(message, duration);
		n.side = 'left';
		return n;
	}


	constructor(side: 'left' | 'right' = 'right') {
		this.type = NotificationTypes.Info;
		this.message = 'Notification';
		this.visible = false;
		this.delay = 3500;
		this.side = side;
	}


	public type: NotificationTypes;
	public message: string;
	public icon?: string;
	public visible: boolean;
	public delay: number;
	public side: 'left' | 'right';

	public show(duration: number = -1) {
		const d = (duration > 0) ? duration : this.delay;
		this.visible = true;
		setTimeout(() => { this.visible = false; }, d);
	}

	public typeStyle(): string {
		switch (this.type) {
			case NotificationTypes.Success: return 'success';
			case NotificationTypes.Info: return 'info';
			case NotificationTypes.Warning: return 'warning';
			case NotificationTypes.Danger: return 'danger';
		}
	}

	public typeIcon(): string {
		switch (this.type) {
			case NotificationTypes.Success: return 'fa-check';
			case NotificationTypes.Info: return 'fa-info-circle';
			case NotificationTypes.Warning: return 'fa-exclamation';
			case NotificationTypes.Danger: return 'fa-exclamation-triangle';
		}
	}

}
