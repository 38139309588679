// Drag Target directive

import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Contained } from '@common/classes/enums';
import { Point, Range, Rectangle } from '@common/classes/lib';
import { ibDragService } from '@common/services/core-services/drag.service';


@Directive({
    selector: '[ibDropTarget]'
})
export class DropTargetDirective {
    // ids of elements allowed to drop
    private sources = new Array<string>();

    constructor(private dragService: ibDragService) {
    }

    @Input('ibDropTarget')
    set accepts(value: string) {
        if (this.sources) {
            this.sources.length = 0;
        } else {
            this.sources = new Array<string>();
        }
        if (value) {
            const _sources = value.split(',');
            for (const source of _sources) {
                this.sources.push(source.trim().toLowerCase());
            }
        }
    }

    @Output() ibDrop = new EventEmitter<IDropData>();
    @Output() ibDragOver = new EventEmitter<IDropData>();

    @HostListener('dragenter', [ '$event' ])
    @HostListener('dragover', [ '$event' ])
    onDragEnter(event: DragEvent): void {
        if (event && event.currentTarget) {
            // const ct = <HTMLElement> event.currentTarget;
            // check if element is allowed
			if (this.dragService.accepts(this.sources)) {
					event.preventDefault();
            } else {
                // console.log('drag enter : Rejected');
            }
        }
    }

    @HostListener('drop', [ '$event' ])
    onDrop(event: DragEvent): void {
        if (event && event.target) {

			// Target Element
			const targetElement: HTMLElement = <HTMLElement> event.currentTarget;
			let target = <HTMLElement> event.target;
			while (target && target.parentElement && !target.hasAttribute('draggable')) {
                target = target.parentElement;
            }
			const nodes = Array.from(targetElement.childNodes);
			const targetIndex = nodes.findIndex((i: any) => i === target);
			const targetName = targetElement.getAttribute('ibDropTarget') + '';
            const isChild = targetElement.hasAttribute('ibDropChild');

            const _data = (event.dataTransfer) ? event.dataTransfer.getData('text') : '';
            const dropData: IDropData = {
				source: DropPoint.assign(JSON.parse(_data)),
				target: new DropPoint(targetName, targetIndex),
                position: new Point(event.offsetX, event.offsetY),
                isChild
            };
            const sourceIdx = (dropData.source) ? dropData.source.index : 0;
            const targetIdx = (dropData.target) ? dropData.target.index : 0;
            const moveToRight = (sourceIdx < targetIdx);

            if (!isChild) {
                const ip = this.getInsertionPoint(targetElement, dropData.position);
                console.log(ip);
                if (dropData.target) {
                    dropData.target.index = (ip.index > 0 && ip.side === 1) ? ip.index - 1 : ip.index;
                    dropData.target.contained = ip.side;
                }
            } else {
                if (dropData.target) {
                    if (event.offsetX > 0 && event.offsetX < target.clientWidth &&
						event.offsetY > 0 && event.offsetY < target.clientWidth) {
                        let offset = 0;
                        dropData.target.contained = (event.offsetX <= target.clientWidth / 2) ? Contained.leftSide : Contained.rightSide;
                        if (moveToRight) {
                            offset = (dropData.target.contained === Contained.leftSide) ? -1 : 0;
                        } else {
                            // dropData.target.index += 1;
                        }
                        dropData.target.index += offset;
                    } else {
                        dropData.target.contained = Contained.false;
                    }
                }
            }

            if (dropData.source && dropData.target &&
				(dropData.source.name === dropData.target.name ||
					dropData.source.name + 's' === dropData.target.name)) {
                dropData.range = new Range(dropData.source.index, dropData.target.index, 'range');
            }

            this.ibDrop.emit(dropData);
        }
    }

    private getInsertionPoint(element: HTMLElement, position: Point | undefined): { index: number; side: Contained; } {
        if (!position) {
            return { index: -1, side: Contained.false };
        }

        // get child elements
        let elements = Array.from(element.querySelectorAll('[ibDropChild]'));
        elements = elements.sort((a, b) => {
            return a.clientTop - b.clientTop || a.clientLeft - b.clientLeft;
        });
        // (<HTMLElement> elements[elements.length - 1]).style.width = '2000px';

        for (let i = 0; i < elements.length; i++) {
            const rect1 = Rectangle.getElementOuterRectangle(<HTMLElement> elements[i]);
            if (i < elements.length - 1) {
                if ((<HTMLElement> elements[i]).offsetTop !== (<HTMLElement> elements[i + 1]).offsetTop) {
                    rect1.fullWidth = true;
                }
            } else {
                rect1.fullWidth = true;
            }
            const isContained = rect1.contains(position);
            if (isContained !== Contained.false) {
                return { index: i, side: isContained };
            }
        }

        return { index: -1, side: Contained.false };
    }
}

export class DropData implements IDropData {

	static assign(source: object): DropData {

		if (!source) { throw new Error("Source is required"); }
		const dp = new DropData();

		if ('source' in source) {
			dp.source =  DropPoint.assign((<any>source)['source'])
		}
		if ('target' in source) {
			dp.target =  DropPoint.assign((<any>source)['target'])
		}
		if ('position' in source) {
			dp.position =  Point.assign((<any>source)['source']);
		}
		if ('isChild' in source) {
			dp.isChild = (<any>source)['isChild'] as boolean;
		}
		if ('range' in source) {
			dp.range = Range.assign((<any>source)['range']);
		}

		return dp;
	}

	source?: DropPoint;
    target?: DropPoint;
    position?: Point;
    isChild?: boolean;
    range?: Range;

}

export interface IDropData {
    source?: DropPoint;
    target?: DropPoint;
    position?: Point;
    isChild?: boolean;
    range?: Range;
}



export class DropPoint {

	public static assign(source: Record<string, any>): DropPoint {
		const dp = new DropPoint();
		if ('name' in source) { dp.name = (<any>source)['name']; } else {
			if ('source' in source) { dp.name = (<any>source)['source']; } else {
			 throw new Error("Field 'name' is required");
			}
		}
		if ('index' in source) { dp.index = parseInt((<any>source)['index']); } else { throw new Error("Field 'index' is required"); }
		if ('data' in source) { dp.data = (<any>source)['data']; }
		if ('contained' in source) { dp.contained = (<any>source)['contained']; }
		return dp;
	}

	constructor(name?: string, index?: number) {
		this.name = (name) ? name : '';
		this.index = (index) ? index : 0;
	}

	public name: string;
	public index: number;
	public data?: any;
	public contained?: Contained
}

