// ---------------------------------------------
// ib-icon
// ---------------------------------------------
// Combines an icon image with a caption
// The caption text can be long and/or short


import { BadgeConfig } from './badgeConfig';
import { Icons } from './icons';
import { Sizes } from './types';
import { ibSizes } from './ui/ib-sizes';

export type IconStores = 'general' | 'public' | 'breeder' | 'iBreeder' | 'design' | 'auth';
export type IconSizes = 'none' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'giga';
export type IconTypes = 'none' | 'solid' | 'regular' | 'light' | 'thin' | 'duotone' | 'brand';
export type IconRotation = 'none' | '90' | '180' | '270' | undefined;
export type IconAnimationKind = 'spin' | 'spin-pulse' | 'flip' | 'beat' | 'fade' | 'beat-fade';
export type IconAnimationMode = 'none' | 'self' | 'spinner';
export type TextSizes = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type CloneMode = 'all' | 'image' | 'caption';
export type IconCaptionLength = 'short' | 'long';

export const IconStoresList = new Set<string>(['general', 'public', 'breeder', 'iBreeder', 'design', 'auth']);
export const IconSizesList = new Set<string>(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'giga']);
export const IconTypesList = new Set<string>(['none', 'solid', 'regular', 'light', 'thin', 'duotone', 'brand']);

export class ibIcon {

	// path : store/iconName[/type][/size]
	public static create(path: string): ibIcon | string {

		let store: IconStores = 'general';
		let id: string = '';
		let type: IconTypes = 'regular';
		let size: IconSizes = 'md';
		const parts = path.split('/');
		parts.forEach((part: string) => {
			if (IconStoresList.has(part)) {
				store = part as IconStores;
			} else if (IconTypesList.has(part)) {
				type = part as IconTypes;
			} else if (IconSizesList.has(part)) {
				size = part as IconSizes;
			} else {
				id = part;
			}
		});

		if (id) {} else { return 'id is required'; }
		const i = ibIcon.getIcon(store, id);
		i.image.type = type;
		i.image.size = size;
		return i;



	}

	static getIcon(store: IconStores, id: string): ibIcon {
		return ibIcon.StoreIcon(IconQuery.create(store, id));
	}

	static StoreIcon(query: IIconStoreQuery): ibIcon {
		let icon: ibIcon;
		switch(query.store) {
			case 'general':
				icon = Icons.generalIcon(query.iconName);
				break;
			case 'public':
				icon = Icons.publicIcon(query.iconName);
				break;
			// case 'breeder':
			// 	return Icons.breeder(query.iconName);
			case 'iBreeder':
				icon = Icons.iBreederIcon(query.iconName);
				break;
			case 'design':
				icon = Icons.designIcon(query.iconName);
				break;
			case 'auth':
				icon = Icons.generalIcon(query.iconName);
				break;
			default:
				icon = Icons.generalIcon(query.iconName);
				break;
		}
		if (query.captionText) { icon.caption.shortText = query.captionText; }
		if (query.iconColor) { icon.image.color = query.iconColor; }
		if (query.iconType) { icon.image.type = query.iconType; }
		if (query.iconSize) { icon.image.size = query.iconSize; }
		if (query.tooltip) { icon.tooltip = query.tooltip; }
		if (query.url) { icon.url = query.url; }

		return icon;
	}

	constructor(config?: IIconConfig) {
		this.image = new IconImage();
		this.caption = new IconCaption();
		this.properties = new Map<string, string>();

		if (config) {
			if ('technology' in config) {
				this.technology = <string> (config['technology']);
			}
			if ('id' in config) {
				this.id = <string> (config['id']);
			}
			if ('iconName' in config) {
				this.image.name = <string> (config['iconName']);
			} else {
				throw new Error('the icon name is required');
			}
			if ('iconColor' in config) {
				this.image.color = <string> (config['iconColor']);
			} else {
				this.image.color = 'ib-color-navy';
			}
			if ('iconSize' in config) {
				this.image.size = <IconSizes>(config['iconSize']);
			} else {
				this.image.size = 'lg';
			}
			if ('iconType' in config) {
				this.image.type = <IconTypes>(config['iconType']);
			} else {
				this.image.type = 'regular';
			}
			if ('iconFixedWidth' in config) {
				this.image.fixedWidth = <any> (config['iconFixedWidth']);
			} else {
				this.image.fixedWidth = true;
			}
			if ('iconAnimationKind' in config) {
				this.image.animationKind = <any> (config['iconAnimationKind']);
			} else {
				this.image.animationKind = 'spin';
			}
			if ('iconAnimationMode' in config) {
				this.image.animationMode = <any> (config['iconAnimationMode']);
			} else {
				this.image.animationMode = 'none';
			}
			if ('showCaption' in config) {
				this.caption.showCaption = <any> (config['showCaption']);
			} else {
				this.caption.showCaption = true;
			}
			if ('captionText' in config) {
				this.caption.shortText = <any> (config['captionText']);
			} else {
				this.caption.shortText = undefined;
			}
			if ('captionLongText' in config) {
				this.caption.longText = <any> (config['captionLongText']);
				if (!this.caption.shortText) {this.caption.shortText = this.caption.longText; }
			} else {
				this.caption.longText = undefined;
			}
			if ('captionLongTextFrom' in config) {
				this.caption.longText = <any> (config['captionLongText']);
				if (!this.caption.shortText) {this.caption.shortText = this.caption.longText; }
			} else {
				this.caption.longTextFrom = undefined;
			}
			if ('captionColor' in config) {
				this.caption.color = <any> (config['captionColor']);
			} else {
				this.caption.color = 'ib-color-text';
			}
			if ('captionSize' in config) {
				this.caption.size = <TextSizes>(config['captionSize']);
				this.caption.showCaption = (this.caption.size !== 'none');
			} else {
				this.caption.size = this.caption.captionSize(this.image);
			}
			if ('tooltip' in config) {
				this.tooltip = <any> (config['tooltip']);
			} else {
				this.tooltip = undefined;
			}
			if ('isSelectable' in config) {
				this.isSelectable = <any> (config['isSelectable']);
			} else {
				this.isSelectable = false;
			}
			if ('isClickable' in config) {
				this.isClickable = <any> (config['isClickable']);
			} else {
				this.isClickable = true;
			}
			if ('isDisabled' in config) {
				this.isDisabled = <any> (config['isDisabled']);
			} else {
				this.isDisabled = true;
			}
			if ('isHidden' in config) {
				this.isHidden = <any> (config['isHidden']);
			} else {
				this.isHidden = true;
			}
			if ('isSelected' in config) {
				this.isSelected = <any> (config['isSelected']);
			} else {
				this.isSelected = true;
			}
			if ('requiresConfirmation' in config) {
				this.requiresConfirmation = <boolean>(config['requiresConfirmation']);
			} else {
				this.requiresConfirmation = false;
			}
			if ('data' in config) {
				this.data = <any> (config['data']);
			} else {
				this.data = undefined;
			}
			if ('badge' in config) {
				this.badge = <any> (config['badge']);
			}
			if ('url' in config) {
				this.url = <any> (config['url']);
			} else {
				this.url = undefined;
			}
		}
	}

	public technology: string = 'fontAwesome';
	public id: string = 'icon';
	public image: IconImage;
	public caption: IconCaption;
	public tooltip?: string;
	public isSelectable: boolean = false;
	public isClickable: boolean = true;
	public isDisabled: boolean = false;
	public isHidden: boolean = false;
	public isSelected: boolean = false;
	public requiresConfirmation: boolean = false;
	public badge?: BadgeConfig;
	public url?: string;
	public data?: any;
	public properties: Map<string, string>;

	// properties are not cloned!
	public clone(mode: CloneMode = 'all'): ibIcon {
		const i = new ibIcon();
		i.technology = this.technology;
		i.id = this.id;
		if (mode === 'all' || mode === 'image') {
			i.image.animationKind = this.image.animationKind;
			i.image.animationMode = this.image.animationMode;
			i.image.color = this.image.color;
			i.image.fixedWidth = this.image.fixedWidth;
			i.image.name = this.image.name;
			i.image.size = this.image.size;
			i.image.type = this.image.type;
		}
		if (mode === 'all' || mode === 'caption') {
			i.caption.showCaption = this.caption.showCaption;
			i.caption.shortText = this.caption.shortText;
			i.caption.longText = this.caption.longText;
			i.caption.longTextFrom = this.caption.longTextFrom;
			i.caption.color = this.caption.color;
			i.caption.size = this.caption.size;
		}
		i.tooltip = this.tooltip;
		i.isSelectable = this.isSelectable;
		i.isClickable = this.isClickable;
		i.isDisabled = this.isDisabled;
		i.isHidden = this.isHidden;
		i.isSelected = this.isSelected;
		i.requiresConfirmation = this.requiresConfirmation;
		i.badge = this.badge;
		i.url = this.url;
		i.data = this.data;

		return i;
	}

	public get name(): string { return this.id.replace('Icon',''); }
	public set name(value: string) { this.id = (value.includes('Icon') ? value : value + 'Icon');}

	public sizedText(length?: IconCaptionLength): string {
		switch(length) {
			case 'short': return this.caption.shortText + '';
			case 'long': return this.caption.longText + '';
			default: return this.caption.shortText + '';
		}
	}

	public fixedWidth(value: boolean = true): ibIcon {
		this.image.fixedWidth = value;
		return this;
	}

	public get imageClasses(): string { return this.image.classes(); }

}


// icon query
export class IconQuery {

	public static create(store: IconStores, iconName: string): IconQuery {
		return new IconQuery({
			store: store,
			iconName: iconName,
			id: iconName,
			tooltip: iconName
		});
	}

	constructor(config: IIconStoreQuery ) {
		this.store = config.store;
		this.iconName = config.iconName;
		this.id = (config.id) ? this.id : this.iconName;
		if (config.iconColor) this.iconColor =config.iconColor;
		if (config.iconSize) this.iconSize =config.iconSize;
		if (config.iconType) this.iconType =config.iconType;
		if (config.captionText) this.captionText = config.captionText;
		this.tooltip = (config.tooltip) ? config.tooltip : '';
		this.url = (config.url) ? config.url : '';
	}

	store: IconStores;
	iconName: string;
	id: string;
	iconColor?: string;
	iconSize?: IconSizes;
	iconType?: IconTypes;
	captionText?: string;
	tooltip?: string;
	url?: string;

}

export interface IIconConfig {
	technology?: string;
	id?: string;
	iconName: string;
	iconColor?: string;
	iconSize?: IconSizes;
	iconType?: IconTypes;
	iconFixedWidth?: boolean;
	iconAnimationKind?: IconAnimationKind;
	iconAnimationMode?: IconAnimationMode;
	captionText?: string;
	captionLongText?: string;
	captionLongTextFrom?: Sizes;
	captionColor?: string;
	captionSize?: TextSizes;
	showCaption?: boolean;
	tooltip?: string;
	isSelectable?: boolean;
	isClickable?: boolean;
	isDisabled?: boolean;
	isHidden?: boolean;
	isSelected?: boolean;
	esConfirmation?: boolean;
	badge?: BadgeConfig;
	url?: string;
	data?: any;
}

export interface IIconStoreQuery {
	store: IconStores;
	iconName: string;
	id?: string;
	iconColor?: string;
	iconSize?: IconSizes;
	iconType?: IconTypes;
	captionText?: string;
	tooltip?: string;
	url?: string;
}

class IconImage {

	public name: string = 'fa-paw';
	public color: string = 'ib-color-navy';
	public size: IconSizes = 'lg';
	public type: IconTypes = 'regular';
	public fixedWidth: boolean = false;
	public rotation: IconRotation = undefined
	public animationKind: IconAnimationKind = 'spin';
	public animationMode: IconAnimationMode = 'none';

	public classes(animationMode: IconAnimationMode = 'none'): string {
		let prefix = this.prefix;
		let value = (this.name) ? ' ' + this.name : ' fa-paw';
		const color = (this.color) ? ' ' + this.color : '';
		const size = (this.size) ? ' ' + this.size : '';
		const fixed = (this.fixedWidth) ? ' fa-fw' : '';
		let rotation = '';
		switch(this.rotation) {
			case '90':
				rotation = ' fa-rotate-90';
				break;
			case '180':
				rotation = ' fa-rotate-180';
				break;
			case '270':
				rotation = ' fa-rotate-90';
				break;
			default:
				rotation = '';
		}
		if (this.animationMode === 'spinner') { value = ' fa-spinner'}
		const anim = (this.animationMode === 'none') ? '' : ` fa-${this.animationKind}`
		const res = prefix + value + color + size + fixed + rotation + anim;
		return res;
	}

	private get prefix(): string {
		switch (this.type) {
			case 'solid': return 'fa-solid';
			case 'regular': return 'fa-regular';
			case 'light': return 'fa-light';
			case 'thin': return 'fa-thin'
			case 'duotone': return 'fa-duotone'
			default: return 'fa-regular';
		}
	}
}
class IconCaption {

	// the icon can display a short or long caption according to display size and a threshold that can be set on a per icon basis
	// the threshold displays short text up to and including its size
	// the icon caption is shown if: show caption is true and text has a value

	private _showCaption: boolean = true;
	private _shortText?: string;
	private _longText?: string;
	private _longTextFrom?: Sizes;
	private _color: string = 'ib-color-text';
	private _size: TextSizes = 'md';


	public get showCaption(): boolean {	return this._showCaption; }
	public set showCaption(value: boolean) { this._showCaption = value; }

	// displays short or long text
	public get text(): string | undefined {
		if (!this._longTextFrom) {
			return (this._shortText) ? this._shortText : undefined;
		} else {
			const comparison = ibSizes.compare(ibSizes.currentSize, this._longTextFrom);
			if (comparison === 'longer' && this._longText) {
				return this._longText;
			} else {
				return (this._shortText) ? this._shortText : undefined;
			}
		}
	 }

	// accepts separated values for short and long: /
	public set text(value: string | undefined) {

		if (value) {
			const parts = (value) ? value.split('/') : [];
			if (parts.length < 1) {
				this.shortText = '';
				this.longText = '';
			} else {
				this.shortText = parts[0];
				this.longText = (parts.length > 1) ? parts[1] : undefined;
			}
		}

    	// const pattern=/(\b\w+\b)/gm
		// if (value) {
		// 	const matches = value.match(pattern);
		// 	console.log(matches);
		// 	if (!matches) {
		// 		this.shortText = '';
		// 		this.longText = '';
		// 	} else {
		// 		this.shortText = matches.shift();
		// 		this.longText = matches.join(' ');
		// 	}
		// }
	}

	public setText(short: string, long?: string, longTextFrom?: Sizes): void {
		this._shortText = short;
		this._longText = long;
		if (long && !this._longTextFrom && !longTextFrom) { this._longTextFrom = 'lg'; }
		this._longTextFrom = longTextFrom
	}

	public get shortText(): string | undefined {
		return this._shortText;
	}
	public set shortText(value: string | undefined)  {
		this._shortText = value;
	}

	public get longText(): string | undefined {
		return (this._longText) ? this._longText : this._shortText;
	}
	public set longText(value: string | undefined)  { this._longText = value; }

	public get longTextFrom(): Sizes | undefined { return this._longTextFrom; }
	public set longTextFrom(value: Sizes | undefined)  { this._longTextFrom = value; }

	public get color(): string { return this._color; }
	public set color(value: string)  { this._color = value; }

	public get size(): TextSizes { return this._size; }
	public set size(value: TextSizes)  {
		if (value !== 'none') {
			this._showCaption = true;
			this._size = value;
		} else {
			this._showCaption = false;
			this._size = 'md';
		}
	}

	// get the caption size for a given icon image size
	public captionSize(icon: IconImage): TextSizes {
		if (!icon) { return 'md'; }
		switch (icon.size) {
			case 'xs': 	return 'xs';
			case 'sm': 	return 'sm';
			case 'md': 	return 'sm';
			case 'lg': 	return 'md';
			case 'xl': 	return 'lg';
			case 'xxl': return 'xl';
			case 'xxxl':return 'xl';
			case 'giga':return 'xl';
			default: 	return 'md';
		}
	}

	public classes(): string {
		return `${this._color} text-${this._size}`;
	}

}

