
// -------------------------------------------------
// ib icon
// -------------------------------------------------
// usage takes an ibIcon or icon descriptor (obsolete)
// if the icon descriptor canSpin is true the icon is responsive to progressService changes
// an will show a spinner

import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, Self, SimpleChanges, SkipSelf, ViewChild } from '@angular/core';
import { ibIcon } from '@common/classes/icon';
import { ibNotification } from '@common/classes/notification';
import { ibCoreService } from '@common/services/core-services/core.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
    selector: 'ib-icon',
    templateUrl: './ib-icon.component.html',
    styleUrls: [ './ib-icon.component.scss' ],
	providers: [ TooltipDirective ]
})
export class ibIconComponent implements OnInit, OnChanges {
    private _icon: ibIcon;

	public get icon(): ibIcon {
        return this._icon;
    }

	/* eslint-disable */
    @Input('icon') set newIcon(value: ibIcon) {
        this._icon = value;
        this._captionClasses = (this._icon && this._icon.caption) ? this._icon.caption.classes() : '';
        this._iconClasses = (this._icon && this._icon.image) ? this._icon.image.classes() : '';
		this._originalColor = (this._icon && this._icon.image) ? this._icon.image.color : 'ib-color-icon';

        if (this._icon) {
            if (this._icon.isHidden) {
                this._isHidden = true;
                this._isDisabled = false;
            } else {
                this._isDisabled = (this._icon.isDisabled);
                if (this._isDisabled) { this._icon.isClickable = false; }
                this._isSelected = (this._icon.isSelectable && this._icon.isSelected);
            }
        }
    }
	/* eslint-enable */
    @Input() color: string = '';
    @Input() hoverColor: string = '';
	@Input() hovering: boolean = false;
	@Output() confirmed = new EventEmitter<boolean>();

	@ViewChild(TooltipDirective) public kTooltip: TooltipDirective;
	@ViewChild('iconContainer') public iconContainer: ElementRef;
	@ViewChild('anchor') public anchor: ElementRef;

    @HostBinding('class.disabled') _isDisabled: boolean = false;
    @HostBinding('class.active') _isSelected: boolean = false;
    @HostBinding('class.hidden') _isHidden: boolean = false;

    private _captionClasses: string = '';
    private _iconClasses: string = '';
	private _clickCount: number = 0;
	private _originalColor: string;

    constructor(private coreService: ibCoreService) {
    }

    ngOnInit(): void {
        this.coreService.progress.inProgress$.subscribe((request) => {
            if (request && this._icon && this._icon.image) {
				const mode = (request.active) ? this._icon.image.animationMode : 'none';
                this._iconClasses = this._icon.image.classes(mode);
            }
        });
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
		// should move icon property to change event
        if ( 'color' in simpleChanges) {
            const color = simpleChanges['color'].currentValue;
            if (color) {
				this.setIconColor(color);
                // this._icon.image.color =  color;
                // this._iconClasses = (this._icon && this._icon.image) ? this._icon.image.classes() : '';
            }
        }

		if ( 'hovering' in simpleChanges) {
			if (this.hovering && this.hoverColor) {
				this.setIconColor(this.hoverColor);
			} else {
				this.setIconColor(this.color);
			}
		}
    }

	classes(): string {
		let style = '';
		if (this.icon) {
			if (this.icon.isClickable) { style = 'clickable'}
			if (this._clickCount > 0) { style += (this.icon.isClickable) ? ' pending' : 'pending'; }
		}
		return style;
	}

	iconClasses(): string {
        if (this._iconClasses) {
            return this._iconClasses;
        } else {
            return 'fas fa-paw ib-color-navy lg';
        }
    }

    captionClasses(): string {
        if (this._captionClasses) {
            return this._captionClasses;
        } else {
            return '';
        }
    }

    showCaption(): boolean {
		if (this.icon && this.icon.caption && this.icon.caption.showCaption) {
            return (this._icon.caption.text !== undefined && this._icon.caption.text.length > 0);
        }
        return false;
    }

    public get tooltip(): string {
		if (this.icon) {
			if (this._clickCount < 1) {
				return (this._icon.tooltip) ? this._icon.tooltip : '';
			} else {
				return (this._icon.tooltip) ? "Click to Confirm '" + this._icon.tooltip + "'" : 'Click to Confirm';
			}
		} else {
			return '';
		}
    }

	public onClick(e: MouseEvent) {
		if (!this.icon) { return; }
		if (!this.icon.requiresConfirmation) {
			this._clickCount = 0;
		} else {
			if (this._clickCount === 0) {
				// initial click
				e.stopPropagation();
				e.preventDefault();
				this._clickCount++;
				this._icon.image.color = 'ib-color-red';
				this.showTooltip(true);
				this.coreService.setNotification(ibNotification.NotificationInfo(this.tooltip, 2500));
				this._iconClasses = (this._icon && this._icon.image) ? this._icon.image.classes() : '';
				setTimeout(() => { this.reset() }, 2500);
			} else if (this._clickCount === 1) {
				this.reset();
				this.confirmed.emit(true);
				const event = new CustomEvent('click', { detail: this.icon });
				(<HTMLElement>this.iconContainer.nativeElement).dispatchEvent(event);
			}
		}
	}

	private reset() {
		this.showTooltip(false);
		this._clickCount = 0;
		this.setIconColor(this._originalColor);
		// this._icon.image.color = this._originalColor;
		// this._iconClasses = (this._icon && this._icon.image) ? this._icon.image.classes() : '';
	}

	private showOn() {
		return (this._clickCount === 0 && this.icon.requiresConfirmation) ? 'hover' : 'click';
	}

	private showTooltip(state: boolean = false) {
		if (this.kTooltip) {
			if (state) {
				this.kTooltip.show(this.anchor);
			} else {
				this.kTooltip.hide();
			}
		}
	}

	private setIconColor(color: string):void {
		this._icon.image.color =  color;
		this._iconClasses = (this._icon && this._icon.image) ? this._icon.image.classes() : '';
	}
}
