// // -------------------------------------------------------
// // db Util
// // -------------------------------------------------------

import { DocumentTypes } from '../types';
import { environment } from 'environments/environment';
import { NameValue } from '@common/tools';
import { ibDocument } from '@common/entities/documents/ib-document';
import { DocumentFactory } from '../documentFactory';
import { MultiSelectTreeHierarchyBindingDirective } from '@progress/kendo-angular-dropdowns';
import { Validate } from './validate';



// these classes are mirrored between the api.iBreeder.com project and the dev.iBreeder.com project
// On app write the classes the other reads them as appropriate

// database and container are specified in the environment

// // export class DBChange {
// //     public static put(doc: ibDocument): DBChange {
// //         return new DBChange('put', doc);
// //     }

// //     public static delete(doc: ibDocument): DBChange {
// //         return new DBChange('delete', doc);
// //     }

// //     constructor(change: DBChangeType, doc: ibDocument) {
// //         this.changeType = change;
// //         this.dType = doc.DType;
// //         this.document = doc;
// //         this.timeStamp = Date.now();
// //     }

// //     changeType: DBChangeType;
// //     dType: DocumentTypes;
// //     document: ibDocument;
// //     timeStamp: number;
// // }


 export class DBRequest {

    public databaseName: string;
    public containerName: string;
    public partitionKey?: string;

    constructor(DType?: DocumentTypes) {
            this.databaseName =environment.apiDatabaseName;
            this.containerName = environment.apiContainerName;
            this.partitionKey = (DType) ? DType.trim() : undefined;
    }

    toJson(): string {
        const val = JSON.stringify(this);
        return val;
    }

    get isQuery(): boolean { return ('query' in this) ? true : false; }
}

// used to request a single database document by id (guid)
export class ItemRequest extends DBRequest {

    constructor(id: string, DType?: DocumentTypes) {
        super(DType);
        this.id = id;
    }

    public id: string;
}

// used to request an array of documents using a parameterized query
export class QueryRequest extends DBRequest{

	public static create(query: string, ...params: Array<string>): QueryRequest {

		let match: RegExpExecArray | null;
		// extract DType
		const patternDType = /c.DType\s*=\s*'([a-zA-Z]{8,})'/g;
		match = patternDType.exec(query);
		if (!match || match.length < 2) {
			throw new Error('Create query request: Query does not include DType WHERE clause');
		}
		const DType = match[1];
		if (!Validate.isDocumentType(DType)) {
			throw new Error('Create query request: Query DType is not a valid DocumentType');
		}
		const q = new QueryRequest(DType as DocumentTypes);
		q.query = query;
		
		// extract the parameters
		const pattern = /@\w+/gi;
		while ((match = pattern.exec(q.query)) != null) {
			if (match && match.length > 0) {
				q.parameters.push(NameValue.createUnformatted(match[0], params.shift()));
			}
		}
		return q;
	}

    constructor(DType: DocumentTypes) {
        super(DType);
        this.parameters = new Array<NameValue>();
    }

    public query: string = '';
    public parameters: Array<NameValue>;
}

// used to write (put or upsert) or delete a document in the database
export class putRequest extends DBRequest{
    constructor(DType: DocumentTypes) {
        super(DType);
    }

    public document?: ibDocument;
}

export class dbResult {

	private _message?: string;
    private _value?: ibDocument;
    private _values : Array<ibDocument | unknown>;

    static assign(source: object): dbResult {
        const r = new dbResult();

        if ('message' in source) { r._message = (<any>source)['message'] as string; }
        if ('value' in source) { r._value = DocumentFactory.create((<any>source)['value'] as Record<string, unknown>); }
        if ('values' in source) {
            const a = (<any>source)['values'];
            if ( Array.isArray(a) && a.length > 0) {
				if (a[0] instanceof ibDocument) {
	                a.forEach(( value: Record<string, unknown>) => {
						r._values.push(DocumentFactory.create(value));
					});
				} else {
					a.forEach((value: unknown) => r._values.push(value));
				}
            }
        }

        return r;
    }

	public static faulted(message: string): dbResult {
		const res = new dbResult();
		res._message = message;
        res._value = undefined;
        res._values = new Array<ibDocument>();
		return res;
	}

	public static success(value: object): dbResult {
        const res = new dbResult();
        if (!value) { return dbResult.faulted('dbResult success error: value is missing'); }
		try {
			const result = dbResult.assign(value);
			if (result.values && result.values.length > 0) {
				result.values.forEach((val: unknown) => {
					if (val && typeof val === 'object') {
						const item = dbResult.assign(val);
						res._values.push(DocumentFactory.create(val));
					}
				});
			} else if (result.value) {
				res._value = DocumentFactory.create(result.value);
			} else { res._message = 'No values'}
		} catch (err) {
			res._message = 'dbResult success error: ' + err;
		}

		return res;
	}

	private constructor() {
		this._message = undefined;
        this._value = undefined;
        this._values = new Array<ibDocument | unknown>();
	}

	public get message(): string | undefined { return this._message; }
    public get value(): ibDocument | undefined { return this._value; }
    public get values(): Array<ibDocument | unknown> { return this._values; }

	public get isSuccess(): boolean {
		return (!this._message) ? true : false;
	}
}

// export class DocumentFactory {

//     public static  create(source: Record<string, unknown> | ibDocument): ibDocument {

//         if (!source) {
//             throw new Error('Document Factory Error: Required source is missing');
//         }
//         if ('DType' in source) {
//             const DType = source['DType'] as DocumentTypes;
//             if (!DType) { throw new Error('Document Factory Error: Required property [DType] is empty ');}
//             switch (DType) {
//                 case 'BreedDocument':
//                     return BreedDocument.assign(source);
//                 case 'BreederDocument':
//                     return BreederDocument.assign(source);
//                 case 'DogDocument':
//                     return DogDocument.assign(source);
//                 // case 'HealthIssueDocument':
//                 //     return HealthIssueDocument.
//                 case 'ImageDocument':
//                     return ImageMetaDocument.assign(source);
//                 // case 'ListItemDocument':
//                 //     return List
//                 case 'LitterDocument':
//                     return LitterDocument.assign(source);
//                 // case 'MessageDocument':
//                 //     return Message
//                 // case 'NoteDocument':
//                 //     return Note
//                 // case 'PageDocument':
//                 //     return P
//                 case 'PersonDocument':
//                     return PersonDocument.assign(source);
//                 case 'VisitorDataDocument':
//                     return visitorDataDocument.assign(source);
//                 default:
//                     throw new Error('Document Factory Error: Required property [DType] is invalid ');
//             }
//         } else {
//             throw new Error('Document Factory Error: Required property [DType] not found in source');
//         }
//     }
// }




// // export class IdentityRequest extends DBRequest{

// //     collectionName: string = 'main';

// //     constructor(action: IdentityActions) {
// //         super("UserDocument");

// //         this.action = action;
// //     }

// //     public action: IdentityActions;
// //     public credentials: Credentials = new Credentials();
// // }

// // the database response to a query or get
// export class dbResponse<T> {


//     constructor(source?: object) {

//         if (source?.hasOwnProperty("_rid")) {
//             this._rid = (<any>source)['_rid'];
//         }

//         if (source?.hasOwnProperty("_count")) {
//             this.count = parseInt((<any>source)['_count']);
//         }

//         this.documents = new Array<T>();
//         if (source?.hasOwnProperty("Documents")) {
//             const docs = (<any>source)['Documents'];
//             if (docs && Array.isArray(docs)) {
//                 for(const item of docs) {
//                     if (DatabaseID.isID(item)) {
//                         this.id = item.id;
//                     } else {
//                         // const tDoc = <T><unknown>ibDBService.assign(item);
//                         // this.documents.push(tDoc);
//                     }
//                 }
//             }
//         }
//     }

//     public _rid?: string;
//     public count: number = 0;
//     public documents: Array<T>;
//     public id: string = '';

// }

// export class DatabaseID {

//     public static isID(source: object): boolean {
//         if (!source) { return false; }
//         if (Object.keys(source).length !== 1) { return false; }
//         return ("id" in source);
//     }

//     // Object is db if it has 1 property id
//     public static assign(source: object): DatabaseID | null {
//         if (!source) { return null;}
//         if (Object.keys(source).length !== 1) { return null; }
//         if ("id" in source) {
//             const dbID = new DatabaseID();
//             dbID.id = (<any>source)['id'];
//             return dbID;
//         } else {
//             return null;
//         }
//     }

//     public id: string = '';
// }

