

	export enum AccountPlans {
		FreeAccount = 0,
		HobbyAccount = 10,
		ProAccount = 20
	}

	export enum AccountStatuses {
		Unknown = 0,
		Lead = 1,                                        // We just know of this account owner - potential prospect
		Prospect = 2,                                    // account owner has been contacted and is potential client
		Active = 10,                                     // active client
		Dormant = 11,                                    // no activity in account for set amount of time
		Closed = 12,                                     // owner closed the account
		Suspended = 13,                                  // account is on hold - no activity allowed -check reason
		Canceled = 14,                                   // we canceled the account - all account info archived
		Imported = 200,                                  // account imported from list - not processed
		Registering= 252,                                // set during the registration process
		Test = 253,                                      //
		Reserved = 255                                   // account number is reserved and in use during account creation
	}

	export enum AddressStatuses {
		Active = 0,
		Inactive = 1,
		OldAddress = 2
	}

	export enum BlobContainers {
		main,
		media
	}

	export enum BreederRating {
		notRecommended = -1,
		notRated = 0,
		good = 1,
		great = 2,
		excellent = 3
	}

	export enum BreederCommitmentLevel {
		noCommitment = 0,
		minimal = 1,
		good = 2,
		excellent = 3
	}

	export enum Contained {
		false,
		leftSide,
		rightSide
	}

	export enum MailFormats {
		Text = 0,
		HTML = 1
	}

	export enum Extensions {
		jpg,
		png,
		pdf,
		doc
	}

	// export enum Genders {
	// 	Male = 0,
	// 	Female = 1
	// }

	// export enum GendersEx {
	// 	Male = 0,
	// 	Female = 1,
	// 	Both = 3
	// }

	// will be replaced with MediaSizes
	export enum ImageSizes {
		thumbnail = 0,
		extraSmall = 1,
		small = 2,
		medium = 3,
		large = 4,
		extraLarge = 5,
		vignette = 8,
		master = 9
	}

	export enum ImageStatus {
		Unknown = 0,
		valid = 1,
		new_pending = 2,
		change_pending = 3,
		del_pending = 4,
		check_pending = 5,
		invalid = 10
	}

	export enum LitterStatuses {
		Created = 0,
		New = 1,
		Pending = 2,
		Active = 3,
		Inactive = 4,
		External = 5
	}

	export enum Media {
		images = 0,
		video = 1,
		audio = 3,
		pdf = 4,
		doc = 5,
		any = 10

	}

	export enum Orientations {
		either = 0,
		landscape = 1,
		portrait = 2
	}

	// export enum PersonTypes {
	// 	prospect = 0,
	// 	client = 1,
	// 	breeder = 2,
	// 	team = 3,
	// 	other = 9
	// }

	export enum PhoneTypes {
		Any = 0,
		LandLine = 1,
		Mobile = 2,
		Fax = 3,
		Skype = 4,
		Other = 99
	}

	export enum RegistriesEnum {
		AKC = 1,
		FCI = 99,
		ANY = 255
   }

	export enum RegistrationTypes {
		Dog = 0,
		Litter = 1,
		KennelName = 2
	}

	// obsolete
	// export enum SearchModes {
	// 	none = 0,
	// 	byAccountID = 1,
	// 	byName = 10,
	// 	byPhone = 20,
	// 	byMail = 21,
	// 	byProximity = 30,
	// 	byField = 40
	// }

	// obsolete
	// export enum SearchStates {
	// 	empty,
	// 	partial,
	// 	complete
	// }

	// obsolete
	// export enum SearchTargets {
	// 	none = 0,
	// 	breeds = 1,
	// 	breeders = 2,
	// 	litters = 3,
	// 	dogs = 4,
	// 	puppies = 5,
	// 	clients = 6
	// }

	export enum Separators {
		space,
		dash,
		rightSlash,
		leftSlash,
		braces,
		brackets,
		parenthesis,
		angleBrackets,
		squareBrackets
	}

	// General Status flag for iBreeder Entities
	// implemented as type see types
	// export enum Statuses {
	// 	Unknown = 0,
	// 	Draft = 5,
	// 	Incomplete = 10,
	// 	Complete = 11,
	// 	Checked = 20,
	// 	Published = 100
	// }

	// export enum TextSizes_obsolete {
	// 	xs,
	// 	sm,
	// 	md,
	// 	lg,
	// 	xl,
	// 	xxl,
	// 	xxxl,
	// 	none
	// }
