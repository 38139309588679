// -----------------------------------------------------------
// Search Selection
// -----------------------------------------------------------

import { SearchTargets } from "@common/classes/search";
import { BreedName } from "@common/entities/parts/breed-name";


export class SearchSelection {

	private _breeds: Array<BreedName> | 'all';
	private _targets: Array<SearchTargets>;
	private _caption: string;

	constructor() {
		this._breeds = 'all';
		this._caption = 'All breeds, adult and puppies';
	}

	public get caption(): string { return this._caption; }

	public setBreeds(breeds: Array<BreedName> | 'all') {
		this._breeds = breeds;
		this.buildCaption();
	}


	private buildCaption() {
		this._caption = 'built caption';
	}

}
