<div #titleBar id='title-bar' kendoTooltip>
	<ib-icon id="home-icon"
			 [icon]="homeIcon"
			 class="clickable"
			 (click)="onHomeClick()">
	</ib-icon>

	<ib-icon id="back-icon"
			 *ngIf="type === 'back'"
			 [icon]="backIcon"
			 class="clickable"
			 (click)="onClick($event)">
	</ib-icon>

	<i 	id="title-icon"
		*ngIf="icon"
		[ngClass]='iconClasses()'
		(click)='onIconClick()'
		[title] = 'titleTooltip()'>
	</i>

	<div id="content">
		<ng-content></ng-content>
	</div>

	<ib-toolbar [items]="toolbarItems"
				(clicked)="onToolBarClicked($event)">
	</ib-toolbar>

	<ib-icon id="close-icon"
			 *ngIf="type === 'close'"
			 [icon]="closeIcon"
			 class="clickable"
			 (click)="onClick($event)">
	</ib-icon>

</div>
<div *ngIf="showNotification()" class="notification {{notification.typeStyle()}}" (click)="onNotificationClick()">
	<div><i class="fas {{notification.typeIcon()}} icon-lg"></i><span>{{notification.message}}</span></div>
</div>

