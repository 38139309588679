import { Component, OnInit } from '@angular/core';
import { ibCoreService, ResizeData } from '@common/services/core-services/core.service';

@Component({
  selector: 'ib-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss']
})
export class ibPublicFooterComponent implements OnInit {

    public size: string = 'md';

    constructor( private coreService: ibCoreService ) {  }

    ngOnInit() {
        this.coreService.resizeInfo$?.subscribe((info: ResizeData) => {
            if (info) { this.size = info.size; }
        });
  }

}
