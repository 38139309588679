import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ibError } from '@common/classes/ib-error';
import { ibIcon } from '@common/classes/icon';
import { IconList } from '@common/classes/iconList';
import { Icons } from '@common/classes/icons';
import { ibIdentityService } from '@common/identity/identity.service';
import { ibCoreService } from '@common/services/core-services/core.service';
import { Tools } from '@common/tools';


@Component({
  selector: 'ib-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class ibMainMenuComponent implements OnInit, AfterContentChecked {

	public titleIcon: ibIcon;
	public authenticated: boolean = false;

	public menuItemsLeft: IconList;
	public menuItemsRight: IconList;
	public menuItemsBottom: IconList;

	constructor(private coreService: ibCoreService,
				private identityService: ibIdentityService,
				private router: Router,
				private cdr: ChangeDetectorRef) {

		this.menuItemsLeft = new IconList('xxxl');
		this.menuItemsRight = new IconList('xxxl');
		this.menuItemsBottom = new IconList('xl');

		this.titleIcon = Icons.generalIcon('menu', 'solid', 'xl');

		this.createIcon(this.menuItemsLeft, 'general', 'home', 'home', 'Return to the home page');
		this.createIcon(this.menuItemsLeft, 'public', 'myDogs', 'my dogs', 'Manage your favorite dogs');
		this.createIcon(this.menuItemsLeft, 'general', 'messages', 'my messages', 'Manage your messages');
		this.createIcon(this.menuItemsLeft, 'public', 'myAccount', 'my account', 'Navigate to your account');
		this.createIcon(this.menuItemsLeft, 'public', 'breed', 'dog breeds', 'Explore a full array of dog breeds');
		this.createIcon(this.menuItemsLeft, 'public','breeder', 'dog breeders', 'Explore breeders across breeds and places');
		this.createIcon(this.menuItemsLeft, 'public', 'dog', 'dogs & puppies', 'Explore available dogs and puppies with your precise criteria');

		this.createIcon(this.menuItemsRight, 'public', 'mission', 'our mission', 'The iBreeder mission statement');
		this.createIcon(this.menuItemsRight, 'public', 'service', 'our service', 'What iBreeder actually do!');
		this.createIcon(this.menuItemsRight, 'public', 'breeding', 'responsible breeding', 'What constitutes \'responsible breeding\'');
		this.createIcon(this.menuItemsRight, 'public', 'commitment', 'breeder commitment', 'Service commitments you should expect from your breeder');
		this.createIcon(this.menuItemsRight, 'public', 'whyPurebreds', 'why purebreds', 'Why are purebred special');
		this.createIcon(this.menuItemsRight, 'public', 'tracking', 'tracking', 'Find the perfect dog or puppy for your family');
		this.createIcon(this.menuItemsRight, 'public','checkList', 'your check list', 'A suggested check list of important steps');
		this.createIcon(this.menuItemsRight, 'public', 'breederSpace', 'present your kennel', 'For breeders: present your kennel and market your dogs and puppies');

		this.createIcon(this.menuItemsBottom, 'general', 'play', 'Get Started', 'A quick walk through to help you discover iBreeder');
		this.createIcon(this.menuItemsBottom, 'public', 'aboutUs', 'about iBreeder', '');
		this.createIcon(this.menuItemsBottom, 'public', 'contactUs', 'contact us', '');
		this.createIcon(this.menuItemsBottom, 'general', 'help', 'help', '');
		this.createIcon(this.menuItemsBottom, 'general', 'support', 'support', '');
		this.createIcon(this.menuItemsBottom, 'auth', 'signOut', 'sign out', '');
		this.createIcon(this.menuItemsBottom, 'auth', 'signIn', 'sign In', '');

	}

	ngOnInit() {
		this.identityService.isAuthenticated$.subscribe((state: boolean) => {
			this.authenticated = state;
		});
	}

	ngAfterContentChecked() {
		this.cdr.detectChanges();
	  }


	show(e: string): boolean {
		const roles = e.match(/(guest)|(visitor)|(breeder)|(iBreeder)|(all)(?:,? *)/g);
		if (roles) {
			return this.identityService.isInRole(roles);
		} else {
			return false;
		}
	}

	showBlock(id: string): string {
		const el = document.getElementById(id);
		return (el && el.children.length > 0) ? 'block' : 'hide';
	}

	onMenuClick(e: ibIcon | string): void {

		let url: string | null = null;
		const id: string = (e instanceof ibIcon) ? e.name : e;

		switch (id) {
			case 'aboutUs':
				url = 'Main/AboutUs';
				break;
			case 'breed':
				url = 'Main/Breeds';
				break;
			case 'breeder':
				url = 'Main/Breeders';
				break;
			case 'breederSpace':
				url = '/Main/BreederSpace/Welcome';
				break;
			case 'breeding':
				url = '/Main/ResponsibleBreeding';
				break;
			case 'checkList':
				url = 'Main/CheckList';
				break;
			case 'commitment':
				url = 'Main/BreederCommitment';
				break;
			case 'contactUs':
				url = 'Main/ContactUs';
				break;
			case 'dog':
				url = 'Main/Dogs';
				break;
			case 'help':
				url = 'Main/Help';
				break;
			case 'home':
				url = '/Home';
				break;
			case 'messages':
				if (this.identityService.isInRole(['visitor'])) {
					url= 'Comms/Messages'
				} else if (this.identityService.isInRole(['breeder'])) {
					url = 'Comms/Messages';
				} else {
					url = '';
				}
				break;
			case 'mission':
				url = '/Main/OurMission';
				break;
			case 'myAccount':

				if (this.identityService.isInRole(['visitor'])) {
					url = 'Favorites/Home';
				}
				// get user from service
				// const user =  this.identityService.currentUser;
				// if (!user || !user.isValid) {
				// 	url = 'Register/Unregistered';
				// } else {
				// 	if (user && user.isInRole(new Role(RoleEnum.iBreederUser))) {
				// 		url = 'iBreeder/Account';
				// 	}  else
				// 	if (user.isInRole(new Role(RoleEnum.iBreederAdmin))) {
				// 		url = 'iBreeder/Account';
				// 	}  else
				// 	if (user.isInRole(new Role(RoleEnum.iBreederOwner))) {
				// 		url = 'iBreeder/Account';
				// 	}  else
				// 	if (user.isInRole(new Role(RoleEnum.BreederUser))) {
				// 		url = 'Breeder/Admin/Home';
				// 	} else
				// 	if (user.isInRole(new Role(RoleEnum.BreederAdmin))) {
				// 		url = 'Breeder/Admin/Home';
				// 	} else
				// 	if (user.isInRole(new Role(RoleEnum.BreederOwner))) {
				// 		url = 'Breeder/Admin/Home';
				// 	} else
				// 	if (user.isInRole(new Role(RoleEnum.Visitor))) {
				// 		url = 'Main/MyAccount';
				// 	} else {
				// 		url = '';
				// 	}
				// }
				break;
			case 'myDogs':
				if (this.identityService.isInRole(['visitor'])) {
					url = 'Favorites/MyDogs'
				} else if (this.identityService.isInRole(['breeder'])) {
					url = 'Breeder/MyDogs';
				} else {
					url = '';
				}
				break;
			case 'play':
					url = '/Main/GetStarted';
					break;
			case 'service':
				if (this.identityService.isInRole(['guest', 'visitor'])) {
					url = 'Favorites/Services'
				} else {
					url = '/Main/OurService';
				}
				break;
			case 'signIn':
				url = '/Identity/SignIn';
				break;
			case 'signOut':
				this.identityService.signOut();
				url = 'Home';
				break;
			case 'support':
				url = 'Main/Support';
				break;
			case 'tracking':
				url = 'Main/TrackingTips';
				break;
			case 'trust':
				url = 'Main/Trust';
				break;
			case 'whyPurebreds':
				url = 'Main/Info/WhyPurebreds';
				break;
			default:
				this.coreService.showError(ibError.create(`Cannot navigate to ${e}`, `${e} was not found`));
				return;
		}

		if (url) {
			this.coreService.startProgress();
			this.router.navigate([url]).catch((reason) => {
				this.coreService.showError(ibError.create(`Cannot navigate to ${url}`, reason));
			}).finally(() => {
				Tools.sleep(100).then(() => { this.coreService.endProgress() });
			});
		}
	}

	showGetStarted() {
		return true;
	}

	public getIcon(items: IconList, name: string): ibIcon {
		if (items.has(name)) {
			return items.get(name);
		} else {
			return Icons.generalIcon('bug', 'solid', 'lg');
		}
	}

	private createIcon(items: IconList, repo: string, name: string, caption?: string, tooltip?: string): ibIcon {
		let _icon: ibIcon = new ibIcon();
		switch(repo) {
			case 'general':
				_icon = Icons.generalIcon(name, 'solid');
				break;
			case 'public':
				_icon = Icons.publicIcon(name, 'solid');
				break;
			case 'auth':
				_icon = Icons.authIcon(name, 'solid');
				break;
			case 'design':
				_icon = Icons.designIcon(name, 'solid');
				break;
			default:
				throw new Error(`unknown icon store: ${repo}`);
		}
		if (caption) { _icon.caption.text = caption; }
		if (tooltip) { _icon.tooltip =  tooltip}
		items.add(_icon);
		return _icon;
	}


}
